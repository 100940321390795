import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Order, SignOut } from '@api/generated-types';
import { AUTH_TOKEN_KEY } from '@core/apollo-client-provider';
import { UniversalCookieConsentViewState } from '@core/cookie-consent/models/universal-cookie-consent-view-state.model';
import { UniversalCookieConsentService } from '@core/cookie-consent/services/universal-cookie-consent.service';
import { DataService } from '@core/providers/data/data.service';
import { UserService } from '@core/providers/data/user.service';
import { GoogleAnalyticsService } from '@core/providers/google-analytics.service';
import { GoogleTagManagerService } from '@core/providers/google-tag-manager.service';
import { I18nService } from '@core/providers/i18n.service';
import { ResponsiveService } from '@core/providers/responsive.service';
import { ScrollPositionService } from '@core/providers/scrollPosition/scroll-position-service';
import { LayoutService } from '@core/providers/state/layout.service';
import { StateService } from '@core/providers/state/state.service';
import { WebViewService } from '@core/providers/webview/web-view.service';
import { SeoService } from '@core/seo/seo.service';
import { Logger } from '@core/utils/logger';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { isCrawlerUserAgent } from 'is-web-crawler';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, skipWhile, switchMap } from 'rxjs/operators';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Zoom } from 'swiper';
import { SIGN_OUT } from './account/components/account/account.graphql';
import { FeedbackWizardComponent } from './account/modules/feedback-wizard/feedback-wizard.component';
import { TalkjsService } from '@core/providers/talkjs.service';
import { UnreadConversation } from 'talkjs/types/talk.types';

const log = new Logger('App');

@Component({
    selector: 'fainin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    menuDrawerVisible$: Observable<boolean>;
    consentDialogueOpen$: Observable<boolean>;
    mobileNavVisible$: Observable<boolean>;
    hideHeader$: Observable<boolean>;
    isHomePage$: Observable<boolean>;
    isSignedIn$: Observable<boolean>;
    showSecondaryFooter$: Observable<boolean>;
    version: string;
    loggedIn: boolean = false;
    isMobile: boolean = false;
    currentYear = new Date().getFullYear();
    isCrawler: boolean = true;

    feedbackWizardOpen: boolean = false;
    awaitingFeedbackOrder: Order | undefined;
    feedbackWizardActionSheetTopBoundary: number = 0;
    @ViewChild(FeedbackWizardComponent) private feedbackWizard: FeedbackWizardComponent;

    constructor(
        private layoutService: LayoutService,
        private scrollPositionService: ScrollPositionService,
        private dataService: DataService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private stateService: StateService,
        private userService: UserService, // needed -> init is in constructor
        private i18nService: I18nService,
        private seo: SeoService,
        private ga: GoogleAnalyticsService,
        private gtmService: GoogleTagManagerService,
        private translateService: TranslateService,
        private cookieConsentService: UniversalCookieConsentService,
        private render: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
        private webview: WebViewService,
        private responsive: ResponsiveService,
        private cd: ChangeDetectorRef,
        private chatService: TalkjsService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.menuDrawerVisible$ = this.stateService.select(state => state.menuDrawerOpen);
        this.consentDialogueOpen$ = this.stateService.select(state => state.cookieConsentDialogOpen);
        this.mobileNavVisible$ = this.stateService.select(state => state.mobileNavMenuIsOpen);
        this.hideHeader$ = this.stateService.select(state => state.hideHeader);
        this.showSecondaryFooter$ = this.layoutService
            .select(layout => layout.showSecondaryFooter)
            .pipe(untilDestroyed(this));
        this.isHomePage$ = this.router.events.pipe(
            untilDestroyed(this),
            filter<any>(event => event instanceof RouterEvent),
            map((event: RouterEvent) => event.url === '/'),
        );

        // Setup logger
        this.version = environment.version ?? 'vX.X.X';
    }

    get isDebugEnabled() {
        return environment.name === 'localhost' || this.webview.isDebug;
    }

    previousFeedbackStep() {
        if (this.feedbackWizard.currentStep > 0) {
            this.feedbackWizard.previousStep();
            this.cd.markForCheck();
        }
    }

    showSecondaryNavigation(): boolean {
        return this.router.url.includes('/account');
    }

    doSignOut() {
        this.dataService.mutate<SignOut.Mutation>(SIGN_OUT).subscribe({
            next: () => {
                this.stateService.setState('signedIn', false);
                this.chatService.destroyChatSession();
                this.stateService.setState('displayProductsInTable', false);
                this.userService.resetUserStates();
                this.dataService.clearCache().then();
                this.webview
                    .logout()
                    .then()
                    .catch(() => {});
                localStorage?.removeItem(AUTH_TOKEN_KEY);

                /**
                 * If already on landing, refresh the page to update conditionally rendered data
                 * based on signed-in state
                 */
                if (this.router.url === '/') {
                    window.location.reload();
                } else {
                    this.router.navigate(['/']);
                }
            },
        });
    }

    /**
     * TODO For a universal solution later to use for iOS bottom floating drawer
     */
    iOS() {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform,
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }

    async ngOnInit() {
        await this.checkForCrawler();
        this.isSignedIn$ = this.stateService.select(state => state.signedIn);
        //adjust top spacing based on breakpoint observer value
        this.responsive
            .isMobile()
            .pipe(untilDestroyed(this))
            .subscribe((mobile: boolean) => {
                this.isMobile = mobile;
                if (mobile) {
                    this.elementRef.nativeElement.style.setProperty('---nav-link-top', '6.25rem');
                } else {
                    this.elementRef.nativeElement.style.setProperty('---nav-link-top', '9.375rem');
                }
            });

        this.checkIfLoggedIn();

        // Setup translations
        this.i18nService.init();

        // Set header state
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter<any>(event => event instanceof ActivationEnd),
                filter<any>(event => !!event.snapshot.component),
                map((event: ActivationEnd) => event.snapshot.data),
                distinctUntilChanged(),
            )
            .subscribe(event => {
                this.stateService.updateState({
                    hideHeader: event.hideHeader ?? false,
                    menuDrawerOpen: false,
                    showSubHeader: false,
                });
            });

        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    // Get the last route
                    let route = this.router.routerState.root;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                switchMap(route => route.data),
                untilDestroyed(this),
            )
            .subscribe(event => {
                const title = event.title;
                const description = event.description;
                const keywords = event.keywords;
                const canonical = event.canonical;

                if (title) {
                    log.debug('Deprecation warning: Please use pageMetadata and SeoGuard to set the title');
                    this.seo.setTitle(this.translateService.instant(title));
                }

                if (description) {
                    this.seo.setDescription(this.translateService.instant(description));
                }

                if (keywords) {
                    this.seo.setKeyword(this.translateService.instant(keywords));
                } else if (keywords === null) {
                    this.seo.removeNameTag('keywords');
                }

                if (canonical) {
                    this.seo.updateCanonicalUrl(canonical);
                }
            });

        if (isPlatformBrowser(this.platformId)) {
            log.info('Version', environment.version);
            // Add event listener to detect outside clicks
            // Add the event listener delayed, because otherwise it will be triggered directly
            setTimeout(() => {
                document.addEventListener('click', this.outsideMenuClickListener);
            }, 300);

            this.gtmService.initializeDefaults();

            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    // Reset the layout state or perform any cleanup here
                    // TODO: THis will mess with the layout state since the guard will not
                    // trigger again! We might have to move the guard here...
                    // this.layoutService.resetLayoutState();
                }
            });

            /**
             * FIX: https://faininpm.atlassian.net/browse/FS-889
             */
            const OLD_AUTH_KEY_TOKEN = 'authToken';
            const oldAuthKey = localStorage.getItem(OLD_AUTH_KEY_TOKEN);
            if (oldAuthKey) {
                if (!localStorage.getItem(AUTH_TOKEN_KEY)) {
                    // Save auth token to new key
                    localStorage.setItem(AUTH_TOKEN_KEY, oldAuthKey);
                }
                // Remove old token if new one was set
                localStorage.removeItem(OLD_AUTH_KEY_TOKEN);
            }

            this.userService.checkOrdersWithAwaitingFeedbacks
                .pipe(untilDestroyed(this))
                .subscribe(async (validForChecking: boolean) => {
                    if (validForChecking) {
                        this.calcFeedbackActionSheetTopBoundary();
                        this.awaitingFeedbackOrder =
                            (await this.userService.fetchOrderWithAwaitingFeedback()) ?? undefined;

                        if (this.awaitingFeedbackOrder) {
                            this.feedbackWizardOpen = true;
                        }
                    }
                });

            if (this.webview.isWebView) {
                // Setup Cookie manager based on consent
                this.webview.getTrackingConsent().then(consent => {
                    if (consent === undefined || consent.trackingStatus?.granted) {
                        this.setupCookieConsent();
                    } else if (consent && !consent.trackingStatus?.granted) {
                        this.cookieConsentService.setViewState(UniversalCookieConsentViewState.CLOSED);
                    }
                });
            } else {
                // Setup Cookie manager
                this.setupCookieConsent();
            }
            // Init "Animation on Scroll"
            AOS.init({
                disable: isCrawlerUserAgent(),
            });
            // Init Swiper
            SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay, Zoom]);
        } else {
            // Set lang tag of html
            this.document.documentElement.lang = this.i18nService.defaultLanguage.split('-')[0];
        }

        this.menuDrawerVisible$.pipe(untilDestroyed(this)).subscribe(isVisible => {
            if (isVisible) {
                this.render.addClass(document.body, 'no-scroll');
            } else {
                this.render.removeClass(document.body, 'no-scroll');
            }
        });

        this.chatService.onChatSessionInitialized.pipe(untilDestroyed(this)).subscribe((initialized: boolean): void => {
            if (initialized) {
                this.chatService.chatSessionRef?.unreads.onChange((unreadConversations: UnreadConversation[]) => {
                    this.recalculateTotalUnreadMessages(unreadConversations);
                });
            }
        });
    }

    /**
     * Listener to detect clicks outside the drawer component
     * @param event
     */
    outsideMenuClickListener = (event: MouseEvent) => {
        if (this.stateService.currentState('menuDrawerOpen')) {
            const menuDrawer = this.document.getElementById('menuDrawer');
            if (event.target && menuDrawer && !menuDrawer.contains(event.target as HTMLElement)) {
                this.closeMenuDrawer();
            }
        }
        if (this.stateService.currentState('cookieConsentDialogOpen')) {
            const menuDrawer = this.document.getElementById('consentDrawer');
            if (event.target && menuDrawer && !menuDrawer.contains(event.target as HTMLElement)) {
                this.stateService.setState('cookieConsentDialogOpen', false);
            }
        }
    };

    closeMenuDrawer() {
        this.stateService.setState('menuDrawerOpen', false);
        this.document.removeEventListener('click', this.outsideMenuClickListener);
    }

    closeConsentDrawer() {
        this.stateService.setState('cookieConsentDialogOpen', false);
    }

    ngOnDestroy(): void {
        this.i18nService.destroy();
        this.seo.destroy();
        this.ga.destroy();
        this.gtmService.destroy();
    }

    private recalculateTotalUnreadMessages(unreadConversations: UnreadConversation[]): void {
        if (unreadConversations.length < 1) {
            this.stateService.setState('totalUnreadMessages', 0);
            return;
        }
        if (unreadConversations.length === 1) {
            this.stateService.setState('totalUnreadMessages', unreadConversations[0].unreadMessageCount);
            return;
        }

        this.stateService.setState(
            'totalUnreadMessages',
            unreadConversations.reduce(
                (acc: number, conversation: UnreadConversation) => acc + conversation.unreadMessageCount,
                0,
            ),
        );
    }

    /**
     * Feedback Sheet for mobile should always have a height of 600px/37.5rem
     * to maintain the content aspect ratio to match the figma design
     * @private
     */
    private calcFeedbackActionSheetTopBoundary() {
        this.feedbackWizardActionSheetTopBoundary = Math.trunc(((window.innerHeight - 550) / window.innerHeight) * 100);
    }

    private checkForCrawler() {
        const userAgent = navigator.userAgent;
        this.isCrawler =
            isCrawlerUserAgent() || this.checkIfUnlighthouseTool() || userAgent.includes('Chrome-Lighthouse');
    }

    private checkIfUnlighthouseTool() {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('unlighthouse');
    }

    private setupCookieConsent() {
        log.debug('setupCookieConsent');
        this.cookieConsentService
            .getGrantedConsents()
            .pipe(
                untilDestroyed(this),
                skipWhile(granted => !Array.isArray(granted)),
            )
            .subscribe(granted => {
                this.gtmService.initScript(granted);
                log.debug('Cookie Granted:', granted?.join(', '));

                if (granted?.includes('base')) {
                    this.i18nService.storeLanguageChange = true;
                }
                if (granted?.includes('analytics')) {
                    this.ga.init();
                }
            });
    }

    private checkIfLoggedIn() {
        this.router.events
            .pipe(switchMap(() => this.stateService.select(state => state.signedIn)))
            .subscribe(signedIn => {
                this.loggedIn = signedIn;
            });
    }
}
