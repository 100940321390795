import { Injectable } from '@angular/core';
import { Conversation } from '@api/generated-types';
import { UserService } from '@core/providers/data/user.service';
import { StateService } from '@core/providers/state/state.service';
import { uuid } from '@core/providers/webview/web-view.events';
import { Logger } from '@core/utils/logger';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data/data.service';
import { SocketioClientService, SocketPayloadReadMessages, SocketPayloadSendMessage } from './socketio-client.service';

const logger = new Logger('ChatService');

/**
 * @deprecated We moved chat features to TalkJS
 */
@Injectable({
    providedIn: 'root',
})
export class ChatService {
    private activeProfileId: string | undefined;
    private chatList: Map<string, Conversation.Fragment>;
    private readonly onChange;
    isLoaded = false;
    private socketLoaded: boolean = false;

    constructor(
        private dataService: DataService,
        private socketClient: SocketioClientService,
        private stateService: StateService,
        private userService: UserService,
    ) {
        this.chatList = new Map<string, Conversation.Fragment>();
        this.onChange = new BehaviorSubject(this.chatList);
    }

    /**
     * @deprecated We moved chat features to TalkJS
     * @param payload
     */
    sendMsg(payload: SocketPayloadSendMessage) {
        this.socketClient.emitSendMessage(payload);
        const chat = this.chatList.get(payload.conversationId);
        if (chat) {
            const messages = chat.messages || [];
            chat.messages = [
                ...messages,
                {
                    __typename: 'ChatMessage',
                    _id: uuid(), // just temporarily
                    createdAt: payload.createdAt.toISOString(),
                    text: payload.text,
                    user: payload.user,
                    received: false,
                },
            ];
            chat.lastActivityAt = payload.createdAt;
            this.chatList.set(payload.conversationId, chat);
            this.onChange.next(this.chatList);
        }
    }

    /**
     * @deprecated We moved chat features to TalkJS
     * @param payload
     */
    readMessages(payload: SocketPayloadReadMessages) {
        this.socketClient.emitReadMessages(payload);
    }

    OnChange() {
        return this.onChange.pipe(
            map(chats => Array.from(chats.values())),
            map((conversations: Conversation.Fragment[]) => {
                conversations.sort(this.compareChats);
                return conversations;
            }),
        );
    }

    select(conversationId: string) {
        return this.onChange.pipe(map(chats => chats.get(conversationId)));
    }

    /**
     * @deprecated We moved chat features to TalkJS
     */
    markAsChanged() {
        this.serialize();
        this.onChange.next(this.chatList);
    }

    /**
     * @deprecated We moved chat features to TalkJS
     * @param conversationId
     */
    markAsRead(conversationId: string) {
        if (!this.chatList) {
            return;
        }
        const chat = this.chatList.get(conversationId);
        if (chat) {
            chat.unreadMessages = 0;
            this.serialize();
            if (chat.members && this.activeProfileId) {
                this.readMessages({
                    conversationId,
                    receiverProfileId: this.activeProfileId as string,
                    senderProfileId: chat.members[0].id,
                });
            }
            return;
        }
    }

    async refresh() {
        await this.loadConversationList();
        this.markAsChanged();
    }

    async init() {
        this.deserialize();
        await this.loadConversationList();
        this.onChange.next(this.chatList);
    }

    /**
     * @deprecated We are using TalkJS now
     * @param conversationId
     */
    loadConversation(conversationId: string): Observable<Conversation | undefined> {
        logger.debug(`loadConversation`, conversationId);
        return of(undefined);
    }

    /**
     * @deprecated We are using TalkJS now
     * @private
     */
    private async loadConversationList() {}

    private subscribeToEvents() {
        this.socketClient.onReceiveMessage().subscribe(async msg => {
            if (!this.chatList) return;
            const conversation = this.chatList.get(msg.conversationId);
            if (!conversation) {
                // Received message from a new conversation
                this.refresh();
                return;
            }
            const message = {
                _id: msg.conversationId,
                createdAt: msg.createdAt,
                text: msg.text,
                received: false,
                user: { _id: msg.user._id, name: msg.user.name, avatar: '' },
            };
            conversation.messages?.push(message);
            conversation.unreadMessages++;
            conversation.lastActivityAt = msg.createdAt;
            this.markAsChanged();
        });
        this.socketClient.onMessageRead().subscribe(async conversationId => {
            const conversation = this.chatList.get(conversationId);
            conversation?.messages?.forEach(msg => {
                msg.received = true;
            });
            this.onChange.next(this.chatList);
        });
    }

    private readonly _storageKey: string = 'ChatState';

    private serialize() {
        logger.debug(`Storing chat in localstorage`);
        localStorage?.setItem(this._storageKey, JSON.stringify(Object.fromEntries(this.chatList)));
    }

    private deserialize() {
        // remove old key
        localStorage?.removeItem('chatList');
        const json = localStorage?.getItem(this._storageKey);
        if (json) {
            logger.debug(`Restoring chat from localstorage`);
            this.chatList = new Map(Object.entries(JSON.parse(json)));
            this.isLoaded = true;
            this.onChange.next(this.chatList);
        }
    }

    private clearStorage() {
        logger.debug('Clear Storage');
        localStorage?.removeItem(this._storageKey);
    }

    private compareChats(a: Conversation.Fragment, b: Conversation.Fragment) {
        if (!new Date(a.lastActivityAt).getTime() && !new Date(b.lastActivityAt).getTime())
            return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1;
        else if (!new Date(b.lastActivityAt).getTime())
            return new Date(a.lastActivityAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1;
        else if (!new Date(a.lastActivityAt).getTime())
            return new Date(a.createdAt).getTime() > new Date(b.lastActivityAt).getTime() ? -1 : 1;
        return new Date(a.lastActivityAt).getTime() > new Date(b.lastActivityAt).getTime() ? -1 : 1;
    }
}
