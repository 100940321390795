import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fainin-feedback-step3',
    templateUrl: './feedback-step3.component.html',
    styleUrls: ['./feedback-step3.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackStep3Component {
    @Output() next = new EventEmitter<any>();
    @Input() rating: number;
    @Output() ratingChange: EventEmitter<number> = new EventEmitter<number>();

    changeRating(value: number) {
        this.ratingChange.emit(value);
    }

    constructor() {}
}
