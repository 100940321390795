export default {
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    proceed: 'Продолжить',
    acceptRequestTitle: 'Принять запрос',
    acceptRequestMsg: 'Пожалуйста, подтвердите принятие этого запроса.',
    declineRequestTitle: 'Запрос на отказ',
    declineRequestMsg: 'Пожалуйста, подтвердите отклонение этого запроса.',
    cancelRequestTitle: 'Отменить запрос',
    cancelRequestMsg: 'Пожалуйста, подтвердите отмену этого запроса.',
    requestAcceptedTitle: 'Запрос принят',
    requestAcceptedMsg: 'Отлично! Вы приняли запрос. Следующий шаг - ждать оплаты от арендатора.',
    requestDeclinedTitle: 'Запрос отклонён',
    requestDeclinedMsg:
        'Вы отклонили запрос. Если это была ошибка, вы можете попросить заинтересованное лицо повторно отправить запрос.',
    understand: 'Понятно',
    startingRental: 'Начать аренду',
    uploadLeast: 'Поздравляем! Вы почти справились. Загрузите не менее 1 фотографий предмета для подтверждения аренды.',
    warning: 'Предупреждение',
    rentalDateHasNotReached:
        'Дата начала аренды еще не достигнута. Мы не можем застраховать ущерб, нанесенный вне периода аренды. Вы все еще хотите начать аренду?',
    endingRental: 'Конец аренды',
    uploadLeastEnd: 'Загрузите не менее 1 фотографий товара для подтверждения возврата',
    rentalEndDateHasNotReached: 'Дата окончания аренды еще не достигнута, Вы хотите завершить аренду в любом случае?',
    refundTitle: 'Возврат',
    refundMsg:
        'Если вы отмените этот заказ, будет отправлен запрос на возврат средств. Вы действительно хотите отменить заказ и получить возмещение?',
    changesDiscardConfirm: 'Вы уверены? \nВаши изменения будут отменены.',
    pwa: {
        title: 'Обновление!',
        msg: 'fainin был обновлен.',
        btnTxt: 'Перезагрузка',
    },
    deletingAccountTitle: 'Удаление аккаунта',
    deletingAccountMsg: 'Вы уверены, что хотите удалить свой аккаунт?',
    modalUpdateTierTitle: 'Хотите обновить ценовые категории?',
    modalUpdateTierMsg:
        'Ваши текущие настроенные ценовые категории будут перезаписаны новым определением на основе вашего ввода.',
    productUpload: {
        cacheRestore: {
            title: 'Existing draft found!',
            msg: 'Do you want to continue editing your product draft or start uploading a new one?',
            confirmLabel: 'Continue',
            cancelLabel: 'New',
        },
    },
    detectedContentLanguage: {
        title: 'Different Language available',
        msg: 'This content is also available in your current language: "{{language}}". Would you see this content in this language?',
    },
};
