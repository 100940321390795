import { Injectable } from '@angular/core';
import { Order, Request } from '@api/generated-types';
import { AUTH_TOKEN_KEY } from '@core/apollo-client-provider';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const io = require('socket.io-client');

export interface SocketPayloadReadMessages {
    conversationId: string;
    receiverProfileId: string;
    senderProfileId: string;
}

export interface SocketPayloadSendMessage {
    user: { _id: string; name: string; avatar: string };
    receiverProfileId: string;
    conversationId: string;
    text: string;
    createdAt: Date;
}

const logger = new Logger('SocketioClientService');

@Injectable({
    providedIn: 'root',
})
export class SocketioClientService {
    socket: any;

    constructor() {}

    /**
     * Connects to the webserver socket
     * @param profileId
     */
    connect() {
        this.socket = io(environment.baseApiUrl, {
            path: environment.faininSocketPath,
            auth: {
                token: `Bearer ${localStorage?.getItem(AUTH_TOKEN_KEY)}`,
            },
            transports: ['websocket'],
            reconnection: true,
            reconnectionDelay: 5000,
            reconnectionAttempts: 5,
        });

        // Listen for the 'exception' event
        this.socket.on('exception', (exception: any) => {
            logger.error('Socket exception:', exception.message);
        });

        this.socket.on('connect', () => {
            logger.debug('Socket connected');
        });

        this.socket.on('disconnect', () => {
            logger.debug('Socket disconnected');
            this.socket = undefined;
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
            logger.debug('Socket disconnected');
        }
    }

    /**
     * Sends information that a message was received and read
     * @param payload
     */
    emitReadMessages(payload: SocketPayloadReadMessages) {
        this.socket.emit('readMessages', payload);
        logger.debug('emit readMessages');
    }

    /**
     * Sends a chat message
     * @param payload
     */
    emitSendMessage(payload: SocketPayloadSendMessage) {
        this.socket.emit('sendMessage', payload);
        logger.debug('emit sendMessage');
    }

    /**
     * Sends information that an order was read
     * @param userId to check if the user is the owner or renter
     * @param orderId
     */
    emitReadOrder(userId: string, orderId: string) {
        this.socket.emit('readOrder', {
            userId: userId,
            orderId: orderId,
        });
        logger.debug('emit readOrder');
    }

    onReceiveMessage() {
        return new Observable<SocketPayloadSendMessage>(observer => {
            this.socket.on('receiveMessage', (msg: SocketPayloadSendMessage) => {
                logger.debug('on receiveMessage');
                observer.next(msg);
            });
        });
    }

    onMessageRead() {
        return new Observable<string>(observer => {
            this.socket.on('messageRead', (convId: string) => {
                logger.debug('on messageRead');
                observer.next(convId);
            });
        });
    }

    onRequestStatusChange() {
        return new Observable<{ request: Request }>(observer => {
            this.socket.on('requestStatusChange', (data: any) => {
                logger.debug('on requestStatusChange');
                observer.next(data);
            });
        });
    }

    onOrderStateChange() {
        return new Observable<{ order: Order }>(observer => {
            this.socket.on('orderStateChange', (data: any) => {
                logger.debug('on orderStateChange');
                observer.next(data);
            });
        });
    }
}
