import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    CategoryDisplay,
    SecondaryFooterService,
} from '@core/components/secondary-footer-content/secondary-footer.service';
import { ResponsiveService } from '@core/providers/responsive.service';
import { LayoutService } from '@core/providers/state/layout.service';
import { Logger } from '@core/utils/logger';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

const log = new Logger('Secondary Footer');

export interface TopSubcategoriesObject {
    indexName: string;
    name: string; // Assign a localized name
    slug: string;
    collectionName: string; // Assign a localized name
    collectionSlug: string;
}

@Component({
    selector: 'fainin-secondary-footer-content',
    templateUrl: './secondary-footer-content.component.html',
    styleUrls: ['./secondary-footer-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryFooterContentComponent implements OnInit, OnDestroy {
    isMobile$: Observable<boolean>;

    readonly index = [
        {
            collection: 'tech',
            categories: ['videospiele-konsolen', 'beamer', 'soundboks', 'ps5', 'gopro'],
        },
        {
            collection: 'events',
            categories: ['dj-equipment', 'bierzeltgarnitur', 'festzelt', 'fotobox', 'stehtisch'],
        },
        {
            collection: 'reisen',
            categories: ['zelte', 'dachbox', 'dachzelt', 'anhaenger', 'campingausruestung'],
        },
        {
            collection: 'foto-video',
            categories: ['kamera', 'videokamera', 'drohne', 'objektiv', 'beleuchtung'],
        },
    ];

    public topCategoryDisplay: CategoryDisplay;

    showSecondaryFooter$: Observable<boolean>;
    constructor(
        private router: Router,
        private cd: ChangeDetectorRef,
        private responsive: ResponsiveService,
        private secondaryFooterService: SecondaryFooterService,
        private layoutService: LayoutService,
    ) {
        this.showSecondaryFooter$ = this.layoutService
            .select(layout => layout.showSecondaryFooter)
            .pipe(untilDestroyed(this));
    }

    async ngOnInit() {
        this.isMobile$ = this.responsive.isMobile();
        this.topCategoryDisplay = await this.secondaryFooterService.getSubCategoryIndex(this.index);
        this.cd.markForCheck();

        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.cd.markForCheck();
            });
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }
}
