<div class="tab-bar" *ngIf="isMobile$ | async">
    <div
        class="tab-bar-item"
        [routerLink]="['/search']"
        [routerLinkActive]="'search-active'"
        [routerLinkActiveOptions]="{ exact: false }">
        <div class="option">
            <img
                class="tab-bar-item-icon"
                src="/assets/icon/account-menu-mobile/layers.svg"
                width="24"
                height="24"
                alt="{{ 'asset.discoverIcon' | translate }}" />
        </div>
        <div class="paragraph-xsmall">{{ 'account.menu.discover' | translate }}</div>
    </div>
    <div
        class="tab-bar-item rent"
        [routerLink]="['/account/orders']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="option">
            <img
                onboarding-id="rentals-menu-button"
                class="tab-bar-item-icon"
                src="/assets/icon/figma/rentals.svg"
                width="24"
                height="24"
                alt="{{ 'asset.rentalsIcon' | translate }}" />
            <ng-container *ngIf="unreadOrdersRequests > 0">
                <span class="notification-dot"> {{ unreadOrdersRequests }}</span>
            </ng-container>
        </div>
        <div class="paragraph-xsmall">{{ 'navigation.rent' | translate }}</div>
    </div>
    <div
        class="tab-bar-item"
        [routerLink]="['/account/product-edit']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="option">
            <img
                class="tab-bar-item-icon"
                src="/assets/icon/account-menu-mobile/plus.svg"
                width="24"
                height="24"
                alt="{{ 'asset.uploadProductIcon' | translate }}" />
        </div>
        <div class="paragraph-xsmall">{{ 'account.lend' | translate }}</div>
    </div>
    <div
        class="tab-bar-item"
        [routerLink]="['/account/chats']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="option">
            <img
                class="tab-bar-item-icon"
                src="/assets/icon/account-menu-mobile/message.svg"
                width="24"
                height="24"
                alt="{{ 'asset.chatIcon' | translate }}" />
            <ng-container *ngIf="unreadChatsCount > 0">
                <span class="notification-dot"> {{ unreadChatsCount }}</span>
            </ng-container>
        </div>
        <div class="paragraph-xsmall">{{ 'account.menu.chats' | translate }}</div>
    </div>

    <div
        class="tab-bar-item"
        [routerLink]="['/account/my-profile']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="option">
            <img
                onboarding-id="tab-bar-profile"
                class="tab-bar-item-icon"
                src="/assets/icon/account-menu-mobile/profile-circled.svg"
                width="24"
                height="24"
                alt="{{ 'asset.myProfileIcon' | translate }}" />
        </div>
        <div class="paragraph-xsmall">{{ 'account.menu.profile' | translate }}</div>
    </div>
</div>
