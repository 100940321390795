export default {
    faininAcronym: 'Найдите все, что угодно, в своем районе',
    subscribe: 'Подписаться',
    links: 'Ссылки',
    readMore: 'Читать дальше',
    settings: 'Настройки',
    newsletter: {
        consent:
            'Да, на регулярные письма от нас с информацией, советами, действиями и захватывающими историями о fainin и сообществе обмена',
        getReady: 'Будьте готовы к fainin 2.0',
        subscribe: 'Подпишитесь на нашу рассылку!',
        subscribe2: 'Подписаться на эксклюзивные новости',
        subscribe3: 'Получать все новости о семье fainin',
        subscribe4: 'Получать наши новости',
        disclaimer: 'Своей регистрацией вы подтверждаете наши',
        placeholder: 'Ваша любимая почта',
        alreadySubscribed: 'Вы уже подписались на fainin',
        success: {
            title: 'Ура! Подписка успешна',
            text: 'Добро пожаловать в семью fainin!',
        },
        invalid: 'Пожалуйста, введите действительный почтовый ящик!',
    },
    error: 'Ошибка',
    someThingWentWrong: 'Извините, что-то пошло не так! Вы можете попробовать еще раз?',
    hashtag: '#ДелитьсяЭтоНовоеОбладание',
    language: 'Язык',
    or: 'или',
    betaInfo: 'Версия, выпущенная для тестирования. Потеря данных на этом этапе - не редкость.',
    article: 'Товар',
    articles: 'Товар',
    loading: 'Загрузка',
    pleaseWait: 'Пожалуйста, подождите',
    voucher: 'Ваучер',
    apply: 'Применить',
};
