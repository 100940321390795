export default {
    aboutUs:
        'Wir sind drei Studenten und heißen Johann, Max und Kevin – wir wollen mit fainin die Welt verbessern und alle Teilnehmer profitieren lassen. Wir freuen uns über Deine Nachricht.',
    yourSharingCommunity: 'Ihre Sharing-Community',
    getToKnowUs: 'Lern uns kennen',
    sharingIsOwning: '#TeilenIstDasNeueHaben',
    becomeFamily: 'Werde Teil der fainin Family',
    title: 'fainin',
    readyForCommunity: 'Bereit für die sicherste Sharing Community?',
    partner: 'Partner',
    caption: {
        lend: 'Verleihe alles',
        lend2: 'was du möchtest',
        rent: 'Leihe alles',
        rent2: 'Was du brauchst',
    },
    tile: {
        safety: {
            title: 'Vertrauen',
            subtitle: '– Design-For-Trust bei fainin',
            content:
                'Machst Du Dir Sorgen um Deine Sachen? Das verstehen wir natürlich! Deshalb haben wir fainin designed-for-trust.',
            point: ["Versicherung bis zu 15'000 €", '100% verifizierte Nutzer', 'Dokumentierter Verleihprozess'],
        },
        what: {
            title: 'Unser Ziel',
            subtitle: '– Die sicherste Sharing Community',
            content: `Wir wollen Deine Stadt zu einem gemeinschaftlichen Ort machen. <br>
Und wir bieten Dir die Möglichkeit in Win-Win-Situationen mit fainin zu profitieren! Gemeinsam etablieren wir die
sicherste Sharing Community in Deiner Nachbarschaft. <b>#TeilenIstDasNeueHaben</b>
            <br><br>Machst Du mit?`,
        },
        app: {
            title: 'Deine Sharing-App',
            subtitle: 'Für iOS und Android',
            content: `fainins neue App ermöglicht vertrauenswürdiges Aus- und Verleihen. Um als erstes die brandneue
App zu benutzen, kannst Du fainins Social Media Kanälen folgen. <br>
<br><br>Bist Du bereit?`,
        },
    },
    cta: {
        flying: {
            title: 'Hast du noch was<br>rum(f)liegen?',
            label: 'Inseriere es bei fainin!',
            action: 'Jetzt inserieren',
        },
    },
    blog: {
        title: 'Blog',
        subtitle: 'Lass uns über die Sharing Economy sprechen',
        text:
            'Wenn Du Etwas zum Thema Nachhaltigkeit, Soziale Nähe oder Teilen beitragen möchtest, ' +
            'dann schreib uns gerne auf Social Media oder eine Mail an contact@fainin.com.',
    },
    topSeller: {
        title: 'Vorgestellte Produkte',
        subtitle: 'Die besten Produkte in deiner Nähe',
    },
    newsletter: {
        title: 'Keine News mehr verpassen!',
        text: 'Melde dich für unseren Newsletter an und du erhältst regelmäßig Updates aus der fainin Family.',
        button: 'Newsletter abonnieren',
    },
};
