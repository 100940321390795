export default {
    homeIcon: 'Домашняя икона Файнина',
    searchIcon: 'Икона "Поиск Файнина',
    lendIcon: 'Икона "Фаинин ленд',
    uploadIcon: 'Fainin Загрузить значок продукта',
    logoutIcon: 'Значок выхода из системы',
    myProfileIcon: 'Значок моего профиля',
    chatIcon: 'Значок чата',
    uploadProductIcon: 'Иконка загрузки продукта',
    rentalsIcon: 'Иконка аренды',
    favoritesIcon: 'Иконка избранного',
    discoverIcon: 'Иконка открытия',
    dashboardIcon: 'Иконка приборной панели',
    uploadImageAfterRental: 'Загруженное изображение',
    uploadImageBeforeRentalPlaceholder: 'Место для загрузки изображения',
    renterThumbnail: 'Изображение профиля арендатора',
    fallbackRenterThumbnail: 'Изображение профиля арендатора по умолчанию',
    stepPending: 'Шаг: ожидание',
    stepAction: 'Шаг: действие',
    productImageAfterRental: 'Изображение продукта после аренды',
    productImageBeforeRental: 'Изображение продукта перед арендой',
    paypalIcon: 'Значок Paypal',
    visaIcon: 'Значок Visa',
    masterCardIcon: 'Значок Master card',
    return: 'Назад',
    borrowWhatYouNeed: 'Займите то, что вам нужно',
    privateOrCompany: 'Вы здесь как частное лицо или как компания',
    private: 'Частно',
    businessAccount: 'Бизнес-счет',
    whatPassword: 'Какой у вас пароль',
    insuranceIllustration: 'Страховая иллюстрация',
    faininTeam: 'Команда fainin',
    faininAppMockup: 'Макет приложения fainin',
    appleAppstoreIcon: 'Значок Apple Appstore',
    googlePlaystoreIcon: 'Значок Google Playstore',
    flyingDroneIcon: 'Значок летающего дрона',
    cookieSettingsButton: 'Кнопка настройки параметров cookie',
    johann: 'fainin Йоханн',
    max: 'fainin Макс',
    kev: 'fainin Кевин',
    govtLogo: 'Логотип Deutsche Bundesstiftung Umwelt',
    companyLogo: 'Логотип компании OVH',
    ergoLogo: 'Ergo логотип - страховать значит понимать',
    assetGallery: {
        productGalleryImage: 'Галерея продуктов',
        productGalleryFallbackImage: 'Галерея продуктов (альтернативно)',
        viewAll: 'Показать все',
        faininProductLocationDot: "fainin's Маркер местоположения продукта",
    },
    accountMenu: {
        chatButtonIcon: 'Иконка кнопки чата',
        accountMenuIconSignedIn: 'Иконка меню аккаунта - зарегистрирован',
        accountMenuIconUnauthenticated: 'Значок меню аккаунта - нет аутентификации',
        burgerMenuIcon: 'Значок меню бургера',
        closeDrawerIcon: 'Значок закрытия меню',
        signupButton: 'Кнопка регистрации',
        desktopLoginButton: 'Кнопка входа на рабочий стол',
    },
    cartToggle: {
        cartToggle: 'Кнопка включения магазина',
    },
    collectionBreadCrumbs: {
        breadcrumb: 'Панировочные сухари',
    },
    layoutLogo: {
        faininStorefrontLogo: 'fainin Витрина логотип',
    },
    layoutSearchButton: {
        searchSheetClose: 'Кнопка закрытия панели поиска',
    },
    mobileMenuToggle: 'Переключение мобильного меню',
    myProductCard: {
        fallbackProductPreview: 'Миниатюра альтернативного продукта',
    },
    pageHeader: {
        back: 'Назад',
    },
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    userComponent: {
        ownerThumbnail: 'Заставка арендатора',
        fallbackOwnerthumbnail: 'Заставка арендатора (альтернативно)',
    },
    dashboard: {
        downloadAndroid: 'Загрузка для Android',
        downloadApple: 'Загрузка для Apple',
    },
    order: {
        productPreviewImage: 'Предварительное изображение забронированного продукта',
    },
    button: {
        close: 'Кнопка закрытия',
        clear: 'Сброс',
    },
};
