import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const WEB_VIEW_ENVIRONMENT_EVENT_NAME = 'WebViewEnvironment';

export interface IWebViewEnvironmentResponse extends IResponsePayload {
    environment?: string;
    debug?: boolean;
}

export type IWebViewEnvironmentEvent = IWebViewEvent<any, IWebViewEnvironmentResponse>;

export class WebViewEnvironmentEvent
    extends AWebViewEvent<any, IWebViewEnvironmentResponse>
    implements IWebViewEnvironmentEvent
{
    constructor() {
        super(WEB_VIEW_ENVIRONMENT_EVENT_NAME);
    }
}

export default [WEB_VIEW_ENVIRONMENT_EVENT_NAME, WebViewEnvironmentEvent];
