import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { PillComponent } from '@shared/components/pill/pill.component';

@Component({
    selector: 'fainin-pills',
    templateUrl: './pills.component.html',
    styleUrls: ['./pills.component.scss'],
    standalone: true,
    imports: [CommonModule],
})

/**
 * Only one PillComponent child instance should have `selected` property initialized as true (if needed)
 */
export class PillsComponent implements AfterContentInit {
    @ContentChildren(PillComponent) pills: QueryList<PillComponent>;
    selectedPill: PillComponent | undefined;

    @Output()
    pillSelected: EventEmitter<string | number | undefined> = new EventEmitter<string | number | undefined>();

    ngAfterContentInit(): void {
        this.pills.forEach((pill: PillComponent): void => {
            if (pill.selected) {
                this.selectedPill = pill;
            }
            pill.select = (): void => this.selectPill(pill);
        });
    }

    private selectPill(pill: PillComponent): void {
        pill.selected = !pill.selected;
        if (pill.selected) {
            this.selectedPill = pill;
            this.updatePills();
            this.pillSelected.emit(pill.key);
            return;
        }
        this.pillSelected.emit(undefined);
    }

    private updatePills(): void {
        this.pills.forEach((pill: PillComponent): void => {
            if (pill !== this.selectedPill) {
                pill.selected = false;
            } else {
                pill.selected = true;
            }
        });
    }
}
