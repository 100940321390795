<div class="d-flex justify-content-around feedback-bars">
    <div (click)="changeRating(1)">
        <img
            *ngIf="rating < 1"
            src="/assets/icon/feedback/outlined.svg"
            alt="{{ 'account.feedbackIconEmpty' | translate }}" />
        <img
            *ngIf="rating >= 1"
            src="/assets/icon/feedback/filled.svg"
            alt="{{ 'account.feedbackIconFilled' | translate }}" />
    </div>
    <div (click)="changeRating(2)">
        <img
            *ngIf="rating < 2"
            src="/assets/icon/feedback/outlined.svg"
            alt="{{ 'account.feedbackIconEmpty' | translate }}" />
        <img
            *ngIf="rating >= 2"
            src="/assets/icon/feedback/filled.svg"
            alt="{{ 'account.feedbackIconFilled' | translate }}" />
    </div>
    <div (click)="changeRating(3)">
        <img
            *ngIf="rating < 3"
            src="/assets/icon/feedback/outlined.svg"
            alt="{{ 'account.feedbackIconEmpty' | translate }}" />
        <img
            *ngIf="rating >= 3"
            src="/assets/icon/feedback/filled.svg"
            alt="{{ 'account.feedbackIconFilled' | translate }}" />
    </div>
    <div (click)="changeRating(4)">
        <img
            *ngIf="rating < 4"
            src="/assets/icon/feedback/outlined.svg"
            alt="{{ 'account.feedbackIconEmpty' | translate }}" />
        <img
            *ngIf="rating >= 4"
            src="/assets/icon/feedback/filled.svg"
            alt="{{ 'account.feedbackIconFilled' | translate }}" />
    </div>
    <div (click)="changeRating(5)">
        <img
            *ngIf="rating < 5"
            src="/assets/icon/feedback/outlined.svg"
            alt="{{ 'account.feedbackIconEmpty' | translate }}" />
        <img
            *ngIf="rating >= 5"
            src="/assets/icon/feedback/filled.svg"
            alt="{{ 'account.feedbackIconFilled' | translate }}" />
    </div>
</div>
