import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog } from '@core/providers/modal/modal-types';

@Component({
    selector: 'fainin-camera-permissions-dialog',
    templateUrl: './camera-permissions-dialog.component.html',
    styleUrls: ['./camera-permissions-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraPermissionsDialogComponent implements Dialog<void> {
    resolveWith(result: void | undefined): void {}

    constructor() {}

    close() {
        this.resolveWith();
    }
}
