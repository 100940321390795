export default {
    firstName: "Ім'я",
    lastName: 'Прізвище',
    fullName: `Повне ім\'я`,
    personalData: 'Особисті дані',
    address: 'Платіжна адреса',
    contact: 'Контакти',
    aboutYou: 'Про вас',
    password: 'Пароль',
    bank: 'Банківські реквізити',
    bankInfo: '(публічно не видно)',
    save: 'Зберегти',
    title: 'Редагувати профіль',
    streetAndNumber: 'Вулиця та номер будинку',
    plz: 'ЗІП код',
    city: 'Місто',
    phoneNumber: 'Наприклад: +4917534777777',
    email: 'Адреса електронної пошти',
    disclaimer:
        'Ми надішлемо вам електронний лист із підтвердженням, щоб підтвердити вашу нову адресу електронної пошти.',
    description: 'Опис',
    oldPass: 'Старий пароль',
    newPass: 'Новий пароль',
    birthday: 'День народження',
    saved: 'Дані збережено!',
    noUpdates: 'Ніяких нових змін!',
    emailChanged:
        'Електронна адреса змінена! Щоб завершити процес, перевірте нову адресу електронної пошти, щоб підтвердити зміни.',
    dd: 'ДД',
    loading: 'Завантаження...',
    emailRequired: "Електронна адреса обов'язкова!",
};
