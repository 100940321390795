import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FinishTutorialMutation, FinishTutorialMutationVariables, Platform } from '@api/generated-types';
import { FINISH_TUTORIAL } from '@api/vendure/documents.graphql';
import { DataService } from '@core/providers/data/data.service';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

export type OnboardingStep = {
    title: string;
    info: string;
    image: string;
    buttonName: string;
    buttonType: string;
    step: number;
};

@Component({
    selector: 'fainin-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit, OnDestroy {
    private accountDropDownRef: HTMLElement;
    private desktopStep1Ref: HTMLElement;
    private step2Ref: HTMLElement;
    private desktopStep3Ref: HTMLElement;
    private mobileStep3Ref: HTMLElement;
    skipOnBoarding: boolean = false;
    currentStep: number = 1;
    isMobile: boolean = false;
    finishingOnboarding: boolean = false;

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        pagination: { clickable: true },
        navigation: { disabledClass: 'disabled-swiper-nav' },
    };

    onBoardingSteps: OnboardingStep[] = [
        {
            title: this.translate.instant('account.onboarding.step1.title'),
            info: this.translate.instant('account.onboarding.step1.info'),
            image: '/assets/image/onBoarding/step-1.webp',
            buttonName: this.translate.instant('account.onboarding.step1.buttonName'),
            buttonType: 'next',
            step: 1,
        },
        {
            title: this.translate.instant('account.onboarding.step2.title'),
            info: this.translate.instant('account.onboarding.step2.info'),
            image: '/assets/image/onBoarding/step-2.webp',
            buttonName: this.translate.instant('account.onboarding.step2.buttonName'),
            buttonType: 'next',
            step: 2,
        },
        {
            title: this.translate.instant('account.onboarding.step3.title'),
            info: this.translate.instant('account.onboarding.step3.info'),
            image: '/assets/image/onBoarding/step-3.webp',
            buttonName: this.translate.instant('account.onboarding.step3.buttonName'),
            buttonType: 'start',
            step: 3,
        },
    ];

    skipOnBoardingStep: OnboardingStep = {
        title: this.translate.instant('account.onboarding.skip.title'),
        info: this.translate.instant('account.onboarding.skip.info'),
        image: '/assets/image/onBoarding/skip-intro.webp',
        buttonName: this.translate.instant('account.onboarding.skip.buttonName'),
        buttonType: 'start',
        step: 4,
    };

    containerClicked(event: any) {
        event.stopPropagation();
    }

    constructor(
        private dataService: DataService,
        private elementRef: ElementRef,
        private stateService: StateService,
        private cd: ChangeDetectorRef,
        private observer: BreakpointObserver,
        private translate: TranslateService,
    ) {}

    toggleOnBoardingSkip(comingFromSkipPage: boolean) {
        //For showing the correct set of markers
        comingFromSkipPage ? (this.currentStep = 1) : (this.currentStep = 4);
        this.skipOnBoarding = !this.skipOnBoarding;
    }

    private updateStep1Marker() {
        this.desktopStep1Ref = <HTMLElement>document.querySelector('[onboarding-id="product-upload-menu-option"]');

        if (this.desktopStep1Ref) {
            this.elementRef.nativeElement.style.setProperty(
                '--desktop-step-1-left',
                this.desktopStep1Ref.getBoundingClientRect().left.toString() + 'px',
            );
            this.elementRef.nativeElement.style.setProperty(
                '--desktop-step-1-top',
                this.desktopStep1Ref.getBoundingClientRect().top.toString() + 'px',
            );

            this.elementRef.nativeElement.style.setProperty(
                '--desktop-step-1-width',
                this.desktopStep1Ref.getBoundingClientRect().width.toString() + 'px',
            );
        }
    }

    private updateStep2Marker() {
        this.step2Ref = <HTMLElement>document.querySelector('[onboarding-id="rentals-menu-button"]');

        if (!this.isMobile) {
            if (this.step2Ref) {
                this.elementRef.nativeElement.style.setProperty(
                    '--desktop-step-2-left',
                    this.step2Ref.getBoundingClientRect().left.toString() + 'px',
                );
                this.elementRef.nativeElement.style.setProperty(
                    '--desktop-step-2-top',
                    this.step2Ref.getBoundingClientRect().top.toString() + 'px',
                );
            }
        } else {
            if (this.step2Ref) {
                this.elementRef.nativeElement.style.setProperty(
                    '--mobile-step-2-left',
                    this.step2Ref.getBoundingClientRect().x.toString() + 'px',
                );
                this.elementRef.nativeElement.style.setProperty(
                    '--mobile-step-2-bottom',
                    this.step2Ref.getBoundingClientRect().y.toString() + 'px',
                );
            }
        }
    }
    private updateStep3Marker() {
        if (!this.isMobile) {
            this.desktopStep3Ref = <HTMLElement>document.querySelector('[onboarding-id="profile-menu-option"]');

            if (this.desktopStep3Ref) {
                this.elementRef.nativeElement.style.setProperty(
                    '--desktop-step-3-left',
                    this.desktopStep3Ref.getBoundingClientRect().left.toString() + 'px',
                );
                this.elementRef.nativeElement.style.setProperty(
                    '--desktop-step-3-top',
                    this.desktopStep3Ref.getBoundingClientRect().top.toString() + 'px',
                );

                this.elementRef.nativeElement.style.setProperty(
                    '--desktop-step-3-width',
                    this.desktopStep3Ref.getBoundingClientRect().width.toString() + 'px',
                );

                this.elementRef.nativeElement.style.setProperty('--desktop-step-3-margin', '-25px');
            }
        } else {
            this.mobileStep3Ref = <HTMLElement>document.querySelector('[onboarding-id="tab-bar-profile"]');
            if (this.mobileStep3Ref) {
                this.elementRef.nativeElement.style.setProperty(
                    '--mobile-step-3-left',
                    this.mobileStep3Ref.getBoundingClientRect().x.toString() + 'px',
                );
                this.elementRef.nativeElement.style.setProperty(
                    '--mobile-step-3-bottom',
                    this.mobileStep3Ref.getBoundingClientRect().y.toString() + 'px',
                );
            }
        }
    }

    onNext() {
        <HTMLElement>this.elementRef.nativeElement.querySelector('.swiper-button-next').click();
        this.currentStep++;
        if (this.currentStep === 2) {
            this.updateStep2Marker();
        }
        if (this.currentStep === 3) {
            this.updateStep3Marker();
        }
        this.cd.markForCheck();
    }

    onPrevious() {
        if (this.currentStep > 1) {
            <HTMLElement>this.elementRef.nativeElement.querySelector('.swiper-button-prev').click();
            this.currentStep--;
            this.cd.markForCheck();
        }
    }

    finishOnBoarding() {
        this.finishingOnboarding = true;
        this.dataService
            .mutate<FinishTutorialMutation, FinishTutorialMutationVariables>(FINISH_TUTORIAL, {
                platform: Platform.WEB,
            })
            .subscribe(success => {
                if (success) {
                    this.stateService.toggleState('webTutorialSeen', true);
                    this.cd.markForCheck();
                }
            });
        this.finishingOnboarding = false;
    }

    ngOnInit(): void {
        this.accountDropDownRef = <HTMLElement>document.querySelector('[onboarding-id="account-dropdown-button"]');
        this.observer
            .observe('(max-width: 575px)')
            .pipe(
                untilDestroyed(this),
                map(value => value.matches),
            )
            .subscribe((value: boolean) => {
                this.isMobile = value;
            });

        setTimeout(() => {
            this.updateStep1Marker();
            this.updateStep2Marker();
            this.updateStep3Marker();
        }, 1000);

        this.cd.markForCheck();
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {}
}
