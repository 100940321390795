export default {
    message: [
        `fainin использует файлы cookie и аналогичные технологии для персонализации контента и оптимизации работы пользователя.
Нажимая кнопку OK или выбирая опцию в настройках cookie, вы соглашаетесь с этим.
Об этом также говорится в нашей`,
        `Политике конфиденциальности`,
        `Чтобы изменить свои настройки или отозвать свое согласие,
просто обновите настройки файлов cookie.
aktualisiere einfach Deine Cookie-Einstellungen.`,
    ],
    title: 'Мы заботимся о защите данных',
    intro:
        'Когда вы посещаете веб-сайт в Интернете, он может хранить и/или получать информацию в вашем браузере. ' +
        'В основном в виде так называемых файлов cookie. Эта информация связана с,' +
        'настройками или устройством пользователя и используется в основном для того, чтобы сайт работал так,' +
        'как ожидается. Поскольку информация (обычно) анонимизирована, она, как правило, не идентифицирует вас напрямую, ' +
        'но помогает персонализировать ваш опыт. Поскольку мы уважаем ваши права на конфиденциальность, ' +
        'вы можете не разрешать некоторые файлы cookie. Однако такая блокировка может повлиять на ' +
        'ваш опыт работы в Интернете и наши услуги.',
    heading: 'Защитите свою конфиденциальность',
    policy: 'Конфиденциальность',
    button: {
        save: 'Сохранить',
        back: 'Назад',
        customize: 'Настройки Cookie',
        accept: 'Принимать все',
    },
    consent: {
        base: {
            title: 'Необходимые файлы cookie',
            description:
                'Эти файлы cookie необходимы для функционирования и отображения веб-сайта и не могут быть ' +
                'выключенными. Например, установка предпочтений конфиденциальности, вход в систему, ' +
                'мера безопасности ReCaptcha или заполнение форм на этом сайте.',
        },
        analytics: {
            title: 'Анонимные аналитические данные',
            description:
                'Эта настройка необходима для анонимного анализа посещений и источников трафика. Давая согласие на сбор общих аналитических данных, ты позволяешь нам понять, как используется наш сайт. Эти данные помогают нам анализировать и улучшать работу нашего сайта, предлагая тебе лучший пользовательский опыт. Твои данные собираются и обрабатываются анонимно, чтобы защитить твою конфиденциальность. Ты активно помогаешь нам оптимизировать наши контент и услуги для тебя и других.',
        },
        ad_user_data: {
            title: 'Понимание твоих интересов',
            description:
                'Эта настройка позволяет нам собирать данные, которые помогают нам лучше понять твои интересы и предпочтения. Это включает в себя информацию, такую как посещаемые тобой сайты или просматриваемые продукты. Это согласие помогает нам сделать твой опыт более индивидуальным и релевантным.',
        },
        ad_personalization: {
            title: 'Реклама, соответствующая тебе',
            description:
                'С твоего разрешения мы можем использовать собранные о тебе данные для персонализации рекламы, которую ты видишь. Это означает меньше времени с незаинтересованными объявлениями и больше открытий продуктов и услуг, которые действительно тебя интересуют. Ты сохраняешь контроль над этой настройкой и можешь в любое время ее изменить.',
        },
        ad_storage: {
            title: 'Запоминание твоих предпочтений',
            description:
                'Твое согласие позволяет нам хранить информацию на твоем устройстве, чтобы запомнить твои предпочтения и повторные посещения. Это обеспечивает максимально гладкий опыт использования, с более быстрыми временами загрузки и рекламой, соответствующей тому, что ты любишь.',
        },
    },
};
