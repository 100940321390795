import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, retry, take } from 'rxjs/operators';
import PlaceResult = google.maps.places.PlaceResult;

export interface GeocodingResultEntity {
    address_components?: AddressComponentsEntity[] | null;
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    types?: string[] | null;
}

export interface Results {
    results: GeocodingResultEntity[];
    status: string;
}

export interface AddressComponentsEntity {
    long_name: string;
    short_name: string;
    types?: string[] | null;
}
export interface Geometry {
    location: NortheastOrSouthwestOrLocation;
    location_type: string;
    viewport: Viewport;
}
export interface NortheastOrSouthwestOrLocation {
    lat: number;
    lng: number;
}
export interface Viewport {
    northeast: NortheastOrSouthwestOrLocation;
    southwest: NortheastOrSouthwestOrLocation;
}

@Injectable({
    providedIn: 'root',
})
export class GoogleGeocodingService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Apply geocoding to get location coordinates for an address
     * @param geolocationAddress location address string
     */
    getLocationCoords(geolocationAddress: string): Observable<GeocodingResultEntity[]> {
        return this.httpClient
            .get<Results>(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${geolocationAddress}&key=${environment.googleDynamicMapsApiKey}`,
            )
            .pipe(
                retry(3),
                take(1),
                map(value => value.results),
            );
    }

    /**
     * Uses the Google Geocoding API to get the address components of location coordinates
     * @param lat
     * @param lng
     */
    getLocationAddressComponents(lat: number, lng: number): Observable<GeocodingResultEntity[]> {
        return this.httpClient
            .get<Results>(
                'https://maps.googleapis.com/maps/api/geocode/' +
                    'json?' +
                    'latlng=' +
                    lat +
                    ',' +
                    lng +
                    '&key=' +
                    environment.googleDynamicMapsApiKey,
            )
            .pipe(
                retry(3),
                take(1),
                map(value => value.results),
            );
    }

    /**
     * Reverse geocodes coordinates into cityname, zipcode and neighborhood. This function
     * flattens the result from {@link getLocationAddressComponents} to an object with data we
     * are interested in.
     * @param latitude
     * @param longitude
     */
    parseLocationAddress(latitude: number, longitude: number) {
        return this.getLocationAddressComponents(latitude, longitude).pipe(
            map(geocodingResult => {
                const { zip, locality, neighborhood, formatted_address } = this.extractAddressComponents(
                    geocodingResult[0],
                );
                return {
                    zip: zip?.long_name,
                    locality: locality?.long_name,
                    neighborhood: neighborhood?.long_name,
                    locationString: formatted_address,
                };
            }),
        );
    }

    /**
     * Extracts the address components from a geocoding result
     * @param geocodingResult
     */
    extractAddressComponents(geocodingResult: GeocodingResultEntity | PlaceResult) {
        const zip = geocodingResult.address_components?.find(l => l.types && l.types[0] === 'postal_code');
        const locality = geocodingResult.address_components?.find(l => l.types && l.types[0] === 'locality');
        const neighborhood = geocodingResult.address_components?.find(l => l.types && l.types[0] === 'neighborhood');
        const formatted_address = geocodingResult.formatted_address;

        return { zip, locality, neighborhood, formatted_address };
    }
}
