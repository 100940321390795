import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const LOGOUT_EVENT_NAME = 'LogoutEvent';

export interface ILogoutResponsePayload extends IResponsePayload {
    success: boolean;
}

export type ILogoutEvent = IWebViewEvent<any, ILogoutResponsePayload>;

export class LogoutEvent extends AWebViewEvent<any, ILogoutResponsePayload> implements ILogoutEvent {
    constructor() {
        super(LOGOUT_EVENT_NAME);
    }
}

export default [LOGOUT_EVENT_NAME, LogoutEvent];
