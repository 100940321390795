import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'fainin-soon-label',
    templateUrl: './soon-label.component.html',
    styleUrls: ['./soon-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoonLabelComponent {
    constructor() {}
}
