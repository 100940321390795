// `.env.ts` is generated by the `npm run env` command
import { LogLevel } from '@core/utils/logger';
// `npm run env` exposes environment variables as JSON for any usage you might
import { env } from '@env/.env';

export const environment = {
    name: 'production',
    production: true,
    logLevel: LogLevel.Warning,
    domain: 'fainin.com',
    hostUrl: 'https://fainin.com',
    shopApiUrl: 'https://fainin.stellate.sh', // Staging Server: https://api.fainin.dev Local Development: http://localhost:3000
    baseApiUrl: 'https://api.fainin.com',
    baseHref: '/',
    tokenMethod: 'bearer',
    publicChannelToken: 'if7th3zalezv0at9sxrn',
    strapiUrl: 'https://strapiv2.fainin.com',
    mailchimpSubscriberUrl:
        'https://fainin.us12.list-manage.com/subscribe/post-json?u=ea6d228321d874fddeb06cf40&amp;id=c79b7cfabc',
    version: env.npm_package_version,
    defaultLanguage: 'de-DE',
    supportedLanguages: ['en-US', 'de-DE', 'uk-UA', 'ru-RU'],
    googleAnalyticsId: 'G-NEMTK0D7NH',
    googleTagsId: 'GTM-N7CXSP9',
    reCaptchaSiteKey: '6LcnbfQbAAAAACzf2u1MbEudx3HHZ_iwmV50bJHh',
    googleDynamicMapsApiKey: 'AIzaSyDWxZjZqbdYe0AEHqj4o3vKFoW7ohSdG2M',
    allowIndexing: true,
    disallowList: ['/auth/', '/account/', '/profile/'],
    featureFlags: {
        product: true,
        collection: true,
        account: true,
        blog: true,
        gamification: true,
        groups: true,
    },
    faininSocketPath: '/fainin-socket',
    GoogleOauthWebId: '814990397176-8df0on1hsi8orgh1ponqqk3vbsj4p1uv.apps.googleusercontent.com',
    sumSubApi: 'https://api.sumsub.com',
    appleClientId: 'com.fainin.FaininApp.service',
    stripeKey: 'pk_live_GhgEwLJrYkQsXjBKybU3tyPN',
    googleLoginPluginName: 'Fainin-Production',
    talkJsAppId: '3siA2Icx',
    keepLogs: false,
};
