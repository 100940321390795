import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { FontAwesomeCoreModule } from '@core/fontAwesome-core.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterSharedModule } from '@shared/footer-shared.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { LayoutAlertHeaderComponent } from './components/layout/layout-alert-header/layout-alert-header.component';
import { LayoutFooterComponent } from './components/layout/layout-footer/layout-footer.component';
import {
    LayoutHeaderComponent,
    LayoutHeaderNavComponent,
} from './components/layout/layout-header/layout-header.component';
import { LayoutLogoComponent } from './components/layout/layout-logo/layout-logo.component';
import { LayoutNavigationHeaderComponent } from './components/layout/layout-navigation-header/layout-navigation-header.component';
import { LayoutSearchBarComponent } from './components/layout/layout-search-button/layout-search-bar.component';
import { LayoutSubHeaderComponent } from './components/layout/layout-sub-header/layout-sub-header.component';
import { LayoutComponent } from './components/layout/layout.component';

const CORE_COMPONENTS = [
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutSubHeaderComponent,
    LayoutHeaderNavComponent,
    LayoutFooterComponent,
    LayoutLogoComponent,
    LayoutSearchBarComponent,
    LayoutNavigationHeaderComponent,
];

@NgModule({
    declarations: [...CORE_COMPONENTS, LayoutAlertHeaderComponent],
    imports: [
        NzInputModule,
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule,
        FooterSharedModule,
        CoreModule,
        FontAwesomeCoreModule,
    ],
    exports: [...CORE_COMPONENTS],
})
export class LayoutCoreModule {}
