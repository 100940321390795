import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CtaNewsletterComponent } from '@shared/components/cta-newsletter/cta-newsletter.component';
import { NewsletterSignupComponent } from '@shared/components/newsletter-signup/newsletter-signup.component';
import { ButtonSharedModule } from './button-shared.module';

@NgModule({
    declarations: [NewsletterSignupComponent, CtaNewsletterComponent],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule, ButtonSharedModule],
    exports: [NewsletterSignupComponent, CtaNewsletterComponent],
})
export class NewsletterSharedModule {}
