import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TabBarComponent } from '@core/components/layout/tab-bar/tab-bar.component';
import { SharedModule } from '@shared/shared.module';
import { ApolloModule } from 'apollo-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SwiperModule } from 'swiper/angular';
import { AssetGalleryCoreModule } from './asset-gallery-core.module';
import { AccountProductTableComponent } from './components/account-product-table/account-product-table.component';
import { AssetSwiperGalleryComponent } from './components/asset-swiper-gallery/asset-swiper-gallery.component';
import { CollectionBreadcrumbsComponent } from './components/collection-breadcrumbs/collection-breadcrumbs.component';
import { CollectionListComponent } from './components/collection-list/collection-list.component';
import { CollectionsMenuComponent } from './components/collections-menu/collections-menu.component';
import { EmptyStateCardComponent } from './components/empty-state-card/empty-state-card.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { RequestReceivedDialogComponent } from './components/request-received-dialog/request-received-dialog.component';
import { SecondaryFooterContentComponent } from './components/secondary-footer-content/secondary-footer-content.component';
import { FontAwesomeCoreModule } from './fontAwesome-core.module';
import { ProductCardCoreModule } from './product-card-core.module';

const CORE_COMPONENTS = [
    CollectionsMenuComponent,
    CollectionBreadcrumbsComponent,
    CollectionListComponent,
    RequestReceivedDialogComponent,
    EmptyStateCardComponent,
    TabBarComponent,
    OnboardingComponent,
    AccountProductTableComponent,
];

@NgModule({
    declarations: [...CORE_COMPONENTS, SecondaryFooterContentComponent],
    imports: [
        HttpClientModule,
        SharedModule,
        CommonModule,
        SwiperModule,
        NzInputModule,
        NzImageModule,
        NzToolTipModule,
        NzSkeletonModule,
        NzButtonModule,
        NzTableModule,
        NzDividerModule,
        NzPaginationModule,
        LazyLoadImageModule,
        ApolloModule,
        FontAwesomeCoreModule,
        ProductCardCoreModule,
        AssetGalleryCoreModule,
        NgOptimizedImage,
        FormsModule,
    ],
    exports: [
        ...CORE_COMPONENTS,
        AssetSwiperGalleryComponent,
        SwiperModule,
        ProductCardCoreModule,
        SecondaryFooterContentComponent,
    ],
})
export class CoreModule {}
