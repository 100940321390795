<ng-container *ngIf="currentLang === 'de-DE'; else englishTerms">
    <div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
        <div class="container text-center">
            <h1 class="mt-5 text-light">Allgemeine Geschäftsbedingungen</h1>
            <span class="spacer-accent mx-auto"></span>
            <h3 class="mt-3 text-light">#TeilenIstDasNeueHaben</h3>
        </div>
    </div>

    <div class="container my-5">
        <h3>{{ 'Begriffsbestimmungen' | uppercase }}:</h3>
        <p><strong>App:</strong> sämtliche Anwendungen oder Systeme für die Bereitstellung des Dienstes an Sie.</p>
        <p>
            <strong>Provisionen:</strong> “Gebühren“ sind die Gebühren, die der Nutzer für diesen Dienst zu zahlen hat.
        </p>
        <p>
            <strong>Geistiges Eigentum bezeichnet:</strong> (a) Urheberrechte; (b) alle Rechte aus Gesetzen, Fallrecht
            (bzw. bürgerlichem Recht) und Billigkeitsrecht (einschließlich Patenten); (c) eingetragene und nicht
            eingetragene Marken; (d) eingetragene und nicht eingetragene Geschmacksmuster; (e) Produktbilder; (f)
            vertrauliche Informationen; (g) Produktbeschreibungen, Grafiken und Code; und (h) alle anderen Rechte, die
            sich aus intellektueller Tätigkeit im gewerblichen, wissenschaftlichen, literarischen oder künstlerischen
            Bereich ergeben.
        </p>
        <p>
            <strong>Artikel:</strong> Bezeichnet einen Gegenstand, der über diesen Dienst von einem Vermieter an einen
            Mieter vermietet oder von einem Verkäufer an einen Käufer verkauft wird.
        </p>
        <p>
            <strong>Artikelproblem:</strong> Bezeichnet ein Materialproblem oder ein anderes Problem mit einem über
            diesen Dienst von einem Vermieter an einen Mieter vermieteten oder von einem Verkäufer an einen Käufer
            verkauften Artikel, wie z.B. kurzfristige Kündigung der Miete durch den Vermieter, verspäteter Erhalt des
            Artikels im Fall von Versand, dass der Vermieter dem Mieter die gewünschte Nutzung nicht gestattet oder dass
            der Vermieter den Zustand und die Merkmale des Artikels nicht korrekt beschrieben hat.
        </p>

        <p>
            <strong>Fainin:</strong> Bezeichnet “Fainin“ sowie “wir“, “uns“, “unser/e“, d.h. die Partei, welche das
            “Produkt“, die “App“, die “Anwendung“, die “Website“ oder die “Dienste“ für die „Nutzer“ bereitstellt.
        </p>
        <p>
            <strong>Produkte:</strong> alle Dienste, Produkte und Online-Dienste, die wir für unsere Nutzer
            bereitstellen.
        </p>
        <p>
            <strong>Dienste:</strong> Alle Apps, Websites und anderen Produkte oder Dienste, die wir für unsere Nutzer
            bereitstellen.
        </p>
        <p>
            <strong>AGB:</strong> Allgemeine Geschäftsbedingungen von Fainin, AGB, Datenschutzerklärung sowie für die
            Dienste geltenden Bedingungen.
        </p>
        <p>
            <strong>Nutzer:</strong> Bezeichnet „Mieter“, „Vermieter“, „Käufer“, „Verkäufer“, „Sie“, „Du“, „dich“,
            „dir“, „Unternehmen“, „Mitglied“ sowie jede andere Person, welche entweder als Gast oder über ein Konto auf
            den Dienst von Fainin zugreift.
        </p>
        <p>
            <strong>Website:</strong> Website, Webseite, Homepage, Seite, Anwendungen und alle Websites, die in
            Zusammenhang mit dem Diensten genutzt werden.
        </p>
        <p>
            <strong>Community:</strong> Bezeichnet die Gruppenfunktion von fainin. Dies sind zum einen öffentliche
            Communities, in die jeder Nutzer eintreten kann, sowie exklusive Communities, bei welchen der Eintritt durch
            bestimmte Kriterien definiert ist. Exklusive Communities werden von uns im Auftrag von Kunden erstellt. Für
            Mitglieder von exklusiven Communities können besondere Nutzungsbedingungen gelten, diese werden ebenfalls in
            diesen AGB genannt.
        </p>
        <p>
            <strong>Kunde:</strong> Oder auch „Kunden“ bezeichnet, die oder den Auftraggeber einer exklusiven fainin
            Community. Auftraggeber einer exklusiven Community unterliegen zusätzlich zu diesen AGB einem gesonderten
            Rahmenvertrag, der zwischen fainin und dem Kunden geschlossen wird.
        </p>
        <hr />
        <ol class="terms-listing">
            <li>
                <span>Durch Nutzung dieser Dienste, die durch uns (Fainin) über unsere App oder Website</span>
                bereitgestellt wurden, stimmen Sie (Nutzer) uneingeschränkt und unwiderruflich den Allgemeinen
                Geschäftsbedingungen (AGB) von Fainin zu. Wir können Ihnen auch andere AGB und Richtlinien bei der
                Nutzung dieser Produkte und Dienste vorlegen. Durch die weitere Nutzung bestätigen Sie die Annahme
                dieser zusätzlichen AGB. Die AGB des Nutzers werden hiermit ausdrücklich ausgeschlossen.
            </li>
            <li>
                Sind Sie Nutzer einer exklusiven Community, können abweichende Vereinbarungen zu den AGB getroffen
                werden. Sie haben die Pflicht, diese Sonderkonditionen eigenständig vom Inhaber der Community zu
                erfragen. Dieser hat die Pflicht, Ihnen die Konditionen rechtzeitig zur Verfügung zu stellen.
                Community-Sonderkonditionen für Nutzer können immer nur gleichwertig oder besser stellend zu unseren AGB
                vereinbart werden.
            </li>
            <li class="uppercase">
                LESEN SIE DIE AGB BITTE SORGFÄLTIG DURCH. DURCH DEN ZUGRIFF BZW. NUTZUNG DER APP / WEBSITE ERKENNEN SIE
                AN, DASS SIE ALLE BESTIMMUNGEN UNSERER NUTZUNGSBEDINGUNGEN GELESEN, ZUR KENNTNIS GENOMMEN UND AKZEPTIERT
                HABEN. DIE NUTZUNG SOLCHER DIENSTE UND DIE MITTEILUNG EMPFINDLICHER DATEN IST MIT BESTIMMTEN RISIKEN
                VERBUNDEN. WENN SIE DIESE BEDINGUNGEN NICHT AKZEPTIEREN, VERLASSEN SIE BITTE JETZT DIESEN DIENST UND
                UNTERLASSEN SIE DIE ZUKÜNFTIGE NUTZUNG. IHNEN ENTSTEHEN DADURCH KEINERLEI KOSTEN ODER PFLICHTEN.
            </li>
            <li>
                <span class="terms-label">Nutzung der Diente</span>
                <div>
                    Wir stellen unseren Nutzern eine Sharing Economy-Plattform bereit. Sie weisen persönliche
                    Informationen wie z.B. Geodaten entweder als Nutzer mit Konto oder als Gast nach. Durch Nutzung des
                    Produkts, des Dienstes, der App und der Website erkennen Sie diese AGB von Fainin an. Sie
                    akzeptieren, dass wir Ihre Anmeldung oder Ihr Konto löschen können, wenn sie unsere AGB verletzen.
                    Im Rahmen dieser Dienste können wir einen Teil Ihrer persönlichen Informationen und
                    Anmeldeinformationen aufzeichnen und verwenden, um einen hohen Service- Standard zu gewährleisten.
                    Weitere Informationen finden Sie in unserer Datenschutzerklärung.
                </div>
            </li>
            <li>
                <span class="terms-label">Voraussetzungen und Anmeldung</span>
                <div>
                    Sie müssen folgende Voraussetzungen erfüllen, um sich anzumelden und die Produkte, Dienste, App und
                    Website nutzen zu können:<br />
                    <span class="term-number">5.1</span> Mindestens achtzehn (18) Jahre alt sein oder die Einwilligung
                    eines Erziehungsberechtigten haben oder eine juristische Person sein (Unternehmen, Behörde,
                    Gewerbetreibende etc.);<br />
                    <span class="term-number">5.2</span> Anmeldung für die Produkte, Dienste, App und Website nur für
                    die beabsichtigten Zwecke dieser Dienste; und<br />
                    <span class="term-number">5.3</span> Anmeldung für die Produkte, Dienste, App und Website nur nach
                    Maßgabe der geltenden Gesetze in Ihrem Staat oder Land.
                </div>
            </li>
            <li>
                <span class="terms-label">Verantwortung für Minderjährige</span>
                <div>
                    Falls Sie einem Minderjährigen die Nutzung dieser Dienste gestattet haben, erkennen Sie an, dass Sie
                    voll verantwortlich sind für:<br />
                    <span class="term-number">6.1</span> Das Online-Verhalten des Minderjährigen;<br />
                    <span class="term-number">6.2</span> Die Kontrolle des Zugriffs und der Nutzung des Dienstes durch
                    den Minderjährigen; und<br />
                    <span class="term-number">6.3</span> Die Folgen eines Missbrauchs durch den Minderjährigen. Sie
                    nehmen zur Kenntnis, dass einige Bereiche des Dienstes, der App und der Website Material enthalten
                    können, das für Minderjährige nicht geeignet ist.
                </div>
            </li>
            <li>
                <span class="terms-label">Anmeldung, Konten und Login</span>
                <div>
                    <span class="term-number">7.1</span> Nutzer können sich für die Website und den Dienst
                    folgendermaßen einloggen:<br />
                    <span class="term-number">7.1.1</span> Fainin-Konto;<br />
                    <span class="term-number">7.1.2</span> Facebook-Login;<br />
                    <span class="term-number">7.1.3</span> Login durch Google;<br />
                    <span class="term-number">7.1.4</span>
                    Login durch Instagram;<br />
                    <span class="term-number">7.1.5</span> Login durch Twitter;<br />
                    <span class="term-number">7.1.6</span> Sonstige soziale Medien.<br />
                    <span class="term-number">7.2.</span> Die Anmeldung für ein Fainin-Konto ist kostenlos. <br />
                </div>
            </li>
            <li>
                <span class="terms-label">Vertrauliche Informationen</span>
                <div>
                    Sie können einen eindeutigen Nutzernamen und ein Passwort erhalten, um mit Ihrem Login auf die
                    Website und App zuzugreifen. Es liegt in ihrer Verantwortung, dass diese Daten vertraulich, sicher
                    und geschützt aufbewahrt werden. Wenn zwischen uns weitere aufgaben- und auftragsspezifische Details
                    vereinbart werden, werden über die Website keine weiteren persönlichen Informationen gespeichert.
                    Diese Details können z.B. Anmeldeinformationen oder persönliche Daten sein. Weitere Informationen
                    zur Informationsspeicherung finden Sie unter „Datenschutzerklärung“ auf unserer Website.
                </div>
            </li>
            <li>
                <span class="terms-label">Nutzerverantwortung</span>
                <div>
                    <span class="term-number">9.1</span> Wir versuchen die App, Website und Dienste so korrekt und
                    aktuell wie möglich zu halten. Durch die Nutzung dieser Dienste erklären Sie sich unwiderruflich und
                    bedingungslos damit einverstanden, die folgenden Handlungen zu unterlassen:<br />
                    <span class="term-number">9.1.1</span> Illegale Tätigkeiten;<br />
                    <span class="term-number">9.1.2</span>
                    Verstoß gegen geltende Gesetze;<br />
                    <span class="term-number">9.1.3</span> Verstoß gegen diese AGB;<br />
                    <span class="term-number">9.1.4</span> Zugriff auf die Dienste über Systemautomatisierung wie z.B.
                    Spambots;<br />
                    <span class="term-number">9.1.5</span> Jegliche Spam-Aktivitäten solcher Art;<br />
                    <span class="term-number">9.1.6</span>
                    Anmeldung mehrerer Konten pro Benutzer;<br />
                    <span class="term-number">9.1.7</span> Nutzung der App oder Website in einer Weise, die Anderen
                    Schaden zufügen könnte;<br />
                    <span class="term-number">9.1.8</span> Hacking oder Zugriff auf die App oder Website durch andere
                    Methoden als in diesen AGB aufgelistet sind.<br />
                    <span class="term-number">9.2.</span> Fainin ermöglicht seinen Nutzern das Einstellen von Produkten,
                    Anfragen, Texten, Bildern und weiteren Inhalten. Das Einstellen von Inhalten die gegen gesetzliche
                    Bestimmungen, diese Nutzungsbedingungen oder Rechte Dritter verstoßen ist untersagt. Insbesondere
                    ist es verboten Inhalte einzustellen,<br />
                    <span class="term-number">9.2.1</span> die gegen urheber-, marken- und wettbewerbsrechtliche
                    Vorschriften verstoßen<br />
                    <span class="term-number">9.2.2</span>
                    die gegen Jugendschutzbestimmungen verstoßen,<br />
                    <span class="term-number">9.2.3</span> die Aufrufe zu Straftaten oder Geldspenden enthalten,<br />
                    <span class="term-number">9.2.4</span> die beleidigender, beschimpfender, diskriminierender,
                    rassistischer oder nationalsozialistischer Natur sind,<br />
                    <span class="term-number">9.2.5</span> die Pornografie oder Prostitution enthalten, <br />
                    <span class="term-number">9.2.6</span>
                    die Alkohol, Tabakwaren oder Drogen im Sinne des Betäubungsmittelgesetzes, sowie entsprechendes
                    Zubehör enthalten,<br />
                    <span class="term-number">9.2.7</span> die Medien mit der Kennzeichnung “Keine Jugendfreigabe”,
                    “Freigabe ab 18”, “FSK 18”, “USK 18” oder sinngemäß identische Medien enthalten,<br />
                    <span class="term-number">9.2.8</span> die gewaltverherrlichender oder für anderer Nutzer
                    verstörender Natur sein könnten.<br />
                    <span class="term-number">9.3</span> Wir behalten uns vor, diese Vereinbarung nach unserem
                    alleinigen Ermessen zu sperren oder zu kündigen, wenn diese AGB verletzt werden. Wir empfehlen
                    Nutzern, direkt über die App oder Website von Fainin zu handeln und andere Nutzer nicht direkt zu
                    kontaktieren, besonders wenn der Erstkontakt durch die App oder Website erfolgte. Ansonsten bestehen
                    kein Versicherungsschutz und kein Anspruch auf Kundensupport oder sonstige Dienste.
                </div>
            </li>
            <li>
                <span class="terms-label">Feedback-Richtlinie</span>
                <div>
                    <span class="term-number">10.1</span> Unsere Dienste enthalten einen Bereich für Bewertungen. Diese
                    Bewertungen sind nützlich für andere (potenzielle) Nutzer. Wir wollen diesen Dienst als
                    Zusatzleistung für alle Nutzer anbieten und bitten Sie um Ihre Mitwirkung durch Beachtung der
                    folgenden Bedingungen.<br />
                    <span class="term-number">10.2</span> Die Feedback-Bedingungen beinhalten u.a.:<br />
                    <span class="term-number">10.2.1</span> Bitte keine Doppelbewertungen hinterlassen;<br />
                    <span class="term-number">10.2.2</span>
                    Keine externen bzw. irrelevanten Links in Bewertungen posten;<br />
                    <span class="term-number">10.2.3</span> Bewertungen nicht zur Eigenwerbung nutzen;<br />
                    <span class="term-number">10.2.4</span> Keine persönlichen Informationen oder Details posten;<br />
                    <span class="term-number">10.2.5</span> Keine volksverhetzenden, schädlichen oder verletzenden
                    Bewertungen posten;<br />
                    <span class="term-number">10.2.6</span> Keine beleidigenden Bewertungen posten;<br />
                    <span class="term-number">10.2.7</span> Möglichst alle genannten Produkte richtig buchstabieren;
                    und<br />
                    <span class="term-number">10.2.8</span>
                    Ausreichende Details und richtige Angaben posten.<br />9.3. Wir behalten uns vor:<br />
                    <span class="term-number">10.3.1</span> Bewertungen falls nötig zu bearbeiten oder zu löschen;<br />
                    <span class="term-number">10.3.2</span> Weitere Informationen oder Beweise anzufordern;<br />
                    <span class="term-number">10.3.3</span>
                    Zu prüfen, ob der der Nutzer das Produkt tatsächlich verwendet hat; und<br />
                    <span class="term-number">10.3.4</span> Nutzerkonten bei Verletzung dieser Bedingungen zu sperren
                    oder zu löschen;
                </div>
            </li>
            <li>
                <span class="terms-label">Artikel mieten und vermieten</span>
                <div>
                    <span class="term-number">11.1</span> Der Mieter schickt dem Vermieter eine Mietanfrage für einen
                    Artikel. Wird die Anfrage angenommen, wird die Zahlung getätigt und von Fainin treuhänderisch
                    empfangen. Der Vermieter und der Mieter entscheiden dann, ob Sie den Artikel persönlich übergeben
                    oder versenden. Nach Versand oder persönlicher Übergabe des Artikels, bestätigt der Vermieter dies
                    über die App oder die Website. Im Falle des Versands erkennt der Mieter an, dass es bis zu 3
                    Werktage dauern kann, bis ein Artikel zugestellt wird. Der Mieter bestätigt ebenfalls durch die App
                    oder Website den Empfang des Artikels. Wenn der Mieter den Empfang nicht innerhalb von 4 Werktagen
                    bestätigt, wird der Artikel automatisch als empfangen markiert. Sobald diese zwei Schritte erfolgt
                    sind, wird die Zahlung vom Fainin-Treuhanddienst an den Vermieter freigegeben.<br />
                    <span class="term-number">11.2</span> Sollten Mieter und Vermieter beschließen den Artikel per
                    Versand zu übermitteln, so ist die den Artikel versendende Person dazu verpflichtet ,den Versand
                    mindestens zum Neupreis des Artikels zu versichern. Sollte ein Mieter dieser Pflicht nicht
                    nachkommen und der Artikel auf dem Versandweg beschädigt werden oder verloren gehen, so ist er
                    verpflichtet dem Vermieter den Fehlbetrag zum Neupreis erstatten. Sollte ein Vermieter dieser
                    Pflicht nicht nachkommen, so musss er für entstehende Schäden oder Verluste selbst aufkommen.<br />
                </div>
            </li>
            <li>
                <span class="terms-label">Artikel kaufen und verkaufen</span>
                <div>
                    Der Käufer schickt eine Kaufanfrage für einen Artikel an den Verkäufer. Wird die Anfrage angenommen,
                    wird die Zahlung getätigt und von Fainin treuhänderisch empfangen. Der Käufer und der Verkäufer
                    entscheiden dann, ob Sie den Artikel persönlich übergeben oder versenden. Nach Versand oder
                    persönlicher Übergabe des Artikels, bestätigt der Verkäufer dies über die App oder die Website. Im
                    Falle des Versands erkennt der Käufer an, dass es bis zu 3 Werktage dauern kann, bis ein Artikel
                    zugestellt wird. Der empfangende Käufer bestätigt über die App oder Website auch den Empfang des
                    Artikels und bestätigt somit auch, den Artikel im selben Zustand zurückzugeben, abgesehen von
                    normalen Gebrauchsspuren und Verschleiß. Wenn der Käufer den Empfang nicht innerhalb von 4
                    Arbeitstagen bestätigt, wird der Artikel automatisch als empfangen markiert. Sobald diese zwei
                    Schritte erfolgt sind, wird die Zahlung vom Fainin-Treuhanddienst an den Verkäufer freigegeben.
                </div>
            </li>
            <li>
                <span class="terms-label">Preise und Servicegebühren</span>
                <div>
                    <span class="term-number">13.1</span> Die Preise einer Miete auf Fainin setzen sich aus mehreren
                    Bestandteilen zusammen. Dies sind zum einen der Mietpreis des Artikels, eine Servicegebühr und
                    gegebenenfalls Versandkosten. Der Mietpreis eines Artikel wird stets vom dem/der Vermieter:in
                    bestimmt. Wir behalten uns jedoch das Recht vor, für längere Mieten Rabatte an Mieter zu gewähren,
                    die Höhe dieser Rabatte zu bestimmen und bei Bedarf auch anzupassen. Zusätzlich zum Mietpreis des
                    Artikels, fällt für den/die Mieter:in bei der Buchung eines Artikels eine Servicegebühr in Höhe von
                    15% an. Sollte darüber hinaus eine kostenpflichtige Versandoption für die Miete gewählt worden sein,
                    so wird diese ebenfalls bei der Buchung fällig. Nach erfolgreichem Erhalt des Artikels, wie in 10.
                    beschrieben, veranlassen wir die Auszahlung an den/die Vermieter:in. Die Auszahlung beträgt dabei
                    den Mietpreis abzüglich einer Servicegebühr in Höhe von 15%. In der Servicegebühr von sowohl
                    Mieter:in als auch Vermieter:in enthalten sind die Kosten für unsere Versicherungsleistungen wie in
                    30. beschrieben. Wir behalten uns das Recht vor, die Höhe der Servicegebühr und die Verteilung
                    dieser an Mieter:in und Vermieter:in bei Bedarf anzupassen. Sollten Zahlungen für Mietgeschäfte
                    außerhalb der von Fainin auf der Webseite oder App angebotenen Zahlungsmöglichkeiten stattfinden, so
                    verfallen in diesem Fall sämtliche Leistungen von Fainin, insbesondere Ansprüche an unsere
                    Versicherungspartner, für dieses Mietgeschäft. <br />
                    <span class="term-number">13.2</span> Für Mitglieder von exklusiven Communities können abweichende
                    Preise und Servicegebühren gelten. Diese werden individuell mit den Kunden vereinbart und im
                    Rahmenvertrag festgehalten.
                </div>
            </li>
            <li>
                <span class="terms-label">Zahlungs- und Rückgabebedingungen</span>
                <div>
                    <span class="term-number">14.1</span> Die Zahlungsverarbeitung für die Fainin-Dienste erfolgt über
                    Paypal und Stripe und unterliegt somit auch dem Stripe Connected Account Agreement, zu dem auch die
                    Stripe Terms of Service gehören (gemeinsam „Stripe Services Agreement“). Durch Bestätigung dieser
                    Bedingungen oder weitere Nutzung von Fainin als Mieter erklären Sie sich bereit, das Stripe Services
                    Agreement in der aktuell gültigen Fassung verbindlich anzuerkennen. Damit Fainin die Zahlung über
                    Stripe aktivieren kann, erklären Sie sich bereit, Fainin richtige und vollständige Angaben über sich
                    selbst und ihr Unternehmen zu machen. Zudem ermächtigen Sie Fainin, diese Angaben sowie von Stripe
                    bereitgestellte Transaktionsdaten zu Ihrer Nutzung der Zahlungsdienste zu teilen.<br />
                    <span class="term-number">14.2</span> Falls der Artikel per Post versandt wird, können Vermieter und
                    Mieter im Vorauseine eigene Frist vereinbaren. Fainin übernimmt keine Verantwortung für den
                    Versicherungsschutz oder die Zahlung außerhalb der ursprünglich vereinbarten Mietdauer, es sei denn,
                    es wird eine Verlängerung über die App vorgenommen und Servicegebühren werden berechnet. Fainin
                    bietet keinen Versicherungsschutz für den Versand durch einen externen Anbieter. Für den Versand
                    müssen Ihre Daten mit der Versandfirma geteilt werden. Wird der Artikel außerhalb der Mietdauer
                    zurückgegeben, behalten wir uns vor, einen bis zu fünf Mal (5x) höheren Mietpreis, für die Dauer die
                    der Artikel dem Vermieter nicht zugänglich ist, zu berechnen. Die exakte Höhe des erhöhten
                    Mietpreises bestimmt fainin nach Beurteilung der gegebenen Situation. Insbesondere anhand der
                    Umstände, durch die die verspätete Rückgabe zustande gekommen ist, sowie bestehenden Rabatten durch
                    Preisstaffelungen.<br />
                    <span class="term-number">14.3</span> Für verspätete Rückgaben von Mietern und Vermietern in
                    exklusiven Communities werden Gebühren in Abstimmung mit den Auftraggebern berechnet.
                </div>
            </li>
            <li>
                <span class="terms-label">Rückgabe und Stornierung</span>
                <div>
                    <span class="term-number">15.1</span> Trifft ein Mieter auf ein Artikelproblem laut obiger
                    Definition, behalten wir uns folgende Schritte vor. Alle Rückgabebestimmungen sind endgültig und für
                    Mieter und Vermieter verbindlich. Wir behalten uns vor:<br />
                    <span class="term-number">15.1.1</span> Den Mieter zu entschädigen. Die Entschädigung ist auf den
                    Betrag beschränkt, der durch die Dienste gezahlt wurde;<br />
                    <span class="term-number">15.1.2</span> Für den Mieter einen alternativen Artikel für die Restdauer
                    Ihres Anteils zu finden und zu buchen; oder<br />
                    <span class="term-number">15.1.3</span> Nach unserem Ermessen einen geeigneten Ersatz zu finden.<br />
                    <span class="term-number">15.2</span> Mieter können über den Dienst getätigte Buchungen bis zu 24h
                    vor Beginn der Miete stornieren und erhalten eine vollständige Rückerstattung innerhalb eines
                    angemessenen Zeitraums nach der Stornierung. Für Stornierungen innerhalb von 24h vor Beginn der
                    Miete behalten wir uns vor, bis zu 50% der Mietgebühren einzubehalten.<br />
                    <span class="term-number">15.3</span> Mieter können einen Artikel stornieren, bevor der Vermieter
                    die Miete bestätigt. Fainin storniert in diesem Fall jede Vorautorisierung für die gewählte
                    Zahlungsmethode des Mieters. Das Angebot des Vermieters kann Stornierungsbedingungen für den Fall,
                    dass ein Mieter eine Miete nach Bestätigung, jedoch noch vor der Nutzung stornieren will enthalten.
                    Falls keine Stornierungsbedingungen genannt sind, liegt es in der alleinigen Verantwortung des
                    Vermieters und des Mieters, die Stornierung zu vereinbaren und Fainin zu kontaktieren, falls
                    Unterstützung nötig ist. Die Erstattung der Mietgebühr an den Mieter durch Fainin hängt von den
                    geltenden Stornierungsbedingungen für das Angebot oder von der Vereinbarung zwischen Mieter und
                    Vermieter nach Absprache mit Fainin ab. Unabhängig von den Stornierungsbedingungen des Vermieters
                    wird die Fainin-Gebühr dem Mieter nicht zurückerstattet.
                </div>
            </li>
            <li>
                <span class="terms-label">Verantwortlichkeiten bei Peer-to-Peer-Vermietungen</span>
                <div>
                    Fainin ist lediglich vermittelnd zwischen Privatpersonen tätig. Abgesehen von der im Dienst
                    enthaltenen Versicherung garantieren wir keine Erbringung der Leistungen und übernehmen keine
                    Verantwortung für die Nutzer. Alle Nutzer sind dafür verantwortlich, diese AGB einzuhalten und stets
                    zusammenzuarbeiten. Dazu gehören folgende Verfahren für Versicherung und Mietverlängerung. Vermieter
                    müssen Artikel korrekt und wahrheitsgemäß beschreiben und möglichst aktuelle Fotos und Videos
                    beifügen. Nutzer sollten durchgehend kooperieren und kommunizieren. Artikel dürfen nur
                    bestimmungsgemäß verwendet werden und müssen achtsam behandelt werden. Alle Artikel müssen nach den
                    Bedingungen des Vermieters zurückgegeben werden und im Originalzustand sein. Mieter sind
                    verantwortlich dafür, den Zustand des Artikels beim Erhalt zu prüfen und zu dokumentieren. Sollte
                    die Dokumentation nicht erfolgen, wird davon ausgegangen, dass der Artikel voll funktionsfähig und
                    in gutem Zustand ist. Falls Vermieter und Mieter Privatpersonen sind, handelt es sich um einen
                    Peer-to-Peer-Vertrag. Eine Einkommenssteuerpflicht kann bestehen; der Vermieter ist für die korrekte
                    Versteuerung verantwortlich.
                </div>
            </li>
            <li>
                <span class="terms-label">Verantwortlichkeiten bei Business-to-Consumer-Vermietungen</span>
                <div>
                    Fainin ist lediglich vermittelnd zwischen Privatpersonen und Unternehmen tätig. Abgesehen von der im
                    Dienst enthaltenen Versicherung garantieren wir keine Erbringung der Leistungen und übernehmen keine
                    Verantwortung für die Nutzer. Alle Unternehmen sind dafür verantwortlich, diese AGB einzuhalten und
                    stets zusammenzuarbeiten. Dazu gehören folgende Verfahren für Versicherung und Mietverlängerung.
                    Unternehmen müssen Artikel korrekt und wahrheitsgemäß beschreiben und möglichst aktuelle Fotos und
                    Videos beifügen. Unternehmen und Nutzer sollten durchgehend kooperieren und kommunizieren. Artikel
                    dürfen nur bestimmungsgemäß verwendet werden und müssen achtsam behandelt werden. Mieter sind
                    verantwortlich dafür, den Zustand des Artikels beim Erhalt zu prüfen und zu dokumentieren. Sollte
                    die Dokumentation nicht erfolgen, wird davon ausgegangen, dass der Artikel voll funktionsfähig und
                    in gutem Zustand ist. Alle Artikel müssen nach den Bedingungen des Vermieters zurückgegeben werden
                    und im Originalzustand sein. Falls der Vermieter ein Unternehmen ist und der Mieter eine
                    Privatperson, dann handelt es sich um einen Business-to-Consumer-Vertrag.
                </div>
            </li>
            <li>
                <span class="terms-label">Verantwortlichkeiten bei Business-to-Business-Vermietungen</span>
                <div>
                    Fainin ist lediglich vermittelnd zwischen verschiedenen Unternehmen tätig. Abgesehen von der im
                    Dienst enthaltenen Versicherung garantieren wir keine Erbringung der Leistungen und übernehmen keine
                    Verantwortung für die Nutzer. Alle Unternehmen sind dafür verantwortlich, diese AGB einzuhalten und
                    stets zusammenzuarbeiten. Dies beinhaltet Verfahren für Versicherung, Mietverlängerungen und
                    Stornierungen. Unternehmen müssen Artikel korrekt und wahrheitsgemäß beschreiben und möglichst
                    aktuelle Fotos und Videos beifügen. Unternehmen sollten durchgehend kooperieren und kommunizieren.
                    Artikel dürfen nur bestimmungsgemäß verwendet werden und müssen achtsam behandelt werden. Mieter
                    sind verantwortlich dafür, den Zustand des Artikels beim Erhalt zu prüfen und zu dokumentieren.
                    Sollte die Dokumentation nicht erfolgen, wird davon ausgegangen, dass der Artikel voll
                    funktionsfähig und in gutem Zustand ist. Alle Artikel müssen nach den Bedingungen des Vermieters
                    zurückgegeben werden und im Originalzustand sein. Falls sowohl Vermieter als auch Mieter Unternehmen
                    sind, handelt es sich um einen Business-to-Business-Vertrag.
                </div>
            </li>
            <li>
                <span class="terms-label">Rent-to-Buy-Richtlinie</span>
                <div>
                    <span class="term-number">19.1</span> Fainin bietet Nutzern in einigen Fällen eine Kaufoption für
                    Mietartikel (Rent-to-Buy).<br />Je nach Vertragsteilnehmern ist dies ein Peer-to-Peer-,
                    Business-to-Consumer- oder Business-to-Business-Vertrag. Diese Option wird auch try before you buy
                    genannt. Der potenzielle Käufer und der Verkäufer können diese Option auf Kauf des Artikels nach
                    Ablauf der Mietdauer vereinbaren. Die Versicherung für diese Dienste deckt nur die Mietdauer ab.
                    Allerdings ist auf Anfrage eine längerfristige Versicherung über unsere Partner möglich.<br />
                    <span class="term-number">19.2</span> Der Artikelzustand ist der Zustand, in dem der Verkäufer ihn
                    an den Käufer übergibt. Möglicherweise bietet Ihnen ein Nutzer beim Kauf eines Artikels eine
                    Gewährleistung. Dazu können ein Gewährleistungszuschlag, eine Gewährleistungsfrist sowie Bedingungen
                    und Ausschlüsse für Gewährleistungsfälle gehören. Der Käufer ist für die Einhaltung
                    Gewährleistungsbedingungen verantwortlich, damit die Gewährleistung gültig und einforderbar bleibt.
                    Die genauen Gewährleistungsbedingungen übergibt der Verkäufer dem Käufer am Verkaufsort.<br />
                    <span class="term-number">19.3</span> Beim Kauf eines Artikels können zusätzliche Steuern anfallen,
                    wie z.B. die Mehrwertsteuer. Der Verkäufer ist für die korrekte Abführung aller Steuern und Abgaben
                    verantwortlich.<br />
                    <span class="term-number">19.4</span> Versicherungen für die App und Dienste gelten nur für die
                    Mietdauer. Beim Kauf eines Artikels geht die Verantwortung für die Beschaffung der passenden
                    Versicherung auf den Käufer über.
                </div>
            </li>
            <li>
                <span class="terms-label">Verantwortung von Fainin</span>
                <div>
                    Es liegt in unserer Verantwortung, die Produkte, Dienste, App und Website betriebsbereit zu halten,
                    damit Nutzer Käufe tätigen können. Wir übernehmen keine Verantwortung für Verluste, Schäden oder
                    Folgeschäden aus diesen Diensten, außer diese wurden durch vorsätzliches oder grob fahrlässiges
                    Handeln seitens Fainin verursacht. Wir versuchen die Kompatibilität der Produkte, Dienste, App und
                    Website mit den meisten Browsern, Benutzeroberflächen und Website-Themes sicherzustellen. Unter
                    Umständen gelingt das jedoch nicht für jedes Anzeigeprogramm und für jede Version.
                </div>
            </li>
            <li>
                <span class="terms-label">Nutzungsbeschränkungen</span>
                <div>
                    Abgesehen von den Bedingungen der Rückgabeerklärung sind wir nicht verpflichtet, eine Gewährleistung
                    für die in diesen AGB genannten Produkte zu bieten, es sei denn ein Schaden wurden durch
                    vorsätzliches oder grob fahrlässiges Handeln seitens Fainin verursacht. Sie willigen ein, diese
                    allgemeinen Geschäftsbedingungen zu befolgen.
                </div>
            </li>
            <li>
                <span class="terms-label">Rechte Dritter</span>
                <div>
                    Die App kann Hyperlinks und Bannerwerbung enthalten sowie Inhalte, die Nutzer auf externe Seiten
                    weiterleiten, die nicht zur App oder zur Website gehören. Diese Inhalte dienen nur als Zusatz zu den
                    Diensten und deuten auf keinerlei Beziehung, Zusammenarbeit oder Finanzierung durch einen Dritten
                    hin. Wir können die Anzeige von Cookies und Bannerwerbung in Ihrem Browser nicht kontrollieren und
                    übernehmen keinerlei Verantwortung für deren Inhalte.
                </div>
            </li>
            <li>
                <span class="terms-label">Geistiges Eigentum</span>
                <div>
                    <span class="term-number">23.1</span> Wir behalten das gesamte geistige Eigentum an den Diensten,
                    der App und der Website. Dazu gehört der Firmenname Fainin sowie der Slogan Fainin. Alle Inhalte der
                    Dienste sind für Fainin lizensiert und enthalten alle oben definierten Bereiche. Wir gewähren Ihnen
                    eine beschränkte, widerrufliche, persönliche, nicht übertragbare, nicht exklusive Erlaubnis zur
                    Nutzung der App und Website oder der Dienste für Nutzerzwecke im Rahmen geltender Gesetze sowie zum
                    Ausdruck oder zur elektronischen Kopie von Auftragsübersichten für den alleinigen Zweck der
                    Bestellaufgabe oder für die persönliche Aufzeichnung eines über Fainin aufgegebenen Auftrags. Die
                    Erlaubnis zur Nutzung, Weitergabe oder Vervielfältigung von Fainins Inhalten für gewerbliche Zwecke
                    ohne die Zustimmung von Fainin ist streng untersagt.<br />
                    <span class="term-number">23.2</span> Sie behalten das geistige Eigentum an allen Inhalten oder
                    Informationen, die Sie an den Dienst übertragen. Diese AGB erlauben in keiner Weise die Übertragung
                    des geistigen Eigentums an den Diensten, der App oder der Website. Sie gewähren uns eine
                    gebührenfreie, unterlizensierbare, übertragbare, unwiderrufliche und dauerhafte Erlaubnis zur
                    Nutzung all Ihrer Beiträge über die App oder Website im Zusammenhang mit unserer Bereitstellung der
                    Dienste.<br />
                    <span class="term-number">23.3</span> Für Auftraggeber exklusiver Communities gelten abweichende
                    Bedingungen zur Gewährung von Nutzungsrechten im unteren Teil der AGB. Zusätzlich können weitere
                    Bedingungen im Rahmenvertrag festgelegt werden.
                </div>
            </li>
            <li>
                <span class="terms-label">Geistiges Eigentum Dritter</span>
                <div>
                    Bannerwerbung, Klickwerbung und sonstiges durch die Dienste verwendetes Werbematerial kann Dritten
                    gehören. Diese AGB und die Nutzung der Dienste an sich geben Ihnen noch keine Erlaubnis bzw. kein
                    Recht zur Nutzung solcher Inhalte. Es bestehen für Sie keine impliziten geistigen Eigentumsrechte
                    daran. Falls Sie eine Verletzung geistigen Eigentums bemerken, melden Sie diese bitte bei uns unter
                    contact@fainin.com.
                </div>
            </li>
            <li>
                <span class="terms-label">Erklärung zu Geschäftsdaten</span>
                <div>
                    Wir behalten uns vor, bestimmte Informationen von Drittanbietern in Verbindung mit diesen Diensten
                    zu teilen, z.B. Informationen von Versicherungsanbietern. Diese Informationen dienen nur als Hinweis
                    und zu Unterhaltungszwecken und sollten daher nicht als Empfehlung interpretiert werden. Die Nutzung
                    dieser Dienste ist kein Ersatz für professionelle Beratung. Da Informationen ständig aktualisiert
                    werden, können manche Informationen gelegentlich veraltet sein. Sie stimmen zu, dass alle Risiken
                    durch Nutzung oder Entscheidungen auf Grundlage dieser Informationen bei Ihnen liegen. Zudem stimmen
                    Sie zu, dass wir und unsere Lieferanten in keiner Weise direkt oder indirekt für Verluste oder
                    Schäden durch Ihre Nutzung dieser Dienste haften, außer diese wurden durch vorsätzliches oder grob
                    fahrlässiges Handeln verursacht.
                </div>
            </li>
            <li>
                <span class="terms-label">Ausschluss der Exklusivität</span>
                <div>
                    Diese AGB begründen keine exklusive Beziehung zwischen den Parteien. Wir sind berechtigt, mit
                    weiteren Nutzern zu arbeiten. Dabei bewerben wir Dienste, die den Ihnen geleisteten Diensten ähneln
                    oder gleich sind. Auch Sie sind frei, die Dienste von anderen Anbietern zu nutzen.
                </div>
            </li>
            <li>
                <span class="terms-label">Änderungen und Abwandlungen</span>
                <div>
                    Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Sollten Änderungen an
                    diesen Nutzungsbedingungen stattfinden, wird die aktuellste Version dieser Nutzungsbedingungen auf
                    unserer Webseite und mobilen Applikation veröffentlicht. Zusätzlich wird diese mit dem Datum der
                    Aktualisierung versehen. Vor Inkrafttreten der veränderten Nutzungsbedingungen werden Sie von uns
                    per E-Mail über die Änderungen in Kenntnis gesetzt. Sollten Sie mit den neuen Nutzungsbedingungen
                    nicht einverstanden sein, können Sie diesen Vertrag fristlos und kostenfrei kündigen. Sie werden
                    hierüber ebenfalls in der E-mail informiert. Wenn Sie den Nutzungsvertrag nicht vor Inkrafttreten
                    der geänderten Nutzungsbedingungen kündigen, gilt Ihre fortgesetzte Nutzung der Fainin-Plattform als
                    Zustimmung zu den geänderten Nutzungsbedingungen.
                </div>
            </li>
            <li>
                <span class="terms-label">Kündigung und Verletzung der AGB</span>
                <div>
                    Wir behalten uns vor zu jeder Zeit und ohne Angabe von Gründen Ihre Nutzeranmeldung abzulehnen, zu
                    sperren oder zu kündigen, oder die Dienste insgesamt zu beenden. Dies kann geschehen, wenn konkrete
                    Anhaltspunkte dafür bestehen, dass ein Nutzer gesetzliche Vorschriften, Rechte Dritter oder diese
                    AGB verletzt oder wenn Fainin ein berechtigtes Interesse hat, insbesondere zum Schutz der Nutzer vor
                    betrügerischen Aktivitäten oder aus geschäftlichen Gründen. In diesem Fall haben Sie keinen Zugriff
                    mehr auf einen Teil oder alle Dienste, je nach unserem Ermessen.
                </div>
            </li>
            <li>
                <span class="terms-label">Haftung</span>
                <div>
                    <span class="term-number">29.1</span> Sie dürfen die App und die Website bzw. die Dienste nicht für
                    einen Zweck verwenden, der in diesen AGB nicht gestattet oder vorgesehen ist (bzw. von uns in
                    anderer Weise nicht genehmigt wurde) oder rechtswidrig ist.<br />
                    <span class="term-number">29.2</span> Folgendes gilt im gesetzlichen Rahmen:<br />
                    <span class="term-number">29.2.1</span> Sie sind verantwortlich für Ihren Zugriff und die Nutzung
                    der App und<br />Website; das beinhaltet die Nutzung des Dienstes durch andere Personen über Ihren
                    Nutzernamen, Ihr Passwort bzw. die Antworten auf Ihre Sicherheitsfragen — auch ohne Ihre Erlaubnis;
                    diese Haftung beschränkt sich nicht auf Produkte oder Dienste;<br />
                    <span class="term-number">29.2.2</span> Wir sind in keiner Weise gegenüber irgendeiner Partei für
                    irgendwelche indirekten, beiläufigen oder besonderen Schäden oder Folgeschäden bzw. Verluste
                    verantwortlich (wie z.B. Schadenersatz für entgangenen Gewinn, Betriebsunterbrechung, Verlust von
                    Informationen oder Schäden an Systemen aufgrund von Viren oder anderen schädlichen Komponenten), die
                    sich aus unserer Bereitstellung oder Ihrem Zugriff bzw. Nutzung der App und der Website ergeben,
                    außer diese wurden durch vorsätzliches oder grob fahrlässiges Handeln seitens Fainin verursacht ;
                    ;<br />
                    <span class="term-number">29.2.3</span> Da wir nur vermittelnd tätig sind, ist unsere Haftung für
                    die Nutzung der Website ausgeschlossen und für alle Fälle auf 15.000 EUR beschränkt.
                </div>
            </li>
            <li>
                <span class="terms-label">Versicherungen und Verpflichtungen aus diesen Diensten</span>
                <div>
                    <span class="term-number">30.1</span> Wir bieten im Rahmen dieser Dienste Versicherungen an. Um
                    unseren Versicherungsschutz zu genießen, müssen Sie dieses Versicherungsverfahren einhalten und in
                    der Europäischen Union oder im Europäischen Wirtschaftsraum leben bzw. ansässig sein. Wenn ein
                    Gegenstand beschädigt ist, müssen Sie uns dies sobald wie möglich melden. Die Person, die den
                    Gegenstand beschädigt hat (in der Regel der Mieter) muss ein Berichtsformular von Fainin ausfüllen
                    und einreichen. Weitere Informationen zum Versicherungsschutz finden Sie in der
                    Versicherungsbestätigung und im Fact Sheet, welche Sie über unsere App und Website herunterladen
                    können.<br />
                    <span class="term-number">30.2</span> Wird dem Mieter ein Gegenstand gestohlen, muss er uns dies
                    sobald wie möglich melden und eine polizeiliche Anzeige beifügen. Erhält der Vermieter einen
                    Gegenstand nicht vom Mieter zurück, muss der Vermieter uns dies sobald wie möglich melden und eine
                    polizeiliche Anzeige beifügen. Wir behalten uns die Eintreibung von Forderungen aus dieser
                    Versicherungspolice vor. Der Forderungshöchstbetrag aus dieser Versicherung beträgt 15.000 EUR.
                    Versichert ist die persönliche gesetzliche Haftpflicht des Mieters für Personen- und/oder
                    Sachschäden, für die der Mieter vom Vermieter oder einem Dritten in Anspruch genommen wird.
                    Ebenfalls versichert ist die persönliche gesetzliche Haftpflicht des Vermieters für Personen-
                    und/oder Sachschäden, für die der Vermieter vom Mieter oder einem Dritten in Anspruch genommen wird.
                    Unabhängig von der Art des Versicherungsfalls, müssen alle Ansprüche innerhalb von 48 Stunden nach
                    Ende der Mietdauer angemeldet werden. Kein Versicherungsschutz besteht für Diebstahl oder für
                    Schäden aus der Anmietung und dem Gebrauch von Kraft-, Luft- und Wasserfahrzeugen sowie von
                    Schusswaffen.
                </div>
            </li>
            <li>
                <span class="terms-label">Haftungsausschluss für Kontaktaufnahme</span>
                <div>
                    Unter Umständen müssen wir Sie unter Ihren bei Erteilung eines Auftrags angegebenen Kontaktdaten
                    erreichen. Wenn wir Sie nicht erreichen können oder Ihre Kontaktdaten falsch sind, behalten wir uns
                    die Kündigung des Auftrags vor. Fainin wird Ihre Daten nicht weitergeben, verkaufen oder teilen und
                    nur für interne Vorgänge verwenden. Weitere Informationen finden Sie in unserer
                    Datenschutzerklärung.
                </div>
            </li>
            <li>
                <span class="terms-label">Beschwerden und Konflikte</span>
                <div>
                    Bitte melden Sie Ihre Anliegen oder Beschwerden zu den Diensten an contact@fainin.com. Wir hoffen,
                    dass wir alle Beschwerden ohne Einschaltung Dritter lösen können.
                </div>
            </li>
            <li>
                <span class="terms-label">Datenschutz</span>
                <div>
                    Wir nehmen den Datenschutz ernst. Unsere Datenschutzerklärung erklärt, wie wir die
                    Datenschutzgrundverordnung 2018 der EU einhalten. Unsere Datenschutzerklärung erklärt, wie wir diese
                    Informationen nutzen und weitergeben können. Insbesondere Nutzen wir Ihre persönlichen Informationen
                    dafür, die Dienste zu erbringen sowie für die anderen in der Erklärung genannten Zwecke.
                </div>
            </li>
            <li>
                <span class="terms-label">Freistellung und Schadloshaltung</span>
                <div>
                    Angesichts der Tatsache, dass wir (d.h. unsere Bediensteten, Vertreter, Beauftragten und Direktoren,
                    Nachfolger oder Abtretungsempfänger) Ihnen die Nutzung der App, Website und Dienste gewähren,
                    erkennen Sie an, dass unsere Haftung auf die Vermittlungstätigkeit und auf 15.000 EUR beschränkt
                    ist, es sei denn es fand ein vorsätzliches oder grob fahrlässiges Handeln seitens Fainin statt.
                </div>
            </li>
            <li>
                <span class="terms-label">Haftungsausschluss</span>
                <div>
                    <span class="term-number">35.1</span> Wir bieten die App, Website und Dienste mit der gebotenen
                    Sorgfalt und Fachkunde Allerdings beschränken wir unsere Garantie für die durchgehende und
                    fehlerfreie Bereitstellung der App, Website oder Dienste soweit gesetzlich zulässig.<br />
                    <span class="term-number">35.2</span> Falls nichts Gegenteiliges in diesen AGB bestimmt wird,
                    schließen wir soweit gesetzlich zulässig jegliche Haftung über die von uns angebotene Versicherung
                    hinaus Ihnen oder Dritten gegenüber für Verluste oder Schäden jeglicher Art aus, einschl.
                    Folgeschäden (unabhängig von Ursache und Entstehen), welche mittelbar oder unmittelbar mit der App,
                    Website oder den Diensten zusammenhängen. Dazu gehören u.a. Schäden oder Verluste Ihrerseits
                    durch:<br />
                    <span class="term-number">35.2.1</span>
                    Falsche Produktbeschreibungen von anderen Händlern;<br />
                    <span class="term-number">35.2.2</span> Fehler, Irrtümer oder Ungenauigkeiten ins unseren Diensten,
                    der App oder der Website;<br />
                    <span class="term-number">35.2.3</span> Unbefugter Zugriff oder Nutzung unserer sicheren
                    Informationen, die auf der Google Cloud Platform gespeichert sind;<br />
                    <span class="term-number">35.2.4</span> Alle Bugs, Viren oder anderen schädlichen Codes oder
                    Nachrichten, die von Dritten an oder durch unsere App, Website oder Dienste übertragen werden
                    könnten, es sei denn diese wurde durch vorsätzliches oder grob fahrlässiges Handeln seitens Fainin
                    ermöglicht;<br />
                    <span class="term-number">35.2.5</span> Handlungen oder Unterlassungen Ihrerseits auf Grundlage von
                    Informationen aus der App, der Website oder den Diensten (einschl. Verweise) oder verlinkten Apps
                    oder Websites;<br />
                    <span class="term-number">35.2.6</span> Sach- oder Personenschäden jeder Art aus Ihrem Zugriff auf
                    oder Ihre Nutzung der App, Website oder Dienste und alle Käufe über die App oder Website;<br />
                    <span class="term-number">35.2.7</span> Alle Beschränkungen aus diesen AGB von Fainin;<br />
                    <span class="term-number">35.2.8</span> Alle Unterbrechungen oder Aussetzungen der Übertragung von
                    Daten zu oder von unseren Diensten, der App oder Website; bzw.<br />
                    <span class="term-number">35.2.9</span> Fehler oder Mängel in der Marktgängigkeit oder Eignung von
                    Diensten auf allen verlinkten Websites, die nicht von uns oder von verbundenen Organisationen
                    betrieben werden.<br />
                    <span class="term-number">35.3</span> Wie in diesen AGB beschrieben, übernehmen wir keine Garantie,
                    Gewährleistung oder Verantwortung und geben keine Empfehlung für Dienste, die von Dritten über die
                    App, Website oder Dienste bzw. über eine verlinkte App oder Website oder über ein Banner oder
                    sonstige Werbung angeboten oder beworben werden. Wir sind in keiner Weise beteiligt an oder
                    verantwortlich für die Überwachung von Transaktionen zwischen Ihnen und Drittanbietern von Produkten
                    und Diensten.<br />
                    <span class="term-number">35.4</span> Wir übernehmen keine Verantwortung für mittelbare oder
                    unmittelbare Schäden oder Folgeschäden, die durch die Verwendung betrügerischer oder nicht
                    autorisierter Internetadressen entstehen. Jegliche Haftung unterliegt unserer in diesen AGB
                    beschriebenen Versicherungspolice und ist auf maximal 15.000 EUR beschränkt. Die einzig zulässigen
                    Zugriffspunkte sind Fainin.com, fainin.de, fainin.co.uk ohne vorangehende oder nachfolgende Zeichen
                    sowie die offizielle Fainin-App, die über den Apple App Store oder Google Play erhältlich ist.<br />
                    <span class="term-number">35.5</span> Es ist wichtig, dass Sie vor dem Kauf feststellen, ob dieser
                    Dienst für Sie geeignet ist.
                </div>
            </li>
            <li>
                <span class="terms-label">Allgemein</span>
                <div>
                    <span class="term-number">36.1</span> Gesamte Vereinbarung Diese AGB von Fainin umfassen die gesamte
                    Vereinbarung zwischen Ihnen und uns in Bezug auf Ihren Zugang und die Nutzung der App, Website und
                    Dienste. Für Auftraggeber exklusiver Communities gelten zusätzlich die in der Rahmenvereinbarung
                    festgelegten Bedingungen. <br />
                    <span class="term-number">36.2</span>
                    Geltendes Recht Die Erbringung der Dienste und diese AGB — einschließlich aller Rechte und Pflichten
                    der Parteien aus oder im Zusammenhang mit der Erbringung der Dienste und diesen AGB — unterliegen
                    deutschem Recht. Wir selbst und Sie erkennen die ausschließliche Zuständigkeit des Gerichts an, in
                    dessen Bezirk der Nutzer zur Zeit der Klageerhebung seinen Wohnsitz, in Ermangelung eines solchen
                    seinen gewöhnlichen Aufenthalt hat.<br />
                    <span class="term-number">36.3</span>
                    Salvatorische Klausel Wenn eine dieser Bestimmungen ungültig, nicht durchsetzbar oder rechtswidrig
                    ist, bleiben die übrigen Bestimmungen unberührt.<br />
                    <span class="term-number">36.4</span> Abtretung Sie dürfen ohne unsere schriftliche Zustimmung diese
                    AGB nicht an Dritte abtreten oder austauschen oder in sonstiger Weise mit Ihren Vorteilen oder
                    Rechten daraus Handel betreiben. Die Erteilung unserer Zustimmung liegt in unserem alleinigen
                    Ermessen. Wir behalten uns vor ohne Mitteilung an Sie unsere Rechte und Pflichten aus diesen AGB
                    jederzeit auszutauschen oder abzutreten. Sofern nötig stimmen Sie hiermit dem Austausch, der
                    Abtretung oder der Übertragung zu.<br />
                    <span class="term-number">36.5</span> Verzicht Ein Verzicht auf Rechte oder Rechtsmittel aus diesen
                    AGB ist nur gültig, falls er schriftlich erfolgt und durch die gewährende Partei unterschrieben ist.
                    <span class="term-number">36.6</span> Apple Nutzungsbestimmungen: Sollten Sie auf unsere App
                    zugreifen oder diese aus dem Apple App Store downloaden, so stimmen Sie damit den
                    <a
                        href="https://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Apple’s Licensed Application End User License Agreement</a
                    >
                    zu.
                </div>
            </li>
            <li>
                <span class="terms-label">Support und über uns</span>
                <div>Wir sind eine in Deutschland ansässige Handelsgesellschaft mit folgenden Kontaktdaten:</div>
                <div class="terms-contact">
                    Fainin GmbH<br />
                    Mühlenkamp 4 – 22303, Hamburg, Germany<br />
                    E-Mail: contact@fainin.com<br />
                    Geschäftsführer: Maximilian Lehmann<br />
                    USt-ID: DE314777699<br />
                    Handelsregisternummer: HRB 167 347<br />
                    Amtsgericht Hamburg
                </div>
            </li>
        </ol>
        <span class="terms-label">Erweiterte Bestimmungen für Kunden exklusiver Communities: </span>Im Folgenden werden
        Bestimmungen für Kunden und Nutzer exklusiver Communities festgelegt. Diese gelten ergänzend zu den oben
        genannten Allgemeinen Geschäftsbedingungen und finden keine Anwendung für Nutzer, die nicht Mitglied exklusiver
        Communities sind. Abtretung oder der Übertragung zu.<br />
        <ol>
            <li>
                <span class="terms-label">Rahmenvertrag</span>
                <div>
                    Zwischen Fainin und dem Auftraggeber werden diese zusätzlichen Bedingungen in einem separaten
                    Rahmenvertrag detailliert festgelegt. Sollten im Rahmenvertrag Bestimmungen getroffen werden, die
                    von den AGB abweichen, haben diese Bestimmungen Vorrang.
                </div>
            </li>
            <li>
                <span class="terms-label">Preise und Konditionen</span>
                <div>
                    Die spezifischen Preise und Konditionen für die Mitgliedschaft in exklusiven Communities werden
                    individuell im Rahmenvertrag festgelegt. Dies umfasst möglicherweise abweichende Preise für
                    Kommissionen und Servicegebühren. Fehlen im Rahmenvertrag spezifische Angaben, gelten die in den AGB
                    aufgeführten Preise. <br />
                    Im Falle der Nichtzahlung fälliger Gebühren durch den Kunden setzt fainin diesen innerhalb von 30
                    Tagen über den Zahlungsverzug in Kenntnis. Der Kunde erhält ab dem Datum der Benachrichtigung eine
                    Frist von 14 Tagen, um die ausstehenden Zahlungen zu leisten. Sollte die Zahlung nicht geleistet
                    werden, behält sich Fainin vor die exklusive Community temporär auszusetzen oder permanent zu
                    schließen. Während einer temporären Aussetzung bleiben fällige Gebühren weiter bestehen und
                    akkumulieren sich bis zur vollständigen Begleichung.
                </div>
            </li>
            <li>
                <span class="terms-label">Kündigungsbedingungen</span>
                <div>
                    Sollten im Rahmenvertrag keine besonderen Kündigungsbedingungen oder Laufzeiten definiert sein,
                    beträgt die Standardlaufzeit für die Mitgliedschaft in einer exklusiven Community 12 Monate mit
                    einer Kündigungsfrist von drei Monaten vor Ablauf dieser Frist. Ohne Kündigung verlängert sich die
                    Mitgliedschaft automatisch um jeweils 12 Monate.
                </div>
            </li>
            <li>
                <span class="terms-label">Sonderkündigungsrecht</span>
                <div>
                    Für folgende Fälle wird ein Sonderkündigungsrecht eingerichtet:<br />
                    - Geschäftsaufgabe, <br />
                    - Insolvenz
                </div>
            </li>
            <li>
                <span class="terms-label">Haftung</span>
                <div>
                    Eine Haftung der Fainin gegenüber dem Kunden ist daher nur dann möglich, wenn Schäden aus grober
                    Fahrlässigkeit oder im Vorsatz herbeigeführt werden.
                </div>
            </li>
            <li>
                <span class="terms-label">Uptime-Garantie</span>
                <div>
                    Fainin verpflichtet sich, eine Uptime von 98% auf jährlicher Basis für die Sharing-Plattform zu
                    gewährleisten. Dies beinhaltet die kontinuierliche und ununterbrochene Verfügbarkeit der Plattform
                    für die Nutzer. Sollte die Plattform in einem Kalenderjahr eine Uptime von weniger als 98%
                    aufweisen, besteht für Kunden Anspruch auf eine anteilige Rückerstattung der in diesem Zeitraum
                    gezahlten Gebühren. Ausgenommen von dieser Garantie sind Zeiten der planmäßigen Wartung, welche den
                    Nutzern mindestens 48 Stunden im Voraus angekündigt wird, sowie Ausfallzeiten, die auf höhere Gewalt
                    oder Handlungen Dritter zurückzuführen sind.
                </div>
            </li>
            <li>
                <span class="terms-label">Informationspflicht der Auftraggeber</span>
                <div>
                    Auftraggeber sind verpflichtet, die Mitglieder ihrer exklusiven Communities über alle abweichenden
                    Konditionen, die im Rahmenvertrag festgelegt wurden, zu informieren.
                </div>
            </li>
            <li>
                <span class="terms-label">Konfliktlösung</span>
                <div>
                    Treten Konflikte oder Verstöße auf, verpflichten sich beide Parteien dazu, zuerst eine
                    außergerichtliche Lösung anzustreben und auf Abmahnungen zu verzichten. Der gerichtliche /
                    anwaltliche Weg steht beiden Parteien offen, sobald keine Einigung ersichtlich ist oder wenn ein
                    Gesetz diesen Weg vorschreibt.
                </div>
            </li>
            <li>
                <span class="terms-label">Gewährung von Nutzungsrechten</span>
                <div>
                    Zur Erstellung und Vermarktung der Community benötigt die Fainin GmbH Nutzungsrechte für Firmenlogo
                    (oder äquivalent) und Texte, die vom Kunden zur Verfügung gestellt werden müssen. Optional dürfen
                    vom Kunden auch zusätzlich Bilder, Videos oder Tonmaterialien zur Verfügung gestellt werden. Der
                    Kunde räumt die Nutzungsrechte ausschließlich für diesen Zweck ein. Die Vermarktung geschieht
                    innerhalb der Community. Die Fainin GmbH hat darüber hinaus das Recht, jedoch nicht die Pflicht, den
                    Kunden als Referenzkunden in der eigenen Außenkommunikation zu führen. Im Gegenzug räumt die Fainin
                    GmbH die Nutzungsrechte für Logo und Bildmaterialien gegenüber dem Kunden ein, für alle Werbezwecke
                    und Kommunikationszwecke, die darauf abzielen, darzustellen, dass den Mitarbeitenden des Kundens
                    eine solche Community zur Verfügung steht. Möchte eine der Vertragsparteien diese Materialien für
                    andere Zwecke nutzen, benötigt dies eine schriftliche Zustimmung. Beiden Seiten ist es untersagt,
                    diese Nutzungsrechte für eine negative Kommunikation zu nutzen.
                </div>
            </li>
        </ol>
        <div>
            <p>Letze Änderung: 29.01.2024</p>
        </div>
    </div>
</ng-container>

<ng-template #englishTerms>
    <fainin-terms-english></fainin-terms-english>
</ng-template>
