import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GetOrder } from '@api/generated-types';
import { FeedbackWizardComponent } from 'src/app/account/modules/feedback-wizard/feedback-wizard.component';

@Component({
    selector: 'fainin-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackModalComponent {
    @Input() order: GetOrder.Order;
    @Output() closeDesktopModal = new EventEmitter<any>();

    @ViewChild(FeedbackWizardComponent) private feedbackWizard: any;

    constructor() {}

    previousFeedbackStep() {
        if (this.feedbackWizard.currentStep > 0) {
            this.feedbackWizard.previousStep();
        }
    }
}
