export default {
    uploadProduct: 'Завантажити продукт',
    contact: "Зв'язатися",
    search: 'Find offers',
    faq: 'питання',
    blog: 'Блог',
    home: 'Старт',
    rent: 'Здати в оренду',
    lend: 'Оренда',
    about: 'Про нас',
    newsletter: 'Інформаційний бюлетень',
    press: 'Преса',
    career: "Кар'єра",
    imprint: 'відбиток',
    agb: 'Загальні положення та умови',
    terms: 'Умови обслуговування',
    privacy: 'Захист даних',
    onboarding: 'Onboarding',
    account: 'Аккаунт',
    orders: 'Бронювання',
    requests: 'Запити',
    products: 'Продукти',
    address: 'Адреси облікових записів',
    accountDetails: 'Деталі аккаунтів',
    accountChange: 'Коригувати аккаунт',
    help: 'Допомога',
    signIn: 'Вхід',
    register: 'Реєстрація',
    verify: 'Перевірка',
    resetPassword: 'Скинути пароль',
    forgottenPassword: 'Забули пароль',
    changeEmailAddress: 'Змінити адресу електронної пошти',
    productUpload: 'Завантаження продукту',
    myProfile: 'Мій профіль',
    editProfile: 'Редагувати профіль',
    favorites: 'Фаворити',
    WelcomeToFainin: 'Ласкаво просимо до fainin',
    footer: {
        offers: 'Пропозиції',
        company: 'fainin',
        about: 'Про нас',
        legal: 'Юридичне',
        starText: '4,7 з 155 оцінок',
        sepa: 'Прямий дебет SEPA',
    },
    communities: 'Communities',
    chats: 'Чати',
    chat: 'Чат',
    request: 'Запит',
    verification: 'перевірка',
    registerStep1: 'Крок реєстрації 1',
    registerStep2: 'Крок реєстрації 2',
    registerStep3: 'Крок реєстрації 3',
    registerStep4: 'Крок реєстрації 4',
    migrationOptOut: 'Міграція Opt-Out',
    migrationOptIn: 'Міграція Opt-In',
};
