<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">Terms and Conditions</h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">#SharingIsTheNewOwning</h3>
    </div>
</div>

<div class="container my-5">
    <h3>{{ 'Definition of terms' | uppercase }}:</h3>
    <p><strong>App:</strong> all and any applications or systems used to provide you the Service.</p>
    <p><strong>Commissions:</strong> “Fees” are the fees payable by the “User” under this “Service”.</p>
    <p>
        <strong>Intellectual Property means:</strong> (a) copyright; (b) all rights conferred under statute, common,
        civil law or equity in relation to inventions (including patents) (c) registered and unregistered trademarks;
        (d) registered and unregistered designs; (e) product imagery; (f) confidential information; (g) product
        descriptions, graphics and code; and (h) all other rights resulting from intellectual activity in the
        industrial, scientific, literary or artistic fields.
    </p>
    <p>
        <strong>Item:</strong> means an item being leased/rented or bought/sold from a Lender to a Renter or Buyer to a
        Seller under these Services.
    </p>
    <p>
        <strong>Item Problem:</strong> means a material problem or issue with an item leased, rented, bought or sold
        from a Lender to a Renter or Buyer to a Seller under these Services including but not limited to the Lender
        cancelling a rental shortly before it is due to start, late reception of the item due to shipping, the Lender
        not allowing the Renter to use the Item as intended or the Lender failing to describe the condition and
        characteristics of the Item correctly.
    </p>
    <p>
        <strong>Fainin:</strong> refers to “Fainin”, and the terms “we”, “us” , “our” which is the party providing the
        “Product” , “App” , “Application”, “Website” and “Services” to the “User”..
    </p>
    <p><strong>Products:</strong> all “Services”, “Products” and “Online Services” we provide our “Users".</p>
    <p>
        <strong>Services:</strong> all activities on Fainin’s “Apps”, “Websites” and any other products or services we
        provide our “Users”.
    </p>
    <p>
        <strong>Terms:</strong> “Fainin terms and conditions”, “T&C’s”, “Privacy Policy” and all Terms relating to the
        “Services.”
    </p>
    <p>
        <strong>Users:</strong> Refers to “Renters”, “Lenders / Owners”, “Buyers”, “Sellers”, “you”, “your”, “Client”,
        “Business”, “Member” and any person who has access to the Fainin Service either as a guest or through an
        account.
    </p>
    <p>
        <strong>Website:</strong> “website”, “site”, “Applications” and all websites used in relation to the “Services.”
    </p>
    <p>
        <strong>Community:</strong> Refers to the group function of fainin. These include public communities, which any
        user can join, as well as exclusive communities, where entry is defined by specific criteria. Exclusive
        communities are created by us on behalf of clients. Special terms of use may apply for members of exclusive
        communities, which are also mentioned in these General Terms and Conditions.
    </p>
    <p>
        <strong>Client:</strong> Also referred to as "Clients," denotes the commissioner of an exclusive fainin
        community. Commissioners of an exclusive community are subject to a separate framework agreement in addition to
        these General Terms and Conditions, which is concluded between fainin and the client.
    </p>
    <hr />
    <ol class="terms-listing">
        <li>
            <span>BY USING THESE SERVICES PROVIDED BY US FAININ THROUGH OUR APP OR WEBSITE</span>
            you the User unconditionally and irrevocably agreed to and are bound by these Fainin Terms and Conditions
            Terms. The User’s general terms and conditions are hereby expressly excluded.
        </li>
        <li>
            If you are a user of an exclusive community, different agreements to the General Terms and Conditions can be
            made. You are obliged to independently inquire about these special conditions from the owner of the
            community. The owner has the duty to provide you with the conditions in a timely manner. Special conditions
            for users of the community can only be agreed upon as equivalent to or more favorable than our General Terms
            and Conditions.
        </li>
        <li class="uppercase">
            PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING OR USING THE APP OR WEBSITe, YOU ACKNOWLEDGE
            THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND TO ALL THE TERMS OF OUR TERMS OF SERVICE. THERE ARE
            ASSOCIATED RISKS WITH USING SUCH SERVICES AND THE DISCLOSURE OF SENSITIVE INFORMATION. IF YOU DO NOT AGREE
            TO THESE TERMS, EXIT THIS SERVICE NOW WITHOUT ANY CHARGE, COST OR COMMITMENT AND DO NOT ACCESS OR USE THE
            SERVICE.
        </li>
        <li>
            <span class="terms-label">USE OF THE SERVICES</span>
            <div>
                We provide a sharing economy platform to our Users. You will be providing personal information including
                geo-location data as a User either through an account or as a guest. By using this Product, Services,
                App and Website, you agree to the Terms of these Fainin terms and conditions. You acknowledge that we
                may edit or delete your registration or account if it does not adhere to the Fainin terms and
                conditions. As part of these Services we may record and use part of your personal information and
                registration information to provide a high standard of Service. For more information please refer to our
                Privacy Policy.
            </div>
        </li>
        <li>
            <span class="terms-label">ELIGIBILITY AND REGISTRATION</span>
            <div>
                To be eligible for registration and use of the Products, Services, App and Website you must:<br />
                <span class="term-number">5.1</span> Be at least eighteen (18) years old or have the consent of a legal
                guardian, or be a legal entity (company, authority, business owner, etc.);<br />
                <span class="term-number">5.2</span> only register for the Products, Services, App and Website for the
                intended purpose of these Services;<br />
                <span class="term-number">5.3</span> only register for the Products, Services, App and Website legally
                pursuant to your state or country.
            </div>
        </li>
        <li>
            <span class="terms-label">RESPONSIBILITY FOR MINORS</span>
            <div>
                In cases where you have authorized a minor to use these Services you recognize that you are fully
                responsible for:<br />
                <span class="term-number">6.1</span> the online conduct of such minor;<br />
                <span class="term-number">6.2</span> controlling the minor’s access to and use of the Service; and<br />
                <span class="term-number">6.3</span> the consequences of any misuse by the minor. You acknowledge that
                some of the areas of the Service, App and Website may contain material that is inappropriate for minors.
            </div>
        </li>
        <li>
            <span class="terms-label">REGISTRATION, ACCOUNTS AND LOGIN</span>
            <div>
                <span class="term-number">7.1</span> Users can log in to the Website and Services via:<br />
                <span class="term-number">7.1.1</span> Fainin Account;<br />
                <span class="term-number">7.1.2</span> Facebook Login;<br />
                <span class="term-number">7.1.3</span> Login through Google;<br />
                <span class="term-number">7.1.4</span> Login through Instagram;<br />
                <span class="term-number">7.1.5</span> Login through Twitter;<br />
                <span class="term-number">7.1.6</span> Other social media.<br />
                <span class="term-number">7.2.</span> Fainin Account registration is free of charge.
            </div>
        </li>
        <li>
            <span class="terms-label">CONFIDENTIAL INFORMATION</span>
            <div>
                You may be given a unique Username and password to access the Website and APP under your login. It is
                your responsibility only to ensure these details are kept confidential, safe and secure. No further
                personal information is recorded on the Website as specific task and arrangement details occur between
                us and you. These details may include registration information and personal information. For more info
                on information storage see “Privacy Policy” on our Website.
            </div>
        </li>
        <li>
            <span class="terms-label">USER RESPONSIBILITIES</span>
            <div>
                <span class="term-number">9.1</span> We will aim to provide the App, Website and Services as accurately
                and up to date as possible. By using these Services, you the User irrevocably and unconditionally agree
                to not act in a way that may constitute:<br />
                <span class="term-number">9.1.1</span> Illegal activities;<br />
                <span class="term-number">9.1.2</span> Breach of any applicable laws;<br />
                <span class="term-number">9.1.3</span> Violation of these Terms;<br />
                <span class="term-number">9.1.4</span> Accessing the Services via systems automation such as spambots or
                similar;<br />
                <span class="term-number">9.1.5</span> Any such spam activities;<br />
                <span class="term-number">9.1.6</span> Registering multiple accounts per one User;<br />
                <span class="term-number">9.1.7</span> Using the App or Website in a way that could cause harm to
                others;<br />
                <span class="term-number">9.1.8</span> Posts mit volksverhetzenden, bedrohlichen oder gewalttätigen
                Bewertungen;<br />
                <span class="term-number">9.1.9</span> Hacking or accessing the App or Website other than methods listed
                in these Terms.<br />
                <span class="term-number">9.2.</span> Fainin allows its users the upload of products, requests, texts,
                images and other contents. The upload of content which infringes existing laws, these terms or the
                rights of a third party in any way is prohibited. In particular, it is forbidden to upload or post
                contents,<br />
                <span class="term-number">9.2.1</span> that violate copyright, trademark and competition law
                regulations;<br />
                <span class="term-number">9.2.2</span> that violate youth protection regulations,<br />
                <span class="term-number">9.2.3</span> which contain calls for crimes or monetary donations,<br />
                <span class="term-number">9.2.4</span> which are insulting, abusive, discriminatory, racist or National
                Socialist in nature,<br />
                <span class="term-number">9.2.5</span> that contain pornography or prostitution,<br />
                <span class="term-number">9.2.6</span> containing alcohol, tobacco products or drugs within the meaning
                of the German narcotics law, as well as corresponding accessories,<br />
                <span class="term-number">9.2.7</span> contain media marked “No youth release”, “Approval from 18”, “FSK
                18”, “USK 18” or similar media,<br />
                <span class="term-number">9.2.8</span> which could be of a nature that glorifies violence or is
                disturbing for other users.<br />
                <span class="term-number">9.3</span> We reserve the right to suspend or terminate this agreement at our
                sole discretion if these Terms are violated. Users are advised to trade through the Fainin App or
                Website and not go direct to other Users, especially if initially established contact through the App or
                Website. Failure to do so results in a loss of the provided insurance coverage, support and other
                services.
            </div>
        </li>
        <li>
            <span class="terms-label">FEEDBACK POLICY</span>
            <div>
                <span class="term-number">10.1</span> Our Services included a feedback review section. These reviews are
                useful for other potential Users and Users. We aim to provide this service complementary for all Users
                and request you assist in following the below terms.<br />
                <span class="term-number">10.2</span> Terms of feedback include but are not limited to:<br />
                <span class="term-number">10.2.1</span> Do not leave duplicate reviews;<br />
                <span class="term-number">10.2.2</span> Do not post outside or irrelevant links in reviews;<br />
                <span class="term-number">10.2.3</span> Do not use the review for self-promotion;<br />
                <span class="term-number">10.2.4</span> Do not leave personal information or details;<br />
                <span class="term-number">10.2.5</span> Do not leave hateful, harmful or hurtful reviews;<br />
                <span class="term-number">10.2.6</span> Do not leave insulting reviews;<br />
                <span class="term-number">10.2.7</span> Do not misspell products where possible; and<br />
                <span class="term-number">10.2.8</span> Do leave sufficient details and honest review information.<br />
                <span class="term-number">10.3. </span>We reserve the right to:<br />
                <span class="term-number">10.3.1</span> Edit or delete reviews as necessary;<br />
                <span class="term-number">10.3.2</span> Request further information or proof;<br />
                <span class="term-number">10.3.3</span>
                Verify if the User has actually used the product; and<br />
                <span class="term-number">10.3.4</span> Suspend or delete a User account for breach of any of these
                Terms;
            </div>
        </li>
        <li>
            <span class="terms-label">RENTING AND LENDING AN ITEM</span>
            <div>
                <span class="term-number">11.1</span> A request to rent an item is made from the Renter to the Lender.
                If the request is accepted, payment is made and held in Fainin Escrow. The Renter and Lender then decide
                whether to personally hand in the item or ship it. After sending or personally handing over the item the
                Lender indicates within the App or Website that the item is sent. In case of shipping, the Renter
                acknowledges that it could take up to 3 working days for an item to be received by mail. The Renter
                receiving the item also indicates in the App or Website when the item is received, if the Renter does
                not indicate within 4 working days that the item has been received, the item will automatically be
                marked as received. Once these two steps take place the payment is unlocked from Fainin Escrow to
                Lender.<br />
                <span class="term-number">11.2</span> In the event that both the Renter and the Lender decide to
                transmit the item via shipping, the party responsible for sending the item is obligated to insure the
                the shipment for at least the original purchase price of the article. Should a Renter fail to fulfill
                obligation, and the item is damaged or lost during transit, the Renter shall be required to reimburse
                the Lender for the shortfall in relation to the original purchase price. Conversely, if a Lender fails
                to meet this obligation, they shall be personally liable for any resulting damages or losses to their
                item.<br />
            </div>
        </li>
        <li>
            <span class="terms-label">BUYING AND SELLING AN ITEM</span>
            <div>
                A request to buy an item is made from the Buyer to the Seller. If the request is accepted, payment is
                made and held in Fainin Escrow. The Buyer and Seller then decide whether to personally hand in the item
                or ship it. After sending or personally handing over the item the Seller indicates within the App or
                Website that the item is sent. In case of shipping, the Buyer acknowledges that the it could take up to
                3 working days for an item to be received by mail. The Buyer receiving the item also indicates in the
                App or Website when the item is received and by doing so accepts the item back in the same condition
                minus some normal wear and tear. If, the Buyer does not indicate within 4 working days that the item has
                been received, the item will automatically be marked as received. Once these two steps take place the
                payment is unlocked from Fainin Escrow to Seller.
            </div>
        </li>
        <li>
            <span class="terms-label">Prices and Servicefees</span>
            <div>
                <span class="term-number">13.1</span> The price of a rental on Fainin consists of multiple parts. First
                the rental price of the item, a service fee and optional shipping costs. The rental price of an item is
                set by its owner. For longer rentals we keep the right to offer discounts to renters and also to adjust
                the height of these discounts. Additional to the rental price of the item, the renter will be charged a
                service fee of 15% of the items rental price. If a chargeable shipping option has been chosen, the
                charge will also be due with the booking. After a successful reception of the item as mentioned in 10.
                we will initiate the payout to the owner. The payout consists of the rental price of the item, deduced
                by a servicefee of 15%. The servicefee of the renter and owner already contains the costs for our
                insurance as mentioned in 29.. We reserve the right to adjust the height and distribution of our
                servicefee. Should payments for rentals on fainin be done in any other way as offered by Fainin on our
                website and app, any benefits or rights offered by Fainin in these terms will be void, especially
                benefits from our insurance. <span class="term-number">13.2</span> For members of exclusive communities,
                different prices and service fees may apply. These are agreed upon individually with the clients and
                documented in the framework agreement
            </div>
        </li>
        <li>
            <span class="terms-label">PAYMENT AND RETURN POLICY</span>
            <div>
                <span class="term-number">14.1</span> Payment processing services for Services provided on Fainin are
                provided by Paypal and Stripe and are hence subject to the Stripe Connected Account Agreement, which
                includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By agreeing to
                these terms or continuing to operate as a Renter on Fainin, you agree to be bound by the Stripe Services
                Agreement, as the same may be modified by Stripe from time to time. As a condition of Fainin enabling
                payment processing services through Stripe, you agree to provide Fainin accurate and complete
                information about you and your business, and you authorize Fainin to share it and transaction
                information related to your use of the payment processing services provided by Stripe.<br />
                <span class="term-number">14.2</span> If delivery method is mail shipment and If previously agreed, a
                grace period can be determined between the Renter and Lender. Fainin takes no responsibility for
                insurance coverage or payment outside the initially agreed on rental period unless extended within the
                app and service charges are applied. Fainin takes no responsibility for insurance coverage while in
                transit with a shipping company. If an item is shipped your data will need to be shared with the
                shipping company. If an item is returned after that period, we have the right to charge up to five times
                (5x) the rental price, for the duration the item was unavailable to the Lender. The exact amount of the
                increased rental price will be defined by fainin considering the given situation of the late return. In
                particular the circumstances which caused the late return and existing discounts for longer rentals.
                <span class="term-number">14.3</span> For late returns by renters and owners in exclusive communities,
                fees are calculated in consultation with the clients.
            </div>
        </li>
        <li>
            <span class="terms-label">REFUND AND CANCELLATION POLICY</span>
            <div>
                <span class="term-number">15.1</span> If a Renter encounters an Item Problem as defined above, we may at
                our own sole discretion take the following actions. All determinations with respect to refunds shall be
                final and binding on the Renter and Lender. We may:<br />
                <span class="term-number">15.1.1</span> Reimburse the Renter up to the amount paid to you through the
                Services;<br />
                <span class="term-number">15.1.2</span> Find and book the Renter an alternative item for the remaining
                duration of your share; or<br />
                <span class="term-number">15.1.3</span> Find another remedy in the reasonable discretion of Fainin.<br />
                <span class="term-number">15.2</span> Lenders may cancel confirmed bookings made through the Services up
                to 24h before the rental begins and receive a full refund in a reasonable time after the cancellation.
                For cancellations within 24h before the rental is supposed to start, we reserve the right to keep up to
                50% of the rental price<br />
                <span class="term-number">15.3</span> Renters can cancel an item before the rental is confirmed by the
                Lender. Fainin will cancel any pre-authorization to the Renters chosen payment method. If Renters wish
                to cancel a rental after confirmation but before use, the Lender listing might include any specific
                cancellation requirements the Renter would need to adhere to, if no cancellation requirements are
                present, it is the sole responsibility of both the Lender and Renter to accept and agree to cancel the
                rental agreement and contact Fainin for Support. Fainin’s ability to refund any Lender Fees will depend
                on the terms of the applicable cancellation policy of the Listing or on the agreement reached by the
                Lender and the Renter and after consulting and confirming with Fainin support. The Fainin Fee is not
                refundable to the Renter regardless of the cancellation policy provided by the Lender.
            </div>
        </li>
        <li>
            <span class="terms-label">PEER TO PEER RENTAL RESPONSIBILITIES</span>
            <div>
                The Fainin Service is that of an intermediary or “middleman” merely connecting individuals. Beyond the
                insurance coverage we provide as part of these Services we do not and cannot guarantee the performance
                of these Services or take responsibility for Users. All Users have the responsibility to act and perform
                within these Terms and cooperate at all times. This includes following insurance coverage procedures and
                rental extension procedures. Lenders are to list items honestly and accurately with recent photos and
                videos where possible. Full cooperation and communication should occur between Users. Only use and
                handle Items as they have been intended and respect all Items being lent. Renter are responsible to
                check and document the item's condition when receiving it. If the documentation is not completed it is
                to be assumed that the item is fully functional and in good condition. All items are to be returned on
                the conditions set by the Lender and in the exact same condition. Where the Lender and Renter are both
                individuals the relationship is that of a peer to peer agreement. Income taxes may also be applicable
                and it is the Renter’s responsibility to ensure all taxes and duties are legally paid and correct.
            </div>
        </li>
        <li>
            <span class="terms-label">BUSINESS TO CUSTOMER RENTAL RESPONSIBILITIES</span>
            <div>
                The Fainin Service is that of an intermediary or “middleman” merely connecting individuals and
                businesses. Beyond the insurance coverage we provide as part of these Services we do not and cannot
                guarantee the performance of these Services or take responsibility for Users. All businesses have the
                responsibility to act and perform within these Terms and cooperate at all times. This includes following
                insurance coverage procedures and rental extension procedures. Businesses are to list items honestly and
                accurately with recent photos and videos where possible. Full cooperation and communication should occur
                between Businesses and Users. Only use and handle Items as they have been intended and respect all Items
                being lent. Renter are responsible to check and document the item's condition when receiving it. If the
                documentation is not completed it is to be assumed that the item is fully functional and in good
                condition. All items are to be returned on the conditions set by the Lender and in the exact same
                condition. Where the Lender is a business and the Renter is an individual the relationship is that of a
                business to customer agreement.
            </div>
        </li>
        <li>
            <span class="terms-label">BUSINESS TO BUSINESS RENTAL RESPONSIBILITIES</span>
            <div>
                The Fainin Service is that of an intermediary or “middleman” merely connecting businesses and other
                businesses. Beyond the insurance coverage we provide as part of these Services we do not and cannot
                guarantee the performance of these Services or take responsibility for Users. All Businesses have the
                responsibility to act and perform within these Terms and corporate at all times. This includes insurance
                coverage procedures, rental extension and cancellation procedures. Businesses are to list items honestly
                and accurately with recent photos and videos where possible. Full cooperation and communication should
                occur occur between Businesses. Only use and handle Items as they have been intended and respect all
                Items lent. Renter are responsible to check and document the item's condition when receiving it. If the
                documentation is not completed it is to be assumed that the item is fully functional and in good
                condition. All items are to be returned on the conditions set by the Lender and in the exact same
                condition. Where the Lender and Renter are both business the relationship is that of a business to
                business agreement.
            </div>
        </li>
        <li>
            <span class="terms-label">RENT TO BUY POLICY</span>
            <div>
                <span class="term-number">19.1</span> The Fainin Service may in some cases offer Users a rent to buy
                option.<br />This may be in the form of peer to peer, business to consumer or business to business. Also
                known as try before your buy; an option may be agreed between the prospective Buyer and Seller to allow
                the rented item to be bought at the end of the rental period. The Insurance coverage under these
                Services only covers the rental period but long-term insurance coverage may be offered through our
                partner providers on enquiry.<br />
                <span class="term-number">19.2</span> The condition of the item is that of the condition it is handed
                over from the Seller to the Buyer. You may be offered a warranty from a User when buying an item. This
                may include a warranty cost and a warranty period as well as inclusion and exclusions. It is the Buyer’s
                responsibility to adhere to any warranty terms to maintain a valid and claimable warranty. The specific
                terms of your warranty will be presented to the Buyer from the Seller at the point of purchase directly
                between the Buyer and Seller.<br />
                <span class="term-number">19.3</span> There may be additional taxes including but not limited to VAT
                when purchasing an item. It is the Seller’s responsibility to ensure all taxes and duties are legally
                paid and correct.<br />
                <span class="term-number">19.4</span> Insurance coverage under the App and Services are only applicable
                to rental periods. Once an item is bought it is the Buyer’s responsibility to arrange insurance coverage
                for the item.
            </div>
        </li>
        <li>
            <span class="terms-label">FAININ RESPONSIBILITIES</span>
            <div>
                It is our responsibility to provide the Products, Services, App and Website in good working order for
                the purposes of User purchases. We cannot take any responsibility for any loss, damage or consequential
                losses arising from using these Services, except if they are caused through willful or grossly negligent
                behavior from Fainin’s side. We will aim to provide the Products, Services, App and Website compatible
                with most browsers, interfaces and website themes. However, this may not always be possible on every
                reader and every version.
            </div>
        </li>
        <li>
            <span class="terms-label">LIMITATIONS OF USE</span>
            <div>
                We are under no obligation to guarantee the products under these Terms except as stated in the Refund
                Policy, as long as there has been no willful or grossly negligent behavior from Fainin’s side. You agree
                to follow the Terms within this agreement.
            </div>
        </li>
        <li>
            <span class="terms-label">THIRD PARTY RIGHTS</span>
            <div>
                The App may contain hyperlinks, banner ads and content directing Users to other sites other than this
                App or Website. This content is only provided as additional to the Services and as such does not imply a
                relationship, affiliation or sponsorship to a third party. We cannot control cookies and banner adverts
                appearing on your browser and take no responsibility for their content.
            </div>
        </li>
        <li>
            <span class="terms-label">INTELLECTUAL PROPERTY</span>
            <div>
                <span class="term-number">23.1</span> We retain the ownership of all Intellectual Property rights in and
                to the Services, App or Website. This includes the business name Fainin and the tag line Fainin. All
                content that is contained within these Services are licensed to Fainin and includes all areas as defined
                above. We grant you a limited, revocable, personal, non- transferable, non-exclusive license to use the
                App and Website or Services for User purposes in line with applicable laws and to print or
                electronically copy order summaries for the sole purpose of placing an order or for personal records for
                an order that has been placed with Fainin. Permission to use, distribute, or reproduce any content from
                Fainin for commercial purposes is strictly prohibited unless authorised by Fainin.<br />
                <span class="term-number">23.2</span> You retain the ownership of all Intellectual Property rights of
                any content or information you submit to the Service. Nothing in these Terms transfer the Intellectual
                Property Rights to the Services, App or Website. You grant us a royalty- free, sub-licensable,
                transferable, irrevocable and perpetual licence to use, any contributions you make to the App or Website
                in connection with our operation of the Services. <span class="term-number">23.3</span> For clients of
                exclusive communities, different conditions apply for the granting of usage rights in the lower part of
                the General Terms and Conditions. Additionally, further conditions can be established in the framework
                agreement.
            </div>
        </li>
        <li>
            <span class="terms-label">INTELLECTUAL PROPERTY OF THIRD PARTIES</span>
            <div>
                Banner ads, click ads and other promotional material used in these Services may belong to third parties.
                As such these Terms and use of these Services do not grant you license or right to use such content. It
                does not imply an intellectual property rights to you. If you see a breach of intellectual property you
                can report it to us at contact@fainin.com.
            </div>
        </li>
        <li>
            <span class="terms-label">PROFESSIONAL INFORMATION DISCLAIMER</span>
            <div>
                We may make certain information available provided by third parties relating to these Services including
                but not limited to insurance providers. This information is provided as a guide and as entertainment
                purposes only and should not be interpreted as a recommendation. Use of these Services does not replace
                consultations with professionals. As information is constantly updated some information may be out of
                date from time to time. You agree that all risk associated with the use of, or reliance on, any of the
                information rests with you. Further you agree that we and our suppliers shall not be liable directly or
                indirectly in any way for any loss or damage of any kind as a result of your use of these Services, as
                long as there has been no willful or grossly negligent behavior from Fainin’s side.
            </div>
        </li>
        <li>
            <span class="terms-label">MUTUAL NO EXCLUSIVITY</span>
            <div>
                These Terms do not create an exclusive relationship between the parties. We are free to engage other
                Users and to advertise of the same or similar nature to those provided by you, and you shall be entitled
                to engage Services of others.
            </div>
        </li>
        <li>
            <span class="terms-label">CHANGES AND VARIATIONS</span>
            <div>
                We may change, suspend or discontinue any part of the Services at any time at our sole discretion. We
                may revise, change or modify these Terms at any time by making updated Terms available through the App
                and Website. Before these updated Terms come into effect, you will be notified about the changes through
                an E-Mail. If you do not agree with the updated Terms, you can terminate this contract at any time and
                without cost. This information will also be provided in the stated E-Mail. By not terminating the
                updated Terms, before they come into effect, continued use of the Services will be deemed acceptance of
                the updated Fainin terms and conditions.
            </div>
        </li>
        <li>
            <span class="terms-label">TERMINATION AND BREACH OF TERMS</span>
            <div>
                We reserve the right to decline, suspend or terminate your User registration and use of the Services, or
                cease the Services in its entirety, at any time for a variety of reasons, including a breach of law, a
                breach of the rights of a third party or for a breach of these Terms by you or to protect users from
                fraud or for our own business reasons. If this occurs you will no longer be able to access some or all
                of the Services, as determined by us.
            </div>
        </li>
        <li>
            <span class="terms-label">LIABILITY</span>
            <div>
                <span class="term-number">29.1</span> You must not use the App and Website or Services for any purpose
                not permitted or contemplated by these Terms (or otherwise not authorised by us), or which is unlawful.
                <br />
                <span class="term-number">29.2</span> To the extent permitted by law:<br />
                <span class="term-number">29.2.1</span> you are responsible for your access to and use of the App and
                Website, including for other people’s use of the Service using your User name, password and/or answers
                to security questions (with or without your permission) not limited to products and Services;
                <br />
                <span class="term-number">29.2.2</span> we are not in any way liable to any party for any indirect,
                incidental, special or consequential damages or loss (without limitation including damages for loss of
                profits, business interruption, loss of information or damage to systems due to viruses or other harmful
                components) arising from the provision of, or your access to or use of, the App and Website as long as
                there has been no willful or grossly negligent behavior from Fainin’s side;<br />
                <span class="term-number">29.2.3</span> our liability for the use of the website as merely and
                intermediary is excluded and for any event the limit of liability is EUR 15,000.
            </div>
        </li>
        <li>
            <span class="terms-label">INSURANCES AND LIABILITIES UNDER THESE SERVICES</span>
            <div>
                <span class="term-number">30.1</span> We provide insurance coverage as part of these Services. To be
                covered by our insurance coverage policy you must adhere to this insurance coverage procedure and have a
                domicile or seat within the European Union or European Economic Area. If an Item is damaged, it must be
                reported to us, as soon as possible. A report provided by Fainin must be filled and returned by the
                person who damaged the item which is typically the Renter. For more details regarding the insurance
                coverage, an insurance certificate and fact sheet are provided to you for download in our App and
                Website.<br />
                <span class="term-number">30.2</span> If an item is stolen from the Renter, then the Renter must report
                it to us providing a police report as soon as possible. If an item is not returned by a Renter to a
                Lender, then the Lender must report it to us providing a police report as soon as possible. We reserve
                the right to claim under this insurance coverage procedure at our sole discretion. The maximum claim
                under this insurance coverage procedure is EUR 15,000. The insured is the personal legal liability of
                the Renter for bodily injury and / or property damage for which the Renter is claimed by the Lender or
                third party. Also, the insured is the personal legal liability of the Lender for bodily injury and or
                property damage for which the Lender is claimed by the Renter or third party. Regardless of the claim
                type, all claims must be made within 48 hours after the rental period finishes. This insurance coverage
                procedure does not cover claims resulting from theft or the rental of motor vehicles, aircraft,
                watercraft and firearms.
            </div>
        </li>
        <li>
            <span class="terms-label">CONTACT DISCLAIMER</span>
            <div>
                We may need to contact you based on the information that you provide us when placing an order. If we are
                unable to contact you or the information that you provide us is incorrect, we reserve the right to
                cancel the order. Fainin will not distribute, sell, or share your information and only uses it in
                relation to internal affairs. Please see the Privacy Policy page for additional clarification.
            </div>
        </li>
        <li>
            <span class="terms-label">COMPLAINTS AND DISPUTES</span>
            <div>
                If you have any concerns or complaints regarding the Services, please report it to contact@fainin.com.
                We hope that we will be able to resolve any complaints you may have without needing to involve third
                parties.
            </div>
        </li>
        <li>
            <span class="terms-label">PRIVACY</span>
            <div>
                We take privacy seriously. Our Privacy Policy explains how we adhere to the EU General Data Protection
                Regulation 2018. Our Privacy Policy explains how we may use and disclose that information. In
                particular, we will use your personal information for the purposes of supplying the Services and the
                other purposes set out in the statement.
            </div>
        </li>
        <li>
            <span class="terms-label">RELEASE AND INDEMNITY</span>
            <div>
                In consideration of us (which expression shall be taken to include our servants, agents, officers and
                directors, successors or assignees) agreeing to permit you use of the App you understand our liability
                is excluded as far as an intermediary and any other liabilities are capped to EUR 15,0000, as long as
                there has been no willful or grossly negligent behavior from Fainin’s side.
            </div>
        </li>
        <li>
            <span class="terms-label">DISCLAIMER</span>
            <div>
                <span class="term-number">35.1</span> We will provide the App, Website or Services with due care and
                skill but except to the fullest extent permitted by law, we do not warrant that the App, Website or
                Services will be provided without fault or disruption.<br />
                <span class="term-number">35.2</span> To the fullest extent permitted at law, except as expressed to the
                contrary these Terms and beyond the insurance coverage we provide, we exclude all liability to you or
                anyone else for loss or damage of any kind, including consequential loss and damage (however caused or
                arising) relating in any way to the App, Website or Services including, but not limited to, loss or
                damage you might suffer as a result of:<br />
                <span class="term-number">35.2.1</span>
                Falsche Produktbeschreibungen von anderen Händlern;<br />
                <span class="term-number">35.2.2</span> Fehler, Irrtümer oder Ungenauigkeiten ins unseren Diensten, der
                App oder der Website;<br />
                <span class="term-number">35.2.3</span> Unbefugter Zugriff oder Nutzung unserer sicheren Informationen,
                die auf der Google Cloud Platform gespeichert sind;<br />
                <span class="term-number">35.2.4</span> Alle Bugs, Viren oder anderen schädlichen Codes oder
                Nachrichten, die von Dritten an oder durch unsere App, Website oder Dienste übertragen werden könnten,
                es sei denn diese wurde durch vorsätzliches oder grob fahrlässiges Handeln seitens Fainin ermöglicht;<br />
                <span class="term-number">35.2.5</span> Handlungen oder Unterlassungen Ihrerseits auf Grundlage von
                Informationen aus der App, der Website oder den Diensten (einschl. Verweise) oder verlinkten Apps oder
                Websites;<br />
                <span class="term-number">35.2.6</span> Sach- oder Personenschäden jeder Art aus Ihrem Zugriff auf oder
                Ihre Nutzung der App, Website oder Dienste und alle Käufe über die App oder Website;<br />
                <span class="term-number">35.2.7</span> any limitations stated in these Fainin terms and conditions;<br />
                <span class="term-number">35.2.8</span> any interruption or cessation of transmission to or from our
                Services, App and Website; and/or<br />
                <span class="term-number">35.2.9</span> failures or deficiencies in relation to the merchantability or
                fitness for any purpose of any Service appearing on any linked sites not operated by us or our related
                entities.<br />
                <span class="term-number">35.3</span> As set out in these Terms, we do not warrant, endorse, guarantee
                or assume responsibility for any Service advertised or offered by a third party at the App, Website or
                Services or any linked app, website or featured in any banner or other advertising. We will not be a
                party to or in any way responsible for monitoring any transaction between you and third party providers
                of products and services.<br />
                <span class="term-number">35.4</span> We do not take responsibility for direct or indirect damages, or
                consequential losses suffered by use of fraudulent or unauthorised web addresses. All responsibility is
                to be considered in line with our insurance coverage policies stated in these Terms as an intermediary
                website and to a maximum of EUR 15,000. The only authorised access point is fainin.com, fainin.de,
                fainin.co.uk with no characters before or after and through our official Fainin application found in the
                Apple app store and Google play.<br />
                <span class="term-number">35.5</span> It is important that you determine prior to purchase, whether a
                Service is suitable for you.
            </div>
        </li>
        <li>
            <span class="terms-label">GENERAL</span>
            <div>
                <span class="term-number">36.1</span> Entire agreement: These Fainin terms and conditions comprise the
                entire agreement between you and us in relation to your access to and use of the App, Website and
                Services.<br />
                <span class="term-number">36.2</span>
                Applicable law: The provision of the Services and these Terms, including all of the rights and duties of
                the parties arising from or relating in any way to the provision of the Services and these Terms, are
                governed by the laws of Germany, and we and you each submit to the exclusive jurisdiction of the Court,
                in which district the User, at the time of filing the lawsuit, has his residence or in case of lacking
                such, his habitual residence.<br />
                <span class="term-number">36.3</span>
                Severability: If any of these Terms are invalid, unenforceable or illegal, the remaining Terms will
                continue to apply.
                <br />
                <span class="term-number">36.4</span> Assignment: You must not assign or novate these Terms or otherwise
                deal with their benefit or a right under them without our prior written consent, which may be withheld
                at our absolute discretion. We may at any time, without notice to you, novate or assign our rights and
                obligations under these Terms, and to the extent necessary you hereby consent to such novation,
                assignment or transfer.<br />
                <span class="term-number">36.5</span> Waiver: No waiver of a right or remedy under these Terms is
                effective unless it is in writing and signed by the party granting it.
                <br />
                <span class="term-number">36.6</span> Apple Terms: If you access or download our application from the
                Apple App Store, you agree to
                <a
                    href="https://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Apple’s Licensed Application End User License Agreement</a
                >.
                <br />
            </div>
        </li>
        <li>
            <span class="terms-label">SUPPORT AND WHO ARE WE?</span>
            <div>We are a general trading company located in Germany with the following contact details:</div>
            <div class="terms-contact">
                Fainin GmbH<br />
                Mühlenkamp 4 – 22303, Hamburg, Germany<br />
                E-Mail: contact@fainin.com<br />
                Managing Director: Maximilian Lehmann<br />
                USt-ID: DE314777699<br />
                Commercial Register: HRB 167 347<br />
                Amtsgericht Hamburg
            </div>
        </li>
    </ol>
    <span class="terms-label">Extended Provisions for Exclusive Community Clients: </span>The following provisions for
    clients and users of exclusive communities are established. These apply in addition to the General Terms and
    Conditions mentioned above and do not apply to users who are not members of exclusive communities.<br />
    <ol>
        <li>
            <span class="terms-label">Framework Agreement</span>
            <div>
                A separate framework agreement details these additional conditions between Fainin and the client. If the
                framework agreement contains terms that deviate from the General Terms and Conditions, these terns take
                precedence.
            </div>
        </li>
        <li>
            <span class="terms-label">Prices and Conditions</span>
            <div>
                The specific prices and conditions for membership in exclusive communities are individually set in the
                framework agreement. This may include different prices for commissions and service fees. If the
                framework agreement lacks specific information, the prices listed in the General Terms and Conditions
                apply. <br />
                In the event of non-payment of due fees by the client, Fainin notifies the client within 30 days of the
                payment delay. The client has 14 days from the date of notification to pay the outstanding amounts. If
                payment is not made, Fainin reserves the right to temporarily suspend or permanently close the exclusive
                community. During a temporary suspension, due fees continue to accrue until fully paid.
            </div>
        </li>
        <li>
            <span class="terms-label">Termination Conditions</span>
            <div>
                If no specific termination conditions or durations are defined in the framework agreement, the standard
                duration for a subscription of an exclusive community is 12 months with a notice period of three months
                before the end of this period. Without termination, the subscription automatically extends for another
                12 months.
            </div>
        </li>
        <li>
            <span class="terms-label">Special Right of Termination</span>
            <div>
                A special right of termination is established for the following cases:<br />
                - Business closure, <br />
                - Insolvency
            </div>
        </li>
        <li>
            <span class="terms-label">Liability</span>
            <div>
                Fainin's liability to the client is only possible if damages are caused by gross negligence or
                intentional action.
            </div>
        </li>
        <li>
            <span class="terms-label">Uptime Guarantee</span>
            <div>
                Fainin commits to an uptime of 98% on an annual basis for the sharing platform. This includes the
                continuous and uninterrupted availability of the platform for users. If the platform has an uptime of
                less than 98% in a calendar year, clients are entitled to a proportional refund of the fees paid during
                this period. Excluded from this guarantee are periods of scheduled maintenance, which will be announced
                to users at least 48 hours in advance, and downtime due to force majeure or third-party actions.
            </div>
        </li>
        <li>
            <span class="terms-label">Information Duty of Contracting Parties</span>
            <div>
                Contracting parties are obliged to inform the members of their exclusive communities about any differing
                conditions set in the framework agreement.
            </div>
        </li>
        <li>
            <span class="terms-label">Conflict Resolution</span>
            <div>
                In case of conflicts or violations, both parties commit to seeking an out-of-court solution first and to
                refrain from issuing warnings. The legal/attorney route is open to both parties as soon as no agreement
                is apparent or if a law prescribes this path.
            </div>
        </li>
        <li>
            <span class="terms-label">Granting of Usage Rights</span>
            <div>
                For the creation and marketing of the community, Fainin GmbH requires usage rights for the company logo
                (or equivalent) and texts, which must be provided by the client. Optionally, the client may also provide
                additional images, videos, or audio materials. The client grants usage rights exclusively for this
                purpose. Marketing takes place within the community. In addition, Fainin GmbH has the right, but not the
                obligation, to feature the client as a reference customer in its external communication. Fainin GmbH
                grants the client usage rights for the logo and image materials for all advertising purposes and
                communication purposes aimed at demonstrating that such a community is available to the client's
                employees. If either party wishes to use these materials for other purposes, written consent is Both
                sides are prohibited from using these usage rights for negative communication.
            </div>
        </li>
    </ol>
    <div>
        <p>Last updated: 29.01.2024</p>
    </div>
</div>
