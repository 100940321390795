export default {
    search: {
        title: 'Местонахождение не указано',
        message: 'Пожалуйста, добавьте местоположение для сортировки товаров по расстоянию',
    },
    shipment: {
        title: 'Shipping not available',
        msg: 'The owner does not offer shipping for this product.',
        shippingMethod: 'Способ доставки*',
        pickup: 'Подборка',
        shipping: 'Доставка',
    },
    error: {
        title: `Ошибка`,
    },
    warning: {
        title: `Предупреждение!`,
    },
    success: {
        title: 'Успех!',
    },
    languageNotAvailable: {
        article: 'This article is not available in your language, instead the original article is displayed.',
    },
    updateCustomerPassword: {
        title: 'Успех!',
        message: 'Пароль изменён!',
    },
    updateCustomerPasswordError: {
        title: 'Не удалось изсменить пароль!',
        message: 'Пожалуйста, убедитесь, что ваш старый пароль был введён правильно.',
    },
    deleteProduct: {
        title: `Удалить продукт`,
        message: `Продукт успешно удалён.`,
    },
    activeOrderError: {
        title: `Аренда в процессе!`,
        message: `Для этого элемента идет аренда.`,
    },
    requestSent: {
        title: `Запрос отправлен!`,
        message: `Ваш запрос успешно отправлен.Пожалуйста, дождитесь ответа владельца.`,
    },
    registrationSuccess: {
        title: 'Регистрация завершена',
        message: 'Пожалуйста, подтвердите регистрацию по ссылке в письме.',
    },
    registrationError: {
        title: 'Регистрация не удалась',
        message: 'Не удолось, создать аккаунт.',
    },
    registrationCancel: {
        title: 'Отменить регистрацию',
        message: 'Вы хотите отменить регистрацию?',
    },
    languageChange: {
        title: 'Язык изменен',
        message: 'Языковые настройки успешно настроены!',
    },
    languageChangeError: {
        title: 'Язык не изменен',
    },
    bugReportSuccess: {
        title: ' Сообщено',
        message: 'Ваше сообщение об ошибке успешно отправлено',
    },
    bugReportError: {
        title: 'Сообщение об ошибке не удалось отправить.',
    },
    describeExperience: {
        label: 'Описание (по желанию)',
        title: 'Каким был ваш опыт общения с  {{recipient}}?',
        placeholder: 'Кратко опишите свой опыт...',
        rules: 'Описание слишком короткое',
    },
    feedback: {
        title: 'Обратная связь',
        placeholder: 'Кратко опишите свой опыт...',
        rules: 'Описание слишком короткое',
        text: 'Каким был ваш опыт?',
    },
    receiveItem: {
        title: 'Товар получен',
        firstMessage: 'Пожалуйста, подтвердите, что товар не поврежден и соответствует описанию.',
        secondMessage: 'Сделайте как минимум две фотографии, чтобы обеспечить свою безопасность.',
    },
    returnItem: {
        title: 'Товар возвращён',
        message: 'Пожалуйста, подтвердите, что товар не поврежден и соответствует описанию.',
    },
    awaitEndRental: {
        title: 'Послать запрос??',
        message: 'Вы уверены, что хотите отправить запрос на завершение аренды?',
    },
    endRental: {
        title: 'Внимание!',
        message: 'Вы хотите завершить бронирование?',
    },
    awaitRefund: {
        title: 'Внимание!',
        message: 'Вы хотите отправить запрос на возврат денег?',
    },
    cancelRental: {
        title: 'Внимание!',
        message: 'Вы хотите отменить бронирование?',
    },
    rentalEnded: {
        title: 'Бронирование окончилось',
        message: 'Бронирование вашего товара было успешно завершено!',
    },
    refundAwaiting: {
        title: 'Запрос отправлен',
        message: 'Ваш запрос на возврат денег отправлен!',
    },
    favoriteRemoveSuccess: {
        title: 'Новый фаворит',
        Message: 'Элемент успешно удалён из избранного.',
        removed: 'Элемент удален из избранного',
    },
    favoriteRemoveError: {
        message: 'Элемент не удалось удалить из избранного',
    },
    awaitEndRentalError: {
        message: 'Запрос на прекращение аренды не может быть отправлен.',
    },
    rentalEndedError: {
        message: 'Бронирование не удалось завершить.',
    },
    awaitRefundError: {
        message: 'Запрос на возврат денег не удалось отправить.',
    },
    rentalCancelled: {
        title: 'Бронирование отменено',
        message: 'Бронирование вашего товара было отменено!',
    },
    rentalCancelledError: {
        message: 'Бронирование не удалось отменить.',
    },
    orderDeliveredSuccess: {
        title: 'Успех',
        message: 'Ваши фотографии были успешно загружены!',
    },
    orderDeliveredError: {
        title: 'Ошибка',
        message: 'При загрузке произошла ошибка.',
    },
    imageSubmitError: {
        noPictures: 'Фотографии не сделаны',
        twoPictures: 'Загрузите не менее двух фотографий*',
    },
    termsAndConditionsCheck: {
        title: 'Условия обслуживания',
        message: 'Примите условия обслуживания для завершения регистрации.',
    },
    requestExists: {
        title: 'Уже запрошено',
        message: 'Вы уже запрашивали этот товар в тот же период',
    },
    invalidDates: {
        title: 'Неверный период времени',
        message: 'Выбранный период вашего запроса недействителен',
    },
    itemUnavailable: {
        title: 'Товар недоступен',
        message: 'Товар недоступен в выбранный период.',
    },
    newsletterAlreadySubscribed: {
        title: 'Информация',
        message: 'Отлично! Вы уже подписались на нашу рассылку!',
    },
    voucher: {
        message: `Применен купон "{{code}}" на {{amount}} €.`,
    },
    noLocationResolved: {
        message: 'Для этого входа не удалось определить местоположение с координатами.',
    },
    deleteAccount: {
        request: 'Запрос на удаление аккаунта',
        message: 'Ваш запрос на удаление аккаунта успешно отправлен.',
    },
    community: {
        cantJoin: 'Вы не можете присоединиться к этому сообществу в данный момент.',
        noAccess: 'У вас нет необходимых требований для доступа к этой группе.',
        noMember: 'You are not a member of this community',
        alreadyMember: 'Вы уже являетесь членом этой группы',
        joined: 'Вы присоединились к сообществу {{name}}',
        left: 'Вы покинули {{name}}',
        leaveFailed: 'Произошла ошибка при попытке покинуть группу',
    },
    notSignedIn: {
        title: 'Не вошли',
        message: 'Вы должны быть авторизованы.',
        actionMsg: 'Вы должны быть авторизованы, чтобы выполнить это действие.',
    },
};
