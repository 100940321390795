<ng-template faininDialogTitle>{{ title | translate }}</ng-template>
<p>
    {{ msg | translate : msgParams }} <br />
    {{ this.label }}
</p>
<textarea
    *ngIf="withInput"
    #textarea
    type="text"
    nz-input
    [placeholder]="inputPlaceholder"
    [nzAutosize]="{ minRows: 1, maxRows: 6 }"
    [(ngModel)]="input"></textarea>

<button faininShadow type="button" class="btn btn-cancel" (click)="close()">{{ 'dialog.cancel' | translate }}</button>
<button
    [ngClass]="{ 'small-font': customButtonText }"
    faininShadow
    type="button"
    class="btn btn-confirm"
    (click)="confirm()"
    [disabled]="confirmDisabled">
    {{ customButtonText ? customButtonText : ('dialog.confirm' | translate) }}
</button>
