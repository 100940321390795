<ng-container *ngIf="assets.length > 0; else noAsset">
    <swiper [config]="config" (slideChange)="onSlideChange()">
        <ng-container *ngFor="let asset of assets; let i = index">
            <ng-template swiperSlide [zoom]="true">
                <div class="swiper-zoom-container">
                    <img
                        (click)="onImagePreview(i)"
                        class="gallery-image"
                        [ngSrc]="asset | assetPreview : 'medium'"
                        [width]="asset.width"
                        [height]="asset.height"
                        [faininFallbackImage]="'/assets/image/fallback/product.png'"
                        [alt]="('asset.assetGallery.productGalleryImage' | translate) + ' #' + i" />
                </div>
                <div class="image-count">{{ i + 1 }}/{{ assets.length }}</div>
                <div class="shipping" *ngIf="shippingAddress">
                    <img
                        class="location-dot"
                        src="/assets/icon/figma/location-dot-solid.svg"
                        width="16"
                        height="16"
                        alt="location icon" />
                    {{ shippingAddress }}
                </div>
            </ng-template>
        </ng-container>
    </swiper>
</ng-container>

<ng-template #noAsset>
    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
</ng-template>
