import { Directive, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SeoService } from '@core/seo/seo.service';
import { HasMetadata, IMetaPageInfo, LocalizedMetaFields } from '@core/seo/site-meta.types';
import { StructuredDataService } from '@core/seo/structured-data.service';
import { TranslateService } from '@ngx-translate/core';
import { from, isObservable, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 * @description
 * Translate the localized meta fields of {@link IMetaPageInfo}.
 * @param meta
 * @param translate
 */
function translateMetaFields(meta: IMetaPageInfo, translate: TranslateService): IMetaPageInfo {
    if (!meta.localized) {
        return meta;
    }

    const fieldsToTranslate: LocalizedMetaFields[] = ['title', 'description', 'keywords'];
    fieldsToTranslate.forEach(field => {
        const value = meta[field];
        if (value) {
            meta[field] = translate.instant(value);
        }
    });

    return meta;
}

/**
 * @description
 * A directive that listens to the router outlet activation events and sets the page metadata.
 * This directive should be used in the root component of the application.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'router-outlet',
})
export class PageMetadataDirective implements OnDestroy {
    private readonly subscription: Subscription;

    constructor(
        routerOutlet: RouterOutlet,
        seoService: SeoService,
        sdService: StructuredDataService,
        private translate: TranslateService,
    ) {
        seoService.init();
        sdService.init();
        this.subscription = routerOutlet.activateEvents
            .pipe(
                switchMap((component: Partial<HasMetadata>) => {
                    if (isObservable(component.pageMetadata)) {
                        return component.pageMetadata;
                    } else {
                        return of(undefined);
                    }
                }),
                map(metadata => {
                    if (metadata?.localized) {
                        return translateMetaFields(metadata, this.translate);
                    }
                    return metadata;
                }),
            )

            .subscribe(metadata => {
                seoService.setPageMetadata(metadata);
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
