<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">
            Datenschutz
            <wbr />
            erklärung
        </h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">{{ 'content.home.sharingIsOwning' | translate }}</h3>
    </div>
</div>

<div class="container my-5">
    <div class="privacy-privacy">
        <h3>{{ 'Zweck' | uppercase }}</h3>

        <p>
            Diese Datenschutzerklärung gilt für Fainin [Handelsregisternummer: HRB 167347 beim Amtsgericht Hamburg] (je
            nach Kontext „wir“, „unser/e“ oder „uns“) für unsere Tätigkeiten in Deutschland. Diese Erklärung beschreibt,
            wie wir persönliche Informationen behandeln und die Anforderungen der EU-Datenschutz-Grundverordnung 2018
            (DSGVO) einhalten. Dies umfasst alle unsere Apps, Websites, Produkte und Dienste für Kunden unter diesen und
            anderen Geschäftsbedingungen. Bei weiteren Fragen zu dieser Erklärung, kontaktieren Sie uns bitte:
            contact@fainin.com.
        </p>
        <p>
            Diese Erklärung bezieht sich auf die Sammlung und den Umgang mit personenbezogenen Daten laut der DSGVO. Sie
            deckt keine persönlichen Informationen ab, die nicht unter die DSGVO fallen. Die gesammelten Informationen
            stammen aus verschiedenen Quellen, wie z.B.:
        </p>
        <ul>
            <li>Unsere App;</li>
            <li>Unsere Websites (u.a. fainin.com, fainin.de, fainin.co.uk);</li>
            <li>Soziale Medien aller Art;</li>
            <li>Banner, Bannerwerbung;</li>
            <li>Cookies von unseren Websites;</li>
            <li>UDIDs von Tablets und anderen Mobilgeräten;</li>
            <li>Software aller Art; und</li>
            <li>Sonstige Informationen die manuell oder elektronisch gesammelt werden.</li>
        </ul>
        <p>
            Diese Datenschutzerklärung dient lediglich der Informationserteilung und bedarf keiner weiteren Zustimmung.
            Wir behalten uns die jederzeitige Änderung dieser Praktiken vor. Alle Änderungen werden veröffentlicht.
            Änderungen gelten nur für zukünftige Aktivitäten und Informationen, nicht rückwirkend. Wir bitten Sie die
            Datenschutzerklärung zu lesen, wenn Sie die Website oder App nutzen, damit Sie verstehen wie ihre
            persönlichen Informationen verwendet werden.
        </p>
    </div>
    <hr />

    <div class="privacy-privacy">
        <h3>{{ 'Warum wir Informationen sammeln' | uppercase }}</h3>
        <p>
            Wir können auch personenbezogene Informationen und Daten sammeln. Dabei verpflichten wir uns, diese
            Informationen zu schützen. Sofern wir dies nicht ausdrücklich anfragen, sammeln, speichern und verarbeiten
            wir nur Informationen in der nachfolgenden Datenschutzerklärung. Die folgende Datenschutzerklärung wurde
            auch entwickelt, um die Best Practices und Standards einzuhalten und erfüllt die
            EU-Datenschutz-Grundverordnung 2018. Wir behalten uns weitergehende und ausführlichere Bestimmungen vor,
            auch in Bezug auf Datenschutz. Diese werden Ihnen mitgeteilt und müssen im Zusammenhang mit dieser
            Datenschutzerklärung betrachtet werden.
        </p>
    </div>

    <div class="privacy-privacy">
        <h3>{{ 'Aktive Sammlung von Informationen' | uppercase }}</h3>
        <p>
            Persönliche Informationen können durch diese Produkte, Dienste, Apps und Websites erfasst werden. Eine
            Aktive Sammlung von Informationen findet u.a. statt bei:
        </p>
        <ul>
            <li>Veröffentlichung von Produktbewertungen nach unserer Feedback-Richtlinie;</li>
            <li>Erwerb von Produkten oder Diensten;</li>
            <li>Hochladen von Produkten mit einem Produkt-Standort</li>
            <li>Ausfüllen von Formularen oder Umfragen;</li>
            <li>
                Anmeldeinformationen durch diese Dienste oder ein Drittanbieter-Login, z.B. per Facebook, Instagram,
                Twitter, Gmail und LinkedIn;
            </li>
            <li>
                Informationen bei weiterleitung zu diesen Diensten über Facebook oder TikTok durch den Facebook oder
                TikTok Pixel;
            </li>
            <li>Telefonnummernverifizierung;</li>
            <li>Bestellung von E-Mails, Benachrichtigungen oder Diensten;</li>
            <li>Bereits mitgeteilte oder angegebene Informationen;</li>
            <li>Direktem Kontakt zu uns; und</li>
            <li>Teilnahme an Aktionen, Sonderangeboten oder Specials.</li>
        </ul>
    </div>

    <div class="privacy-privacy">
        <h3>{{ 'Passive Sammlung von Informationen, IP-Adressen und Cookies' | uppercase }}</h3>
        <p>
            Nach der branchenüblichen Praxis sammeln wir eventuell Informationen über Ihren Computer, Tablet bzw.
            Mobilgerät. Dazu gehören, falls verfügbar, Ihre IP-Adresse, Ihr Betriebssystem, Ihr Standort, demographische
            Daten und Ihr Browsertyp für die Systemverwaltung und zur Berichterstattung von aggregierten Informationen
            an unsere Werbepartner. Dies sind statistische Daten über Browseraktionen und Verhaltensmuster unserer
            Nutzer, die nicht persönlich zugeordnet werden können.
        </p>
        <p>
            Sollten Sie über Webseiten oder Dienste von Drittanbietern (Zum Beispiel: Google, Facebook oder TikTok) auf
            unsere App oder Webseite weitergeleitet werden so können wir hierzu Informationen über den Facebook oder
            TikTok-Pixel speichern. Weiterhin können wir bei der Verknüpfung Ihres Konto mit einem Konto solcher
            Drittanbieter, von diesen Informationen zu Ihrem Nutzerprofil erhalten.
        </p>
        <p>
            Unsere App und Website nutzen Cookies (und Ihre Gerätekennung, falls Sie die App auf einem Mobilgerät
            nutzen), um Sie von anderen Nutzern zu unterscheiden. Dies hilft uns, Ihnen ein besseres Nutzungserlebnis zu
            bieten und unsere Dienste zu verbessern.
        </p>
        <p>
            Diese Zugangspunkte können entweder direkt, über eine andere App/Website oder über eine E-Mail von uns an
            Sie auf unsere App/Website zugreifen. Durch diesen indirekten Zugriff erklären Sie sich mit der Erfassung
            von Daten und persönlichen Informationen einverstanden.
        </p>
        <p>
            Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu verstehen und das Angebot und die Erfahrung
            auf dieser Webseite zu optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein besseres Verständnis
            von den Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer auf welchen Seiten verbringen, welche Links sie
            anklicken, was sie mögen und was nicht etc.) und das hilft uns, unser Angebot am Feedback unserer Nutzer
            auszurichten. Hotjar arbeitet mit Cookies und anderen Technologien, um Daten über das Verhalten unserer
            Nutzer und über ihre Endgeräte zu erheben, insbesondere IP Adresse des Geräts (wird während Ihrer
            Website-Nutzung nur in anonymisierter Form erfasst und gespeichert), Bildschirmgröße, Gerätetyp (Unique
            Device Identifiers), Informationen über den verwendeten Browser, Standort (nur Land), zum Anzeigen unserer
            Webseite bevorzugte Sprache. Hotjar speichert diese Informationen in unserem Auftrag in einem
            pseudonymisierten Nutzerprofil. Hotjar ist es vertraglich verboten, die in unserem Auftrag erhobenen Daten
            zu verkaufen. Weitere Informationen finden Sie in unter dem Abschnitt ‚about Hotjar‘ auf Hotjars
            Hilfe-Seite.
        </p>
    </div>

    <div class="privacy-section">
        <h3>{{ 'Wo wir Ihre persönlichen Informationen speichern' | uppercase }}</h3>
        <p>
            Ihre gesammelten Daten können auf unsere OVH Cloud Platform übertragen und dort gespeichert werden. Falls
            die Daten im Ausland gespeichert werden, können Sie auch von Mitarbeitern außerhalb Deutschlands verarbeitet
            werden, die für uns oder unsere Lieferanten arbeiten. Diese Mitarbeiter können z.B. an der Bereitstellung
            Ihres Dienstes, der Verarbeitung Ihrer Zahlungsdetails oder an Support-Diensten beteiligt sein.
        </p>
        <p>
            Die gesammelten Daten und persönlichen Informationen werden für die folgenden Zwecke verarbeitet und
            weitergegeben:
        </p>
        <ul>
            <li>Bereitstellung von Diensten;</li>
            <li>Antwort auf Anfragen;</li>
            <li>Pflege der Kundenbeziehung;</li>
            <li>Anmeldung und Annahme neuer Kunden oder Nutzer;</li>
            <li>Erfüllung von Pflichten aus anderen Richtlinien, z.B. für Retouren;</li>
            <li>Pflege der Website und App;</li>
            <li>Verbesserung der Benutzerfreundlichkeit der Website / App;</li>
            <li>
                Versorgung von Dritten mit statistischen (nicht personenbezogenen) Informationen über unsere Nutzer –
                ausschließlich im Rahmen dieser Datenschutzerklärung und nur unter der Voraussetzung, dass keine
                einzelnen Nutzer erkennbar sind;
            </li>
            <li>Für die Zwecke der Erfassung laut dieser Datenschutzerklärung;</li>
            <li>Bearbeitung von Anfragen und Beschwerden von Kunden und Nutzern;</li>
            <li>Sicherung der Website / App und zur Betrugsvorbeugung;</li>
            <li>Updates, Angebote und Newsletter; und</li>
            <li>Verbesserung von Produkten, Diensten, App und Website.</li>
        </ul>
    </div>

    <div class="privacy-section">
        <h3>{{ 'Rechtsgrundlage der Verarbeitung Ihrer Personenbezogenen Daten' | uppercase }}</h3>
        <p>
            Zahlungsdienste: Die verantwortlichen Zahlungsdienstleister verarbeiten die personenbezogenen Informationen
            im Rahmen Ihres berechtigten Interesses an der Verbesserung der Zahlungsdienste und um den Vertrag mit ihnen
            angemessen zu erfüllen und geltende Gesetze einzuhalten.
        </p>

        <p>Wie wir von uns erhobene Daten nutzen:</p>
        <li>
            Wir verarbeiten personenbezogene in Rahmen unseres berechtigten Interesses an der Verbesserung der
            Fainin-Plattform, sowie der damit zusammenhängenden Erfahrung unserer Nutzer, und soweit es für die
            angemessene Erfüllung des Vertrags mit Ihnen erforderlich ist.
        </li>
        <li>
            Wir verarbeiten diese personenbezogenen Daten zu diesen Zwecken im Rahmen unseres berechtigten Interesses am
            Schutz von Fainin und unserer Nutzer, um die angemessene Erfüllung unseres Vertrags mit Ihnen zu überprüfen,
            um geltendes Recht einzuhalten, zum Schutz Ihrer lebenswichtigen Interessen oder der lebenswichtigen
            Interessen einer anderen Person und aus Gründen des öffentlichen Interesses.
        </li>
        <li>
            Wir verarbeiten diese personenbezogenen Daten mit Ihrer Zustimmung oder basierend auf unserem berechtigten
            Interesse an Marketingaktivitäten, um Ihnen Produkte oder Dienstleistungen anzubieten, die Sie interessieren
            könnten.
        </li>

        <p>
            Einhaltung von Gesetzen, Beantwortung rechtlicher Anfragen, Schadensvermeidung und Schutz unserer Rechte:
            Diese Offenlegungen können erforderlich sein, um unseren rechtlichen Verpflichtungen nachzukommen, zum
            Schutz der lebenswichtigen Interessen einer Person, aus Gründen des wesentlichen öffentlichen Interesses
            oder zum Zwecke des berechtigten Interesses von Fainin oder eines Dritten an der Sicherung von Fainin, zur
            Verhinderung einer Rechtsverletzung, eines Schadens oder einer Straftat, zur Durchsetzung oder Verteidigung
            gesetzlicher Rechte, Ansprüche oder Verpflichtungen, zur Erleichterung der Steuererhebung und zur
            Verhinderung von Steuerbetrug oder zur Verhinderung von Verlust oder Schaden.
        </p>
        <p>
            Analyse Ihrer Kommunikation: Grundlage dieser Aktivitäten ist das berechtigte Interesse von Fainin, die
            Einhaltung der geltenden Gesetze und unserer Nutzungsbedingungen sicherzustellen, Betrug zu verhindern, die
            Sicherheit zu fördern und die angemessene Ausführung unserer Dienstleistungen zu verbessern und
            sicherzustellen.
        </p>
        <p>
            Verknüpfung mit Accounts von Drittanbietern sowie externen Partnern und Integrationen: Wir verarbeiten
            personenbezogene Daten, soweit erforderlich, von verknüpften Accounts von Drittanbietern, externen Partnern
            und externen Integrationen, um die angemessene Erfüllung unseres Vertrags mit Ihnen sicherzustellen, oder um
            sicherzustellen, dass wir geltendes Recht einhalten, oder mit Ihrer Zustimmung.
        </p>

        <div class="privacy-section">
            <h3>{{ 'Verwendung dieser Informationen' | uppercase }}</h3>
            <p>Wir verwenden über Sie gespeicherte Informationen folgendermaßen:</p>
            <ul>
                <li>
                    Ihnen Informationen, Produkte oder Dienste zu liefern, die Sie von uns bestellen, oder die wir für
                    Sie für relevant halten, wenn Sie zugestimmt haben für solche Zwecke kontaktiert zu werden;
                </li>
                <li>
                    Sicherzustellen, dass die Inhalte aus unserer App und Website für Sie und Ihren Computer/Tablet bzw.
                    Mobilgerät bestmöglich dargestellt werden;
                </li>
                <li>Erfüllung unserer Pflichten aus Verträgen zwischen Ihnen und uns;</li>
                <li>
                    Ihnen die Teilnahme an den interaktiven Features unserer Produkte und Dienste zu ermöglichen, sofern
                    Sie sich dafür entscheiden;
                </li>
                <li>Um Sie über Änderungen an unseren Produkten und Diensten zu informieren.</li>
                <p class="bulet_spaced_para">
                    Wir können Ihre Daten auch dazu nutzen, Sie über Produkte und Dienste zu informieren, die für Sie
                    interessant sein könnten und wir können Sie in diesem Zusammenhang kontaktieren bzw. ausgewählten
                    Dritten die Nutzung zu diesem Zweck erlauben.
                </p>
            </ul>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Informationen, die wir über sie sammeln können' | uppercase }}</h3>
            <p>Wir können die folgenden Daten über sie Sammeln und verarbeiten:</p>
            <ul>
                <li>Kontaktinformationen;</li>
                <li>Anmeldeinformationen;</li>
                <li>Details zur Zahlungsverarbeitung;</li>
                <li>
                    Persönliche Informationen zur Bonitätsprüfung, wie z.B. Name, Geburtsdatum, aktuelle oder vorige
                    Adresse, Name des aktuellen Arbeitgebers, Führerscheinnummer, IP-Adressen und Gerätekennungen
                    (UDIDs);
                </li>
                <li>Bevorzugte Kommunikationswege;</li>
                <li>
                    Fotos von Ihnen, falls Sie sich über soziale Medien anmelden; über all die oben genannten
                    Drittanbieterdienste und anderen Dienste, die in unserer App und Website integriert sind.
                </li>
                <li>Ihr Geschlecht und Familienstand;</li>
                <li>Sensible Informationen laut Abschnitt Sensible Informationen dieser Datenschutzerklärung;</li>
                <li>Persönliche Interessen;</li>
                <li>
                    Details Ihrer Aufrufe unserer Website und App, wie z.B. Traffic-Daten, Standortdaten, Weblogs und
                    andere Kommunikationsdaten, sei es für unsere eigenen Abrechnungszwecke oder für andere Zwecke, und
                    auf die Ressourcen, die Sie aufrufen;
                </li>
                <li>Standortdaten;</li>
                <li>Informationen über Ihre Erfahrungen mit unseren Diensten;</li>
                <li>Produktbewertungsdaten im Rahmen unserer Feedbackrichtlinie;</li>
                <li>Wenn Sie uns kontaktieren, können wir Aufzeichnungen dieser Kommunikation aufbewahren;</li>
                <li>
                    Wir können Sie auch bitten, Umfragen für Forschungszwecke auszufüllen; Sie müssen aber nicht
                    teilnehmen;
                </li>
                <li>Alle anderen persönliche Informationen, die unsere Dienste abfragen.</li>
            </ul>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Identitätsprüfung und Bewertungen' | uppercase }}</h3>
            <p>
                Im Rahmen unserer Dienste können wir Sie um eine Identitätsprüfung bitten. Nutzer können selbst
                Bewertungen erhalten oder Bewertungen für andere Nutzer oder Unternehmen hinterlassen.
                Drittanbieterdienste wie
                <a href="https://sumsub.com/privacy-notice/" target="_blank" rel="noopener noreferrer">Sumsub</a> oder
                <a href="https://www.schufa.de/datenschutz/" target="_blank" rel="noopener noreferrer">Schufa</a> können
                integriert werden, um Nutzer zu identifizieren oder um Nutzerbewertungen, persönliche Daten oder
                historische Bewertungsinformationen zu sammeln.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Sensible Informationen' | uppercase }}</h3>
            <p>
                Wenn Sie uns durch diese Dienste sensible Informationen liefern, nutzen wir diese nur für die in dieser
                Datenschutzerklärung genannten Zwecke. Dazu gehören politische Ansichten, religiöse Ansichten und
                aktuelle persönliche Meinungen. Wir sammeln sensible Informationen nur mit Ihrer Einwilligung. Sollten
                wir diese Informationen mit Dritten teilen, dann nur im Rahmen dieser Datenschutzerklärung.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Veröffentlichung Ihrer Informationen' | uppercase }}</h3>
            <p>
                Wir können Ihre Anmeldeinformationen mit jedem Teil unserer Firmengruppe teilen; das beinhaltet
                Tochtergesellschaften, unsere Muttergesellschaft sowie deren Tochtergesellschaften.
            </p>
            <p>
                Wir teilen persönliche Informationen gewöhnlich nicht mit anderen Organisationen, außer in folgenden
                Fällen:
            </p>
            <ul>
                <li>Es ist gesetzlich erforderlich;</li>
                <li>
                    Wir halten es für nötig, um Ihnen ein Produkt oder einen Diensten anzubieten, die sie angefragt
                    haben;
                </li>
                <li>Die Nutzung oder Weitergabe ist nach dieser Datenschutzerklärung zulässig;</li>
                <li>
                    Zum Schutz der Rechte, des Eigentums oder der persönlichen Sicherheit jedes Bürgers oder unserer
                    Kunden oder in unserem Interesse;
                </li>
                <li>Es wird von Dritten wie z.B. Versandfirmen benötigt, um Ihnen diese Dienstleistung zu liefern;</li>
                <li>
                    Falls unser Vermögen oder unser Betrieb ganz oder teilweise im Rahmen einer teilweisen oder
                    vollständigen Veräußerung unseres Unternehmens an Dritte übertragen wird oder werden soll;
                </li>
                <li>Mit Ihrer Einwilligung.</li>
            </ul>
            <p>
                Die Weitergabe Ihrer Informationen erfolgt dabei an folgende Unternehmen oder Kategorien von
                Unternehmen:
            </p>
            <li>Versicherungspartner</li>
            <li>Google (Unter anderem Analytics Dienste und Tag-Manager)</li>
            <li>Web-Infrastruktur Anbieter</li>
            <li>Verifizierungspartner</li>
            <li>Schufa</li>
            <li>Zahlungsanbieter (Stripe, PayPal)</li>
            <li>Lieferdienste</li>

            <p>
                Wir können ebenfalls nicht-personenbezogene, anonymisierte und aggregierte Informationen für Forschungs-
                und Werbezwecke weitergeben. Abgesehen von den in dieser Erklärung genannten Fällen, verkaufen wir keine
                persönlichen Informationen an Dritte oder erlauben Dritten die Nutzung dieser persönlichen
                Informationen.
            </p>
            <p>
                <strong>Informationen</strong><br />können auch mit Dritten, wie z.B. Versicherungsanbietern, geteilt
                werden um unsere Dienste anbieten zu können oder Versicherungsrisiken zu bestimmen.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Datenschutz' | uppercase }}</h3>
            <p>
                Alle Informationen, die Sie uns übermitteln, werden auf unserer OVH Cloud-Plattform gespeichert. Alle
                Zahlungstransaktionen werden verschlüsselt. Falls wir Ihnen ein Passwort zugewiesen haben (oder Sie ein
                Passwort gewählt haben), um Teile unserer App und Website zu nutzen, sind Sie dafür zuständig dieses
                Passwort vor unbefugtem Zugriff zu schützen. Wir bitten Sie, dieses Passwort mit niemandem zu teilen.
            </p>
            <p>
                Leider ist die Übertragung von Informationen über das Internet nicht vollständig sicher. Zwar tun wir
                unser Bestes, um Ihre persönlichen Daten zu schützen, können jedoch nicht garantieren, dass die an
                unsere App und an unsere Website übertragenen Daten sicher sind. Jede Übertragung findet auf eigenes
                Risiko statt. Sobald wir Ihre Informationen erhalten haben, setzen wir strikte Verfahren und
                Sicherheitsfunktionen ein, um unberechtigten Zugriff zu verhindern.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Links zu anderen Websites' | uppercase }}</h3>
            <p>
                Banner und Werbung auf unserer App und Website können auf andere Websites, Apps oder soziale Medien
                verlinken. Sollte dies der Fall sein, können wir keine Verantwortung für deren Inhalte und Praktiken
                übernehmen. Andere Apps oder Websites sind für ihre eigenen Inhalte und Sammlung von Informationen
                verantwortlich; es gelten deren eigene Datenschutzerklärungen. Wir übernehmen keine Verantwortung oder
                Haftung für die Datenschutzerklärungen Dritter. Bitte prüfen Sie solche Erklärungen, bevor Sie
                persönliche Daten an diese Apps oder Websites übertragen. Diese Datenschutzerklärung erfasst keine
                anderen Apps oder Websites.
            </p>
            <p>
                Cookies in Verbindung mit diesen Diensten sind von unseren Bedingungen abgedeckt. Allerdings können
                andere Apps oder Websites Cookies oder Tracking nutzen. Es wird empfohlen, dass Sie die Bestimmungen zu
                Cookies, Tracking und Informationsspeicherung in der Datenschutzerklärung der anderen Websites oder Apps
                prüfen.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Ihre Rechte' | uppercase }}</h3>
            <p>
                Sie haben das Recht uns zu bitten, Ihre persönlichen Daten nicht für Werbezwecke zu verarbeiten. Wir
                informieren Sie normalerweise (vor Erfassung Ihrer Daten), falls wir vorhaben Ihre Daten für solche
                Zwecke zu nutzen oder falls wir vorhaben Ihre Daten für solche Zwecke an Dritte weiterzugeben. Sie
                können dieses Recht jederzeit ausüben. Sie können uns per E-Mail darüber informieren: contact@fainin.com
            </p>
            <p>
                Sie haben das Recht, von uns die Offenlegung all Ihrer persönlichen Daten zu verlangen. Damit wir Ihnen
                diese Informationen liefern können, müssen Sie:
            </p>
            <ul>
                <li>Uns schriftlich informieren; und</li>
                <li>Ihre Identität ausreichend nachweisen.</li>
            </ul>
            <p>Unser Recht zur Rückhaltung dieser persönlichen Daten ist gesetzlich beschränkt.</p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Wie wir Ihre persönlichen Informationen sammeln und aufbewahren' | uppercase }}</h3>
            <p>
                Wir können Ihre persönlichen Informationen durch diese Dienste und im Rahmen dieser Datenschutzerklärung
                oder in anderen gesetzlich zulässigen Fällen sammeln. Wir können persönliche Informationen von Dritten
                beziehen. In diesem Fall sammeln wir die Informationen ebenfalls, um die Dienste zu erbringen und im
                Rahmen dieser Datenschutzerklärung.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Datenschutzverpflichtung' | uppercase }}</h3>
            <p>
                Ihre personenbezogenen Informationen werden sicher gespeichert. Nur befugte Mitarbeiter, Vertreter und
                Subunternehmer haben Zugriff. Diese sind ebenfalls verpflichtet, die Informationen sicher und
                vertraulich zu behandeln. Alle E-Mails und Newsletter aus dieser App/Website erlauben Ihnen die
                Abbestellung weiterer Benachrichtigungen.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Zugriff auf Informationen durch Dritte' | uppercase }}</h3>
            <p>
                Durch diese Dienste können Sie Beiträge, Kommentare und Produktbewertungen im Rahmen unserer
                Feedbackrichtlinie hinterlassen. Diese Informationen können von Dritten eingesehen und als
                Entscheidungsgrundlage verwendet werden. Sie kann über andere Apps, Websites oder soziale Medien geteilt
                werden. Diese Informationen können in einem offenen Forum öffentlich werden. Wir übernehmen keine
                Verantwortung für eine Vervielfältigung oder Weitergabe dieser Informationen.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Datenspeicherung' | uppercase }}</h3>
            <p>
                Um die gesetzlichen Anforderungen zu erfüllen, speichern wir Ihre Informationen so lange wie für die
                Bereitstellung der Dienste notwendig. Wenn Sie die Dienste nicht weiter nutzen möchten, können Sie die
                Dienste im Rahmen unserer Geschäftsbedingungen über unsere App oder Website kündigen. Nach einer
                Kündigung müssen wir jedoch u.U. einige Informationen zur Erfüllung gesetzlicher Pflichten weiterhin
                aufbewahren.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Informationen über Kinder' | uppercase }}</h3>
            <p>
                Diese Datenschutzerklärung und all unsere Dienste richten sich nicht an Personen unter achtzehn (18)
                Jahren. Wir sammeln keine Informationen über Personen unter dieser Altersgrenze, sofern uns dies bekannt
                ist, außer mit der Einwilligung eines Erziehungsberechtigten.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Änderung und Aktualisierung von Informationen' | uppercase }}</h3>
            <p>
                Von uns gesammelte und gespeicherte Informationen können bei Bedarf aktualisiert oder verändert werden.
                Sie können uns dazu per E-Mail kontaktieren: contact@fainin.com. Falls eine Löschung der Informationen
                erforderlich ist, muss diese im Rahmen unserer AGB und dieser Datenschutzerklärung erfolgen. Laut Gesetz
                kann eine länger andauernde Speicherung dieser Informationen erforderlich sein.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Fortbestehen der Datenschutzerklärung' | uppercase }}</h3>
            <p>
                Diese Datenschutzerklärung besteht über eine Kündigung dieser Dienste oder eine Veräußerung des
                Unternehmens durch uns fort. Ihre Informationen können an potenzielle Käufer weitergegeben werden und
                Teil der veräußerten Vermögenswerte sein. In diesem Fall besteht diese Datenschutzerklärung fort.
            </p>
        </div>

        <div class="privacy-section">
            <h3>{{ 'Kontakt' | uppercase }}</h3>
            <p>
                Sie können jederzeit die über sie gespeicherten persönlichen Informationen von uns abfragen oder
                Beschwerden bei uns melden. Wenden Sie sich dazu schriftlich an contact@fainin.com oder an Fainin GmbH,
                Mühlenkamp 4, 22303 Hamburg, Deutschland.
            </p>
        </div>
    </div>
</div>
