import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule, isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@core/providers/chat.service';
import { DataService } from '@core/providers/data/data.service';
import { UserService } from '@core/providers/data/user.service';
import { GoogleTagManagerService } from '@core/providers/google-tag-manager.service';
import { ResponsiveService } from '@core/providers/responsive.service';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable } from 'rxjs';

@Component({
    selector: 'fainin-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, NzToolTipModule],
})
export class AccountMenuComponent implements OnInit, OnDestroy {
    signedIn$: Observable<boolean>;
    firstVisit$: Observable<boolean>;
    unreadMessagesCount: number = 0;
    unreadOrdersRequests: number = 0;
    webTutorialSeen$: Observable<boolean>;
    profileImageUrl: string = '';

    drawerVisible = false;
    @HostBinding('class.desktop') isDesktop: boolean = false;
    isTablet: boolean = false;
    totalNotifications: number;
    isPlatformServer: boolean;

    @HostBinding('class.isSearching') isSearching: boolean = false;

    constructor(
        private stateService: StateService,
        private chatService: ChatService,
        public router: Router,
        private screenObserver: BreakpointObserver,
        private responsive: ResponsiveService,
        private tm: GoogleTagManagerService,
        public userService: UserService,
        private dataService: DataService,
        private cd: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {}

    async ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            this.isPlatformServer = true;
        } // Do not show any account-page for SSR on first Render

        this.setOrderRequestNotifications();

        this.setNotifications();
        this.responsive
            .isDesktop()
            .pipe(untilDestroyed(this))
            .subscribe(desktop => {
                this.isDesktop = desktop;
            });

        this.responsive
            .isTablet()
            .pipe(untilDestroyed(this))
            .subscribe(mobile => {
                this.isTablet = !mobile;
            });

        this.stateService
            .select(state => state.menuDrawerOpen)
            .pipe(untilDestroyed(this))
            .subscribe(menuDrawerOpen => {
                this.drawerVisible = menuDrawerOpen;
            });

        this.stateService
            .select(state => state.showSubHeader)
            .pipe(untilDestroyed(this))
            .subscribe(showSubHeader => {
                this.isSearching = showSubHeader;
            });

        this.signedIn$ = this.stateService.select(state => state.signedIn);
        this.firstVisit$ = this.stateService.select(state => state.firstVisit);

        this.webTutorialSeen$ = this.stateService.select(state => state.webTutorialSeen);
        this.initializeProfileImage();
        this.setUnreadMessagesMarker();
        this.cd.detectChanges();
    }

    private initializeProfileImage(): void {
        /**
         * Using subscription instead of `currentState` because logging out
         * and logging back in shows previous profile icon until page is
         * reloaded
         */
        this.stateService
            .select(state => state.activeProfile)
            .pipe(untilDestroyed(this))
            .subscribe(profile => {
                if (!profile) {
                    return;
                }

                this.profileImageUrl =
                    (profile && profile?.featuredAsset?.preview) ??
                    `https://dummyimage.com/200x200/5BCBCE/fff&text=${profile.name.substring(0, 1)}`;
            });
    }

    onMenuClicked(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.stateService.setState('menuDrawerOpen', true);
        this.tm.pushTag({ event: 'MenuClicked' });
    }

    onCloseMenuClicked(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.stateService.setState('menuDrawerOpen', false);
        this.tm.pushTag({ event: 'MenuClosed' });
    }

    closeSearchAndNavigateSignIn() {
        this.stateService.setState('firstVisit', false);
        this.router.navigate(['auth/sign-in-landing']);
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }

    async navigateToRentals() {
        this.router.navigate(['account/orders']);
    }

    async navigateToDashboard() {
        this.router.navigate(['account/']);
    }

    private setUnreadMessagesMarker(): void {
        this.stateService
            .select(state => state.totalUnreadMessages)
            .pipe(untilDestroyed(this))
            .subscribe((unreadCount: number) => {
                this.unreadMessagesCount = unreadCount;
                this.cd.detectChanges();
            });
    }

    private setOrderRequestNotifications() {
        this.userService.unreadMarkers.pipe(untilDestroyed(this)).subscribe(markers => {
            this.unreadOrdersRequests = markers.filter(
                marker => marker.entityName === 'Order' || marker.entityName === 'Request',
            ).length;
            this.cd.markForCheck();
        });
    }

    private setNotifications() {
        this.userService.unreadMarkers.pipe(untilDestroyed(this)).subscribe(markers => {
            this.totalNotifications = markers.length;
        });
    }
}
