import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fainin-app-downloads',
    templateUrl: './app-downloads.component.html',
    styleUrls: ['./app-downloads.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDownloadsComponent {
    @Input() withQRCodes: boolean = false;

    constructor() {}
}
