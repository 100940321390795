export default {
    message: [
        `fainin використовує файли cookie та подібні технології для персоналізації вашого вмісту та оптимізації вашого користування.
Натиснувши ОК або активувавши опцію в налаштуваннях файлів Cookies. Diese Infos beziehen sich auf den jeweiligen Nutzer,, ви погоджуєтеся на це.
Це також буде у `,
        `Захист даних`,
        `описано. Щоб змінити налаштування або відкликати свою згоду, просто оновіть налаштування файлів cookie.`,
    ],
    title: 'Ми дбаємо про захист ваших даних',
    intro:
        'Коли ви відвідуєте веб-сайт в Інтернеті, він може зберігати та/або отримувати інформацію у вашому браузері. ' +
        'Переважно у вигляді так званого Cookies. Ця інформація стосується відповідного користувача, ' +
        'їх налаштування або пристрій і використовуються в основному для того, щоб веб-сайт працював належним чином' +
        'Оскільки інформація (переважно) анонімна, вона зазвичай не ідентифікує вас безпосередньо, ' +
        'але допоможе вам персоналізувати роботу користувача. Тому що ми поважаємо ваші права на конфіденційність, ' +
        'ви можете заборонити деякі файли Cookies. Однак це блокування може вплинути' +
        'впливати на ваш досвід роботи в Інтернеті та наші послуги.',
    heading: 'Захист ваших даних',
    policy: 'конфіденційність',
    button: {
        save: 'Зберегти',
        back: 'Повернення',
        customize: 'Налаштування файлів Cookies',
        accept: 'Прийняти все',
    },
    consent: {
        base: {
            title: 'Суворо необхідні Cookies',
            description:
                'Ці файли cookie необхідні для функціонування та представлення веб-сайту і не можуть ' +
                'бути вимкненими. Наприклад, налаштування конфіденційності, вхід, ' +
                'заходи безпеки ReCaptcha або заповнення форм на цьому веб-сайті.',
        },
        analytics: {
            title: 'Анонімні аналітичні дані',
            description:
                'Цей параметр необхідний для анонімного аналізу відвідувань та джерел трафіку. Даючи згоду на збір загальних аналітичних даних, ти дозволяєш нам зрозуміти, як наш сайт використовується. Ці дані допомагають нам аналізувати та покращувати продуктивність нашого сайту, пропонуючи тобі кращий користувацький досвід. Твої дані збираються та обробляються анонімно, щоб захистити твою приватність. Ти активно допомагаєш нам оптимізувати наш контент і послуги для тебе та інших.',
        },
        ad_user_data: {
            title: 'Розуміння твоїх інтересів',
            description:
                'Цей параметр дозволяє нам збирати дані, які допомагають нам краще розуміти твої інтереси та уподобання. Це включає інформацію, таку як відвідувані тобою сайти або переглянуті продукти. Ця згода допомагає нам зробити твій досвід більш індивідуальним і релевантним.',
        },
        ad_personalization: {
            title: 'Реклама, яка підходить саме тобі',
            description:
                'З твоєї згоди ми можемо використовувати зібрані про тебе дані для персоналізації реклами, яку ти бачиш. Це означає менше часу з незацікавленою рекламою та більше відкриттів продуктів та послуг, які дійсно тебе цікавлять. Ти зберігаєш контроль над цим параметром і можеш змінити його в будь-який час.',
        },
        ad_storage: {
            title: "Запам'ятовування твоїх переваг",
            description:
                "Твоя згода дозволяє нам зберігати інформацію на твоєму пристрої, щоб пам'ятати твої переваги та повторні візити. Це забезпечує максимально зручний досвід користування, з швидшими часами завантаження та рекламою, яка відповідає тому, що ти любиш.",
        },
    },
};
