export default {
    title: 'Про нас',
    caption: {
        mission: 'Наша Місія',
        vision: 'Наше Бачення',
        what: 'Що таке fainin?',
        why: 'Чому ми це робимо?',
        story: 'Історія fainin',
    },
    text: {
        mission:
            `fainin дозволяє безпечно обмінюватися інформацією між друзями та незнайомими людьми. <br> ` +
            `Завдяки заходам, які створюють довіру, оренда стає частиною повсякденного життя, а отже ` +
            `наше колективне споживання зменшується. <br> <br> Переконайтеся в місії fainin і станьте частиною сім'ї.`,
        vision: 'Спільний доступ – це нове володіння!',
        what: `fainin — це ваша спільнота для безпечної оренди і позик. Ми полегшуємо для вас позику та заробіток. fainin на 100% захищений і покриває кожен крок
        транзакції. Дизайн-на-довіру, включаючи страхування, перевірку та документацію, уникає
        негативний досвід в fainin.`,
        why: `Ми хочемо покращити світ разом із fainin. Через це всі виграють від участі, бо через
ділимося, ми створюємо можливості один для одного! #faininIsForYou`,
        us: `Нас звуть Йоганн, Макс і Кевін. Ми хочемо покращити світ, і разом із fainin ми надаємо вам можливість взяти участь.`,
        usCaption: 'Це ми',
    },
    card: {
        sustainability: {
            title: 'Єкологічність',
            body: `Чим більше ми ділимося, тим менше нам потрібно.`,
        },
        socialTrust: {
            title: 'Безпека',
            body: `Обмін базується на довірі. Ось чому fainin створений для довіри.`,
        },
        adventure: {
            title: 'Свобода',
            body: `Володіння має переваги, але використання приносить досвід. Припиніть купувати, почніть ділитися.`,
        },
        winWin: {
            title: 'WinWin',
            body: `Пропонуйте своїм ближнім можливості та заробляйте гроші, позичаючи.`,
        },
        forYou: {
            title: 'Для всіх',
            body: `100% безпечна оренда між друзями та незнайомими людьми.`,
        },
    },
    story: {
        '1': {
            title: 'Початок',
            text: `Сім'я fainin була заснована в Берліні з баченням зробити світ трохи більш стійким.`,
        },
        '2': {
            title: 'Початок нової єри',
            text: `Ми Йоганн, Кевін і Макс. За допомогою програми fainin ми пропонуємо вам найкращі умови для обміну.

            `,
        },
        '3': {
            title: 'Позитивний вплив',
            text: `Сім'я fainin прагне зробити світ трохи кращим. Чим менше лежить довколо невикористованих речей, тим менше потрібно нових ресурсів, що сприяє єкологічності.
            Спільнота fainin об’єднує людей і створює безпрограшні ситуації для всіх сторін.`,
        },
        '4': {
            title: 'Три принципа',
            text: `fainin заснований на трьох основних принципах: <br>
            Win-Win, єкологічность і соціальна близькість, <br>
            основа будь-якої діяльності fainin`,
        },
        '5': {
            title: 'Твоя Sharing сімя',
            text: `Кожен учасник може зробити свій внесок
            сприяє сталому довкіллю і є частиною чудових змін. Разом ми рятуємо
            наша улюблена планета!`,
        },
        '6': {
            title: 'Нескінченні можливості',
            text: `Станьте частиною спільноти найбезпечнішого обміну. Кожен виграє від
         обміну в сім'ї fainin і роблять світ трохи краще.`,
        },
    },
    team: {
        johann: 'Я хочу створити для вас безмежні можливості',
        max: 'Я хочу зробити наш світ кращим',
        kevin: 'Я хочу запропонувати дистриб юторам необхідну довіру',
    },
};
