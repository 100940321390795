import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { MyProductCardComponent } from '@core/components/my-product-card/my-product-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { AssetPreviewSharedModule } from '@shared/asset-preview-shared.module';
import { RatingComponentModule } from '@shared/components/rating/rating-component.module';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import { FormatPriceSharedModule } from '@shared/format-price-shared.module';
import { SharedModule } from '@shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { FontAwesomeCoreModule } from './fontAwesome-core.module';

@NgModule({
    declarations: [ProductCardComponent, MyProductCardComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormatPriceSharedModule,
        LazyLoadImageModule,
        AssetPreviewSharedModule,
        FontAwesomeCoreModule,
        NzDropDownModule,
        RouterLink,
        RouterLinkWithHref,
        SharedDirectiveModule,
        RatingComponentModule,
        NzToolTipModule,
        NzSkeletonModule,
        SharedModule,
        NgOptimizedImage,
    ],
    exports: [ProductCardComponent, MyProductCardComponent],
})
export class ProductCardCoreModule {}
