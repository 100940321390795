export default {
    button: {
        label: 'To the article',
    },
    alt: {
        imageBlog: 'Preview image for the {{slug}} article',
        imageCampaign: 'Preview image for the {{title}} campaign',
        fallbackImageBlog: 'Fallback image for the {{slug}} article',
        fallbackImageCampaign: 'Fallback image for the {{title}} campaign',
    },
};
