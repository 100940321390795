<div class="community">
    <a class="community-image" [routerLink]="['/community', community.slug]">
        <ng-container *ngIf="community.featuredAsset; else fallbackImage">
            <img
                *ngIf="community.featuredAsset"
                [lazyLoad]="community.featuredAsset | assetPreview"
                [alt]="'Group image for ' + community.name" />
        </ng-container>
        <ng-template #fallbackImage>
            <img
                class="thumbnail"
                src="/assets/logo/icon-192x192.png"
                [alt]="'Fallback community image for ' + community.name" />
        </ng-template>
    </a>

    <div class="community-info" [class.no-actions]="!allowActions">
        <div class="name">{{ community.name }}</div>
        <div class="description paragraph-small">{{ community.description }}</div>
        <!--        <div class="members">
            {{ community.members }}
            <fa-icon icon="users"></fa-icon>
        </div>-->
    </div>

    <div class="community-actions" *ngIf="allowActions">
        <ng-container *ngIf="(actionState | async) === 'CAN_JOIN'; else showView">
            <ng-container *ngIf="signedIn$ | async; else notSignedIn">
                <fainin-button class="join" buttonClass="primary" (debounceClick)="doJoinGroup(community)">
                    {{ 'content.community.join' | translate }}
                </fainin-button>
            </ng-container>
            <ng-template #notSignedIn>
                <fainin-button class="signin-then-join" buttonClass="primary" (debounceClick)="doSignIn()">
                    {{ 'content.community.join' | translate }}
                </fainin-button>
            </ng-template>
        </ng-container>
        <ng-template #showView>
            <fainin-button class="show" buttonClass="primary" [routerLink]="['/community', community.slug]">
                {{ 'content.community.show' | translate }}
            </fainin-button>
        </ng-template>
    </div>
</div>
