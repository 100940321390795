import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RouterEvent } from '@angular/router';
import { AppleSignInEvent, IAppleSignInResponsePayload } from '@core/providers/webview/events/apple-signin.event';
import {
    CameraPermissionEvent,
    ICameraPermissionPayload,
} from '@core/providers/webview/events/camera-permission.event';
import { DeviceTokenEvent, IDeviceTokenPayload } from '@core/providers/webview/events/device-token.event';
import { GoogleSignInEvent, IGoogleSignInResponsePayload } from '@core/providers/webview/events/google-signin.event';
import { ILogoutResponsePayload, LogoutEvent } from '@core/providers/webview/events/logout.event';
import { IMediaPermissionPayload, MediaPermissionEvent } from '@core/providers/webview/events/media-permission.event';
import {
    IPayPalRequestPayload,
    IPayPalResponsePayload,
    PayPalEvent,
} from '@core/providers/webview/events/paypal.event';
import {
    ISumSubResultPayload,
    ISumSubVerifyPayload,
    SumSubVerifyEvent,
} from '@core/providers/webview/events/sumsub-verify.event';
import { ITrackingConsentResponse, TrackingConsentEvent } from '@core/providers/webview/events/tracking-consent.event';
import {
    IWebViewEnvironmentResponse,
    WebViewEnvironmentEvent,
} from '@core/providers/webview/events/web-view-environment.event';
import {
    AWebViewEvent,
    IRequestPayload,
    IResponsePayload,
    IWebViewEvent,
    WebViewEventHandler,
} from '@core/providers/webview/web-view.events';
import { Logger, LogLevel } from '@core/utils/logger';

declare var ReactNativeWebView: any;

const logger = new Logger('WebViewService');

@Injectable({
    providedIn: 'root',
})
export class WebViewService {
    private _isWebView: boolean;
    private _webViewEnv?: IWebViewEnvironmentResponse;
    private readonly eventHandler: Map<string, WebViewEventHandler> = new Map<string, WebViewEventHandler>();
    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        this._isWebView = 'ReactNativeWebView' in window;
        logger.debug(`isWebView`, this._isWebView);
        if (this._isWebView) {
            this.getWebViewEnvironment();
        }
    }

    public addEventHandler(eventName: string, handler: WebViewEventHandler) {
        logger.debug(`addEventHandler`, eventName);
        this.eventHandler.set(eventName, handler);
        this.listenForWebviewEvent(eventName);
    }

    get isWebView(): boolean {
        return this._isWebView;
    }

    get isDebug(): boolean {
        return this._isWebView && this._webViewEnv?.debug === true;
    }

    private getWebViewEnvironment(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        if (!this._isWebView) return;
        logger.debug(`getWebViewEnvironment`);

        // Create and prepare new event to be sent
        const event = new WebViewEnvironmentEvent();
        this.sendWebViewEvent<WebViewEnvironmentEvent, IWebViewEnvironmentResponse>(event).then(value => {
            if (value) {
                this._webViewEnv = value;
                if (this.isDebug) {
                    Logger.setLogLevel(LogLevel.Debug);
                }
            }
        });
    }

    public getTrackingConsent(): Promise<ITrackingConsentResponse | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`getTrackingConsent`);

        // Create and prepare new event to be sent
        const event = new TrackingConsentEvent();
        event.init({ askAgain: true });
        return this.sendWebViewEvent<TrackingConsentEvent, ITrackingConsentResponse>(event);
    }

    public requestDeviceToken(): Promise<IDeviceTokenPayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestDeviceToken`);

        // Create and prepare new event to be sent
        const event = new DeviceTokenEvent();
        return this.sendWebViewEvent<DeviceTokenEvent, IDeviceTokenPayload>(event);
    }

    public requestCameraPermissions(): Promise<ICameraPermissionPayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestCameraPermissions`);

        // Create and prepare new event to be sent
        const event = new CameraPermissionEvent();
        return this.sendWebViewEvent<CameraPermissionEvent, ICameraPermissionPayload>(event);
    }

    public requestMediaPermissions(): Promise<IMediaPermissionPayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestMediaPermissions`);

        // Create and prepare new event to be sent
        const event = new MediaPermissionEvent();
        return this.sendWebViewEvent<MediaPermissionEvent, IMediaPermissionPayload>(event);
    }

    public requestGoogleSignIn(): Promise<IGoogleSignInResponsePayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestGoogleSignIn`);

        // Create and prepare new event to be sent
        const event = new GoogleSignInEvent();
        return this.sendWebViewEvent<GoogleSignInEvent, IGoogleSignInResponsePayload>(event);
    }

    public requestAppleSignIn(): Promise<IAppleSignInResponsePayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestAppleSignIn`);

        // Create and prepare new event to be sent
        const event = new AppleSignInEvent();
        return this.sendWebViewEvent<AppleSignInEvent, IAppleSignInResponsePayload>(event);
    }

    public requestPayPalPayment(
        request: Omit<IPayPalRequestPayload, '__type'>,
    ): Promise<IPayPalResponsePayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestDeviceToken`);

        // Create and prepare new event to be sent
        const event = new PayPalEvent().init(request);
        return this.sendWebViewEvent<PayPalEvent, IPayPalResponsePayload>(event);
    }

    public requestSumSubVerification(
        payload: Omit<ISumSubVerifyPayload, '__type'>,
    ): Promise<ISumSubResultPayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`requestSumSubVerification`);

        // Create and prepare new event to be sent
        const event = new SumSubVerifyEvent();
        event.init(payload);
        return this.sendWebViewEvent<SumSubVerifyEvent, ISumSubResultPayload>(event);
    }

    public logout(): Promise<ILogoutResponsePayload | undefined> {
        if (!isPlatformBrowser(this.platformId)) return Promise.reject('Not a browser!');
        if (!this._isWebView) return Promise.reject('Not running in a webview!');
        logger.debug(`logout`);

        // Create and prepare new event to be sent
        const event = new LogoutEvent();
        return this.sendWebViewEvent<LogoutEvent, ILogoutResponsePayload>(event);
    }

    private sendWebViewEvent<E extends AWebViewEvent<any, Res>, Res extends IResponsePayload>(
        event: E,
    ): Promise<Res | undefined> {
        logger.debug(`sendWebViewEvent`, event.name);
        return new Promise((resolve, reject) => {
            if ('ReactNativeWebView' in window) {
                try {
                    const eventListener = (evt: Event | RouterEvent) => {
                        const response = (evt as CustomEvent).detail as E;
                        if (response.id !== event.id) {
                            reject("Id's are not matching!");
                            return;
                        }
                        logger.debug(
                            `Received ${response.name} response with payload being: ${
                                !!response.payload ? 'defined' : 'undefined'
                            }`,
                        );
                        resolve(response.payload);
                    };
                    /*
                     * By adding an event listener with the option "once" = true,
                     * the listener would be automatically removed when invoked.
                     * See: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
                     */
                    window.addEventListener(event.name, eventListener, {
                        once: true,
                        passive: true,
                    });

                    ReactNativeWebView.postMessage(event.stringify());
                } catch (e: any) {
                    logger.error(e.message, e.stack);
                    reject(e.message);
                }
            } else {
                reject('Not running in a webview!');
            }
        });
    }

    private sendWebViewResponse<E extends AWebViewEvent<P, any>, P extends IRequestPayload>(event: E): boolean {
        logger.debug(`sendWebViewResponse`, event.name);
        if ('ReactNativeWebView' in window) {
            try {
                ReactNativeWebView.postMessage(event.stringify());
                return true;
            } catch (e: any) {
                logger.error(e.message, e.stack);
                return false;
            }
        } else {
            logger.error('Not running in a webview!');
            return false;
        }
    }

    private listenForWebviewEvent(name: string): void {
        const eventListener = (evt: Event) => {
            const event = evt as CustomEvent;
            try {
                if (!event.detail) return;
                const request = event.detail as IWebViewEvent<any, any>;
                if (request.handled === undefined || request.id === undefined || request.name === undefined) {
                    logger.debug(`Received event that is not a request generated by the webview.`, request);
                    return;
                }
                const eventName = request.name;
                const eventHandler = this.eventHandler.get(eventName);
                if (eventHandler) {
                    logger.debug(`Handle event: ${request.name}`);
                    const handledEvent = eventHandler(request);
                    Promise.resolve(handledEvent).then(x => {
                        if (x) {
                            this.sendWebViewResponse(x);
                        } else {
                            logger.error(`Event handler for ${eventName} returned undefined event data.`);
                        }
                    });
                }
            } catch (e) {
                logger.debug(`Could not parse MessageEvent data to json`);
                return;
            }
        };
        /*
         * By adding an event listener with the option "once" = true,
         * the listener would be automatically removed when invoked.
         * See: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
         */
        logger.debug(`Listening for ${name}`);
        window.addEventListener(name, eventListener, {
            passive: true,
        });
    }
}
