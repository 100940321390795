import { isPlatformServer } from '@angular/common';
import { FactoryProvider, InjectionToken, makeStateKey, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import possibleTypesData from '@api/introspection-results';
import { ApolloClientOptions, ApolloLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';

import { FetchAdapter } from '@core/providers/data/fetch-adapter';
import { I18nService } from '@core/providers/i18n.service';

import { environment } from '@env/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createUploadLink } from 'apollo-upload-client';
import { Request } from 'express';

if (!environment.production) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}

export const AUTH_TOKEN_KEY = 'auth_token';
export const STATE_KEY = makeStateKey<any>('apollo.state');
export const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
export const APOLLO_CACHE_PROVIDER: FactoryProvider = {
    provide: APOLLO_CACHE,
    useFactory: () => {
        return new InMemoryCache({
            possibleTypes: possibleTypesData.possibleTypes,
            dataIdFromObject(responseObject) {
                switch (responseObject.__typename) {
                    case 'SearchResult':
                        return `SearchResult:${responseObject.productId}`;
                    default:
                        return defaultDataIdFromObject(responseObject);
                }
            },
            typePolicies: {
                Query: {
                    fields: {
                        eligibleShippingMethods: {
                            merge: replaceFields,
                        },
                    },
                },
                Product: {
                    fields: {
                        customFields: {
                            merge: mergeFields,
                        },
                    },
                },
                Collection: {
                    fields: {
                        customFields: {
                            merge: mergeFields,
                        },
                    },
                },
                Order: {
                    fields: {
                        lines: {
                            merge: replaceFields,
                        },
                        shippingLines: {
                            merge: replaceFields,
                        },
                        discounts: {
                            merge: replaceFields,
                        },
                        shippingAddress: {
                            merge: replaceFields,
                        },
                        billingAddress: {
                            merge: replaceFields,
                        },
                    },
                },
                Customer: {
                    fields: {
                        addresses: {
                            merge: replaceFields,
                        },
                        customFields: {
                            merge: mergeFields,
                        },
                    },
                },
            },
        });
    },
};
export const APOLLO_CLIENT_PROVIDER: FactoryProvider = {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [HttpLink, APOLLO_CACHE, PLATFORM_ID, FetchAdapter, I18nService, TransferState, [new Optional(), REQUEST]],
};

function mergeFields(existing: any, incoming: any) {
    return { ...existing, ...incoming };
}

function replaceFields(existing: any, incoming: any) {
    return incoming;
}

// Trying to debug why sessions won't work in Safari 13.1
// but only on the live prod version.
function logInterceptorData(on: boolean) {
    localStorage.setItem('_logInterceptorData', on ? 'true' : 'false');
}

if (typeof window !== 'undefined') {
    (window as any).logInterceptorData = logInterceptorData;
}

export function apolloOptionsFactory(
    httpLink: HttpLink,
    apolloCache: InMemoryCache,
    platformId: object,
    fetchAdapter: FetchAdapter,
    i18nService: I18nService,
    transferState: TransferState,
    req?: Request,
): ApolloClientOptions<any> {
    /**
     * Important to initialize the i18n service otherwise 'i18nService.language' always returns null
     * (apolloOptionsFactory) only fires once so not an issue to init here
     */
    i18nService.init();
    let languageUriKey = (i18nService.language ?? '').split('-')[0];

    const { shopApiUrl } = environment;

    // The uri to be used to the api
    const uri = languageUriKey ? `${shopApiUrl}?languageCode=${languageUriKey}` : `${shopApiUrl}`;

    const http = httpLink.create({
        uri,
        withCredentials: false,
    });

    const isBrowser = transferState.hasKey<any>(STATE_KEY);
    if (isBrowser) {
        const state = transferState.get<any>(STATE_KEY, null);
        apolloCache.restore(state);
    } else {
        transferState.onSerialize(STATE_KEY, () => {
            return apolloCache.extract();
        });
        // Reset apolloCache after extraction to avoid sharing between requests
        apolloCache.reset();
    }

    const uploadLink = createUploadLink({
        uri,
        fetch: fetchAdapter.fetch,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    }) as any;

    const isServer = isPlatformServer(platformId);
    return {
        cache: apolloCache,
        // link: ApolloLink.from(isServer ? [http] : [middleware, afterware, uploadLink /*, http*/]),
        link: ApolloLink.from(
            isServer
                ? [http]
                : [
                      setContext((_, prevContext) => {
                          const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
                          if (authToken) {
                              return {
                                  headers: {
                                      ...prevContext.headers,
                                      authorization: `Bearer ${authToken}`,
                                  },
                              };
                          }
                          return {};
                      }),

                      uploadLink,
                  ],
        ),
        ssrMode: isServer, // avoid to run twice queries with `forceFetch` enabled
        ssrForceFetchDelay: 500, // queries with `forceFetch` enabled will be delayed
    };
}
