<ng-template faininDialogTitle>{{ 'account.requestReceived' | translate }}</ng-template>

<ng-container *ngIf="requests">
    <p>
        {{ 'account.greatNews' | translate }}
    </p>
    <swiper id="card-swiper" [config]="config">
        <ng-template *ngFor="let request of requests" swiperSlide>
            <div class="request">
                <img
                    *ngIf="request.productVariant.product.featuredAsset; else dummyImage"
                    [src]="request.productVariant.product.featuredAsset | assetPreview : 'small'"
                    [alt]="request.productVariant.product.slug + ' preview image'" />
                <ng-template #dummyImage>
                    <img
                        src="https://dummyimage.com/200x200/5BCBCE/fff&text=ProductImage"
                        alt="{{ 'asset.myProductCard.fallbackProductPreview' | translate }}" />
                </ng-template>
                <h5>
                    {{ request.productVariant.product.name }}
                </h5>
                <p class="ps">
                    {{ 'account.period' | translate }}: {{ request.startDate | date : 'dd.MM' }}-{{
                        request.endDate | date : 'dd.MM.YYY'
                    }}
                </p>
            </div>

            <button faininShadow type="button" class="btn btn-show-request h5" (click)="goToRequest(request)">
                {{ 'account.showRequest' | translate }}
            </button>
        </ng-template>
    </swiper>
</ng-container>
