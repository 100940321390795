import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/providers/data/user.service';
import { ResponsiveService } from '@core/providers/responsive.service';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { Observable } from 'rxjs';

@Component({
    selector: 'fainin-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent implements OnInit, OnDestroy {
    @Input() hideTabBar: boolean = true;
    isMobile$: Observable<boolean>;
    unreadChatsCount: number = 0;
    unreadOrdersRequests: number = 0;

    constructor(
        private stateService: StateService,
        public router: Router,
        private responsive: ResponsiveService,
        public userService: UserService,
        private cd: ChangeDetectorRef,
    ) {
        this.isMobile$ = this.responsive.isMobile();
    }

    /* eslint-disable-next-line */
    ngOnInit(): void {
        this.stateService
            .select(state => state.totalUnreadMessages)
            .pipe(untilDestroyed(this))
            .subscribe((unreadCount: number) => {
                this.unreadChatsCount = unreadCount;
                this.cd.detectChanges();
            });
        this.setOrderRequestNotifications();
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {}

    private setOrderRequestNotifications() {
        this.userService.unreadMarkers.pipe(untilDestroyed(this)).subscribe(markers => {
            this.unreadOrdersRequests = markers.filter(
                marker => marker.entityName === 'Order' || marker.entityName === 'Request',
            ).length;
            this.cd.markForCheck();
        });
    }
}
