import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeCoreModule } from '@core/fontAwesome-core.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { PageHeaderOptionsComponent } from './components/page-header/page-header-options/page-header-options.component';

@NgModule({
    declarations: [LoadingComponent, PageHeaderComponent, PageHeaderOptionsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NzDropDownModule,
        FontAwesomeCoreModule,
        RouterModule,
        SharedDirectiveModule,
        NzSkeletonModule,
    ],
    exports: [LoadingComponent, PageHeaderComponent, PageHeaderOptionsComponent],
})
export class PageModule {}
