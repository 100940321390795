import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatDateFormatPipe } from '@shared/pipes/chat-date-format.pipe';
import { ProductUrlPipe } from '@shared/pipes/product-url.pipe';
import { SortByPipe } from '@shared/pipes/sort-by.pipe';
import { StrapiAssetPipe } from '@shared/pipes/strapi-asset.pipe';
import { TranslateToPipe } from '@shared/pipes/translate-to.pipe';
import { OrderPipe } from './order.pipe';

@NgModule({
    declarations: [ProductUrlPipe, StrapiAssetPipe, TranslateToPipe, ChatDateFormatPipe, SortByPipe, OrderPipe],
    imports: [CommonModule],
    exports: [ProductUrlPipe, StrapiAssetPipe, TranslateToPipe, ChatDateFormatPipe, SortByPipe, OrderPipe],
})
export class SharedPipesModule {}
