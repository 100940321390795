export default {
    empty: {
        title: `Keine Ergebnisse für "{{term}}"`,
        subtitle: 'Hilfestellung',
        help: [
            'Überprüfen Sie Ihre Suche auf Tippfehler',
            'Verwenden Sie allgemeinere Suchbegriffe',
            'Das Produkt, das Sie suchen, ist möglicherweise noch nicht auf unserer Website',
        ],
        needHelp: 'Brauchen Sie Hilfe, etwas zu finden?',
    },
    pagination: {
        previous: 'Zurück',
        next: 'Weiter',
        info: 'Zeige <span class="number text-primary">{{ displayItems }}</span> von <span class="number text-primary">{{ totalItems }}</span> Ergebnissen',
    },
    filter: 'Filter',
    sort: 'Sortieren',
};
