import { Injectable, NgModule } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    NoPreloading,
    Route,
    RouteReuseStrategy,
    RouterModule,
    UrlSegment,
} from '@angular/router';
import { CustomReuseStrategy } from '@core/custom-reuse-strategy.service';
import { I18nService } from '@core/providers/i18n.service';
import { WebViewService } from '@core/providers/webview/web-view.service';
import { environment } from '@env/environment';
import { PressComponent } from './static/press/press.component';
import { PrivacyComponent } from './static/privacy/privacy.component';
import { TermsEnglishComponent } from './static/terms/terms-english/terms-english.component';
import { TermsComponent } from './static/terms/terms.component';

@Injectable()
class LanguageDetectionOnActivate implements CanActivate {
    constructor(private i18nService: I18nService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (route.data && 'lang' in route.data) {
            this.i18nService.setStaticLanguageRoute();
            this.i18nService.language = route.data.lang;
        }
        return true;
    }
}

@Injectable()
class DevModeGuard implements CanActivate, CanLoad {
    constructor(private webview: WebViewService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return environment.name === 'localhost' || this.webview.isDebug;
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        return environment.name === 'localhost' || this.webview.isDebug;
    }
}

const entityRoutes: Route[] = [
    { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
    { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
    { path: 'collection', loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule) },
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
    { path: 'community', loadChildren: () => import('./community/community.module').then(m => m.CommunityModule) },
    {
        path: 'p',
        loadChildren: () => import('./product-template/product-template.module').then(m => m.ProductTemplateModule),
    },
    {
        path: ':collectionSlug/:subCategorySlug',
        loadChildren: () => import('./category/category-page.module').then(m => m.CategoryPageModule),
    },
];

const blogRoutes: Route[] = [
    { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
];

const devRoutes: Route[] = [
    {
        path: 'development',
        loadChildren: () => import('./static/development/development.module').then(m => m.DevelopmentModule),
        canActivate: [DevModeGuard],
    },
];

const accountRoutes: Route[] = [
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    },
];

const routes: Route[] = [
    { path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    { path: 'linktree', loadChildren: () => import('./linktree/linktree.module').then(m => m.LinktreeModule) },
    { path: 'home', redirectTo: 'landing' },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    { path: 'about', loadChildren: () => import('./static/about/about.module').then(m => m.AboutModule) },
    { path: 'imprint', loadChildren: () => import('./static/imprint/imprint.module').then(m => m.ImprintModule) },
    { path: 'terms', component: TermsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'press', component: PressComponent },
    { path: 'contact', loadChildren: () => import('./static/contact/contact.module').then(m => m.ContactModule) },
    { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
    ...(environment.featureFlags.blog ? blogRoutes : []),
    ...(environment.featureFlags.product ? entityRoutes : []),
];

export const entryPoints: Route[] = [
    ...accountRoutes,
    ...devRoutes,
    {
        path: 'de',
        children: routes,
        data: {
            lang: 'de-DE',
        },
        canActivate: [LanguageDetectionOnActivate],
    },
    {
        path: 'en',
        children: routes,
        data: {
            lang: 'en-US',
        },
        canActivate: [LanguageDetectionOnActivate],
    },
    {
        path: 'uk',
        children: routes,
        data: {
            lang: 'uk-UK',
        },
        canActivate: [LanguageDetectionOnActivate],
    },
    {
        path: 'ru',
        children: routes,
        data: {
            lang: 'ru-RU',
        },
        canActivate: [LanguageDetectionOnActivate],
    },
    ...routes,
    { path: '**', loadChildren: () => import('./static/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(entryPoints, {
            preloadingStrategy: NoPreloading,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
    providers: [
        LanguageDetectionOnActivate,
        DevModeGuard,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    ],
})
export class AppRoutingModule {}
