import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Request } from '@api/generated-types';
import { Dialog } from '@core/providers/modal/modal-types';
import { SwiperOptions } from 'swiper';

@Component({
    selector: 'fainin-request-received-dialog',
    templateUrl: './request-received-dialog.component.html',
    styleUrls: ['./request-received-dialog.component.scss'],
})
export class RequestReceivedDialogComponent implements Dialog<void> {
    resolveWith: (result?: void | undefined | string) => void;
    requests: Request[];
    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: { clickable: true },
    };

    constructor(private router: Router) {}

    goToRequest(request: Request): void {
        this.resolveWith('gotoRequestClicked');
        this.router.navigate(['account/requests', request.id], { state: { request } });
    }
}
