export default {
    button: {
        label: 'К статье',
    },
    alt: {
        imageBlog: 'Изображение предварительного просмотра для статьи {{slug}}',
        imageCampaign: 'Изображение предварительного просмотра для кампании {{title}}',
        fallbackImageBlog: 'Вспомогательное изображение для статьи {{slug}}',
        fallbackImageCampaign: 'Вспомогательное изображение для кампании {{title}}',
    },
};
