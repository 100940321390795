export default {
    faininAcronym: 'Find Anything In Your Neighborhood',
    subscribe: 'subscribe',
    links: 'Links',
    readMore: 'Read more',
    settings: 'Settings',
    newsletter: {
        consent:
            'Yes, to regular mails from us with info, tips, actions and exciting stories about fainin and the sharing community.',
        getReady: 'Get ready for fainin 2.0',
        subscribe: 'Subscribe to our Newsletter!',
        subscribe2: 'Subscribe to our mailing list for exclusive news and offers!',
        subscribe3: 'Get news from the fainin-Family',
        subscribe4: 'Get our news',
        disclaimer: 'With your registration you confirm to our',
        placeholder: 'Your email',
        alreadySubscribed: 'You are already subscribed',
        success: {
            title: 'Yay! Registration successful',
            text: 'Welcome to the fainin Family!',
        },
        invalid: 'Please enter a valid email Address!',
    },
    error: 'Error',
    someThingWentWrong: 'Sorry, something went wrong! Can you try again?',
    hashtag: '#SharingIsTheNewOwning',
    language: 'Language',
    or: 'or',
    betaInfo: 'A version released for testing purposes. A loss of data during this phase may appear.',
    article: 'Article',
    articles: 'Articles',
    loading: 'Loading',
    pleaseWait: 'Please wait',
    voucher: 'Voucher',
    apply: 'Apply',
};
