<div class="empty-container">
    <div class="card" [routerLink]="routerLink">
        <h5>
            <ng-content select="[header]"></ng-content>
        </h5>
        <div class="card-footer">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>
