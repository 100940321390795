import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'fainin-pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss'],
    standalone: true,
    imports: [CommonModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PillComponent,
            multi: true,
        },
    ],
})
export class PillComponent implements ControlValueAccessor {
    @Input() selected: boolean = false;
    @Input() key: string | number | undefined;
    @Output() choose: EventEmitter<string | number | undefined> = new EventEmitter<string | number | undefined>();

    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    select(): void {
        this.selected = !this.selected;
        this.choose.emit(this.key);
        this.onChange(this.key);
        this.onTouched();
    }

    writeValue(value: any): void {
        this.selected = value === this.key;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
