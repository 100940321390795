export default {
    noCommunity: {
        title: 'Відсутність доступу до спільноти',
        msg: 'Ви не є членом цієї спільноти. Будь ласка, приєднайтеся до спільноти, до якої належить цей продукт, щоб його орендувати!',
        okay: 'Добре',
    },
    leavingPage: 'Ви збираєтеся покинути сторінку...',
    loginToChat: 'You need to be signed in to chat with other users',
    hour: 'Година',
    shippingAddress: 'Shipping Address',
    shippingReturnAddress: 'Return Address',
    shippingCost: 'Shipping Cost',
    failedClipboard: 'Не вдалося скопіювати посилання в буфер обміну!',
    linkCopiedToClipboard: 'Посилання скопійовано в буфер обміну!',
    invalidPeriod: 'Недійсний період',
    requestAlreadyExists:
        'Запит вже існує. Запит на цей товар очікує на розгляд. Будь ласка, скасуйте його, щоб подати запит ще раз.',
    itemUnavailableForPeriod: 'Товар відсутній на запитуваний період!',
    pleaseSignIn: 'Будь ласка, авторизуйтесь для запиту!',
    lessExpensiveDailyPrice: 'Добова ціна дешевша і застосовується',
    incl: 'В т.ч.',
    inTotal: 'Всього',
    insuranceCoverage: 'Страхове покриття до €15,000',
    returnTime: 'Час повернення*',
    returnTimeOptional: "Час повернення (необов'язково)",
    pickupTime: 'Час забирання*',
    pickupTimeOptional: 'Час забирання (за бажанням)',
    overview: 'Огляд',
    availability: 'Доступність',
    exactPrice: 'Введіть період оренди, щоб побачити точну ціну',
    reviews: 'перегляди',
    rentalPrice: 'ціна оренди',
    rentalPeriod: 'термін оренди',
    rentNow: 'Оренда зараз',
    days: 'дні',
    serviceFee: 'Плата за послуги',
    totalAmount: 'Загальна кількість',
    similarProducts: 'Подібні продукти',
    youMightAlsoLike: 'Вам також можуть сподобатися ці продукти',
    checkAvailibility: 'Перевірити наявність',
    inquireNow: 'Запитуйте зараз',
    day: 'День',
    week: 'Неділя',
    month: 'Місяць',
    description: 'Опис',
    owner: 'Власник',
    favorite: 'Улюбленець',
    shipping: {
        notSignedIn: 'Ви повинні увійти в систему, щоб вибрати адресу.',
        chooseAddress: 'Виберіть свою адресу',
        newAddress: 'Нова адреса',
    },
    noInsuranceDisclaimer: 'Цей продукт не підлягає страхуванню.',
};
