import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import meta from '@core/localization/de/meta';
import { HasMetadata } from '@core/seo/site-meta.types';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';

@Component({
    selector: 'fainin-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, UpperCasePipe],
})
export class PrivacyComponent implements HasMetadata {
    pageMetadata = of({
        lang: 'de-DE',
        title: meta.privacy.title,
        description: meta.privacy.description,
    });
}
