import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

const FormsBarrel = [
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzButtonModule,
    NzCheckboxModule,
];

export default FormsBarrel;
