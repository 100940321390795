import { NgModule } from '@angular/core';
import { BackButtonDirective } from '@shared/directive/back-button.directive';
import { FocusNextDirective } from '@shared/directive/focus-next.directive';
import { FallbackImageDirective } from './fallback-image.directive';
import { IfFeatureDirective } from './if-feature.directive';

@NgModule({
    declarations: [IfFeatureDirective, FocusNextDirective, BackButtonDirective, FallbackImageDirective],
    exports: [IfFeatureDirective, FocusNextDirective, BackButtonDirective, FallbackImageDirective],
})
export class SharedDirectiveModule {}
