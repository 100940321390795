export default {
    updateAddress: 'Оновити адресу',
    downloadAppNow: 'Завантажте додаток зараз.',
    downloadAppNowNote: 'З додатком використовувати fainin стає ще простіше.',
    uploadNewProduct: 'Завантажте новий продукт',
    verifyFree: 'Підтвердьте особу безкоштовно',
    nothingGoingOnHere: 'Тут нічого не відбувається?',
    uploadSomething: 'Завантажте предмет!',
    toProducts: 'До продуктів',
    noNewMsgs: 'У вас немає нових повідомлень',
    noFavsYet: 'У вас ще немає улюблених',
    search: 'Пошук',
    reachNewMS: 'Досягайте віх і отримуйте чудові результати',
    badgesAndAwards: 'Значки та нагороди',
    stats: {
        title: 'Прогрес',
        totalCount: 'Разом з fainin ви виконали <b>{{totalCount}} замовлень</b> до цього часу.',
        totalRevenue: 'Усього ви заробили <b>{{totalRevenue}} доходу</b>.',
        emissionSaved: 'У середньому, вдалося заощадити <b>{{emissionSaved}} кг CO2</b>!',
        totalCountLabel: 'Замовлення',
        totalRevenueLabel: 'Дохід',
        emissionSavedLabel: 'Збереження CO2 (Середнє)',
    },
};
