import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DebounceClickDirective } from '@shared/directive/debounce-click.directive';
import { ShadowDirective } from '@shared/directive/shadow.directive';
import { ButtonComponent } from './components/button/button.component';
import { PillComponent } from './components/pill/pill.component';
import { PillsComponent } from './components/pills/pills.component';

@NgModule({
    declarations: [ButtonComponent, ShadowDirective],
    imports: [
        RouterModule,
        DebounceClickDirective,
        PillComponent,
        PillsComponent,
        NgIf,
        AsyncPipe,
        NgTemplateOutlet,
        NgClass,
    ],
    exports: [ButtonComponent, ShadowDirective, PillComponent, PillsComponent],
})
export class ButtonSharedModule {}
