export default {
    updateAddress: 'Update Address',
    downloadAppNow: 'Download app now.',
    downloadAppNowNote: 'With the app using fainin becomes even easier.',
    uploadNewProduct: 'Upload New Product',
    verifyFree: 'Verify Identity for Free',
    nothingGoingOnHere: 'Nothing going on here?',
    uploadSomething: 'Upload an item!',
    toProducts: 'To Products',
    noNewMsgs: 'You have no new messages',
    noFavsYet: "You don't have any favorites yet",
    search: 'Search',
    reachNewMS: 'Reach milestones and receive great',
    badgesAndAwards: 'Badges & Rewards',
    stats: {
        title: 'Progress',
        totalCount: 'Together with fainin, you have completed <b>{{totalCount}} orders</b> so far.',
        totalRevenue: 'Altogether, you have made <b>{{totalRevenue}} revenue</b>.',
        emissionSaved: 'On average, <b>{{emissionSaved}}kg CO2</b> have been saved!',
        totalCountLabel: 'Orders',
        totalRevenueLabel: 'Revenue',
        emissionSavedLabel: 'CO2 Savings (Avg)',
    },
};
