import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
    selector: '[faininIfFeature]',
})
export class IfFeatureDirective implements OnInit {
    @Input('faininIfFeature') featureName: 'account' | 'blog' | 'product' | 'collection' | 'groups' | 'gamification';

    constructor(private el: ElementRef) {}

    ngOnInit() {
        const { featureFlags } = environment;
        if (this.featureName in featureFlags && (featureFlags as any)[this.featureName] === false) {
            this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
        }
    }
}
