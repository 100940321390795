/**
 * Generates uuid to send an event
 */
export const uuid = (): string => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}`;
};

export type WebViewEventHandler<
    RequestPayload extends IRequestPayload = any,
    ResponsePayload extends IResponsePayload = any,
> = (
    data: IWebViewEvent<RequestPayload, ResponsePayload>,
) =>
    | AWebViewEvent<RequestPayload, ResponsePayload>
    | undefined
    | Promise<AWebViewEvent<RequestPayload, ResponsePayload> | undefined>;

export interface IResponsePayload {
    __type: 'Response';
}

export interface IRequestPayload {
    __type: 'Request';
}

export interface IWebViewEvent<RequestPayload extends IRequestPayload, ResponsePayload extends IResponsePayload> {
    readonly id: string;
    readonly name: string;
    payload?: ResponsePayload | RequestPayload;
    handled: boolean;
}

export abstract class AWebViewEvent<RequestPayload extends IRequestPayload, ResponsePayload extends IResponsePayload>
    implements IWebViewEvent<RequestPayload, ResponsePayload>
{
    readonly id: string;
    readonly name: string;
    handled: boolean;
    payload: any;

    protected constructor(name: string) {
        this.name = name;
        this.id = uuid();
        this.handled = false;
        this.payload = undefined;
    }

    /**
     * This function shall be used to prepare a request
     * @param payload
     */
    init(payload?: Omit<RequestPayload, '__type'>): AWebViewEvent<RequestPayload, ResponsePayload> {
        this.payload = payload ? { ...payload, __type: 'Request' } : undefined;
        this.handled = false;
        return this;
    }

    /**
     * This function shall be used to prepare a response
     * and set `handled` to true
     * @param payload
     */
    handle(payload?: Omit<ResponsePayload, '__type'>): AWebViewEvent<RequestPayload, ResponsePayload> {
        this.payload = payload ? { ...payload, __type: 'Response' } : undefined;
        this.handled = true;
        return this;
    }

    stringify(): string {
        return JSON.stringify(this);
    }

    fromJSON(
        json: IWebViewEvent<RequestPayload, ResponsePayload> | any,
    ): AWebViewEvent<RequestPayload, ResponsePayload> {
        Object.assign(this, json);
        return this;
    }
}
