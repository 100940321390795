<ng-container *ngIf="routerLink; else buttonImpl">
    <a
        [class]="classes"
        role="button"
        [attr.aria-label]="label"
        [class.disabled]="disabled"
        [routerLink]="routerLink"
        [queryParams]="queryParams"
        [fragment]="fragment"
        [routerLinkActive]="routerLinkActive ?? ''"
        [routerLinkActiveOptions]="routerLinkActiveOptions ?? {}"
        [target]="target"
        faininDebounceClick
        [debounceTime]="debounceTime"
        (debounceClick)="onDebounceClicked()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-container>

<ng-template #buttonImpl>
    <button
        [class]="classes"
        role="button"
        [type]="type"
        [attr.aria-label]="label"
        [disabled]="disabled"
        faininDebounceClick
        [debounceTime]="debounceTime"
        (debounceClick)="onDebounceClicked()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
</ng-template>

<ng-template #content>
    <ng-container *ngIf="loading">
        <span class="loading-indicator spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    </ng-container>
    <ng-content></ng-content>
</ng-template>
