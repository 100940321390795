import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
    selector: 'fainin-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() label: string = 'button';
    @Input() buttonClass: string = '';
    @Input() disabled: boolean = false;

    @Input() loading?: boolean | null = false;
    @Input() type: 'submit' | 'reset' | 'button' | 'menu' = 'button';

    @Input() debounceTime: number = 200;
    @Output() debounceClick = new EventEmitter();

    @Input() routerLink?: string | any[];
    @Input() target?: '_blank' | '_self' | '_parent' | '_top' | string = '_blank';
    @Input() fragment?: string;
    @Input() queryParams?: { [k: string]: any };
    @Input() routerLinkActive?: string | string[];
    @Input() routerLinkActiveOptions?: { exact: boolean };

    @HostBinding('class')
    @Input()
    size: 'small' | 'medium' | 'large' | 'block' | undefined = undefined;
    constructor() {}

    get classes() {
        return `fainin-button btn ${this.buttonClass ?? ''} ${this.size ?? ''}`;
    }

    onDebounceClicked() {
        this.debounceClick.emit();
    }
}
