export default {
    uploadProduct: 'Upload Product',
    contact: 'Contact',
    search: 'Find offers',
    faq: 'FAQ',
    blog: 'Blog',
    home: 'Start',
    rent: 'Bookings',
    lend: 'Lend',
    about: 'About Us',
    newsletter: 'Newsletter',
    press: 'Press',
    career: 'Career',
    imprint: 'Imprint',
    agb: 'AGB',
    terms: 'Terms and Conditions',
    privacy: 'Privacy Policy',
    onboarding: 'Onboarding',
    account: 'Account',
    orders: 'Orders',
    requests: 'Requests',
    products: 'Products',
    address: 'Account Addresses',
    accountDetails: 'Account Details',
    accountChange: 'Account Changes',
    help: 'Help',
    signIn: 'Sign-In',
    register: 'Register',
    verify: 'Verify',
    resetPassword: 'Reset Password',
    forgottenPassword: 'Forgotten Password',
    changeEmailAddress: 'Change Email Address',
    productUpload: 'Product Upload',
    myProfile: 'My Profile',
    editProfile: 'Edit Profile',
    favorites: 'Favorites',
    WelcomeToFainin: 'Welcome to fainin',
    footer: {
        offers: 'Offers',
        company: 'fainin',
        about: 'About Us',
        legal: 'Categories',
        starText: '4,7 on 155 reviews',
        sepa: 'SEPA direct debit',
    },
    communities: 'Communities',
    chats: 'Chats',
    chat: 'Chat',
    request: 'Request',
    verification: 'Verification',
    registerStep1: 'Registration Step 1',
    registerStep2: 'Registration Step 2',
    registerStep3: 'Registration Step 3',
    registerStep4: 'Registration Step 4',
    migrationOptOut: 'Migration Opt-Out',
    migrationOptIn: 'Migration Opt-In',
};
