export default {
    homeIcon: 'Fainin Home Icon',
    searchIcon: 'Fainin Search Icon',
    lendIcon: 'Fainin Lend Icon',
    uploadIcon: 'Fainin Upload Product Icon',
    logoutIcon: 'Logout Icon',
    myProfileIcon: 'My Profile Icon',
    chatIcon: 'Chats Icon',
    uploadProductIcon: 'Upload Product Icon',
    rentalsIcon: 'Rentals Icon',
    favoritesIcon: 'Favorites Icon',
    discoverIcon: 'Discover Icon',
    dashboardIcon: 'Dashboard Icon',
    uploadImageAfterRental: 'Upload image after rental',
    uploadImageBeforeRentalPlaceholder: 'Upload image before rental placeholder',
    renterThumbnail: 'Renter thumbnail',
    fallbackRenterThumbnail: 'Fallback renter thumbnail',
    stepPending: 'Stop Pending',
    stepAction: 'Step Action',
    productImageAfterRental: 'Product Image After rental',
    productImageBeforeRental: 'Product image before rental',
    paypalIcon: 'Paypal icon',
    visaIcon: 'Visa Icon',
    masterCardIcon: 'Master card icon',
    return: 'Return',
    borrowWhatYouNeed: 'Borrow what you need!',
    privateOrCompany: 'Are you here privately or as a company',
    private: 'Private',
    businessAccount: 'Business Account',
    whatPassword: "What's your Email / password",
    insuranceIllustration: 'insurance illustration',
    faininTeam: 'fainin Team',
    faininAppMockup: 'fainin Application mockup',
    appleAppstoreIcon: 'Apple Appstore icon',
    googlePlaystoreIcon: 'Google Playstore icon',
    flyingDroneIcon: 'Flying drone icon',
    cookieSettingsButton: 'Cookie settings button',
    johann: 'fainin Johann',
    max: 'fainin Johann',
    kev: 'fainin Johann',
    govtLogo: 'German Federal Foundation for the Environment logo',
    companyLogo: 'OVH Company Logo',
    ergoLogo: 'Ergo logo - to assure means to understand',
    assetGallery: {
        productGalleryImage: 'Product gallery image',
        productGalleryFallbackImage: 'Product gallery fallback image',
        viewAll: 'View All',
        faininProductLocationDot: "fainin's product Location Dot",
    },
    accountMenu: {
        chatButtonIcon: 'Chat button icon',
        accountMenuIconSignedIn: 'Account menu icon - signed in',
        accountMenuIconUnauthenticated: 'Account menu icon - unauthenticated',
        burgerMenuIcon: 'Burger menu icon',
        closeDrawerIcon: 'Close drawer icon',
        signupButton: 'Signup button',
        desktopLoginButton: 'Desktop login button',
    },
    cartToggle: {
        cartToggle: 'Cart Toggle',
    },
    collectionBreadCrumbs: {
        breadcrumb: 'breadcrumb',
    },
    layoutLogo: {
        faininStorefrontLogo: 'fainin Storefront Logo',
    },
    layoutSearchButton: {
        searchSheetClose: 'search sheet close button',
    },
    mobileMenuToggle: 'Mobile menu toggle',
    myProductCard: {
        fallbackProductPreview: 'Fallback product preview image',
    },
    pageHeader: {
        back: 'Back',
    },
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    userComponent: {
        ownerThumbnail: "Product owner's thumbnail",
        fallbackOwnerthumbnail: "Fallback product owner's thumbnail",
    },
    dashboard: {
        downloadAndroid: 'Download Android',
        downloadApple: 'Download Apple',
    },
    order: {
        productPreviewImage: 'Preview image of booked product',
    },
    button: {
        close: 'Close button',
        clear: 'Clear button',
    },
};
