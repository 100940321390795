import { gql } from 'apollo-angular';

export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        width
        height
        name
        preview
        source
        mimeType
        focalPoint {
            x
            y
        }
    }
`;

export const SEARCH_RESULT_FRAGMENT = gql`
    fragment SearchResult on SearchResult {
        productId
        slug
        productName
        description
        productAsset {
            id
            preview
            focalPoint {
                x
                y
            }
        }
        location {
            zip
            cityName
            districtName
            latitude
            longitude
        }
        priceWithTax {
            ... on SinglePrice {
                value
            }
            ... on PriceRange {
                min
                max
            }
        }
        currencyCode
        customMappings {
            category
            subCategory
            reviewCount
            reviewRating
            availableChannelName
            availableChannelIds
            ownerBusinessName
            ownerProfileName
            shippingFee
            templateSlug
        }
        geoDistance {
            field
            distanceInMeters
        }
    }
`;

export const SEARCH_RESPONSE_FRAGMENT = gql`
    fragment SearchResponse on SearchResponse {
        queryId
        cacheIdentifier {
            collectionSlug
        }
        items {
            ...SearchResult
        }
        totalItems
        facetValues {
            ...FacetValueResult
        }
        prices {
            range {
                min
                max
            }
        }
    }

    fragment FacetValueResult on FacetValueResult {
        count
        facetValue {
            id
            name
            code
            facet {
                id
                name
                code
            }
        }
    }
    ${SEARCH_RESULT_FRAGMENT}
`;

export const COMMUNITY_DETAIL_FRAGMENT = gql`
    fragment CommunityDetail on Group {
        id
        name
        slug
        description
        visibility
        members
        featuredAsset {
            ...Asset
        }
        commission
        isMember
        createdAt
        channel {
            id
            code
            token
            customFields {
                name
            }
        }
        tags
        location {
            id
            city
            zip
            latitude
            longitude
        }
        requirementFilters {
            code
            args {
                name
                value
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const COMMUNITY_LIST_FRAGMENT = gql`
    fragment CommunityList on Group {
        id
        name
        slug
        description
        visibility
        createdAt
        isMember
        members
        featuredAsset {
            ...Asset
        }
        requirementFilters {
            code
        }
        tags
    }
    ${ASSET_FRAGMENT}
`;

export const SUBCATEGORY_FRAGMENT = gql`
    fragment SubCategory on FacetValue {
        id
        code
        name
        customFields {
            priority
        }
    }
`;

export const CATEGORY_FRAGMENT = gql`
    fragment Category on FacetValue {
        id
        name
        code
        customFields {
            priority
            childFacetValue {
                ...SubCategory
            }
        }
    }
    ${SUBCATEGORY_FRAGMENT}
`;

export const UPLOAD_LOCATION_FRAGMENT = gql`
    fragment UploadLocation on UploadLocation {
        id
        createdAt
        updatedAt
        address
        zip
        cityName
        countryName
        districtName
        contactName
        contactNumber
        latitude
        longitude
        isDefault
    }
`;
export const CONVERSATION_FRAGMENT = gql`
    fragment Conversation on Conversation {
        id
        type
        createdAt
        updatedAt
        lastActivityAt
        unreadMessages
        members {
            id
            name
            description
            featuredAsset {
                ...Asset
            }
            userRelation
        }
        messages {
            _id
            text
            createdAt
            received
            image
            audio
            video
            user {
                _id
                name
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const CART_FRAGMENT = gql`
    fragment Cart on Order {
        id
        createdAt
        code
        state
        active
        lines {
            id
            featuredAsset {
                ...Asset
            }
            unitPrice
            unitPriceWithTax
            quantity
            linePrice
            linePriceWithTax
            discountedLinePriceWithTax
            productVariant {
                id
                name
            }
            discounts {
                amount
                amountWithTax
                description
                adjustmentSource
                type
            }
        }
        totalQuantity
        subTotal
        subTotalWithTax
        total
        totalWithTax
        shipping
        shippingWithTax
        shippingLines {
            priceWithTax
            shippingMethod {
                id
                code
                name
                description
            }
        }
        discounts {
            amount
            amountWithTax
            description
            adjustmentSource
            type
        }
    }
    ${ASSET_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
    fragment Country on Country {
        id
        code
        name
        enabled
    }
`;

export const ORDER_ADDRESS_FRAGMENT = gql`
    fragment OrderAddress on OrderAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        phoneNumber
    }
`;

export const ORDER_DETAIL_FRAGMENT = gql`
    fragment OrderDetail on Order {
        id
        createdAt
        state
        discounts {
            description
            amount
        }
        lines {
            id
            featuredAsset {
                ...Asset
            }

            quantity
            linePrice
            productVariant {
                id
                sku
                name
                price
                assets {
                    ...Asset
                }
                product {
                    slug
                    location {
                        address
                    }
                }
            }
        }
        totalQuantity
        subTotal
        subTotalWithTax
        total
        totalWithTax
        serviceFee
        commission
        productTotal
        shipping
        surcharges {
            id
            sku
            priceWithTax
            description
        }
        unread
        customFields {
            requestId
            duration {
                startDate
                endDate
                isHourly
            }
            beforeImage {
                ...Asset
            }
            afterImage {
                ...Asset
            }
            beforeImageComment
            afterImageComment
            ownerProfile {
                id
                name
                featuredAsset {
                    ...Asset
                }
                statistics {
                    id
                    renting
                    lending
                    averageRating
                    feedbackCount
                }
            }
            renterProfile {
                id
                name
                featuredAsset {
                    ...Asset
                }
                statistics {
                    id
                    renting
                    lending
                    averageRating
                    feedbackCount
                }
            }
        }
        shippingAddress {
            ...OrderAddress
        }
        billingAddress {
            ...OrderAddress
        }
        history(options: { filter: { type: { eq: "ORDER_STATE_TRANSITION" } } }) {
            items {
                id
                createdAt
                updatedAt
                type
                data
            }
        }
        userRelation
    }
    ${ASSET_FRAGMENT}
    ${ORDER_ADDRESS_FRAGMENT}
`;

export const ADDRESS_FRAGMENT = gql`
    fragment Address on Address {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
            id
            code
            name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
    }
`;

export const ERROR_RESULT_FRAGMENT = gql`
    fragment ErrorResult on ErrorResult {
        errorCode
        message
    }
`;

export const REQUEST_FRAGMENT = gql`
    fragment Request on Request {
        id
        createdAt
        renterProfile {
            id
            name
            type
            featuredAsset {
                ...Asset
            }
            statistics {
                id
                averageRating
            }
        }
        ownerProfile {
            id
            name
            type
            featuredAsset {
                ...Asset
            }
            statistics {
                id
                averageRating
            }
        }
        dayDiscount
        productVariant {
            product {
                id
                name
                slug
                featuredAsset {
                    ...Asset
                }
                location {
                    zip
                    cityName
                    districtName
                    address
                }
                assets {
                    ...Asset
                }
            }
            price
        }
        startDate
        endDate
        deliveryMethod {
            name
        }
        status
        days
        serviceFee
        subTotal
        total
        userRelation
        conversationId
        isHourly
        shipping
        shippingAddress {
            fullName
            streetLine1
            postalCode
            city
        }
    }
    ${ASSET_FRAGMENT}
`;

export const CHANNEL_FRAGMENT = gql`
    fragment Channel on Channel {
        id
        code
        token
        customFields {
            name
        }
    }
`;

export const USER_DEVICE_FRAGMENT = gql`
    fragment UserDevice on UserDevice {
        id
        createdAt
        updatedAt
        modelName
        platform
        deviceName
    }
`;

export const CURRENT_USER_FRAGMENT = gql`
    fragment CurrentUser on CurrentUser {
        id
        authStrategy
        identifier
        authStrategy
        channels {
            id
            code
            token
            permissions
        }
        profileId
    }
`;

export const ACTIVE_CUSTOMER_FRAGMENT = gql`
    fragment ActiveCustomer on Customer {
        id
        firstName
        lastName
        emailAddress
        phoneNumber
        title
        addresses {
            id
            fullName
            city
            country {
                name
                code
            }
            streetLine1
            streetLine2
            postalCode
            province
            defaultShippingAddress
        }
        commissionPercentage
        customFields {
            birthday
            allowNewsletter
            gender
            language
        }
        user {
            id
            customFields {
                identified
                appTutorialSeen
                webTutorialSeen
                stripeAccount
                paypalEmail
            }
        }
    }
`;

export const ACTIVE_PROFILE_FRAGMENT = gql`
    fragment ActiveProfile on Profile {
        id
        createdAt
        updatedAt
        name
        type
        description
        verificationStatus
        business {
            identifier
            businessName
            businessType
            vat
            registrationNumber
        }
        featuredAsset {
            ...Asset
        }
        statistics {
            id
            averageRating
            feedbackCount
            lending
            renting
        }
        links {
            id
            link
            platform
        }
        userRelation
    }
    ${ASSET_FRAGMENT}
`;

export const FEEDBACK_FRAGMENT = gql`
    fragment Feedback on CustomerFeedback {
        id
        createdAt
        updatedAt
        author {
            id
            type
            name
            verificationStatus
            featuredAsset {
                ...Asset
            }
        }
        headline
        description
        feedbackRating
        recipient {
            id
        }
    }
`;

export const PROFILE_FRAGMENT = gql`
    fragment Profile on Profile {
        id
        createdAt
        name
        type
        description
        verificationStatus
        business {
            identifier
            businessName
            businessType
            vat
            registrationNumber
        }
        featuredAsset {
            ...Asset
        }
        links {
            id
            link
            platform
        }
        statistics {
            id
            averageRating
            feedbackCount
            lending
            renting
        }
    }
    ${ASSET_FRAGMENT}
`;

export const PRODUCT_CARD_FRAGMENT = gql`
    fragment ProductCard on Product {
        id
        createdAt
        name
        slug
        description
        location {
            zip
            districtName
            cityName
        }
        featuredAsset {
            ...Asset
        }
        channels {
            ...Channel
        }
        variants {
            id
            name
            price
        }
        customFields {
            reviewCount
            reviewRating
        }
    }
    ${ASSET_FRAGMENT}
    ${CHANNEL_FRAGMENT}
`;

export const PRODUCT_TEMPLATE_FAQ_FRAGMENT = gql`
    fragment ProductTemplateFaq on ProductTemplateFaq {
        id
        question
        answer
        order
    }
`;

export const PRODUCT_TEMPLATE_SPEC_FRAGMENT = gql`
    fragment ProductTemplateSpec on ProductTemplateSpecs {
        id
        heading
        description
        order
    }
`;

export const PRODUCT_TEMPLATE_SEO_FRAGMENT = gql`
    fragment ProductTemplateSEO on SEO {
        id
        createdAt
        updatedAt
        title
        description
        keywords
        canonicalUrl
        noIndex
        noFollow
        openGraph {
            id
            title
            description
            image
            url
            type
            locale
            siteName
        }
        twitterCard {
            id
            card
            title
            description
            image
            site
            creator
        }
    }
`;

export const PRODUCT_TEMPLATE_CARD_FRAGMENT = gql`
    fragment ProductTemplateCard on ProductTemplate {
        id
        name
        slug
        category {
            id
            code
            name
        }
        subCategory {
            id
            code
            name
        }
        featuredAsset {
            ...Asset
        }
        updatedAt
    }
    ${ASSET_FRAGMENT}
`;

export const PRODUCT_TEMPLATE_FRAGMENT = gql`
    fragment ProductTemplate on ProductTemplate {
        id
        slug
        name
        description
        priceRecommendation
        seo {
            ...ProductTemplateSEO
        }
        category {
            id
            name
            code
        }
        subCategory {
            id
            name
            code
        }
        products {
            items {
                name
                featuredAsset {
                    ...Asset
                }
                slug
                customFields {
                    reviewCount
                }
            }
        }
        faqs {
            ...ProductTemplateFaq
        }
        specs {
            ...ProductTemplateSpec
        }
        featuredAsset {
            ...Asset
        }
        assets {
            ...Asset
        }
    }
    ${ASSET_FRAGMENT}
    ${PRODUCT_TEMPLATE_FAQ_FRAGMENT}
    ${PRODUCT_TEMPLATE_SPEC_FRAGMENT}
    ${PRODUCT_TEMPLATE_SEO_FRAGMENT}
`;
