import { PermissionResponse } from '../permission';
import { AWebViewEvent, IRequestPayload, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const TRACKING_CONSENT_EVENT_NAME = 'TrackingConsent';

export interface ITrackingConsentRequest extends IRequestPayload {
    askAgain?: boolean;
}

export interface ITrackingConsentResponse extends IResponsePayload {
    trackingStatus: null | PermissionResponse;
}

export type ITrackingConsentEvent = IWebViewEvent<any, ITrackingConsentResponse>;

/**
 * Event for requesting permission to track the user or their device. Examples of data used for tracking include
 * email address, device ID, advertising ID, etc... This permission is only necessary on iOS 14 and higher;
 * on iOS 13 and below this permission is always granted.
 * @see https://docs.expo.dev/versions/latest/sdk/tracking-transparency/
 */
export class TrackingConsentEvent
    extends AWebViewEvent<ITrackingConsentRequest, ITrackingConsentResponse>
    implements ITrackingConsentEvent
{
    constructor() {
        super(TRACKING_CONSENT_EVENT_NAME);
    }
}

export default [TRACKING_CONSENT_EVENT_NAME, TrackingConsentEvent];
