import { gql } from 'apollo-angular';

import {
    ACTIVE_CUSTOMER_FRAGMENT,
    ADDRESS_FRAGMENT,
    ASSET_FRAGMENT,
    COUNTRY_FRAGMENT,
    CURRENT_USER_FRAGMENT,
    USER_DEVICE_FRAGMENT,
} from './fragments.graphql';

export const TALKJS_SIGNATURE = gql`
    query TalkJsSignature {
        talkSignature
    }
`;

export const FINISH_TUTORIAL = gql`
    mutation FinishTutorial($platform: Platform!) {
        finishTutorial(platform: $platform) {
            success
        }
    }
`;

export const GET_CUSTOMER_ADDRESSES = gql`
    query GetCustomerAddresses {
        activeCustomer {
            id
            addresses {
                ...Address
            }
        }
    }
    ${ADDRESS_FRAGMENT}
`;

export const GET_AVAILABLE_COUNTRIES = gql`
    query GetAvailableCountries {
        availableCountries {
            ...Country
        }
    }
    ${COUNTRY_FRAGMENT}
`;

export const GET_ACTIVE_CUSTOMER = gql`
    query GetActiveCustomer {
        activeCustomer {
            ...ActiveCustomer
        }
    }
    ${ACTIVE_CUSTOMER_FRAGMENT}
`;

export const GET_ACTIVE_USER = gql`
    query GetActiveUser {
        me {
            ...CurrentUser
        }
    }
    ${CURRENT_USER_FRAGMENT}
`;

export const GET_FAVORITES_IDS_LIST = gql`
    query GetFavoritesIdsList {
        favorites(options: { take: 100 }) {
            items {
                id
            }
        }
    }
`;

/**
 * TODO: This query is used in too many places. We should use one service and cache
 * the result, since it's quiet static data
 */
export const GET_COLLECTIONS = gql`
    query GetCollections($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                ...Collection
            }
        }
    }
    fragment Collection on Collection {
        id
        name
        slug
        languageCode
        parent {
            id
            name
            slug
        }
        featuredAsset {
            ...Asset
        }
    }
    ${ASSET_FRAGMENT}
`;

export const REGISTER_DEVICE_TOKEN = gql`
    mutation RegisterDevice($input: RegisterUserDeviceInput!) {
        registerUserDevice(input: $input) {
            ...UserDevice
        }
    }
    ${USER_DEVICE_FRAGMENT}
`;

export const GET_NOTIFICATIONS = gql`
    query GetNotifications {
        getNotifications {
            unreadOrderIds
            unreadRequests
        }
    }
`;

export const GET_UNREAD_MARKERS = gql`
    query GetUnreadMarkers {
        unreadMarkers {
            entityId
            entityName
        }
    }
`;
