import { SEARCH_RESPONSE_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const SEARCH_OFFERS = gql`
    query SearchOffers($input: SearchInput!) {
        search(input: $input) {
            ...SearchResponse
        }
    }
    ${SEARCH_RESPONSE_FRAGMENT}
`;

export const LOG_SEARCH_LIST_VIEWED = gql`
    mutation LogSearchListViewed($input: SearchListViewedEventInput!) {
        logSearchListViewed(input: $input)
    }
`;

export const LOG_SEARCH_RESULT_CLICKED = gql`
    mutation LogSearchResultClicked($input: SearchResultClickedEventInput!) {
        logSearchResultClicked(input: $input)
    }
`;
