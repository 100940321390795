import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const slideAnimation = trigger('fadeInOut', [
    state('true', style({ top: '{{top}}', visibility: 'visible' }), { params: { top: '5%' } }),
    state('false', style({ top: '100%', visibility: 'hidden' })),
    transition('false <=> true', animate(300)),
]);

@Component({
    selector: 'fainin-action-sheet',
    templateUrl: './action-sheet.component.html',
    styleUrls: ['./action-sheet.component.scss'],
    animations: [slideAnimation],
})
export class ActionSheetComponent implements OnDestroy {
    private _open: boolean = false;

    @Input()
    hasBackButton: boolean = false;

    @Input()
    disableDrag: boolean = false;

    @Input()
    title: string;

    @Input()
    topBoundary: number = 5;

    @Output()
    openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    feedbackPrevStep = new EventEmitter<any>();

    tabBarOpen$: Observable<boolean>;
    get upperBoundary() {
        return `${this.topBoundary}%`;
    }

    @HostBinding('style.--action-sheet-height')
    get height() {
        return `${100 - this.topBoundary}%`;
    }

    @Input()
    set open(value: boolean) {
        this._open = value;
        const htmlElement = this._document.getElementsByTagName('html')[0];
        if (value) {
            this.renderer.addClass(htmlElement, 'cdk-global-scrollblock');
        } else {
            this.renderer.removeClass(htmlElement, 'cdk-global-scrollblock');
        }
    }

    get open() {
        return this._open;
    }

    private _positionY = 0;
    @HostBinding('style.--action-sheet-positionY')
    get positionY(): string {
        return `${this._positionY}px`;
    }

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
        private router: Router,
        private state: StateService,
    ) {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                untilDestroyed(this),
            )
            .subscribe(() => this.doClose());
        this.tabBarOpen$ = this.state.select(state => !state.hideTabBar).pipe();
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }

    doClose() {
        this.openChange.emit(false);
    }

    onDragEnded(event: CdkDragEnd) {
        if (event.dropPoint.y >= 400) {
            this.doClose();
            // wait till animation end
            setTimeout(() => event.source.reset(), 300);
        }
    }

    onDragMove(event: CdkDragMove<any>) {
        this._positionY = event.source.getFreeDragPosition().y;
    }
}
