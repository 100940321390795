import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonSharedModule } from '@shared/button-shared.module';
import { PageModule } from '@shared/page.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { FeedbackWizardComponent } from 'src/app/account/modules/feedback-wizard/feedback-wizard.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { FeedbackSelectorComponent } from './feedback-selector/feedback-selector.component';
import { FeedbackStep1Component } from './feedback-step1/feedback-step1.component';
import { FeedbackStep2Component } from './feedback-step2/feedback-step2.component';
import { FeedbackStep3Component } from './feedback-step3/feedback-step3.component';
import { FeedbackStep4Component } from './feedback-step4/feedback-step4.component';

@NgModule({
    declarations: [
        FeedbackWizardComponent,
        FeedbackStep1Component,
        FeedbackStep2Component,
        FeedbackStep3Component,
        FeedbackSelectorComponent,
        FeedbackStep4Component,
        FeedbackModalComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NzStepsModule,
        FormsModule,
        PageModule,
        FontAwesomeModule,
        ButtonSharedModule,
        NzInputModule,
    ],
    exports: [FeedbackWizardComponent, FeedbackModalComponent],
})
export class FeedbackWizardModule {}
