import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AssetPreviewSharedModule } from '@shared/asset-preview-shared.module';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { SwiperModule } from 'swiper/angular';
import { AssetGalleryComponent } from './components/asset-gallery/asset-gallery.component';
import { AssetSwiperGalleryComponent } from './components/asset-swiper-gallery/asset-swiper-gallery.component';

const CORE_COMPONENTS = [AssetGalleryComponent, AssetSwiperGalleryComponent];

@NgModule({
    declarations: [...CORE_COMPONENTS],
    imports: [
        CommonModule,
        SwiperModule,
        AssetPreviewSharedModule,
        LazyLoadImageModule,
        NzImageModule,
        TranslateModule,
        NzSkeletonModule,
        NgOptimizedImage,
        SharedDirectiveModule,
    ],
    exports: [...CORE_COMPONENTS],
})
export class AssetGalleryCoreModule {}
