export default {
    cancel: 'Перервати',
    confirm: 'Підтвердити',
    proceed: 'Продовжити',
    acceptRequestTitle: 'Прийняти запит',
    acceptRequestMsg: 'Будь-ласка, підтвердьте прийняття цього запиту.',
    declineRequestTitle: 'Відхилити запит',
    declineRequestMsg: 'Будь-ласка, підтвердьте відхилення цього запиту.',
    cancelRequestTitle: 'Скасувати запит',
    cancelRequestMsg: 'Будь-ласка, підтвердьте скасування цього запиту.',
    requestAcceptedTitle: 'Запит прийнято',
    requestAcceptedMsg: 'Чудово! Ви прийняли запит. Наступний крок – дочекатися оплати орендарем.',
    requestDeclinedTitle: 'Запит відхилено',
    requestDeclinedMsg:
        'Ви відхилили запит. Якщо це помилка, ви можете попросити потенційного клієнта подати запит ще раз.',
    understand: 'Зрозуміло',
    startingRental: 'Розпочати оренду',
    uploadLeast: 'Вітаю! Ви майже на місці. Завантажте принаймні 1 фотографії об’єкта та підтвердьте оренду.',
    warning: 'Попередження',
    rentalDateHasNotReached:
        'Дата початку оренди ще не настала. Ми не можемо застрахувати будь-які збитки за межами періоду оренди. Ви все ще хочете почати оренду?',
    endingRental: 'Закінчити оренду',
    uploadLeastEnd: 'Завантажте принаймні 1 фотографії товару, щоб підтвердити повернення.',
    rentalEndDateHasNotReached: 'Дата закінчення позики ще не досягнута. Ви все ще хочете припинити позику?',
    refundTitle: 'Повернення грошей',
    refundMsg:
        'Якщо ви скасуєте це замовлення, буде надіслано запит на повернення коштів. Ви впевнені, що хочете скасувати та повернути це замовлення?',
    changesDiscardConfirm: 'Ви впевнені, що хочете піти? \nВаші зміни буде відхилено.',
    pwa: {
        title: 'Оновлення!',
        msg: 'fainin оновлено.',
        btnTxt: 'Перезавантажити',
    },
    deletingAccountTitle: 'Удаление аккаунта',
    deletingAccountMsg: 'Вы уверены, что хотите удалить свой аккаунт?',
    modalUpdateTierTitle: 'Хочете оновити цінові категорії?',
    modalUpdateTierMsg:
        'Ваші поточні налаштовані цінові категорії будуть перезаписані новим визначенням на основі вашого введення.',
    productUpload: {
        cacheRestore: {
            title: 'Existing draft found!',
            msg: 'Do you want to continue editing your product draft or start uploading a new one?',
            confirmLabel: 'Continue',
            cancelLabel: 'New',
        },
    },
    detectedContentLanguage: {
        title: 'Different Language available',
        msg: 'This content is also available in your current language: "{{language}}". Would you see this content in this language?',
    },
};
