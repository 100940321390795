import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

declare var gtag: Function;

const log = new Logger('GoogleAnalytics');

export interface GAEvent {
    /**
     * The value that will appear as the event action in Google Analytics Event reports.
     */
    eventName: string; // TODO: Define a list of possible event names
    /**
     * The category of the event.
     */
    eventCategory?: string;
    /**
     * The label of the event.
     */
    eventLabel?: string;
    /**
     * A non-negative integer that will appear as the event value.
     */
    eventValue?: number;
    /**
     * Any additional parameters
     */
    parameters?: { [key: string]: any };
}

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    private routerSubscription: Subscription;
    private renderer2: Renderer2;
    private readonly googleAnalyticsId: string;
    private scriptsLoaded: boolean = false;

    constructor(
        private router: Router,
        private rendererFactory2: RendererFactory2,
        private title: Title,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.googleAnalyticsId = environment.googleAnalyticsId;
    }

    /**
     * Injects the googletagmanager and the gtag function
     */
    public init() {
        if (this.googleAnalyticsId && !this.scriptsLoaded && isPlatformBrowser(this.platformId)) {
            this.doInjectGaScript();
        }
    }

    /**
     * Cancels the router subscription
     */
    public destroy() {
        if (this.routerSubscription) this.routerSubscription.unsubscribe();
    }

    /**
     * Set values that persist across all the subsequent gtag() calls
     * @param parameterName a name usable as object key
     * @param parameterValue the parameter value
     */
    public setParameter(parameterName: string, parameterValue: string | number) {
        if (this.googleAnalyticsId && this.scriptsLoaded) {
            const parameterKey = parameterName.replace(/\s+/g, '_');
            gtag('set', {
                [parameterKey]: parameterValue,
            });
        }
    }

    /**
     * @param event
     * @param interaction To send a non-interaction even set this to `false`
     */
    public trackEvent(event: GAEvent, interaction = true) {
        if (this.googleAnalyticsId && this.scriptsLoaded) {
            const { eventName, eventCategory, eventLabel, eventValue, parameters } = event;
            const gaEvent = {
                event_category: eventCategory,
                event_label: eventLabel,
                value: eventValue,
                ...parameters,
            };

            log.debug('Event', eventName, gaEvent);
            if (interaction) {
                gtag('event', eventName, gaEvent);
            } else {
                gtag('event', eventName, {
                    ...gaEvent,
                    non_interaction: true,
                });
            }
        }
    }

    private doInjectGaScript() {
        const script: HTMLScriptElement = this.renderer2.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = this.doInjectGaFunction.bind(this);
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
        script.text = '';
        script.id = 'gtag';
        script.setAttribute('crossorigin', 'anonymous');
        this.renderer2.appendChild(this._document.body, script);
    }

    private doInjectGaFunction() {
        log.debug('Injecting gtag function');
        const src = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${this.googleAnalyticsId}', { send_page_view: false } );
        `;

        const script: HTMLScriptElement = this.renderer2.createElement('script');
        script.type = 'text/javascript';
        script.text = src;
        script.id = 'gtag-init';
        this.renderer2.appendChild(this._document.body, script);
        this.onGoogleAnalyticsLoaded();
    }

    /**
     * This function is called once the googletagmanager and the gtag function
     * got initialized
     * @private
     */
    private onGoogleAnalyticsLoaded() {
        this.scriptsLoaded = true;
        log.info('Initialized');

        const urlWithoutParams = this.router.url.split('?')[0];
        log.debug('Event', 'page_view', urlWithoutParams);
        gtag('set', { page_location: `${environment.hostUrl}${urlWithoutParams}` });
        gtag('send', 'pageview');

        this.routerSubscription = this.router.events
            .pipe(
                filter<any>(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects.split('?')[0]),
                distinctUntilChanged((x, y) => x === y),
            )
            .subscribe(urlWithoutParams => {
                log.debug('Event', 'page_view', urlWithoutParams);
                gtag('set', { page_location: `${environment.hostUrl}${urlWithoutParams}` });
                gtag('send', 'pageview');
            });

        gtag('consent', 'analytics_storage', 'granted');
        // Disable automatic page view hit to fix duplicate page view count
        gtag('config', this.googleAnalyticsId, { send_page_view: false });
        // Set global currency to Euros
        gtag('set', { currency: 'EUR', webVersion: environment.version });
    }
}
