<div class="loading">
    <div class="d-flex justify-content-center">
        <div class="icon-containr" role="status">
            <img class="boomerang" src="/assets/icon/fainin/fainin_boomerang.svg" />
        </div>
        <div class="icon-containr" role="status">
            <img class="boomerang-white" src="/assets/icon/fainin/fainin_boomerang_white.svg" />
        </div>
    </div>
</div>
