export default {
    search: {
        title: 'Місцезнаходження не вказано',
        message: 'Будь ласка, додайте місце розташування, щоб сортувати товари за відстанню',
    },
    shipment: {
        title: 'Shipping not available',
        msg: 'The owner does not offer shipping for this product.',
        shippingMethod: 'Спосіб відвантаження*',
        pickup: 'Самовивіз',
        shipping: 'Судноплавство',
    },
    error: {
        title: `Помилка!`,
    },
    warning: {
        title: `Попередження`,
    },
    success: {
        title: 'Успіх!',
    },
    languageNotAvailable: {
        article: 'This article is not available in your language, instead the original article is displayed.',
    },
    updateCustomerPassword: {
        title: 'Успіх!',
        message: 'Ви отрималі електронний лист для підтвердження внесених змін.',
    },
    updateCustomerPasswordError: {
        title: 'Зміна паролю була невдала!',
        message: 'Будь-ласка переконайтесь що старий пароль був введений правильно.',
    },
    deleteProduct: {
        title: `Видалити продукт`,
        message: `Продукт був успішно видалений.`,
    },
    activeOrderError: {
        title: `Оренда триває!`,
        message: `Для цього продукта триває оренда.`,
    },
    requestSent: {
        title: `Запит відправлено`,
        message: `Ваш запит був успишно відправлено.\n\nБудь-ласка зачекайте на відповідь власника.`,
    },
    registrationSuccess: {
        title: 'Реєстрацію завершено',
        message: 'Будь-ласка підтвердіть реєстрацію через посилання в електронному листі.',
    },
    registrationError: {
        title: 'Реєстрація невдала',
        message: 'Не вдалося створити ваш обліковий запис.',
    },
    registrationCancel: {
        title: 'Скасувати реєстрацію',
        message: 'Ви дійсно хочете прерваті реєстрацію?',
    },
    languageChange: {
        title: 'Мову змінено',
        message: 'Налаштування мови було успішно відрегульовано!',
    },
    languageChangeError: {
        title: 'Мова не змінена',
    },
    bugReportSuccess: {
        title: 'Повідомлено',
        message: 'Звіт про повідомлення про помилку був успішно відправлено',
    },
    bugReportError: {
        title: 'Звіт про повідомлення про помилку не вдалося відіслати',
    },
    describeExperience: {
        label: 'Опис (Необовязково)',
        title: 'Яким був ваш досвід з {{recipient}}?',
        placeholder: 'Опишіть коротко ваш досвід...',
        rules: 'Опис є закоротким',
    },
    feedback: {
        title: 'Зворотній звязок',
        placeholder: 'Опишіть коротко ваш досвід ...',
        rules: 'Опис є закоротким',
        text: 'Як був ваш досвід?',
    },
    receiveItem: {
        title: 'Продукт був отриманий',
        firstMessage: 'Будь ласка, переконайтеся, що продукт не пошкоджений і відповідає опису.',
        secondMessage: 'Зробіть принаймні дві фотографії, щоб забезпечити свою безпеку.',
    },
    returnItem: {
        title: 'Товар повернуто',
        message: 'Будь ласка, переконайтеся, що товар не пошкоджений і відповідає опису.',
    },
    awaitEndRental: {
        title: 'Відправляти запит?',
        message: 'Ви впевнені, що хочете надіслати запит на припинення оренди?',
    },
    endRental: {
        title: 'Попередження!',
        message: 'Бажаєте завершити бронювання?',
    },
    awaitRefund: {
        title: 'Попередження!',
        message: 'Хочете надіслати запит на повернення коштів?',
    },
    cancelRental: {
        title: 'Попередження',
        message: 'Бажаєте скасувати бронювання?',
    },
    rentalEnded: {
        title: 'Бронювання закінчено',
        message: 'Бронювання вашого товару успішно завершено!',
    },
    refundAwaiting: {
        title: 'Запит було відправлено',
        message: 'Ваш запит на повернення коштів було надісланно!',
    },
    favoriteRemoveSuccess: {
        title: 'Новий фаворит',
        Message: 'Продукт було видалено з фаворитів.',
        removed: 'Видалити продукт з фаворитів',
    },
    favoriteRemoveError: {
        message: 'Не вдалося видалити продукт з фаворитів',
    },
    awaitEndRentalError: {
        message: 'Не вдалося надіслати запит на припинення оренди.',
    },
    rentalEndedError: {
        message: 'Не вдалося завершити бронування.',
    },
    awaitRefundError: {
        message: 'Не вдалося видалити статтю з вибраного',
    },
    rentalCancelled: {
        title: 'Бронювання скасовано',
        message: 'Бронювання вашого продукту скасовано!',
    },
    rentalCancelledError: {
        message: 'Бронювання не вдалося скасувати.',
    },
    orderDeliveredSuccess: {
        title: 'Успіх',
        message: 'Твої фотографії були успішно завантажені!',
    },
    orderDeliveredError: {
        title: 'Помилка',
        message: 'Під час завантаження сталася помилка.',
    },
    imageSubmitError: {
        noPictures: 'Немає фотографій',
        twoPictures: 'Завантажте принаймні дві фотографії*',
    },
    termsAndConditionsCheck: {
        title: 'Умови Обслуговування',
        message: 'Прийміть Умови Обслуговування, щоб завершити реєстрацію.',
    },
    requestExists: {
        title: 'Вже запитано',
        message: 'На цей продукт вами вже був надісланий запит на цю дату',
    },
    invalidDates: {
        title: 'Недійсній період',
        message: 'Вибраний період не дійсній',
    },
    itemUnavailable: {
        title: 'Продукт не доступний',
        message: 'Цей продукт недоступний для вибраного періоду.',
    },
    newsletterAlreadySubscribed: {
        title: 'Інформація',
        message: 'Супер! Ви вже підписалися на нашу розсилку!',
    },
    voucher: {
        message: `Застосовано купон "{{code}}" на {{amount}}€.`,
    },
    noLocationResolved: {
        message: 'Для цього входу не вдалося визначити місцезнаходження з координатами.',
    },
    deleteAccount: {
        request: 'Запит на видалення акаунта',
        message: 'Ваш запит на видалення облікового запису успішно відправлено.',
    },
    community: {
        cantJoin: 'Ви не можете приєднатися до цього спільноти в даний момент.',
        noAccess: 'У вас немає необхідних вимог для доступу до цієї групи.',
        noMember: 'You are not a member of this community',
        alreadyMember: 'Ви вже є членом цієї групи',
        joined: 'Ви приєдналися до спільноти {{name}}',
        left: 'Ви залишили {{name}}',
        leaveFailed: 'Сталася помилка при спробі покинути групу',
    },
    notSignedIn: {
        title: 'Не увійшли',
        message: 'Ви повинні увійти в систему.',
        actionMsg: 'Ви повинні увійти в систему, щоб виконати цю дію.',
    },
};
