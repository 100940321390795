export default {
    empty: {
        title: `No results for "{{term}}"`,
        subtitle: 'Search help',
        help: [
            'Check your search for typos',
            'Use more generic search terms',
            "The product you're searching for may be not yet on our site",
        ],
        needHelp: 'Need help finding something?',
    },
    pagination: {
        previous: 'Previous',
        next: 'Next',
        info: 'Showing <span class="number text-primary">{{ displayItems }}</span> of <span class="number text-primary">{{ totalItems }}</span> Results',
    },
    filter: 'Filter',
    sort: 'Sort by',
};
