<div class="step-4-card-comment-container">
    <div class="d-flex justify-content-center rating">
        <img src="/assets/icon/feedback/filled.svg" />
        <div class="text-h1 title-h1 rating-number">
            {{ averageRating }}
        </div>
    </div>
    <div class="d-flex justify-content-center paragraph-regular overall">
        {{ 'account.feedbackStep4.overall' | translate }}
    </div>
    <div class="paragraph-regular">{{ 'account.feedbackStep4.comment' | translate }}</div>
    <textarea
        [(ngModel)]="comment"
        nz-input
        type="text"
        id="review-comments"
        autocomplete="off"
        [placeholder]="'account.feedbackStep4.typeSomething' | translate"
        class="description"></textarea>
    <div class="d-flex justify-content-center feedback-action-button-container">
        <button faininShadow class="btn btn-primary feedback-next" (click)="submitReview.emit(comment)">
            {{ 'account.rate' | translate }}
        </button>
    </div>
</div>
