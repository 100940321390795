import { Injectable } from '@angular/core';
import { GetOrderList, GetRequestList, Order, SortOrder, StatusType } from '@api/generated-types';
import { DataService } from '@core/providers/data/data.service';
import { Logger } from '@core/utils/logger';
import { PaginatedListState, PaginatedResult } from '@core/utils/PaginatedListState';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EOrderState } from '../../../account/components/account-order-detail/order-state.enum';
import { GET_ORDER_LIST } from '../../../account/components/account-order-list/account-order-list.graphql';
import { EFilterOption } from '../../../account/components/account-order-list/e-filter-option';
import { GET_REQUEST_LIST } from '../../../account/components/account-requests-list/account-request-list.graphql';

export const ACTIVE_ORDER_STATES: EOrderState[] = [
    EOrderState.ValidatingCustomer,
    EOrderState.ValidationPending,
    EOrderState.ArrangingPayment,
    EOrderState.PaymentAuthorized,
    EOrderState.PaymentSettled,
];
export const PENDING_ORDER_STATES = [
    EOrderState.Shipped,
    EOrderState.ShippedBack,
    EOrderState.OrderDelivered,
    EOrderState.RentalEnded,
];
export const PROCESSED_ORDER_STATES = [EOrderState.AwaitRefund, EOrderState.AwaitFeedback, EOrderState.ItemDamaged];
export const COMPLETED_ORDER_STATES = [EOrderState.Cancelled, EOrderState.Completed];

export type OrderFilterType = {
    label: string;
    option: EFilterOption;
    states: Array<EOrderState | StatusType>;
    default?: boolean;
};

export const ORDER_FILTERS: OrderFilterType[] = [
    {
        label: 'account.order.filterOptions.requested',
        states: [StatusType.PENDING],
        default: true,
        option: EFilterOption.REQUESTED,
    },
    {
        label: 'account.order.filterOptions.active',
        states: ACTIVE_ORDER_STATES,
        default: true,
        option: EFilterOption.ACTIVE,
    },
    {
        label: 'account.order.filterOptions.pending',
        states: PENDING_ORDER_STATES,
        default: true,
        option: EFilterOption.PENDING,
    },
    {
        label: 'account.order.filterOptions.processed',
        states: PROCESSED_ORDER_STATES,
        default: true,
        option: EFilterOption.PROCESSED,
    },
    {
        label: 'account.order.filterOptions.completed',
        states: COMPLETED_ORDER_STATES,
        option: EFilterOption.COMPLETED,
    },
];

export type OrderFilterOption = { [key: string]: boolean };
export interface IOrderState {
    filterOptions: OrderFilterOption;
}

const initialState: IOrderState = {
    filterOptions: ORDER_FILTERS.reduce((value, filter) => {
        value[`${filter.option}`] = !!filter.default;
        return value;
    }, {} as any),
};

const logger = new Logger('OrderState');

export type OrderStatesGroup =
    | 'PendingRequests'
    | 'ActiveOrders'
    | 'PendingOrders'
    | 'ProcessedOrders'
    | 'CompletedOrders';

const REQUEST_LIST_KEY: OrderStatesGroup = 'PendingRequests';

const ORDER_LIST_ACTIVE_KEY: OrderStatesGroup = 'ActiveOrders';

const ORDER_LIST_PENDING_KEY: OrderStatesGroup = 'PendingOrders';

const ORDER_LIST_PROCESSED_KEY: OrderStatesGroup = 'ProcessedOrders';

const ORDER_LIST_COMPLETED_KEY: OrderStatesGroup = 'CompletedOrders';

export type PaginatedListStateMapType =
    | PaginatedListState<GetOrderList.Items>
    | PaginatedListState<GetRequestList.Items>;

export type PaginatedListStateResultType = GetOrderList.Items | GetRequestList.Items;

@Injectable({
    providedIn: 'root',
})
export class OrderStateService {
    private readonly stateSubject = new BehaviorSubject<IOrderState>(initialState);
    protected state: IOrderState;
    private localStorageKey = 'OrderState';
    private listStates$: Map<EFilterOption, PaginatedListStateMapType> = new Map();

    constructor(private dataService: DataService) {
        this.state = this.retrieveState();
        this.stateSubject.next(this.state);
        this.initializePaginatedOrderLists();
    }

    public initializePaginatedOrderLists() {
        this.initPaginatedOrderList(
            EFilterOption.REQUESTED,
            new PaginatedListState<GetRequestList.Items>(
                REQUEST_LIST_KEY,
                this.queryRequests(this.dataService, [StatusType.PENDING]),
            ),
        );
        this.initPaginatedOrderList(
            EFilterOption.ACTIVE,
            new PaginatedListState<GetOrderList.Items>(
                ORDER_LIST_ACTIVE_KEY,
                this.queryOrders(this.dataService, ACTIVE_ORDER_STATES),
            ),
        );
        this.initPaginatedOrderList(
            EFilterOption.PENDING,
            new PaginatedListState<GetOrderList.Items>(
                ORDER_LIST_PENDING_KEY,
                this.queryOrders(this.dataService, PENDING_ORDER_STATES),
            ),
        );
        this.initPaginatedOrderList(
            EFilterOption.PROCESSED,
            new PaginatedListState<GetOrderList.Items>(
                ORDER_LIST_PROCESSED_KEY,
                this.queryOrders(this.dataService, PROCESSED_ORDER_STATES),
            ),
        );
        this.initPaginatedOrderList(
            EFilterOption.COMPLETED,
            new PaginatedListState<GetOrderList.Items>(
                ORDER_LIST_COMPLETED_KEY,
                this.queryOrders(this.dataService, COMPLETED_ORDER_STATES),
            ),
        );
    }

    private initPaginatedOrderList(option: EFilterOption, paginatedListState: PaginatedListStateMapType) {
        this.listStates$.set(option, paginatedListState);
    }

    private queryOrders(dataService: DataService, states: EOrderState[]) {
        return (skip: number, take: number) => {
            return dataService
                .query<GetOrderList.Query, GetOrderList.Variables>(
                    GET_ORDER_LIST,
                    {
                        options: { filter: { state: { in: states } }, skip, take, sort: { updatedAt: SortOrder.DESC } },
                    },
                    'no-cache',
                )
                .pipe(map(value => value.orders));
        };
    }

    private queryRequests(dataService: DataService, states: StatusType[]) {
        return (skip: number, take: number) => {
            return dataService
                .query<GetRequestList.Query, GetRequestList.Variables>(
                    GET_REQUEST_LIST,
                    {
                        options: {
                            filter: { status: { in: states } },
                            skip,
                            take,
                            sort: { updatedAt: SortOrder.DESC },
                        },
                    },
                    'no-cache',
                )
                .pipe(map(value => value.requests));
        };
    }

    async refreshListForOrder(order: Pick<Order, 'state'>) {
        if (ACTIVE_ORDER_STATES.includes(order.state as EOrderState)) {
            // Refresh list where the order was before
            await this.stateList(EFilterOption.REQUESTED).fetch();
            // Refresh the list where the order is now
            await this.stateList(EFilterOption.ACTIVE).fetch();
        }
        if (PENDING_ORDER_STATES.includes(order.state as EOrderState)) {
            await this.stateList(EFilterOption.ACTIVE).fetch();
            await this.stateList(EFilterOption.PENDING).fetch();
        }
        if (PROCESSED_ORDER_STATES.includes(order.state as EOrderState)) {
            await this.stateList(EFilterOption.PENDING).fetch();
            await this.stateList(EFilterOption.PROCESSED).fetch();
        }
        if (COMPLETED_ORDER_STATES.includes(order.state as EOrderState)) {
            await this.stateList(EFilterOption.PROCESSED).fetch();
            await this.stateList(EFilterOption.COMPLETED).fetch();
        }
    }

    public observeList<T extends PaginatedListStateResultType>(option: EFilterOption) {
        const orderList = this.listStates$.get(option);
        if (!orderList) throw Error(`There is no order list defined for ${option}`);
        return orderList.observe() as Observable<PaginatedResult<T>>;
    }

    public observeLists<T extends PaginatedListStateResultType>(options: EFilterOption[]) {
        return combineLatest(
            options.map(option => {
                const orderList = this.listStates$.get(option);
                if (!orderList) throw Error(`There is no order list defined for ${option}`);
                return orderList.observe() as Observable<PaginatedResult<T>>;
            }),
        );
    }

    public stateList(option: EFilterOption) {
        const orderList = this.listStates$.get(option);
        if (!orderList) throw Error(`There is no order list defined for ${option}`);
        return orderList;
    }

    public fetchList(option: EFilterOption) {
        const orderList = this.listStates$.get(option);
        if (!orderList) throw Error(`There is no order list defined for ${option}`);
        return orderList.fetch();
    }

    setState<T extends keyof IOrderState>(key: T, value: IOrderState[T]) {
        logger.debug('SET', key, 'TO', value);
        this.state[key] = value;
        this.serialize();
        this.stateSubject.next(this.state);
    }

    patchState<T extends keyof IOrderState>(key: T, value: Partial<IOrderState>) {
        logger.debug('PATCH', key, 'with', value);
        this.state[key] = Object.assign(this.state[key], value);
        this.serialize();
        this.stateSubject.next(this.state);
    }

    select<R>(selector: (state: IOrderState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector), distinctUntilChanged());
    }

    currentState<T extends keyof IOrderState>(key: T): IOrderState[T] {
        return this.stateSubject.value[key];
    }

    resetState() {
        this.state = initialState;
        localStorage?.removeItem(this.localStorageKey);
        this.stateSubject.next(this.state);
        Array.from(this.listStates$.values()).forEach(list => list.reset());
    }

    private serialize() {
        localStorage?.setItem(this.localStorageKey, JSON.stringify(this.state));
    }

    private retrieveState(): IOrderState {
        const stateStr = localStorage?.getItem(this.localStorageKey) as string;
        if (stateStr) {
            return Object.assign(initialState, JSON.parse(stateStr));
        }
        return initialState;
    }
}
