export default {
    hero: {
        summer: {
            page1: {
                title: 'Summer is here!',
                subTitle: 'Rent SUPs, (inflatable) boats, kayaks & other things for the summer.',
            },
            page2: {
                title: 'Lend everything',
                subTitle: 'Rent drills, jigsaws, ladders instead of buying them new.',
            },
            page3: {
                title: '24/7 gaming time',
                subTitle: 'Rent game consoles, controllers, beamers for your gaming nights.',
            },
        },
        winter: {
            page1: {
                title: 'fainin',
                desktopTitle: 'fainin | Sharing is the new owning',
                subTitle: 'Earn money on the side',
                text: "Rent things you don't use. Up to €15,000 insured & available to verified members.",
                button: 'Signup for free',
            },
            page2: {
                title: 'Save money',
                subTitle: 'Save money',
                text: "Rent affordably and save money - whether it's a camera, drill, SUP, Soundboks, or drone.",
                button: 'Rent now',
            },
            page3: {
                title: 'Are you a student?',
                subTitle: 'Are you a student?',
                text: 'Students participate for free! Sign up and send proof to contact@fainin.com',
                button: 'Get your discount',
            },
        },
        button: {
            becomeRenter: 'Become a renter',
            goToDashboard: 'Go to dashboard',
        },
    },
    topSellers: {
        button: 'Cheap rentals',
    },
    news: {
        title: 'Known from...',
        toTheArticle: 'To the article',
    },
    trust: {
        title: 'Earn money online',
        subTitle: "Saving & earning money is easy! With fainin's all-around carefree package:",
        cards: {
            card1: {
                statement: 'Worried about your rentals? ',
                description:
                    "Don't worry! Ergo insures every rental up to €15,000. Thanks to our unique Design-For-Trust, every rental through fainin is fully covered.",
            },
            card2: {
                statement: 'No place for scammers!',
                description:
                    "Every tenant must verify with our verification partner SumSub - at fainin we don't give scammers a chance.",
            },
            card3: {
                statement: 'Are you looking for something specific?',
                description:
                    'If your desired item is not available in your area - no problem! With a public request other fainin-family members can see what you need.',
            },
            card4: {
                statement: '... Your Design For Trust!',
                description:
                    'Insurance, verification, smart payment and a documented rental process make fainin the safest sharing community in Germany.',
            },
        },
        button: 'Submit a product now',
    },
    app: {
        reviews: {
            review1: {
                title: 'Efficient & safe rental.',
                description:
                    'Christian Wolf (Wolf-Record) digitizes event equipment rental & earns via an additional revenue channel.',
            },
            review2: {
                title: "It's nice to share more.",
                description:
                    'As a student, Laini loves the opportunity to earn some pocket money on the side while renting.',
            },
            review3: {
                title: 'Unforgettable days renting',
                description: 'Johanna loves to collect unforgettable moments while renting.',
            },
        },
    },
    campaign: {
        title: 'Current topics',
        text: 'Learn from the most efficient renters - whether they are rental companies, retail partners or small business owners. In the safest sharing community, all participants benefit.\n',
    },
    nearProducts: {
        title: 'Rental offers near you',
        subtitle: 'Cheap rent nearby',
        button: 'Favorable rental offers',
    },
    blog: {
        title: 'Lender blog',
        subtitle: 'Let’s talk about the sharing economy',
        text: 'Learn from the most efficient renters - whether they are rental companies, retail partners or small business owners. In the safest sharing community, all participants benefit.',
    },
    gif: {
        title: 'Safe renting & leasing',
        text: 'With fainin, renting and sharing is just as safe as renting to friends or acquaintances from the neighbourhood. ',
        button: 'Register now',
    },
    newsletter: {
        title: "Don't miss any news!",
        text: 'Sign up for our newsletter and receive regular updates from the fainin Family.',
        button: 'Subscribe to newsletter',
    },
    aboutUs:
        'Wir sind drei Studenten und heißen Johann, Max und Kevin – wir wollen mit fainin die Welt verbessern und alle Teilnehmer profitieren lassen. Wir freuen uns über Deine Nachricht.',
    yourSharingCommunity: 'Your Sharing Community',
    getToKnowUs: 'Get to Know Us',
    sharingIsOwning: '#SharingIsTheNewOwning',
    becomeFamily: 'Become part of the fainin Family',
    title: 'fainin',
    readyForCommunity: 'Ready for the safest Sharing Community?',
};
