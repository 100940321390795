<a class="fainin-logo" [routerLink]="'/'">
    <img
        class="desktop d-none d-md-block"
        src="/assets/logo_fainin_rgb_colored.svg"
        alt="{{ 'asset.layoutLogo.faininStorefrontLogo' | translate }}"
        height="32"
        width="148" />
    <img
        class="mobile d-block d-md-none"
        src="/assets/icon/fainin/fainin_boomerang.svg"
        alt="{{ 'asset.layoutLogo.faininStorefrontLogo' | translate }}"
        height="32"
        width="64" />
</a>
