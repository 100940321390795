<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title h5"><ng-container *ngTemplateOutlet="titleTemplateRef$ | async"></ng-container></div>
        <button
            *ngIf="options?.closable"
            type="button"
            (click)="closeModal()"
            class="close"
            data-dismiss="modal"
            attr.aria-label="{{ 'label.close' | translate }}">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div class="modal-body">
        <fainin-dialog-component-outlet
            [component]="childComponentType"
            (create)="onCreate($event)"></fainin-dialog-component-outlet>
    </div>
    <div class="modal-footer">
        <ng-container *ngTemplateOutlet="buttonsTemplateRef$ | async"></ng-container>
    </div>
</div>
