export default {
    whatsPhoneNumber: 'Який у вас номер телефону?',
    howLogin: 'Як ви хочете увійти?',
    notRegisteredYet: 'Ще не зареєстровані?',
    appleAuthHasFailed: 'Не вдалося увійти до Apple. Спробуйте ще раз або зверніться до служби підтримки',
    addMoreProducts: 'Добавить дополнительные продукты',
    gotoArticle: 'Перейти к статье',
    weLetYouKnow: 'Мы сообщим вам, когда кто-то отправит вам запрос на аренду.',
    productUploaded: 'Ваш продукт был загружен.',
    congrats: 'Поздравляю!',
    success: 'Успіх!',
    information: 'Інформація',
    email: 'Адреса електронної пошти',
    password: 'Пароль',
    passwordHint: 'Щонайменше 8 символів',
    passwordHint2: 'Ваш пароль повинен містити цифри, великі літери та спеціальний символ',
    confirmPassword: 'Підтвердьте пароль',
    rememberMe: 'Залишайтеся в обліковому записі',
    reset: 'Скинути налаштування',
    setPassword: 'встановити пароль',
    login: 'Реєстрація',
    loginWithEmail: 'Увійдіть за допомогою електронної пошти',
    alreadyHaveAccount: 'Вже є аккаунт?',
    becomeRenter: 'Стати Орендарем',
    register: 'Реєструвати',
    registerNow: 'Приєднуйся зараз',
    mr: 'Mr',
    mrs: 'Mrs',
    notSpecified: 'Не визначено',
    gender: 'Стать',
    birthday: 'Дата народження',
    createAccount: 'Створити аккаунт',
    noAccount: 'Ще немає облікового запису',
    hasAccount: 'Вже є аккаунт?',
    agreedTo: 'Я приймаю',
    favoritesCannotBeSaved: 'Вибране не можна зберегти назавжди, доки ви не ввійдете в систему',
    version: 'Версія',
    okay: 'добре',
    filters: 'фільтри',
    loadMore: 'Завантажити ще',
    noResults: 'Немає результатів',
    of: 'з',
    imprint: 'Відбиток',
    representedBy: 'Представлений',
    managingDirector: 'Керуючий директор',
    registerEntry: 'реєстраційний запис',
    registeredIn: 'Зареєстровано в комерційному реєстрі',
    registrationCourt: 'Реєстраційний суд: окружний суд Гамбурга',
    registrationNumber: 'Реєстраційний номер',
    taxId: 'ІПН',
    salesTaxId: 'Ідентифікаційний номер податку з продажів відповідно до §27a закону про податок з продажів',
    vatId: 'Vat-ID',
    businessType: 'Тип бизнеса',
    comingSoon: 'Незабаром',
    type: {
        PERSONAL: 'Особистий аккаунт',
        BUSINESS: 'Бізнес-аккаунт',
    },
    forgotPassword: {
        title: 'Забули пароль',
        instructions:
            'Спочатку введіть свою електронну адресу fainin. Після цього ви отримаєте допомогу електронною поштою',
        success: 'Дуже добре! Вам надіслано електронний лист. Будь ласка, перевірте свій поштовий ящик',
    },
    resetPassword: {
        title: 'Скинути пароль',
        instructions: 'Виберіть свій новий пароль fainin',
    },
    verify: {
        title: 'Ласкаво просимо!',
        subTitle: 'Нарешті ви можете розпочати роботу і ви є частиною сім’ї fainin',
        instructions: 'Вам надіслано електронний лист. Тепер просто перевірте.',
        refreshToken: 'Будь-ласка, введіть свою електронну адресу, щоб надіслати запит на новий маркер підтвердження',
    },
    business: {
        title: 'Компанія',
        type: 'Тип компании',
        name: 'Назва компанії',
        registrationNumber: 'Регистрационный номер/ Символ акции',
        vat: 'Ідентифікаційний номер платника ПДВ',
    },
    error: {
        googleAuthFlowRestarted: 'Sign in flow restarted by the user',
        googleFailedToIssueCred: 'Failed to issue credentials for continuing with Google, please try again.',
        googleAuthCancelledByUser: 'Sign in with Google cancelled by the user.',
        googleOneTapOptedOut:
            'Please check if you have opted out of Google one Tap Sign In. If not then please try clearing the website cache and try again.',
        googleAuthSuppressedByUser: 'Sign in with Google suppressed by the user.',
        googleAuthCustomerSupport:
            'Something went wrong for signing in with Google, if this issue persists, please contact customer support.',
        googleAuthUnsupported:
            'Your browser does not support Continuing with Google, please try using an email or in another browser.',
        initGoogleAuthError: 'Failed to initialize Google Authentication. Please refresh and try again.',
        errorOccurred: 'Сталась помилка',
        phoneNumberError: 'Ой! Щось не так із вашим номером телефону – ви додали до нього код країни?',
        errorDeletingLocation: 'Помилка видалення місцезнаходження',
        removeFailed: 'Помилка видалення',
        startBeforeEnd: 'Дата початку має бути перед датою завершення!',
        noAddressProvided: 'Адреса не вказана',
        failedToFindLocation: 'Ваше поточне місцезнаходження не знайдено',
        failedToCreateUploadLocation: 'Не вдалося створити місце завантаження',
        failedToLocateAddress: 'Не вдалося знайти вказану адресу',
        failedToGeolocate: 'Не вдалося отримати дані про геолокацію',
        invalidZip: 'Недійсний поштовий індекс міста/країни',
        invalidCityName: 'Недійсна назва міста',
        invalidCountryName: 'Недійсна назва країни',
        attention: 'Увага!',
        cantVerifyZipTitle: 'Ми не знаємо цього поштового індексу',
        cantVerifyZipBody: 'Ви впевнені, що хочете продовжити з поточним введенням?',
        zipCityDontMatchTitle: 'Уваги',
        zipCityDontMatchBody: 'Поштовий індекс і місто не збігаються',
        startEndDates: 'Будь ласка, виберіть дату початку та закінчення (можна бути в один день)',
        startEndTimes: 'Будь ласка, оберіть час забирання та повернення',
        invalidCredentials: 'Невірна інформація. Будь-ласка спробуйте ще раз',
        recaptcha: 'Помилка reCAPTCHA',
        email: 'Будь-ласка, введіть свою улюблену електронну адресу',
        password: 'Будь-ласка, введіть правильний пароль',
        confirmPassword: 'Небезпека! Два паролі не співпдають',
        name: "Будь-ласка, скажіть нам своє ім'я",
        firstName: 'Скажіть, будь-ласка, своє ім’я',
        lastName: 'Скажіть, будь-ласка, своє прізвище',
        gender: 'Виберіть будь-ласка',
        businessName: 'Будь-ласка, введіть назву вашої компанії',
        businessType: 'Будь ласка, вкажіть тип вашого бізнесуБудь ласка, вкажіть тип вашого бізнесу',
        vat: 'Будь ласка, введіть свій ідентифікаційний номер платника ПДВ',
        noToken: 'На жаль, токен не знайдено',
        invalidToken: 'Використовуваний токен недійсний!',
        verificationTokenInvalid: 'Токен підтвердження недійсний.',
        verificationTokenExpired: 'Токен підтвердження закінчився.',
        title: 'Потрібна назва (мінімум 3 символи)',
        image: 'Завантажте зображення',
        category: 'Будь-ласка, додайте категорію',
        subCategory: 'Будь-ласка, додайте підкатегорію',
        description: 'Напишіть опис (мінімум 5 символів)',
        perDay: 'Мінімум 1 €',
        perHour: 'Мінімум 1 € і максимум 2 знаки після коми',
        basePrice: 'Мінімум 1 € і максимум 2 знаки після коми',
        perWeek: 'Тижнева ціна не може бути 0',
        perMonth: 'Місячна ціна не може бути 0',
        perWeek2: 'Тижневий тариф має бути вищим за денний',
        perMonth2: 'Місячна ціна має бути вищою за тижневу та щоденну',
        purchasePrice: 'Мінімум 1 €',
        location: 'Місцезнаходження не знайдено. Створіть нове місце завантаження.',
        locationName: 'Введіть назву місця',
        districtName: 'Будь-ласка, введіть назву району',
        zip: 'Потрібно ввести поштовий індекс',
        cityName: 'Будь-ласка, введіть назву міста',
        countryName: 'Будь-ласка, введіть назву країни',
        agree: 'Треба прийняти!',
        duplicateEmail: 'Ви вже зареєструвалися з цією адресою електронної пошти, спробуйте увійти!',
        nativeAuthenticationMethodsNotFound: 'Ви зареєстровані методом реєстрації в соцмережах. Спробуйте ще раз.',
        signedUpThroughEmail: 'Уже є обліковий запис. Спробуйте ввійти за допомогою електронної пошти',
        birthday: 'День народження обов’язковий',
        validEmail: 'Будь ласка, введіть дійсну адресу електронної пошти',
        checkInputs: 'Будь ласка, перевірте введені дані та спробуйте зберегти ще раз',
        fillAllForBank: 'Будь ласка, заповніть усі поля, необхідні для додавання банківського рахунку!',
        iban: 'IBAN неправильний!',
        ibanMin: 'Макс. 34 символи!',
        positionStack: 'Не вдалося знайти вказану адресу, перевірте її ще раз.',
        changeEmail: 'Будь-ласка, введіть свій пароль, щоб змінити адресу електронної пошти!',
        loadingError: 'Сталася помилка. Перевірте мережу та оновіть сторінку!',
        cantRemoveDefault: 'Ви не можете видалити своє місцезнаходження за замовчуванням',
    },
    migrationOptOut: {
        title: 'Скасувати міграцію',
        instructions:
            'Якщо ви почнете процедуру відмови, всі ваші дані будуть видалені. Звичайно, ви можете створити новий обліковий запис.',
        button: 'Opt-Out!',
        buttonLabel: 'Міграція Opt-Out Кнопка',
    },
    migrationOptOutNotification: {
        onSuccessHeading: 'Міграцію скасовано',
        onSuccessParagraph: 'Шкода, що ти йдеш! Ваші дані будуть повністю видалені.',
        onError: "Сталося дещо непередбачуване. Будь ласка, зв'яжіться з нами через contact@fainin.com!",
    },
    migrationOptIn: {
        title: 'Закінчити міграцію',
        instructions: "Ласкаво просимо до сім'ї fainin! Ви можете обрати свій новий пароль вже зараз.",
        button: 'Виберіть пароль',
        buttonLabel: 'Міграція Opt-In Кнопка',
    },
    migrationOptInNotification: {
        onError: "Сталося дещо непередбачуване. Будь ласка, зв'яжіться з нами через contact@fainin.com!",
    },
    passwordResetNotification: {
        onSuccessHeading: 'Скидання пароля.',
        onSuccessParagraph: 'Вітання!',
    },
    checkoutConfirmation: {
        thankYouForOrder: 'Дякуємо за ваше замовлення',
        orderCode: 'Order code',
        placed: 'Розміщено',
        createAccount: 'Створити аккаунт',
        createAccountMore:
            'Створіть обліковий запис, щоб насолоджуватися швидшими оплатами, відстеженням замовлень, історією замовлень тощо',
        checkEmail: 'Чудово! Тепер перевірте свою електронну пошту',
        toCompleteRegistration: 'для завершення реєстрації',
        pageNotFound: 'Сторінку не знайдено',
        payWithMethod: 'Оплата методом',
        examplePaymentForm: 'Це приклад форми оплати. Не використовуйте реальні дані картки',
        expiry: 'Термін дії',
        month: 'Місяць',
        year: 'Рік',
        completeOrder: 'Повне замовлення',
        change: 'Змінити',
        selectAddress: 'Виберіть Адрес',
        addNewAddress: 'Додати нову адресу',
        customerDetails: 'Інформація про клієнта',
        next: 'Далі',
        shippingAddress: 'Адреса доставки',
        sendToThisAddress: 'Відправити на цю адресу',
        shippingMethod: 'Метод доставки',
        proceedToPayment: 'Перейти до оплати',
        account: 'Рахунок',
        shipping: 'Доставка',
        payment: 'Оплата',
        complete: 'Завершено',
        haveAccount: 'Вже є аккаунт',
        or: 'чи',
        continueAsGuest: 'Продовжуйте як гість',
    },
    google: 'Продовжуйте з Google',
    apple: 'Продовжуйте з Apple',
    continueWithMail: 'Продовжуйте з електронною поштою',
    termsAgreement:
        'Використовуючи fainin, ви погоджуєтеся з нашими Умовами використання та Політикою конфіденційності.',
    termsAgreement1: 'Використовуючи fainin, ви погоджуєтеся з нашими',
    termsAgreement2: 'Правила та умови',
    termsAgreement3: 'i',
    termsAgreement4: 'Політика конфіденційності',
    or: 'чи',
    agreeToNotify: 'Будь-ласка, прийміть положення та умови.',
    next: 'W',
    whatsYourName: 'Як вас звати?',
    MandatoryField: "Обов'язкове поле",
};
