import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import deDE from '@core/localization/de';
import enUS from '@core/localization/en';
import ruRU from '@core/localization/ru';
import ukUA from '@core/localization/uk';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

const log = new Logger('I18nService');
const languageKey = 'language';

export class TranslateEmbeddedLoader implements TranslateLoader {
    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<any> {
        switch (lang) {
            case 'de':
            case 'de-DE': {
                return of(deDE);
            }
            case 'en':
            case 'en-US': {
                return of(enUS);
            }
            case 'uk':
            case 'uk-UA': {
                return of(ukUA);
            }
            case 'ru':
            case 'ru-RU': {
                return of(ruRU);
            }
            default: {
                return of(deDE);
            }
        }
    }
}

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
    return s;
}

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    initialized: boolean = false;

    private _storeLanguageChange: boolean;
    private _defaultLanguage!: string;
    private _supportedLanguages!: string[];

    private langChangeSubscription!: Subscription;

    private _isStaticLanguageRoute: boolean;

    constructor(
        private translateService: TranslateService,
        @Inject(LOCALE_ID) private localId: string,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        // Embed languages to avoid extra HTTP requests
        this.translateService.addLangs(environment.supportedLanguages);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang(this.localId || 'de-DE');
        this._defaultLanguage = environment.defaultLanguage;
        this._supportedLanguages = environment.supportedLanguages;
    }

    /**
     * Initializes i18n for the application
     */
    init() {
        if (isPlatformBrowser(this.platformId) && !this.initialized) {
            log.debug('Init');
            if (!this.isStaticLanguageRoute) {
                this.language =
                    localStorage.getItem(languageKey) || (this.translateService.getBrowserCultureLang() as string);
            }
            // Warning: this subscription will always be alive for the app's lifetime
            this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                if (isPlatformBrowser(this.platformId) && this._storeLanguageChange)
                    localStorage.setItem(languageKey, event.lang);
            });
            this.initialized = true;
        }
    }

    /**
     * Cleans up language change subscription.
     */
    destroy() {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    /**
     * Wrapper for TranslateService.onLangChange
     */
    onLangChange() {
        return this.translateService.onLangChange;
    }

    get isStaticLanguageRoute(): boolean {
        return this._isStaticLanguageRoute;
    }

    public setStaticLanguageRoute() {
        log.info('Static language route detected');
        this._isStaticLanguageRoute = true;
    }

    get storeLanguageChange(): boolean {
        return this._storeLanguageChange;
    }

    set storeLanguageChange(value: boolean) {
        this._storeLanguageChange = value;
    }

    /**
     * Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @param language The IETF language code to set.
     */
    set language(language: string) {
        // Don't do anything if the language did not change
        if (language && language === this.translateService.currentLang) return;

        language =
            language || localStorage.getItem(languageKey) || (this.translateService.getBrowserCultureLang() as string);

        let isSupportedLanguage = this._supportedLanguages.includes(language);

        // If no exact match is found, search without the region
        if (language && !isSupportedLanguage) {
            const commonLang = language.split('-')[0];
            language =
                this._supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(commonLang)) || '';
            isSupportedLanguage = Boolean(language);
        }

        // Fallback if language is not supported
        if (!isSupportedLanguage) {
            language = this._defaultLanguage;
        }

        log.debug(`Language set to ${language}`);
        this.translateService.use(language);
    }

    /**
     * Gets the current language.
     * @return The current language code.
     */
    get language(): string {
        return this.translateService.currentLang;
    }

    get supportedLanguages(): string[] {
        return this.translateService.langs;
    }

    get defaultLanguage(): string {
        return this.translateService.defaultLang;
    }

    /**
     * Returns a translation instantly from the internal state of loaded translation.
     * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
     */
    translate(key: string | Array<string>, interpolateParams?: Object): string | any {
        this.translateService.instant(key, interpolateParams);
    }

    /**
     * Returns a translation instantly from the specified language.
     * @param key
     * @param language
     * @param interpolateParams
     */
    translateTo(key: string, language: string, interpolateParams?: Object) {
        return this.translateService
            .getTranslation(language)
            .pipe(map(translation => this.translateService.getParsedResult(translation, key, interpolateParams)));
    }
}
