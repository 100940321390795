export default {
    cancel: 'Cancel',
    confirm: 'Confirm',
    proceed: 'Proceed',
    acceptRequestTitle: 'Accepting Request',
    acceptRequestMsg: 'Please confirm accepting this request.',
    declineRequestTitle: 'Declining Request',
    declineRequestMsg: 'Please confirm declining this request.',
    cancelRequestTitle: 'Canceling Request',
    cancelRequestMsg: 'Please confirm canceling this request.',
    requestAcceptedTitle: 'Request accepted',
    requestAcceptedMsg:
        'Next, the other person must verify their ID and pay the rent via fainin. In the meantime, you can write to each other and arrange a handover or clarify any open questions.',
    requestDeclinedTitle: 'Request Declined',
    requestDeclinedMsg: "You've declined the request. If it was a mistake, you can ask the renter to request again.",
    understand: 'Alright',
    startingRental: 'Starting Rental',
    uploadLeast: "Congratulations! You're almost there. Upload at least 1 image of the item to confirm delivery.",
    warning: 'Warning',
    rentalDateHasNotReached:
        'The starting time of your rental has not been reached. We cannot cover any damages outside of your rental period.\n\nWould you still like to commence the rental?',
    endingRental: 'Ending Rental',
    uploadLeastEnd: 'Upload at least 1 image of the item to confirm return.',
    rentalEndDateHasNotReached: 'Rental end time has not reached yet, are you sure you want to confirm return?',
    refundTitle: 'Refund',
    refundMsg:
        'By cancelling this order, a refund request will be sent. Are you sure you want to cancel and refund this order?',
    changesDiscardConfirm: 'Are you sure you want to leave? \nYour changes will be discarded.',
    pwa: {
        title: 'Update!',
        msg: 'fainin has been updated.',
        btnTxt: 'Reload',
    },
    deletingProductTitle: 'Deleting Product',
    deletingProductMsg: "Are you sure you want to delete your '{{productName}}'?",
    deletingAccountTitle: 'Delete Account',
    deletingAccountMsg: 'Are you sure you want to delete your account?',
    modalUpdateTierTitle: 'Do you want to update the price tiers?',
    modalUpdateTierMsg:
        'Your currently configured price tiers will be overwritten with a new definition based on your input.',
    deletingAccountLabel: "To confirm please type 'DELETE' into the field below",
    deletingAccountInput: 'DELETE',
    productUpload: {
        cacheRestore: {
            title: 'Existing draft found!',
            msg: 'Do you want to continue editing your product draft or start uploading a new one?',
            confirmLabel: 'Continue',
            cancelLabel: 'New',
        },
    },
    detectedContentLanguage: {
        title: 'Different Language available',
        msg: 'This content is also available in your current language: "{{language}}". Would you see the content in this language?',
    },
};
