import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LocationCoords, LocationService } from '@core/providers/location.service';
import { Dialog } from '@core/providers/modal/modal-types';

@Component({
    selector: 'fainin-location-permission-modal',
    templateUrl: './location-permission-modal.component.html',
    styleUrls: ['./location-permission-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationPermissionModalComponent implements Dialog<LocationCoords | undefined> {
    deniedPermission = false;
    resolveWith: (result?: LocationCoords | undefined) => void;

    constructor(private locationService: LocationService, private cdr: ChangeDetectorRef) {}

    givePermission() {
        this.resolveLocation().then(() => {});
    }

    close() {
        this.resolveWith();
    }

    private async resolveLocation() {
        if (this.deniedPermission) {
            window.location.reload();
            return;
        }
        try {
            const location = await this.locationService.getLocation();
            this.resolveWith(location);
        } catch (e) {
            this.deniedPermission = true;
            this.cdr.markForCheck();
        }
    }
}
