import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { debounceTime, Subject } from 'rxjs';

@Directive({
    selector: '[faininDebounceClick]',
    standalone: true,
})
export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() debounceTime: number = 250;
    @Output() debounceClick = new EventEmitter();
    private clicks = new Subject();
    ngOnInit() {
        this.clicks
            .pipe(debounceTime(this.debounceTime), untilDestroyed(this))
            .subscribe(e => this.debounceClick.emit(e));
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }

    @HostListener('click', ['$event'])
    clickEvent(event: Event) {
        event.preventDefault();
        //event.stopPropagation();
        this.clicks.next(event);
    }
}
