export default {
    firstName: 'Имя',
    lastName: 'Фамилия',
    fullName: 'Полное имя',
    personalData: 'Персональные данные',
    address: 'Адрес для выставления счета',
    contact: 'Контакт',
    aboutYou: 'О себе',
    password: 'Пароль',
    bank: 'Банковские реквизиты',
    bankInfo: '(непублично)',
    save: 'Сохранить',
    title: 'Редактировать профиль',
    streetAndNumber: 'Улица + номер дома',
    plz: 'Почтовый индекс',
    city: 'Город',
    phoneNumber: 'Например: +4917534777777',
    email: 'Адрес электронной почты',
    disclaimer: 'Для подтверждения вашего нового адреса электронной почты мы отправим вам письмо с подтверждением.',
    description: 'Описание',
    oldPass: 'Старый пароль',
    newPass: 'Новый пароль',
    birthday: 'День рождения',
    saved: 'Данные сохранены!',
    noUpdates: 'Никаких новых изменений!',
    emailChanged:
        'Электронная почта изменена! Для завершения процесса, пожалуйста, проверьте новый адрес электронной почты, чтобы подтвердить изменения.',
    dd: 'ДД',
    loading: 'Загрузка...',
    emailRequired: 'Требуется электронная почта!',
};
