export default {
    noCommunity: {
        title: 'Kein Community-Zugriff',
        msg: 'Du bist kein Mitglied dieser Community. Bitte treten zuerst der Community bei, in der dieses Produkt gelistet ist!',
        okay: 'Okay',
    },
    leavingPage: 'Du bist dabei diese Webseite zu verlassen...',
    loginToChat: 'Melde Dich an um mit anderen Nutzer:innen zu chatten',
    shippingAddress: 'Versand',
    shippingReturnAddress: 'Rückversand',
    shippingCost: 'Versandkosten',
    failedClipboard: "Couldn't copy link to clipboard!",
    linkCopiedToClipboard: 'Link copied to clipboard!',
    invalidPeriod: 'Ungültiger Zeitraum',
    requestAlreadyExists:
        "Request already exists. This product's request is pending. Please cancel it to request again.",
    itemUnavailableForPeriod: 'Item unavailable for the requested period!',
    pleaseSignIn: 'Please sign in to request!',
    lessExpensiveDailyPrice: 'Der Tagespreis ist günstiger und wird dir daher berechnet',
    hour: 'Stunde',
    incl: 'inkl',
    inTotal: 'Gesamt',
    insuranceCoverage: 'Versicherungsschutz bis zu 15.000€ mit',
    returnTime: 'Rückgabe*',
    returnTimeOptional: 'Rückgabe (optionaler Vorschlag)',
    pickupTime: 'Abholzeit*',
    pickupTimeOptional: 'Abholzeit (optionaler Vorschlag)',
    overview: 'Zur Übersicht',
    availability: 'Verfügbarkeit',
    exactPrice: 'Gib deine Mietdauer an, um den genauen Preis zu sehen',
    reviews: 'Bewertungen',
    rentalPrice: 'Mietpreis',
    rentalPeriod: 'Mietdauer',
    rentNow: 'Jetzt mieten',
    days: 'Tage',
    serviceFee: 'Servicegebühr',
    totalAmount: 'Gesamtbetrag',
    similarProducts: 'Ähnliche Produkte',
    youMightAlsoLike: 'Diese Produkte könnten dir vielleicht auch gefallen',
    checkAvailibility: 'Verfügbarkeit Prüfen',
    inquireNow: 'Jetzt Anfragen',
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    description: 'Beschreibung',
    edit: 'Produkt bearbeiten',
    owner: 'Anbieter',
    favorite: 'Favorit',
    shipping: {
        notSignedIn: 'Du musst angemeldet sein, um eine Addresse auswählen zu können.',
        chooseAddress: 'Wähle deine Addresse',
        newAddress: 'Neue Addresse',
    },
    noInsuranceDisclaimer: 'Für dieses Produkt kann keine Versicherung angeboten werden.',
};
