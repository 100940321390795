export default {
    intro: 'З спільнотами Fainin ми надаємо вам можливість ексклюзивного кредитування у вашому університеті, місці роботи, спортивному клубі та багатьох інших місцях.',
    requestCommunity: 'Якщо ви хочете створити спільноту, надішліть нам короткий лист на адресу contact@fainin.com.',
    recommended: 'Рекомендації для вас:',
    join: 'Приєднатися',
    leave: 'Залишити',
    show: 'Показати',
    allProducts: 'Всі продукти',
    requiresLogin: 'Для вступу в спільноту вам потрібно увійти у систему',
    section: {
        info: 'Інформація',
        members: 'Учасники',
        products: 'Продукти',
    },
};
