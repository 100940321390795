export default {
    message: [
        `fainin verwendet Cookies und ähnliche Technologien, um Deine Inhalte zu personalisieren und Dein Nutzererlebnis zu optimieren.
Indem Du auf OK klickst oder in den Cookie-Einstellungen eine Option aktivierst, stimmst Du dem zu.
Dies wird auch in unserer`,
        `Datenschutzerklärung`,
        `beschrieben. Um Deine Einstellungen zu ändern oder Deine Einwilligung zu widerrufen,
aktualisiere einfach Deine Cookie-Einstellungen.`,
    ],
    title: 'Uns liegt der Schutz Deiner Daten am Herzen',
    intro:
        'Wenn Du eine Website im Internet besuchst, kann sie Infos in Deinem Browser speichern und/oder abrufen. ' +
        'Meistens in Form von sogenannten Cookies. Diese Infos beziehen sich auf den jeweiligen Nutzer, ' +
        'dessen Einstellungen oder das Gerät und werden hauptsächlich dafür genutzt, damit die Website wie erwartet ' +
        'funktioniert. Da die Angaben (meist) anonymisiert werden, identifizieren sie Dich in der Regel nicht direkt, ' +
        'helfen Dir aber zu einer personalisierten Nutzungserfahrung. Da wir Deine Datenschutzrechte respektieren, ' +
        'kannst Du Dich dafür entscheiden, einige Cookies nicht zuzulassen. Dieses Blockieren kann sich jedoch auf ' +
        'Dein Web-Erlebnis und unsere Services auswirken.',
    heading: 'Uns liegt der Schutz Deiner Daten am Herzen',
    policy: 'Datenschutz',
    button: {
        save: 'Speichern',
        back: 'Zurück',
        customize: 'Einstellungen',
        accept: 'Alle akzeptieren',
    },
    consent: {
        base: {
            title: 'Unbedingt erforderliche Cookies',
            description:
                'Diese Cookies sind für die Funktion und Darstellung der Website notwendig und können nicht ' +
                'ausgeschaltet werden. Wie zum Beispiel das Festlegen Deiner Datenschutzeinstellungen, das Einloggen, ' +
                'die Sicherheitsmaßnahme ReCaptcha oder das Ausfüllen von Formularen auf dieser Website.',
        },
        analytics: {
            title: 'Anonyme Analysedaten',
            description:
                'Diese Einstellung ist notwendig für das anonymisierte Auswerten von Besuchen und Traffic-Quellen.' +
                'Mit Deiner Zustimmung zur Erfassung von generellen Analyse-Daten ermöglichst Du uns, zu verstehen, wie unsere Website genutzt wird.' +
                'Diese Daten helfen uns, die Leistung unserer Seite zu analysieren und zu verbessern, sodass wir Dir ein besseres Nutzererlebnis bieten können.' +
                'Deine Daten werden anonym erfasst und verarbeitet, um Deine Privatsphäre zu schützen. Du trägst damit aktiv dazu bei, dass wir unsere Inhalte und Dienstleistungen für Dich und Andere optimieren können.',
        },
        ad_user_data: {
            title: 'Deine Interessen verstehen',
            description:
                'Diese Einstellung erlaubt uns, Daten zu sammeln, die uns helfen, Deine Interessen und Vorlieben besser zu verstehen. Das umfasst Informationen wie die Websites, die Du besuchst oder die Produkte, die Du Dir ansiehst. Diese Zustimmung hilft uns, Deine Erfahrung individueller und relevanter zu gestalten.',
        },
        ad_personalization: {
            title: 'Anzeigen, die zu Dir passen',
            description:
                'Durch Deine Erlaubnis können wir die Daten, die wir über Dich gesammelt haben, nutzen, um die Werbung, die Du siehst, persönlich auf Dich zuzuschneiden. Das bedeutet weniger Zeit mit uninteressanten Anzeigen und mehr Entdeckungen von Produkten und Dienstleistungen, die Dich wirklich interessieren. Du behältst die Kontrolle über diese Einstellung und kannst sie jederzeit anpassen.',
        },
        ad_storage: {
            title: 'Deine Vorlieben merken',
            description:
                'Deine Zustimmung erlaubt es uns, Informationen auf Deinem Gerät zu speichern, um Deine Präferenzen und wiederholten Besuche zu merken. Dies stellt sicher, dass Deine Erfahrung so reibungslos wie möglich ist, mit schnelleren Ladezeiten und Anzeigen, die zu dem passen, was Du liebst.',
        },
    },
};
