import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieConsentContainerComponent } from '@core/cookie-consent/components/cookie-consent-container/cookie-consent-container.component';
import { CookieConsentComponent } from '@core/cookie-consent/components/cookie-consent/cookie-consent.component';
import { UniversalCookieConsentService } from '@core/cookie-consent/services/universal-cookie-consent.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonSharedModule } from '@shared/button-shared.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import {
    UNIVERSAL_COOKIE_CONSENT_OPTIONS,
    UniversalCookieConsentOptions,
} from './models/universal-cookie-consent-options.model';

export const DEFAULT_MODULE_PROVIDERS = [UniversalCookieConsentService];

export const UNIVERSAL_COOKIE_CONSENT_OPTION_DEFAULTS: Partial<UniversalCookieConsentOptions> = {
    acceptText: 'Accept',
    customizeText: 'Customize',
    backText: 'Back',
    saveText: 'Save',
    customizeHeadingText: 'Cookie Settings',
};

@NgModule({
    declarations: [CookieConsentComponent, CookieConsentContainerComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzFormModule,
        NzSwitchModule,
        TranslateModule,
        RouterModule,
        FontAwesomeModule,
        ButtonSharedModule,
    ],
    exports: [CookieConsentComponent, CookieConsentContainerComponent],
})
export class UniversalCookieConsentModule {
    static forRoot(options: UniversalCookieConsentOptions): ModuleWithProviders<UniversalCookieConsentModule> {
        return {
            ngModule: UniversalCookieConsentModule,
            providers: [
                ...DEFAULT_MODULE_PROVIDERS,
                {
                    provide: UNIVERSAL_COOKIE_CONSENT_OPTIONS,
                    useValue: {
                        ...UNIVERSAL_COOKIE_CONSENT_OPTION_DEFAULTS,
                        ...options,
                    },
                },
            ],
        };
    }

    constructor() {}
}
