import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { levelToName, Logger, LogItem, LogLevel } from '@core/utils/logger';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
    enableProdMode();
}

if (environment.keepLogs) {
    (window as any).logs = new Array<LogItem>();
    Logger.outputs.push((source, level, style, ...objects) => {
        (window as any).logs.push({ source, level: levelToName(level), style, objects });
        if ((window as any).logs.length === 50) {
            (window as any).logs.shift();
        }
    });
}

if (environment.name !== 'production') {
    (window as any).enableLoggerDebugMode = function () {
        Logger.setLogLevel(LogLevel.Debug);
        console.log('Changed LogLevel to Debug!');
    };
}

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => console.log(err));
});
