export default {
    faininAcronym: 'Знайдіть що-небудь у вашому районі',
    subscribe: 'Підписатись',
    links: 'Links',
    readMore: 'Читаті далі',
    settings: 'Налаштування',
    newsletter: {
        consent:
            'Так, на регулярні електронні листи від нас з інформацією, порадами, рекламними акціями та захоплюючими історіями про fainin та спільноту обміну',
        getReady: 'Будьте готові до fainin 2.0',
        subscribe: 'Підпишіться на нашу розсилку!',
        subscribe2: 'Підпишіться на ексклюзивні новини',
        subscribe3: 'Отримайте всі новини про родину fainin',
        subscribe4: 'Отримуйте наші новини',
        disclaimer: 'Своєю реєстрацією ви підтверджуєте нашу',
        placeholder: 'Ваш улюблений електронний лист',
        alreadySubscribed: 'Ви вже підписані на fainin',
        success: {
            title: 'Ура! Вхід успішний',
            text: 'Ласкаво просимо до родини fainin!',
        },
        invalid: 'Будь-ласка, введіть дійсну улюблену адресу електронної пошти!',
    },
    error: 'Помилка',
    someThingWentWrong: 'Вибачте, щось пішло не так! можеш спробувати ще раз',
    hashtag: '#TeilenIstDasNeueHaben',
    language: 'Мова',
    or: 'або',
    betaInfo: 'Версія, випущена для тестування. Втрата даних під час фази не є рідкістю.',
    article: 'Продукт',
    articles: 'Продукт',
    loading: 'Завантаження',
    pleaseWait: 'Будь-ласка, зачекайте',
    voucher: 'Ваучер',
    apply: 'Застосувати',
};
