import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Asset, Group } from '@api/generated-types';
import { NavigationService } from '@core/providers/navigation/navigation.service';
import { StateService } from '@core/providers/state/state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { canJoinCommunity } from '../../../../community/util/can-join';

export type GroupComponentType = Pick<
    Group,
    'id' | 'slug' | 'name' | 'description' | 'members' | 'isMember' | 'visibility' | 'requirementFilters'
> & { featuredAsset?: Pick<Asset, 'preview'> };

type GroupActionState = 'CAN_JOIN' | 'CAN_VIEW';

@Component({
    selector: 'fainin-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityComponent {
    @Input() community: GroupComponentType;
    @Input() allowActions: boolean = false;
    @Output() join: EventEmitter<GroupComponentType> = new EventEmitter<GroupComponentType>();

    signedIn$: Observable<boolean>;
    constructor(
        private stateService: StateService,
        private navigationService: NavigationService,
        private router: Router,
    ) {
        this.signedIn$ = this.stateService.select(state => state.signedIn);
    }

    get actionState(): Observable<GroupActionState> {
        return this.signedIn$.pipe(
            map(signedIn => {
                if (canJoinCommunity(this.community)) {
                    return 'CAN_JOIN';
                }
                return 'CAN_VIEW';
            }),
        );
    }

    doSignIn() {
        if (this.community)
            this.navigationService.navigateBackToRoute = `/community/${this.community.slug}?action=join`;
        this.router.navigate(['auth/sign-in-landing']);
    }

    doJoinGroup(community: GroupComponentType) {
        this.join.emit(community);
    }
}
