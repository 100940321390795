<div class="nav-header-container hide-scrollbar">
    <a
        class="nav-bar-link"
        [routerLink]="'/account'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'account.menu.dashboard' | translate }}</a
    >
    <a
        class="nav-bar-link"
        [routerLink]="'/account/favorites'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'account.menu.favorites' | translate }}</a
    >
    <a
        onboarding-id="product-upload-menu-option"
        class="nav-bar-link"
        [routerLink]="'/account/product-edit'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'account.menu.uploadProduct' | translate }}</a
    >
    <a
        class="nav-bar-link"
        [routerLink]="'/account/chats'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'account.menu.chats' | translate }}</a
    >
    <a
        class="nav-bar-link"
        [routerLink]="'/account/products'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        {{ 'account.menu.products' | translate }}</a
    >
    <a
        onboarding-id="profile-menu-option"
        class="nav-bar-link"
        [routerLink]="'/account/my-profile'"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'account.menu.profile' | translate }}</a
    >
</div>
