export default {
    faininAcronym: 'Find Anything In Your Neighborhood',
    subscribe: 'Abonnieren',
    links: 'Links',
    readMore: 'Weiterlesen',
    settings: 'Einstellungen',
    newsletter: {
        consent:
            'Ja, zu regelmäßigen Mails von uns mit Infos, Tipps, Aktionen und spannenden Geschichten über fainin und die Sharing-Community',
        getReady: 'Sei bereit für fainin 2.0',
        subscribe: 'Abonniere unseren Newsletter!',
        subscribe2: 'Abonniere exklusive Neuigkeiten',
        subscribe3: 'Erhalte alle Neuigkeiten über die fainin-Family',
        subscribe4: 'Erhalte unsere Neuigkeiten',
        disclaimer: 'Mit Deiner Registrierung bestätigst Du unsere',
        placeholder: 'E-Mail Adresse',
        alreadySubscribed: 'Du hast fainin schon abonniert',
        success: {
            title: 'Yay! Anmeldung erfolgreich',
            text: 'Willkommen in der fainin Family!',
        },
        invalid: 'Bitte gib eine gültige Lieblings-Mail ein!',
    },
    error: 'Fehler',
    someThingWentWrong: 'Sorry, etwas ist schief gelaufen! Kannst Du es nochmal versuchen?',
    hashtag: '#TeilenIstDasNeueHaben',
    language: 'Sprache',
    or: 'oder',
    betaInfo:
        'Eine zu Testzwecken veröffentlichte Version. Der Verlust von Daten während der Phase ist nicht unüblich.',
    article: 'Artikel',
    articles: 'Artikel',
    loading: 'Ladend',
    pleaseWait: 'Bitte warten',
    voucher: 'Gutschein',
    apply: 'Anwenden',
};
