import dashboard from './dashboard';
import editProfile from './editProfile';
import productUpload from './productUpload';

export default {
    productUpload,
    editProfile,
    dashboard,
    pickupOf: 'Подборка {{name}}',
    returnOf: 'Возвращение {{name}}',
    addToCalendar: 'Добавить в календарь',
    uploadAProduct: 'Загрузить товар',
    defaultFeedbackComment: 'Аренда прошла отлично, рекомендую всем! #SharingIsTheNewOwning',
    defaultFeedback: [
        'The rental went perfectly, happy to do it again! #SharingIsTheNewOwning',
        'I am very satisfied with the rental of this item. The landlord was extremely friendly and the product was exactly as described. The communication was clear and the handover smooth. Highly recommended!',
        'Everything went perfectly! The item was in excellent condition and the landlord was very helpful and accommodating. Thanks for the great service!',
        'Outstanding experience! The landlord was on time and very professional. The rented product worked flawlessly and matched the description perfectly. Happy to rent again!',
        'Very pleasant rental! The contact with the landlord was uncomplicated and friendly. The item was clean and in perfect condition. Top!',
        'I can definitely recommend this landlord. Very reliable and accommodating. The item was like new and worked flawlessly. Great service!',
        'The rental process was super easy and the landlord was very flexible with the handover. The product was top notch and exceeded my expectations. Happy to rent again!',
        'Excellent condition of the item and very friendly landlord. Everything was explained in detail and the communication was great. Highly recommended!',
        'Wonderful experience! The item was exactly as described and the landlord was very patient and helpful. The process was simple and quick. I am very satisfied!',
        'Very professional and pleasant contact. The item was in perfect condition and the handover was timely and uncomplicated. Thanks for the great service!',
        'A fantastic rental experience! The landlord was super friendly and the item worked perfectly. I was completely satisfied with everything and will definitely rent here again.',
        'Totally uncomplicated and super nice! The item was in top shape and everything worked without any problems. Happy to rent again!',
        'Super satisfied! Really cool guy, the landlord. Everything went smoothly and the item was flawless. Top experience!',
        'Super easy and relaxed. The handover was quick and the item fully met my expectations. Went really well!',
        'All chill! Great contact and the item was just as promised. Really enjoyed it and would definitely rent again!',
        'Absolutely seamless! Cool contact and the item was awesome. Everything super easy and quick. Would recommend to anyone!',
    ],
    feedbackIconFilled: 'Feedback Icon - Filled',
    feedbackIconEmpty: 'Feedback Icon - Empty',
    feedbackStep1: 'Rate your rental from 1-5 boomerangs',
    feedbackStep2: 'How was the communication?',
    feedbackStep3: 'How would you rate the delivery or pickup?',
    feedbackStep4: {
        overall: 'Overall Rating Result',
        comment: 'Text (optional)',
        typeSomething: 'Type something ...',
    },
    rate: 'Rate',
    showReceipt: 'Показать квитанцию',
    ergoTransactions: 'До 15 000€ застрахованных операций в соответствии с условиями fainin.',
    approx: 'Approx.',
    time: 'Часы',
    requestAgain: 'Повторный запрос',
    popUpDisabled: 'Всплывающие окна отключены!',
    enablePopups:
        'Пожалуйста, включите всплывающие окна в настройках вашего браузера для оплаты через Paypal. Если вы не хотите включать всплывающие окна, вы можете заплатить дебетовой или кредитной картой или в приложении fainin.',
    noFavorites: 'В настоящее время у вас нет фаворитов.',
    searchNow: 'Ищите сейчас!',
    noProducts: 'В настоящее время у вас нет никаких предметов.',
    lendNow: 'Одолжите предметы сейчас!',
    moreFunWithListings: 'Фейнин веселее со списками!',
    rememberFavorites: 'Запомните объекты в избранном и возвращайтесь, чтобы забронировать аренду в ближайшее время.',
    rentOrUpload: 'Арендуйте или загрузите товар, чтобы начать работу!',
    noChatsYet: 'Пока нет чатов',
    createReqAndChat: 'Создайте запрос и общайтесь с людьми.',
    browse: 'Просмотреть',
    share: 'Делиться',
    shareProduct: 'Перегляньте цю пропозицію оренди сейфа на fainin:',
    shareCommunity: 'Look at this Community on fainin:',
    sumsubError: 'Неожиданная ошибка. Пожалуйста, попробуйте еще раз!',
    sumsubDelayError: 'Неожиданная ошибка! Это занимает больше времени, чем обычно. Пожалуйста, попробуйте еще раз!',
    rentalDuration: 'Продолжительность аренды',
    totalPayment: 'Общая сумма оплаты',
    totalPayout: 'Общая выплата',
    toRequest: 'Запросить',
    toOrder: 'К приказу',
    logout: 'Выход из системы',
    period: 'Период',
    uploadNewProduct: 'Загрузить новый продукт',
    requestList: {
        requester: 'Заявитель',
        product: 'Продукт',
        products: 'Продукты',
        days: 'Дни',
        total: 'Сумма',
        start: 'Старт',
        end: 'Конец',
        requested: 'Запрошено',
        viewDetails: 'Просмотр подробностей',
    },
    myProfile: {
        info: 'Информация',
        badges: 'Значки',
        reviews: 'рейтинги',
        onTotal: 'в целом',
        showMoreReviews: 'Показать больше отзывов',
        myUploadLocation: 'Мое местоположение загрузки',
    },
    statusType: {
        PENDING: 'Запрос отправлен',
        MYPENDING: 'Запрос получен',
        CANCELLED: 'Отменено',
        DECLINED: 'Отклонено',
        ACCEPTED: 'Принято',
        EXPIRED: 'Просроченный',
    },
    lender: 'Арендодатель',
    noData: 'Нет данных',
    createdAt: 'Создано на:',
    chatWith: 'Пообщаться с',
    your: 'Ваш',
    product: 'Продукт',
    rent: 'Аренда',
    lend: 'Сдать в аренду',
    greatNews: 'Отличные новости! Кто-то заинтересован в вашем продукте. Взгляните.',
    showRequest: 'Показать запрос',
    downloadNoteOnRequest: 'Чтобы обработать ваш запрос, пожалуйста, загрузите наше приложение.',
    requestAccepted: 'Запрос принят',
    downloadApp: 'Загрузить приложение',
    requestReceived: 'Запрос получен',
    requestDeclined: 'Запрос отклонен',
    requestCanceled: 'Запрос отменен',
    canceled: 'Отменено',
    requestSent: 'Запрос отправлен',
    requestSentMsg: 'Для завершения транзакции необходимо войти и оплатить через приложение fainin.',
    request: {
        rentalDetails: 'Детали аренды',
        pickup: 'Забрать',
        return: 'Возврат',
        rentalPrice: 'Цена аренды',
        perDay: 'Цена за день',
        perHour: 'Price per Hour',
        daysTotal: 'Общее количество дней',
        hoursTotal: 'Total Hours',
        fees: 'Плата за услуги',
        total: 'Всего',
        sendMsg: 'В чат',
        decline: 'Отклонить',
        accept: 'Принять',
        cancel: 'Отмена',
        noRentalRequests: 'У вас еще нет запросов на аренду!',
        findProduct: 'Найдите свой любимый продукт',
        noLendRequests: 'У вас еще нет заявок на ваши продукты!',
        tryUploading: 'Загрузить интересный продукт',
        upload: 'Загрузка',
        subTotal: 'Subtotal',
    },
    order: {
        rentalEnded: 'Аренда закончилась',
        pay: 'Оплатите',
        waitPayment: 'Ждать оплаты',
        status: 'Статус',
        filterOptions: {
            requested: 'Запрошено',
            active: 'Сделать',
            pending: 'Текущий',
            processed: 'подождите',
            completed: 'Закончено',
            rented: 'Одолженно',
            paymentrequired: 'Должен быть оплачен',
            ended: 'Закончился',
            cancelled: 'Отменено',
            paid: 'Оплачено',
        },
        rentalOngoing: 'Аренда в процессе',
        endRental: 'Конец аренды',
        noRentedOrder: 'Зарабатывайте деньги на аренде',
        uploadNow: 'Помещай товары сейчас!',
        giveFeedback: 'Напиши отзыв!',
        confirmDelivery: 'Подтвердить доставку',
        confirmReturn: 'Подтвердить возврат',
        awaitRefund: 'Ожидайте возврата денег',
        discount: 'Скидка',
    },
    orderTimeline: {
        completed: 'Завершено',
        feedback: 'Обратная связь',
        itemReturned: 'Товар возвращен',
        uploadPhotosReturn: 'Загрузите фотографии сейчас и заполните возврат.',
        return: 'Возвращено',
        addedComment: 'Комментарий добавлен! Подтвердите передачу для сохранения.',
        productWithRenter: 'Вещь находится у арендатора.',
        deliveryDone: 'Сбор завершен',
        rentalAwaits: 'Аренда ожидает',
        rentalAwaitsRenter: 'Арендатор начинает аренду с загрузки фотографий',
        paymentSuccess: 'Платеж получен',
        cancelled: 'Отменено',
        requestAccepted: 'Запрос принят',
        verify: 'Верифицировать',
        verifyNow: 'Верифицировать сейча',
        verificationStarted: 'Верификация завершена',
        verificationInProcess: 'Верификация в процессе',
        verificationDone: 'Верификация завершена',
        rentalStarted: 'Аренда начинается',
        productWithYou: 'Товар находится у вас.',
        returnAwaitsOwner: 'Арендатор завершает аренду, загружая фотографии',
        rentalOngoing: 'Аренда в процессе',
        refund: 'Возврат',
    },
    orderRequestStateText: {
        requestReceivedEditNow: 'Запрос получен: Редактируйте сейчас!',
        requestReceivedProfile: 'Запрос получен: Полный профиль',
        requestAcceptedWait: 'Запрос принят: Жду оплаты',
        rentalAwaits: 'Аренда ожидается: Организовать передачу?',
        ongoingRental: 'Аренда в процессе: обсудить возврат?',
        returnStarted: 'Возврат начинается:',
        uploadPhotosReturn: 'Загрузите фотографии и заполните возврат',
        review: 'Обеспечить обратную связь',
        awaitReview: 'Awaiting feedback',
        requestCancelledRenter: 'Запрос отменен: Связаться с арендатором',
        requestDeclinedRenter: 'Запрос отклонен: Связаться с арендатором',
        requestSentWait: 'Запрошенный элемент: Ожидание ответа',
        requestAcceptedVerify: 'Запрос принят. Верифицировать сейчас!',
        requestAcceptedVerifyPending: 'Запрос принят. Проверка продолжается',
        requestAcceptedPay: 'Запрос принят. Оплатите сейчас!',
        rentalStarted: 'Аренда начинается:',
        uploadPhotosRent: 'Загрузить фотографии и начать аренду',
        requestCancelledLender: 'Запрос отменен: Свяжитесь с арендодателем',
        requestDeclinedLender: 'Запрос отклонен: Свяжитесь с арендодателем',
        awaitRefundOwner: 'Арендатор запросил возврат денег! Свяжитесь с арендатором',
        awaitRefundRenter: 'Арендатор запросил возврат денег! Связаться с арендодателем',
    },
    verifyAccount: 'Верифицировать аккаунт',
    return: 'Возврат',
    uploadPhotos: 'Загружать фотографии',
    getRefund: 'Запросить возврат',
    chat: 'Чат',
    renter: 'Арендатор',
    requestedFrom: 'Запрашивающее лицо',
    payNow: 'Оплатить сейчас',
    congratsNiceChoice: 'Поздравляем, вы сделали отличный выбор. Теперь просто оплатите.',
    fullDownloadApp: 'Для полноценного пользовательского опыта вам все еще необходимо приложение fainin.',
    back: 'Назад',
    payment: {
        paymentSucceeded: 'Ваш платеж прошел успешно!',
        paymentProcessing: 'Ваш платеж обрабатывается.',
        paymentFailed: 'Ваш платеж не прошел успешно, пожалуйста, попробуйте еще раз.',
        somethingWrong: 'Что-то пошло не так.',
        unableAuthenticate: 'Мы не смогли проверить подлинность вашего метода оплаты. Пожалуйста, попробуйте еще раз.',
        cardError: 'На вашей карте недостаточно средств.',
        validationError: 'Ошибка валидации',
    },
    comment: 'Комментарий',
    cancel: 'Отмена',
    refundReason: 'Причина возврата*',
    completeProfile: 'Полный профиль',
    checkIban: 'Ваш IBAN, почтовый индекс и город должны быть правильными',
    edit: 'Редактировать профиль',
    editOnly: 'Редактировать',
    rented: 'Арендованный',
    lent: 'Арендованный',
    welcome: 'Добро пожаловать',
    rentals: 'Взято напрокат',
    badges: 'Значки',
    messages: 'Сообщения',
    menu: {
        discover: 'Откройте для себя',
        dashboard: 'Приборная панель',
        products: 'Мои товары',
        orders: 'Одолжить',
        chats: 'Чаты',
        profile: 'Профиль',
        uploadProduct: 'Загрузить товар',
        addressBook: 'Адрессы',
        help: 'Помощь',
        favorites: 'Избранное',
    },
    blockUser: 'Блок {{userName}}',
    noMsgs: 'Пока нет сообщений',
    sendAMsg: 'Отправить сообщение для {{userName}} ниже',
    messageplaceholder: 'Сообщение',
    onboarding: {
        step1: {
            title: 'Загрузка товара',
            info: 'Вы можете легко начать использовать fainin, загрузив товары за 2 минуты.',
            buttonName: 'Следующий',
        },
        step2: {
            title: 'Начните общаться и запрашивать',
            info: 'Запросите предмет, чтобы арендовать его у соседей. После этого вы можете пообщаться с владельцем.',
            buttonName: 'Следующий',
        },
        step3: {
            title: 'Заполните свой профиль',
            info: 'Для того чтобы получить полный опыт использования fainin, вам необходимо заполнить свой профиль.',
            buttonName: 'Начало',
        },
        skip: {
            title: 'Пропустить вступление?',
            info: 'Вы действительно хотите пропустить вступление и начать делиться информацией прямо сейчас?',
            buttonName: 'Скип',
        },
    },
};
