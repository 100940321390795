export default {
    topCategories: 'Основні категорії',
    register: 'Реєстрація',
    login: 'авторизувати',
    forgotPassword: 'Забули пароль',
    resetPassword: 'Скинути пароль',
    email: 'Електронна пошта',
    downloadIOSApp: 'Завантажте додаток iOS',
    downloadAndroidApp: 'Завантажте додаток Android App',
    ctaSignup: 'CTA Реєстрація зараз',
    cookieSettingsButton: 'Кнопка налаштувань файлів cookie',
    signupButton: 'Кнопка реєстрації',
    desktopLoginButton: 'Налаштуйте згоду на використання файлів cookie',
    viewAll: 'Подивитись все',
    breadcrumb: 'Навігація по підменю',
    mobileMenuToggle: 'Перемикач меню',
    location: 'Місцезнаходження',
    cookieConsent: {
        customizeCookieConsent: 'Налаштуйте згоду на використання файлів cookie',
        acceptCookieConsent: 'Прийняти згоду на використання файлів cookie',
        backClicked: 'Клацнув назад',
    },
    closeActionSheet: 'закрити аркуш дій',
    remove: 'Видалити',
    add: 'Додати',
    close: 'Закрити',
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    dashboard: {
        downloadAndroidApp: 'Завантажити Android App',
        downloadIOSApp: 'Завантажити iOS App',
    },
    searchSheet: {
        loadMore: 'Завантажити ще',
    },
    newsLetterSignup: {
        email: 'електронна пошта',
    },
    myProfile: {
        showMoreReviews: 'Показати більше оглядуs',
    },
    productUpload: {
        currentLocation: 'Поточне місцезнаходження',
        save: 'Зберегти',
        deleteLocation: 'Видалити місцезнаходження',
        addLocation: 'Додати місцезнаходження',
        uploadLocation: 'Місце завантаження',
        getCurrentLocationButton: 'Кнопка "Отримати поточне місцезнаходження".',
    },
    voucher: {
        code: 'Код ваучера',
        submit: 'Активувати ваучер',
    },
    requirements: 'Requirements',
    showMore: 'Show more',
};
