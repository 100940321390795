/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

import { untilDestroyed } from '@core/utils/until-destroyed';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger level property to disable logs.
 */
export const enum LogLevel {
    Off = 0,
    Error = 1,
    Warning = 2,
    Info = 3,
    Debug = 4,
}

export function levelToName(level: LogLevel) {
    switch (level) {
        case LogLevel.Off:
            return 'Off';
        case LogLevel.Error:
            return 'Error';
        case LogLevel.Warning:
            return 'Warning';
        case LogLevel.Info:
            return 'Info';
        case LogLevel.Debug:
            return 'Debug';
        default:
            return 'Undefined';
    }
}

/**
 * Log output handler function.
 */
export type LogOutput = (source: string | undefined, level: LogLevel, style: string, ...objects: any[]) => void;

export type LogItem = {
    source: string | undefined;
    level: string;
    style: string;
    objects: any[];
};

export class Logger {
    /**
     * Current logging level.
     * Set it to LogLevel.Off to disable logs completely.
     */
    static level = environment.logLevel ?? LogLevel.Warning;

    /**
     * Additional log outputs.
     */
    static outputs: LogOutput[] = [];

    constructor(private source?: string, private color?: string) {
        if (!this.color) {
            this.color = !!this.source ? '#' + this.intToRGB(this.hashCode(this.source)) : 'yellow';
        }
    }

    static disable() {
        this.setLogLevel(LogLevel.Off);
    }

    /**
     * Sets logging level (default to LogLevel.Warning).
     * @param level
     */
    static setLogLevel(level: LogLevel = LogLevel.Warning) {
        Logger.level = level;
    }

    /**
     * Logs messages or objects  with the debug level.
     * Works the same as console.log().
     */
    debug(...objects: any[]) {
        this.log(console.log, LogLevel.Debug, objects);
    }

    /**
     * Logs messages or objects  with the info level.
     * Works the same as console.log().
     */
    info(...objects: any[]) {
        this.log(console.info, LogLevel.Info, objects);
    }

    /**
     * Logs messages or objects  with the warning level.
     * Works the same as console.log().
     */
    warn(...objects: any[]) {
        this.log(console.warn, LogLevel.Warning, objects);
    }

    /**
     * Logs messages or objects  with the error level.
     * Works the same as console.log().
     */
    error(...objects: any[]) {
        this.log(console.error, LogLevel.Error, objects);
    }

    private log(func: (...args: any[]) => void, level: LogLevel, objects: any[]) {
        if (level <= Logger.level) {
            const log = this.source ? ['[ %c' + this.source, this.css(), ']'].concat(objects) : objects;
            func.apply(console, log);
            Logger.outputs.forEach(output => output.apply(output, [this.source, level, this.css(), ...objects]));
        }
    }

    private css() {
        return `color: ${this.color};`;
    }

    private hashCode(str: string) {
        // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // eslint-disable-next-line no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    private intToRGB(i: number) {
        // eslint-disable-next-line no-bitwise
        const c = (i & 0x00ffffff).toString(16).toUpperCase();

        return '00000'.substring(0, 6 - c.length) + c;
    }

    /**
     * Prints debug lines for an observable
     * @param name of the observable
     * @param observable
     * @param instance component instance to unsubscribe debugging
     */
    debugObservable(name: string, observable: Observable<any>, instance: any) {
        observable.pipe(untilDestroyed(instance)).subscribe(value => this.debug(`Observe "${name}"`, value));
    }
}
