import { ApolloQueryResult, NetworkStatus, OperationVariables } from '@apollo/client/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';

/**
 * @description
 * This class wraps the Apollo Angular QueryRef object and exposes some getters
 * for convenience.
 *
 * @docsCategory providers
 * @docsPage DataService
 */
export class QueryResult<T, V extends OperationVariables = Record<string, any>> {
    constructor(private queryRef: QueryRef<T, V>, private apollo: Apollo) {
        this.valueChanges = queryRef.valueChanges;
    }

    completed$ = new Subject();
    private valueChanges: Observable<ApolloQueryResult<T>>;

    /**
     * @description
     * Returns an Observable which emits a single result and then completes.
     */
    get single$(): Observable<T> {
        return this.valueChanges.pipe(
            filter(result => result.networkStatus === NetworkStatus.ready),
            take(1),
            map(result => result.data),
            finalize(() => {
                this.completed$.complete();
            }),
        );
    }

    /**
     * @description
     * Returns an Observable which emits until unsubscribed.
     */
    get stream$(): Observable<T> {
        return this.valueChanges.pipe(
            filter(result => result.networkStatus === NetworkStatus.ready),
            map(result => result.data),
            finalize(() => {
                this.completed$.complete();
            }),
        );
    }

    get ref(): QueryRef<T, V> {
        return this.queryRef;
    }

    /**
     * @description
     * Returns a single-result Observable after applying the map function.
     */
    mapSingle<R>(mapFn: (item: T) => R): Observable<R> {
        return this.single$.pipe(map(mapFn));
    }

    /**
     * @description
     * Returns a multiple-result Observable after applying the map function.
     */
    mapStream<R>(mapFn: (item: T) => R): Observable<R> {
        return this.stream$.pipe(map(mapFn));
    }
}
