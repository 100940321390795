import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    Renderer2,
} from '@angular/core';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { fromEvent } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';

@Component({
    selector: 'fainin-header-nav',
    template: ` <div class="nav">
        <ng-content></ng-content>
    </div>`,

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderNavComponent {}

@Component({
    selector: 'fainin-layout-header',
    templateUrl: `./layout-header.component.html`,
    styleUrls: ['./layout-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderComponent implements AfterViewInit, OnInit, OnDestroy {
    @HostBinding('class.scrolled')
    scrolled = false;

    @Input()
    pinned = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private stateService: StateService,
    ) {}

    ngOnInit(): void {
        this.scrolled = this.pinned;
        if (isPlatformBrowser(this.platformId)) {
            this.stateService
                .select(state => state.hideHeader)
                .pipe(untilDestroyed(this))
                .subscribe(value => {
                    this.pinned = value;
                    if (window.scrollY <= this.elementRef.nativeElement.offsetHeight) {
                        this.setFloating(false);
                    }
                });
        }
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.setUpScrollHandler(window);
        }
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }

    private setUpScrollHandler(_window: Window) {
        if (_window.scrollY <= this.elementRef.nativeElement.offsetHeight) {
            this.setFloating(false);
        }

        fromEvent(_window, 'scroll')
            .pipe(
                untilDestroyed(this),
                map(() => _window.scrollY),
                bufferTime(250),
                filter(val => 1 < val.length),
                map(val => val[val.length - 1] - val[0]),
            )
            .subscribe(diff => {
                const floatingThreshold = this.elementRef.nativeElement.offsetHeight;

                if (_window.scrollY > floatingThreshold) {
                    this.setFloating(true);
                } else {
                    this.setFloating(false);
                }
            });
    }

    private setFloating(isFloating: boolean) {
        this.scrolled = this.pinned || isFloating;
    }
}
