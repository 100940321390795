<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">{{ 'navigation.press' | translate }}</h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">{{ 'content.home.sharingIsOwning' | translate }}</h3>
    </div>
</div>

<div class="container my-5">
    <div class="press-section">
        <h2>Fainin GmbH</h2>
        <p>Mühlenkamp 4, 22303 Hamburg, Deutschland</p>
        <p>E-mail: <a href="mailto:contact@fainin.com">contact@fainin.com</a></p>
    </div>

    <div class="press-section">
        <h3>Assets</h3>
        <p>
            <a
                target="_blank"
                aria-label="Google Drive Link"
                href="https://drive.google.com/drive/folders/1H0RJe3Ta5zyRZJIHPIhdL1JY8IHJXggM"
                >Pressekit herunterladen</a
            >
        </p>
    </div>
</div>
