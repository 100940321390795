import { gql } from 'apollo-angular';

export const GET_SUBCATEGORIES = gql`
    query GetSubcategories {
        subcategories {
            name
            id
            code
        }
    }
`;
