import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { clearCookie, readCookie, writeCookie } from '../helpers/cookie.helper';
import {
    UNIVERSAL_COOKIE_CONSENT_OPTIONS,
    UniversalCookieConsentOptions,
} from '../models/universal-cookie-consent-options.model';
import { UniversalCookieConsentViewState } from '../models/universal-cookie-consent-view-state.model';

const UNIVERSAL_COOKIE_CONSENT_CONSENTS_KEY = 'consents';

/** @dynamic */
@Injectable()
export class UniversalCookieConsentService {
    /**
     * The current view state
     */
    private viewState$: BehaviorSubject<UniversalCookieConsentViewState> =
        new BehaviorSubject<UniversalCookieConsentViewState>(UniversalCookieConsentViewState.CLOSED);

    /**
     * The current options
     */
    private options$: BehaviorSubject<UniversalCookieConsentOptions | null> =
        new BehaviorSubject<UniversalCookieConsentOptions | null>(null);

    /**
     * The currently granted consents
     */
    private grantedConsents$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);

    private renderer: Renderer2;

    constructor(
        @Optional() @Inject(UNIVERSAL_COOKIE_CONSENT_OPTIONS) private defaultOptions: UniversalCookieConsentOptions,
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object,
        rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.options$.next(defaultOptions);

        if (isPlatformBrowser(platformId)) {
            combineLatest([this.viewState$, this.options$]).subscribe(([viewState, options]) => {
                if (options) {
                    this.updateBodyScroll(viewState, options);
                }
            });

            const grantedConsents = readCookie<string[]>(UNIVERSAL_COOKIE_CONSENT_CONSENTS_KEY);
            this.grantedConsents$.next(grantedConsents);

            this.grantedConsents$.pipe(skip(1)).subscribe(consents => this.onConsentsUpdated(consents));

            combineLatest([this.viewState$, this.options$, this.grantedConsents$]).subscribe(
                ([viewState, options, consents]) => {
                    if (options) {
                        this.handleAutoShow(viewState, options, consents);
                    }
                },
            );
        }
    }

    getGrantedConsents(): Observable<string[] | null> {
        return this.grantedConsents$.asObservable();
    }

    setGrantedConsents(consents: string[]) {
        this.grantedConsents$.next(consents);
    }

    /**
     * Get the current view state
     */
    getViewState(): Observable<UniversalCookieConsentViewState> {
        return this.viewState$.asObservable();
    }

    /**
     * Get the current options
     */
    getOptions(): Observable<UniversalCookieConsentOptions | null> {
        return this.options$.asObservable();
    }

    /**
     * Set the current view state
     * @param viewState
     */
    setViewState(viewState: UniversalCookieConsentViewState) {
        this.viewState$.next(viewState);
    }

    /**
     * Set the cookie consent options
     * @param options
     */
    setOptions(options: Partial<UniversalCookieConsentOptions>) {
        const defaultOptions = this.defaultOptions || {
            consentTypes: [],
        };
        this.options$.next({
            ...defaultOptions,
            ...options,
        });
    }

    /**
     * Show the cookie consent prompt
     * @param options The cookie consent options passed to the cookie consent prompt
     */
    show(options?: UniversalCookieConsentOptions): any;
    show(showAdvanced: boolean, options?: UniversalCookieConsentOptions): any;
    show(optionsOrShowAdvanced?: UniversalCookieConsentOptions | boolean, options?: UniversalCookieConsentOptions) {
        let showAdvanced = false;
        if (optionsOrShowAdvanced && typeof optionsOrShowAdvanced === 'boolean') {
            showAdvanced = optionsOrShowAdvanced;
        } else {
            options = optionsOrShowAdvanced as UniversalCookieConsentOptions;
        }
        if (options) {
            this.setOptions(options);
        }
        this.setViewState(
            showAdvanced ? UniversalCookieConsentViewState.ADVANCED : UniversalCookieConsentViewState.SIMPLE,
        );
    }

    /**
     * Called when the granted consents have changed
     * @param consents
     */
    protected onConsentsUpdated(consents: string[] | null) {
        if (consents !== null) {
            const cookieSettings = this.options$.value?.cookieSettings;
            writeCookie(UNIVERSAL_COOKIE_CONSENT_CONSENTS_KEY, consents, cookieSettings);
        } else {
            clearCookie(UNIVERSAL_COOKIE_CONSENT_CONSENTS_KEY);
        }
    }

    /**
     * Update the body element's overflow property as needed
     */
    protected updateBodyScroll(viewState: UniversalCookieConsentViewState, options: UniversalCookieConsentOptions) {
        const body = this.document.body;
        if (
            options.disableBodyScroll &&
            (viewState === UniversalCookieConsentViewState.SIMPLE ||
                viewState === UniversalCookieConsentViewState.ADVANCED)
        ) {
            this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
        } else {
            this.renderer.removeStyle(this.document.body, 'overflow');
        }
    }

    /**
     * Automatically show the consent modal if the autoShow options is set and the autoShow conditions are met
     * @param viewState
     * @param options
     * @param consents
     */
    protected handleAutoShow(
        viewState: UniversalCookieConsentViewState,
        options: UniversalCookieConsentOptions,
        consents: string[] | null,
    ) {
        if (options.autoShow && viewState === UniversalCookieConsentViewState.CLOSED && consents === null) {
            this.show();
        }
    }
}
