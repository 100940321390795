import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fainin-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarRatingComponent {
    @Input() textColor: string;

    constructor() {}
}
