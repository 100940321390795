<nz-input-group class="fainin-input" [nzSuffix]="suffixIconSearch">
    <input
        nz-input
        (focusin)="searching = true"
        type="text"
        id="search"
        name="search"
        pattern=".*\S.*"
        [formControl]="searchTermControl"
        (keydown.enter)="doSearch(searchTermControl.value)"
        placeholder="{{ 'core.searchbar' | translate }}"
        #search />
</nz-input-group>
<ng-template #suffixIconSearch>
    <img
        *ngIf="searching"
        (click)="doClear()"
        src="/assets/icon/figma/close.svg"
        width="24"
        height="24"
        alt="Clear search term icon" />
    <img *ngIf="!searching" src="/assets/icon/figma/search.svg" width="24" height="24" alt="Search icon" />
</ng-template>
