import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CustomReuseStrategy implements RouteReuseStrategy {
    private handlers: { [key: string]: DetachedRouteHandle } = {};

    /**
     * This will be called first while leaving from one route to another to
     * determine whether the component can be detached (stored instead of destroying it) for re-use.
     * @param route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route?.data['reuseRoute'] === true;
    }

    /**
     * When shouldDetach returned true means, this method will be called to store
     * the component instance.
     * @param route
     * @param handle
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        if (!handle) return;
        const key = this.getRouteKey(route);
        this.handlers[key] = handle;
    }

    /**
     * This method is used to determine whether the component that we are trying
     * to load should be re-used (instead of creating it newly) or not
     * @param route
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const key = this.getRouteKey(route);
        return !!this.handlers[key] && route?.data['reuseRoute'] === true;
    }

    /**
     * When shouldAttach returns true means, this method will be called,
     * and here we need to retrieve and return the component instance (DetachedRouteHandle)
     * which we have stored in the store() method for this route path
     * @param route
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const key = this.getRouteKey(route);
        return this.handlers[key] || null;
    }

    /**
     * This method is used to determine whether the route should be re-used or not.
     * @param future
     * @param curr
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Default reuse strategy
        return future?.routeConfig !== undefined && future?.routeConfig === curr?.routeConfig;
    }

    /**
     * Get the route key
     * @param route
     * @private
     */
    private getRouteKey(route: ActivatedRouteSnapshot): string {
        const path = route?.routeConfig?.path ?? '';
        const queryParams = route?.queryParams ? JSON.stringify(route.queryParams) : '';
        return `${path}?${queryParams}`;
    }
}
