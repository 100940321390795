export default {
    topCategories: 'Верхние категории',
    register: 'Зарегистрироваться',
    login: 'Вход в систему',
    forgotPassword: 'Забыли пароль',
    resetPassword: 'Сброс пароля',
    email: 'Почта',
    downloadIOSApp: 'Загрузить приложение для iOS',
    downloadAndroidApp: 'Загрузить приложение для Android',
    ctaSignup: 'Зарегистрировать CTA сейчас',
    cookieSettingsButton: 'Кнопка для настроек cookie',
    signupButton: 'Кнопка для регистрации',
    desktopLoginButton: 'Кнопка входа в систему рабочего стола',
    viewAll: 'Просмотреть все',
    breadcrumb: 'Навигация для подменю',
    mobileMenuToggle: 'Кнопка меню',
    location: 'Местонахождение',
    cookieConsent: {
        customizeCookieConsent: 'Настроить cookies',
        acceptCookieConsent: 'Принимать cookies',
        backClicked: 'Назад',
    },
    closeActionSheet: 'закрыть лист действий',
    remove: 'Удалить',
    add: 'Добавить',
    close: 'Закрыть',
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    dashboard: {
        downloadAndroidApp: 'Загрузить приложение для Android',
        downloadIOSApp: 'Загрузить приложение для iOS',
    },
    searchSheet: {
        loadMore: 'Загрузить больше',
    },
    newsLetterSignup: {
        email: 'почта',
    },
    myProfile: {
        showMoreReviews: 'Показать больше отзывов',
    },
    productUpload: {
        currentLocation: 'Текущее местоположение',
        save: 'Сохранить',
        deleteLocation: 'Удалить местоположение',
        addLocation: 'Добавить местоположение',
        uploadLocation: 'Местоположение продукта',
        getCurrentLocationButton: 'Получить текущее местоположение',
    },
    voucher: {
        code: 'Код ваучера',
        submit: 'Активировать ваучер',
    },
    requirements: 'Requirements',
    showMore: 'Show more',
};
