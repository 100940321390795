import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fainin-user-rating-bar',
    templateUrl: './user-rating-bar.component.html',
    styleUrls: ['./user-rating-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRatingBarComponent {
    @Input() rating: number;

    constructor() {}

    getFilledRatingBarsNumber() {
        if (this.rating >= 1) {
            return Math.trunc(this.rating);
        }
        return 0;
    }

    getEmptyRatingBarsNumber() {
        if (this.rating < 5) {
            const remainingBar = Math.trunc(this.rating);
            return Math.trunc(5 - remainingBar);
        }
        return 0;
    }
}
