import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

export interface PositionObject {
    x: number;
    y: number;
}

export interface SearchPagePositionObject {
    position: PositionObject;
    totalItems: number;
}

@Injectable({
    providedIn: 'root',
})
export class ScrollPositionService {
    /**
     * Map<route.url, scrollY>
     * @private
     */
    private positions = new Map<string, PositionObject | SearchPagePositionObject>();

    constructor(private router: Router) {
        /**
         * For retaining scroll positions for all pages
         */
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart && !this.isSearchPage) {
                this.setPosition(this.router.url, window.scrollX, window.scrollY);
            }
        });
    }

    public get isSearchPage(): boolean {
        return this.router.url.includes('/search?');
    }

    public setPosition(key: string, windowX: number, windowY: number, totalItems?: number) {
        const positionCoords: PositionObject = {
            x: windowX,
            y: windowY,
        };
        if (totalItems) {
            this.positions.set(key, { position: positionCoords, totalItems: totalItems });
            return;
        }
        this.positions.set(key, positionCoords);
    }

    public getPosition(key: string) {
        return this.positions.get(key);
    }
}
