import dashboard from './dashboard';
import editProfile from './editProfile';
import productUpload from './productUpload';

export default {
    productUpload,
    editProfile,
    dashboard,
    pickupOf: 'Вивезення {{name}}',
    returnOf: 'Повернення {{name}}',
    addToCalendar: 'Додати до календаря',
    uploadAProduct: 'Завантажити продукт',
    defaultFeedbackComment: 'Оренда пройшла чудово, рекомендую всім! #SharingIsTheNewOwning',
    defaultFeedback: [
        'The rental went perfectly, happy to do it again! #SharingIsTheNewOwning',
        'I am very satisfied with the rental of this item. The landlord was extremely friendly and the product was exactly as described. The communication was clear and the handover smooth. Highly recommended!',
        'Everything went perfectly! The item was in excellent condition and the landlord was very helpful and accommodating. Thanks for the great service!',
        'Outstanding experience! The landlord was on time and very professional. The rented product worked flawlessly and matched the description perfectly. Happy to rent again!',
        'Very pleasant rental! The contact with the landlord was uncomplicated and friendly. The item was clean and in perfect condition. Top!',
        'I can definitely recommend this landlord. Very reliable and accommodating. The item was like new and worked flawlessly. Great service!',
        'The rental process was super easy and the landlord was very flexible with the handover. The product was top notch and exceeded my expectations. Happy to rent again!',
        'Excellent condition of the item and very friendly landlord. Everything was explained in detail and the communication was great. Highly recommended!',
        'Wonderful experience! The item was exactly as described and the landlord was very patient and helpful. The process was simple and quick. I am very satisfied!',
        'Very professional and pleasant contact. The item was in perfect condition and the handover was timely and uncomplicated. Thanks for the great service!',
        'A fantastic rental experience! The landlord was super friendly and the item worked perfectly. I was completely satisfied with everything and will definitely rent here again.',
        'Totally uncomplicated and super nice! The item was in top shape and everything worked without any problems. Happy to rent again!',
        'Super satisfied! Really cool guy, the landlord. Everything went smoothly and the item was flawless. Top experience!',
        'Super easy and relaxed. The handover was quick and the item fully met my expectations. Went really well!',
        'All chill! Great contact and the item was just as promised. Really enjoyed it and would definitely rent again!',
        'Absolutely seamless! Cool contact and the item was awesome. Everything super easy and quick. Would recommend to anyone!',
    ],
    feedbackIconFilled: 'Feedback Icon - Filled',
    feedbackIconEmpty: 'Feedback Icon - Empty',
    feedbackStep1: 'Rate your rental from 1-5 boomerangs',
    feedbackStep2: 'How was the communication?',
    feedbackStep3: 'How would you rate the delivery or pickup?',
    feedbackStep4: {
        overall: 'Overall Rating Result',
        comment: 'Text (optional)',
        typeSomething: 'Type something ...',
    },
    rate: 'Rate',
    showReceipt: 'Показати квитанцію',
    ergoTransactions: 'До 15,000 євро застрахованих операцій згідно з умовами fainin.',
    approx: 'Approx.',
    time: "O'clock",
    requestAgain: 'Повторний запит',
    popUpDisabled: 'Спливаючі вікна відключені!',
    enablePopups:
        'Будь ласка, увімкніть спливаючі вікна в налаштуваннях вашого браузера для оплати через Paypal. Якщо ви не бажаєте вмикати спливаючі вікна, ви можете оплатити дебетовою або кредитною карткою або в додатку fainin.',
    noFavorites: 'Наразі у вас немає фаворитів.',
    searchNow: 'Шукайте негайно!',
    noProducts: 'Наразі у вас немає жодного товару.',
    lendNow: 'Позичайте речі вже зараз!',
    moreFunWithListings: 'З оголошеннями fainin веселіше!',
    rememberFavorites:
        "Запам'ятовуйте елементи, які вам сподобалися, і повертайтеся, щоб забронювати прокат найближчим часом.",
    rentOrUpload: 'Орендуйте або завантажте товар, щоб почати!',
    noChatsYet: 'Ще немає чатів',
    createReqAndChat: 'Створюйте запит і спілкуйтеся з людьми.',
    browse: 'Переглянути',
    share: 'Поділіться',
    shareProduct: 'Ознакомься с безопасным предложением на сайте fainin:',
    shareCommunity: 'Look at this Community on fainin:',
    sumsubError: 'Unexpected Error. Please try again!',
    rentalDuration: 'Тривалість прокату',
    totalPayment: 'Загальна сума платежу',
    totalPayout: 'Загальна виплата',
    sumsubDelayError: 'Неочікувана помилка! Це займає більше часу, ніж зазвичай. Будь ласка спробуйте ще раз!',
    toRequest: 'Вимагати',
    toOrder: 'До наказу',
    logout: 'Вийти',
    period: 'Українська',
    uploadNewProduct: 'Завантажте новий продукт',
    requestList: {
        requester: 'Запитувач',
        product: 'Продукт',
        products: 'Продукти',
        days: 'Дні',
        total: 'Всього',
        start: 'Старт',
        end: 'Кінець',
        requested: 'Запит',
        viewDetails: 'Докладніше',
    },
    myProfile: {
        info: 'Інформація',
        badges: 'Значки',
        reviews: 'Відгуки',
        onTotal: 'Всього',
        showMoreReviews: 'Показати більше оглядів',
        myUploadLocation: 'Моє місце завантаження',
    },
    statusType: {
        PENDING: 'Запит надіслано',
        MYPENDING: 'Ви отримали запит',
        CANCELLED: 'Скасовано',
        DECLINED: 'Відхилено',
        ACCEPTED: 'Прийнято',
        EXPIRED: 'Термін дії закінчився',
    },
    lender: 'Орендодавець',
    noData: 'Немає даних',
    chatWith: "Зв'язатися",
    createdAt: 'Створено в:',
    your: 'Твій',
    product: 'Продукт',
    rent: 'Оренда',
    lend: 'Орендувати',
    greatNews: 'Хороші новини! Хтось зацікавився вашим продуктом. Заглянь сюди.',
    showRequest: 'Показати запит',
    downloadNoteOnRequest: 'Щоб обробити ваші запити, завантажте наш додаток.',
    requestAccepted: 'Запит прийнято',
    downloadApp: 'Завантажити додаток',
    requestReceived: 'Отриманий запит',
    requestDeclined: 'Запит відхилено',
    requestCanceled: 'Запит скасовано',
    canceled: 'Скасовано',
    requestSent: 'Запит надіслано',
    requestSentMsg: 'Щоб завершити транзакцію, ви повинні зареєструватися та оплатити через додаток fainin.',
    request: {
        rentalDetails: 'Деталі оренди',
        pickup: 'Abholung',
        return: 'Повернення',
        rentalPrice: 'Ціна оренди',
        perDay: 'Ціна за добу',
        perHour: 'Цена за час',
        daysTotal: 'Днів усього',
        hoursTotal: 'Total Hours',
        fees: 'Плата за послуги',
        total: 'Загалом',
        sendMsg: 'До чату',
        decline: 'Відхилити',
        accept: 'Прийняти',
        cancel: 'Перервати',
        noRentalRequests: 'У вас ще немає запитів на оренду!',
        findProduct: 'Знайдіть свій улюблений продукт',
        noLendRequests: 'У вас ще немає запитів на вашу продукцію!',
        tryUploading: 'Завантажте цікавий продукт',
        upload: 'Завантажити',
        subTotal: 'Промежуточный итог',
    },
    order: {
        rentalEnded: 'Оренда закінчилася',
        pay: 'Платити',
        waitPayment: 'Очікування оплати',
        status: 'Статус',
        filterOptions: {
            requested: 'Запитано',
            active: 'Зробити',
            pending: 'Триває',
            processed: 'почекайте',
            completed: 'Закінчено',
            rented: 'запозичено',
            paymentrequired: 'Платити',
            ended: 'Завершено',
            cancelled: 'Скасовано',
            paid: 'Оплачено',
        },
        rentalOngoing: 'оренда триває',
        endRental: 'Завершити позику',
        noRentedOrder: 'Заробляйте гроші в кредит',
        uploadNow: 'Рекламуйте зараз!',
        giveFeedback: 'Дати оцінку',
        confirmDelivery: 'Підтвердити передачу',
        confirmReturn: 'Підтвердити повернення',
        awaitRefund: 'Очікування повернення коштів',
        discount: 'Знижка',
    },
    orderRequestStateText: {
        requestRecievedEditNow: 'Запит отримано: обробляйте зараз!',
        requestRecievedProfile: 'Запит отримано: повний профіль',
        requestAcceptedWait: 'Запит прийнято: Очікується оплата',
        rentalAwaits: 'Оренда в очікуванні: організувати передачу?',
        ongoingRental: 'Триває оренда: обговорювати повернення?',
        returnStarted: 'Повернення починається:',
        uploadPhotosReturn: 'Завантажте фотографії та повне повернення',
        review: 'Дати відгук',
        awaitReview: 'Awaiting feedback',
        requestCancelledRenter: "Запит скасовано: зв'язатися з орендарем",
        requestDeclinedRenter: 'Запит відхилено: зв’язатися з орендарем',
        requestSentWait: 'Продукт запитаний: Очікується відповідь',
        requestAcceptedVerify: 'Запит прийнято. Перевірити зараз!',
        requestAcceptedVerifyPending: 'Запит прийнято. Триває перевірка',
        requestAcceptedPay: 'Запит прийнято. Платити зараз!',
        rentalStarted: 'Початок оренди:',
        uploadPhotosRent: 'Завантажте фотографії та почніть оренду',
        requestCancelledLender: "Запит скасовано: зв'язатися з орендодавцем",
        requestDeclinedLender: "Запит відхилено: зв'язатися з орендодавцем",
        awaitRefundOwner: "Орендар попросив повернути гроші! Зв'язатися з орендарем",
        awaitRefundRenter: "Орендар попросив повернути гроші! Зв'яжіться з орендодавцем",
    },
    orderTimeline: {
        completed: 'Завершино',
        feedback: "Зворотній зв'язок",
        itemReturned: 'Товар повернуто',
        uploadPhotosReturn: 'Завантажте фотографії зараз і завершите повернення.',
        return: 'Повернення',
        addedComment: 'Коментар додано! Підтвердьте перенесення, щоб зберегти.',
        productWithRenter: 'Предмет знаходиться у орендаря.',
        deliveryDone: 'Колекція завершена',
        rentalAwaits: 'Оренда чекає',
        rentalAwaitsRenter: 'Кредитор починає оренду із завантаження фотографій',
        paymentSuccess: 'Отримано платіж',
        cancelled: 'Скасовано',
        requestAccepted: 'Запит прийнято',
        verify: 'Перевірити',
        verifyNow: 'Перевірити зараз',
        verificationStarted: 'Проведена перевірка',
        verificationInProcess: 'Триває перевірка',
        verificationDone: 'Перевірка завершена',
        rentalStarted: 'Починається оренда',
        productWithYou: 'Товар знаходиться у вас.',
        returnAwaitsOwner: 'Орендодавець завершує оренду, завантажуючи фотографії',
        rentalOngoing: 'Оренда працює',
        refund: 'Повернення коштів',
    },
    verifyAccount: 'Підтвердити обліковий запис',
    return: 'Рука назад',
    uploadPhotos: 'Завантажити фотографії',
    getRefund: 'Вимагати повернення коштів',
    chat: 'Чат',
    renter: 'Орендар',
    requestedFrom: 'Запитувач',
    payNow: 'Платити зараз',
    congratsNiceChoice: 'Вітаю.Чудовий вибір',
    fullDownloadApp: 'Вам все одно потрібен додаток Файнін для повноцінного користування.',
    back: 'Назад',
    payment: {
        paymentSucceeded: 'Оплата успішна!',
        paymentProcessing: 'Ваш платіж обробляється.',
        paymentFailed: 'Ваш платіж був невдалим, спробуйте ще раз.',
        somethingWrong: 'Пішло не так.',
        unableAuthenticate: 'Нам не вдалося підтвердити ваш спосіб оплати. Будь ласка спробуйте ще раз.',
        cardError: 'На вашій картці недостатньо коштів.',
        validationError: 'Помилка перевірки',
    },
    comment: 'Анотація',
    cancel: 'Перервати',
    refundReason: 'Причина повернення грошей*',
    completeProfile: 'Повний профіль',
    checkIban: 'Ваш IBAN, поштовий індекс і місто мають бути правильними',
    edit: 'Редагувати профіль',
    editOnly: 'Редагувати',
    rented: 'Взято в оренду',
    lent: 'Орендовано',
    welcome: 'Ласкаво просимо',
    rentals: 'Оренда',
    badges: 'Значки',
    messages: 'Повідомлення',
    menu: {
        discover: 'Відкрийте для себе',
        dashboard: 'Панель',
        products: 'Продукти',
        orders: 'Оренда',
        favorites: 'Вибране',
        chats: 'Чати',
        profile: 'Профіль',
        uploadProduct: 'Завантажити продукт',
        addressBook: 'Адресна книга',
        help: 'Допоможіть',
    },
    blockUser: 'Заблокувати {{userName}}',
    noMsgs: 'Ще немає повідомлень',
    sendAMsg: 'Надішліть повідомлення на {{userName}} нижче',
    messageplaceholder: 'Повідомлення',
    onboarding: {
        step1: {
            title: 'Завантаження товару',
            info: 'Ви можете легко почати користуватися fainin, завантаживши товари за 2 хвилини.',
            buttonName: 'Далі',
        },
        step2: {
            title: 'Почніть спілкуватися та запитувати',
            info: 'Запитайте предмет, щоб здати його в оренду у своїх сусідів. Після цього ви зможете поспілкуватися з власником.',
            buttonName: 'Далі',
        },
        step3: {
            title: 'Заповніть свій профіль',
            info: 'Для того, щоб мати повний досвід роботи з фаїніном Вам необхідно заповнити свій профіль.',
            buttonName: 'Старт',
        },
        skip: {
            title: 'Пропустити вступ?',
            info: 'Ви дійсно хочете пропустити вступ і почати ділитися зараз?',
            buttonName: 'Пропустити',
        },
    },
};
