import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Product } from '@api/generated-types';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'fainin-account-product-table',
    templateUrl: './account-product-table.component.html',
    styleUrls: ['./account-product-table.component.scss'],
})
export class AccountProductTableComponent implements OnInit, OnDestroy {
    @Input() products$: Observable<Product[]>;

    @Output() deleteProduct = new EventEmitter<Product>();
    @Output() sortChanged = new EventEmitter<{ field: string; direction: string | null }>();

    sortField: string;
    sortDirection: 'asc' | 'desc';
    products: Product[] = [];

    constructor(private translate: TranslateService) {}

    onSortChange(sortField: string, sortDirection: string): void {
        if (sortDirection != null) {
            sortDirection = sortDirection == 'ascend' ? 'ASC' : 'DESC';
        }
        this.sortChanged.emit({ field: sortField, direction: sortDirection });
    }

    readonly listOfColumn = [
        {
            title: 'account.productTable.name',
            field: 'name',
        },
        {
            title: 'account.productTable.createdAt',
            field: 'createdAt',
        },
        {
            title: 'account.productTable.description',
            field: 'description',
        },
        {
            title: 'account.productTable.purchasePrice',
            field: 'purchasePrice',
        },
    ];

    getDateOnly(timestamp: string) {
        let dateObj = new Date(timestamp);

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth() + 1;
        let day = dateObj.getDate();

        return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    }

    ngOnInit(): void {
        this.products$.pipe(untilDestroyed(this)).subscribe((products: Product[]) => {
            this.products = products;
        });
    }
    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // required for untilDestroyed pipe
    }
}
