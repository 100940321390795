import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'fainin-layout-navigation-header',
    templateUrl: './layout-navigation-header.component.html',
    styleUrls: ['./layout-navigation-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutNavigationHeaderComponent {
    constructor() {}
}
