export default {
    title: 'О нас',
    caption: {
        mission: 'Миссия',
        vision: 'Наше видение',
        what: 'Что такое fainin?',
        why: 'Зачем мы это делаем?',
        story: 'История fainin',
    },
    text: {
        mission:
            'fainin беспечивает безопасный обмен между друзьями и незнакомцами. <br> ' +
            'Благодаря мерам по укреплению доверия, кредитование и заимствования становятся обычным делом, и чтобы наши' +
            'массовое потребление снижалось. <br> <br> Убедите себя в миссии fainin и станьте частью семьи.',
        vision: 'Совместное использование - это новое обладание!',
        what: `fainin это ваше сообщество совместного использования для безопасного кредитования и заимствования.  Мы даём вам возможность в семье fainin
        давать займы без особых сложностей и зарабатывать на этом деньги. fainin на 100% безопасен и покрывает все этапы сделки.
        Design-For-Trust включая страхование, проверку и документацию, позволяет избежать
        негативный опыт в семье fainin.`,
        why: `Мы хотим сделать мир лучше с помощью fainin. По этой причине все выигрывают от участия, потому что благодаря
обмениваясь, мы создаем возможности друг для друга! #faininДляТебя`,
        us: `Наши имена Йоханн, Макс и Кевин. Мы хотим сделать мир лучше и предлагаем вам возможность присоединиться к нему вместе с fainin.`,
        usCaption: 'Это мы',
    },
    card: {
        sustainability: {
            title: 'Экологическая устойчивость',
            body: `Чем больше мы делимся, тем меньше нам нужно.`,
        },
        socialTrust: {
            title: 'Безопасность',
            body: `Обмен основан на доверии. Именно поэтому fainin Designed-For-Trust.`,
        },
        adventure: {
            title: 'Свобода',
            body: `Владение имеет свои преимущества, но аренда приносит опыт. Stop buying, start sharing.`,
        },
        winWin: {
            title: 'ВинВин',
            body: `Развивайте возможности для своих собратьев и зарабатывайте на аренде.`,
        },
        forYou: {
            title: 'Для всех',
            body: `100% безопасная аренда между друзьями и незнакомцами. Ты с нами?`,
        },
    },
    story: {
        '1': {
            title: 'Начало',
            text: `Семья fainin была основана в Берлине с видением того, как сделать мир немного более устойчивым.`,
        },
        '2': {
            title: 'Перезагрузка эпохи',
            text: `Мы - Йоханн, Кевин и Макс. С приложением fainin мы предлагаем вам оптимальную основу для аренды.

            `,
        },
        '3': {
            title: 'Позитивное влияние',
            text: `Семья fainin стремится сделать мир немного лучше. Если количество неиспользованных
            вещей, лежащих без дела уменьшится, требуется меньше ресурсов, что способствует устойчивому развитию.
            Сообщество объединяет людей и создает беспроигрышные ситуации в семье fainin.`,
        },
        '4': {
            title: 'Три принципа',
            text: `основан на трех основных принципах: <br>
            ВинВин, устойчивое развитие и социальная близость, <br>
             основа любой деятельности семьи fainin.`,
        },
        '5': {
            title: 'Ваша Sharing Family',
            text: `Каждый участник может внести свой вклад
           к устойчивой окружающей среде и стать частью замечательных перемен. Вместе мы можем спасти
            нашу любимую планету!`,
        },
        '6': {
            title: 'Бесконечные возможности',
            text: `Участвуйте в самом безопасном сообществе совместного использования. Каждый получает выгоду от
            беспроигрышной ситуации в семье fainin делает мир чуточку лучше.`,
        },
    },
    team: {
        johann: 'Я хочу создать для вас безграничные возможности',
        max: 'Я хочу сделать наш мир лучше',
        kevin: 'Я хочу дать арендаторам уверенность, в которой они нуждаются',
    },
};
