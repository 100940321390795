import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset, UploadLocation } from '@api/generated-types';
import { SeoDefault } from '@core/seo/seo.service';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface IMetaInfo {
    title: string;
    description: string;
    creator: string;
    image: string;
}

/**
 * Twitter Cards Markup
 * @see https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
 */
export interface ITwitterMeta {
    /**
     * Title of content (max 70 characters)
     * Alternative: og:title
     */
    title: string;
    /**
     *  Description of content (maximum 200 characters).
     *  Alternative: og:description
     */
    description: string;
    /** The @username of content creator */
    creator?: string;
    /**
     *  URL of image to use in the card. Images must be less than 5MB in size.
     *  JPG, PNG, WEBP and GIF formats are supported. Only the first frame of
     *  an animated GIF will be used. SVG is not supported.
     */
    image: string;
    /**
     * The @username of website. Either twitter:site or twitter:site:id is required.
     * Default: @fainin_sharing
     */
    site?: string;
    /**
     * The card type. One of: summary, summary_large_image, app, player
     */
    card?: 'summary' | 'summary_large_image' | 'app' | 'player';
}

export type MetaAssetType =
    | Pick<Asset, 'preview' | 'mimeType'>
    | Pick<Asset, 'source' | 'mimeType' | 'height' | 'width'>;
export interface IMetaPageInfo {
    localized?: boolean;
    lang?: string;
    title: string;
    description?: string;
    author?: string;
    image?: string;
    asset?: MetaAssetType;
    canonicalUrl?: string;
    noIndex?: boolean;
    keywords?: string;
    /**
     * Object representing custom property meta tags.
     * Generates <meta property="..."> tags
     */
    property?: { [key: string]: string | undefined };
    /**
     * Object representing custom name meta tags.
     * Generates <meta name="..."> tags
     */
    name?: { [key: string]: string | undefined };
}

export type LocalizedMetaFields = 'title' | 'description' | 'keywords';

export interface HasMetadata {
    pageMetadata: Observable<IMetaPageInfo | undefined>;
}

/** ############################################################
 *
 * TwitterMeta
 *
 * #############################################################*/

/**
 * Twitter Cards Markup
 * @link https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
 */
export class TwitterMeta {
    constructor(private meta: Meta, public defaultCard: ITwitterMeta) {}

    setDefaults(): TwitterMeta {
        this.meta.updateTag({
            name: 'twitter:card',
            content: 'summary_large_image',
        });
        this.meta.updateTag({
            name: 'twitter:site',
            content: '@fainin_sharing',
        });

        return this.setCreator(this.defaultCard.creator)
            .setDescription(this.defaultCard.description)
            .setTitle(this.defaultCard.title)
            .setImage(this.defaultCard.image);
    }

    setSite(site: string): TwitterMeta {
        this.meta.updateTag({
            name: 'twitter:site',
            content: site,
        });
        return this;
    }

    setCreator(creator?: string): TwitterMeta {
        if (creator) {
            if (this.meta.getTag(`property='twitter:creator'`)) {
                this.meta.updateTag({
                    name: 'twitter:creator',
                    content: creator,
                });
            } else {
                this.meta.addTag({
                    name: 'twitter:creator',
                    content: this.defaultCard.creator ?? SeoDefault.siteTwitterCardSite,
                });
            }
        } else {
            if (this.meta.getTag(`property='twitter:creator'`)) {
                this.meta.removeTag(`property='twitter:creator'`);
            }
        }
        return this;
    }

    setImage(image: string): TwitterMeta {
        this.meta.updateTag({
            name: 'twitter:image',
            content: image,
        });
        return this;
    }

    setTitle(title: string): TwitterMeta {
        this.meta.updateTag({
            name: 'twitter:title',
            content: title,
        });
        return this;
    }

    setDescription(description: string): TwitterMeta {
        this.meta.updateTag({
            name: 'twitter:description',
            content: description,
        });
        return this;
    }
}

/** ############################################################
 *
 * OG Meta
 *
 * #############################################################*/
export type OGType = 'website' | 'product' | 'video' | 'music' | 'article' | 'profile';

export class OGMeta {
    constructor(private meta: Meta, public defaultMeta: IMetaInfo) {}

    setDefaults(): OGMeta {
        return this.setDescription(this.defaultMeta.description)
            .setTitle(this.defaultMeta.title)
            .setImage(this.defaultMeta.image)
            .setType();
    }

    setImage(image: string): OGMeta {
        this.meta.updateTag({
            property: 'og:image',
            content: image,
        });
        return this;
    }

    setTitle(title: string): OGMeta {
        this.meta.updateTag({
            property: 'og:title',
            content: title,
        });
        return this;
    }

    setDescription(description: string): OGMeta {
        this.meta.updateTag({
            property: 'og:description',
            content: description,
        });
        return this;
    }

    setType(type: OGType = 'website'): OGMeta {
        this.meta.updateTag({
            property: 'og:type',
            content: type,
        });
        return this;
    }

    set(name: string, content: string): OGMeta {
        const property = `og:${name}`;
        try {
            if (this.meta.getTag(`property=${property}`)) {
                this.meta.updateTag({
                    property,
                    content,
                });
                return this;
            }
        } catch (e) {
            // Nothing to do here
        }
        this.meta.addTag({
            property,
            content,
        });
        return this;
    }
}

/** ############################################################
 *
 * GeoMeta
 *
 * #############################################################*/

export class GeoMeta {
    constructor(private meta: Meta) {}

    setGeoMetaFromUploadLocation(location: UploadLocation, productName: string) {}
}
