import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog } from '@core/providers/modal/modal-types';

@Component({
    selector: 'fainin-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements Dialog<boolean | string> {
    resolveWith: (result?: boolean | string) => void;
    withInput: boolean = false;
    title: string;
    msg: string;
    msgParams?: { [key: string]: string };
    label: string;
    input?: string;
    inputPlaceholder?: string;
    expectedInput?: string;
    customButtonText?: string;

    //button label
    confirmLabel: string = 'dialog.confirm';
    cancelLabel: string = 'dialog.cancel';

    confirm() {
        if (this.withInput && !!this.input) {
            if (this.expectedInput) this.resolveWith(this.input.trim() === this.expectedInput);
            else this.resolveWith(this.input.trim());
        } else {
            this.resolveWith(true);
        }
    }

    get confirmDisabled() {
        return this.withInput && this.expectedInput && this.input !== this.expectedInput;
    }
    close() {
        this.resolveWith(false);
    }
}
