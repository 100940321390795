export default {
    title: 'fainin | günstig Mieten & versichert Vermieten | Geld verdienen',
    keywords:
        'Sachen Mieten, Dinge vermieten, Online leihen, Gebraucht verleihen, Equipment leihen, Online Vermietung, Miet Versicherung, Studio mieten, Top 10 Vermietung, Kunstverleih Hamburg',
    description:
        'Online Geld verdienen beim sicheren Vermieten. Oder Tech-Produkte günstig ab 1 Tag mieten. Sicher, einfach & nachhaltig verleihen bei fainin',
    faq: {
        title: 'Frequently Asked Questions | Sicheres Vermieten',
        description:
            'Mit fainin etablieren wir sicheres Mieten und Vermieten. Möchtest Du wissen wie wir das machen? Logge Dich ein um personalisierten Support zu erhalten.',
        keywords:
            'Sachen Mieten, Dinge vermieten, Online leihen, Gebraucht verleihen, Was kann ich vermieten, welcher Mieterschutz, was kann ich leihen, Wo verleihe ich etwas, Wie miete versichern, Geldverdienen bei Vermietung',
    },
    about: {
        title: 'Über uns | Deine sichere Miet Community',
        description:
            'fainin bietet sicheres Vermieten und Verleihen in Deiner Sharing Community. Damit etablieren wir nachhaltigen Konsum und bieten jedem die Möglichkeit die Welt ein bisschen besser zu machen. ',
        keywords:
            'Sachen Mieten, Dinge vermieten, Online leihen, Gebraucht verleihen, warum mieten, Prinzipien, Welt verbessern, sicheres verleihen, Freiheit durch Teilen, vertrauenswürdiges vermieten',
    },
    blog: {
        title: 'Der fainin Blog: Wie man sicher teilen kann',
        description:
            'Lass Dich im fainin Blog von Neuigkeiten, Artikeln und Ressourcen von nachhaltig orientieren Mitmenschen und Freunden inspirieren.',
        keywords:
            'Sachen Mieten, Dinge vermieten, Online leihen, Gebraucht verleihen, Was kann ich vermieten, welcher Mieterschutz, was kann ich leihen, Wo verleihe ich etwas, Wie miete versichern, Geldverdienen bei Vermietung',
    },
    search: {
        title: 'fainin | Produkte in deiner Nachbarschaft',
        description:
            'Bei fainin findest Du alle Dinge zum abgesicherten Teilen in der Stadt. Nutze Alles was Du brauchst und profitiere von sicherem Mieten und Vermieten.',
        keywords:
            'Sachen Mieten, Dinge vermieten, Online leihen, Gebraucht verleihen, Equipment leihen, Online Vermietung, Studios mieten, versichertes Mieten, sicheres Leihen',
    },
    linktree: {
        title: 'fainin | Linktree',
        description:
            'Werde Mitglied in fainins Sharing Community. Gemeinsam machen wir Mieten, Vermieten und nachhaltigen Konsum so leicht wie nie zuvor.',
        keywords:
            'Sharing Community, Monatlich mieten, Sport Equipment, Sicher Vermieten, Soundboks leihen, Konsolen miete, Marken mieten',
    },
    contact: {
        title: 'Kontaktdaten von fainin',
        description:
            'Wie können wir dir helfen? Melde Dich bei uns über contact@fainin.com oder ruf uns über die +491753477656 an.',
    },
    privacy: {
        title: 'Datenschutz auf fainin',
        description:
            'Wir bewahren Aktivitätsdaten zur Optimierung & nach Bedarf zur Verhinderung von Betrug, Schäden oder Verlusten auf.',
    },
    imprint: {
        title: 'fainin | Impressum',
        description:
            'Impressum. Anbieter der Website: fainin GmbH, eine Gesellschaft mit beschränkter Haftung - Mühlenkamp 4, 22303 Hamburg.',
    },
    terms: {
        title: 'Nutzungsbedingungen auf fainin',
        description:
            'Als Vermieter:in bietet Dir fainin die perfekte Verleihapp, um Dinge, Erlebnisse und Dienstleistungen zu teilen.',
    },
    community: {
        title: 'fainin | In der Uni einfach mieten: flexibel und günstig',
        description:
            'In der Uni günstig mieten und leihen: Einfach und komplett versichert ✓ Zeitraum wählen und los! ✓ Flexibel ab 1 Tag ✓ Jetzt verleihen',
        keywords:
            'studenten, musikanlage mieten, musikanlage ausleihen, playstation leihen, konsole leihen, konsolen leihen, xbox leihen, drohne mieten, drohne ausleihen, drohne leihen, drohnen mieten, drohnen ausleihen, drohnen leihen',
    },
    press: {
        title: 'fainin | Presse',
        description: 'Logos, Bilder und Pressemitteilungen von fainin für Deine Berichterstattung.',
    },
};
