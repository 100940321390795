import { ASSET_FRAGMENT, FEEDBACK_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const GET_ACCOUNT_OVERVIEW = gql`
    query GetAccountOverview {
        activeCustomer {
            commissionPercentage
        }
    }
`;

export const GET_PROFILE_OVERVIEW = gql`
    query GetProfileOverview {
        activeProfile {
            id
            type
            name
            description
            verificationStatus
            userRelation
            links {
                link
                platform
            }
            featuredAsset {
                ...Asset
            }
            business {
                identifier
            }
            statistics {
                id
                averageRating
                feedbackCount
                lending
                renting
            }
            user {
                id
                customFields {
                    paypalEmail
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const GET_PROFILE_FEEDBACKS = gql`
    query GetProfileFeedbacks($profileId: ID!, $options: CustomerFeedbackListOptions) {
        feedbacks(profileId: $profileId, options: $options) {
            items {
                ...Feedback
            }
            totalItems
        }
    }
    ${FEEDBACK_FRAGMENT}
`;
