export default {
    whatsPhoneNumber: 'What´s your phone number?',
    howLogin: 'How do you want to login?',
    notRegisteredYet: 'Not registered yet?',
    appleAuthHasFailed: 'The sign-in with Apple has failed. Please try again or contact our support.',
    addMoreProducts: 'Add more products',
    gotoArticle: 'Go to Article',
    weLetYouKnow: "We'll let you know when someone sends you a rental request.",
    productUploaded: 'Your product has been uploaded.',
    congrats: 'Congratulations!',
    success: 'Success!',
    information: 'Information',
    email: 'Email',
    password: 'Password',
    passwordHint: 'Minimum 8 characters',
    passwordHint2: 'Must contain numbers, uppercase letters and a special character',
    confirmPassword: 'Confirm Password',
    rememberMe: 'Remember me',
    reset: 'Reset',
    setPassword: 'Set Password',
    login: 'Login',
    loginWithEmail: 'Login with your email',
    alreadyHaveAccount: 'Already have an account?',
    becomeRenter: 'List item',
    register: 'Signup',
    registerNow: 'Register Now',
    mr: 'Mr.',
    mrs: 'Mrs.',
    notSpecified: 'Divers',
    gender: 'Gender',
    birthday: 'Birthday',
    createAccount: 'Create account',
    noAccount: "Don't have an account?",
    hasAccount: 'You already have an account',
    agreedTo: 'I have read and agree to the',
    favoritesCannotBeSaved: 'Favorites cannot be saved permanently until you log in',
    version: 'Version',
    okay: 'Okay',
    filters: 'Filters',
    loadMore: 'Load More',
    noResults: 'No Results',
    of: 'of',
    imprint: 'Imprint',
    representedBy: 'Represented By',
    managingDirector: 'Managing Director',
    registerEntry: 'register entry',
    registeredIn: 'Registered in the commercial register',
    registrationCourt: 'Registration court: District Court of Hamburg',
    registrationNumber: 'Registration Number',
    taxId: 'Tax-ID',
    salesTaxId: 'Sales tax identification number according to §27a sales tax law',
    vatId: 'USt-ID',
    businessType: 'Business type',
    comingSoon: 'Coming Soon',
    type: {
        PERSONAL: 'Private Account',
        BUSINESS: 'Business Account',
    },
    forgotPassword: {
        title: 'Forgot Password',
        instructions: 'First enter your favorite email address. Then follow the instructions in the following email.',
        success: 'An email has been sent to you. Please check your inbox.',
    },
    resetPassword: {
        title: 'Choose Password',
        instructions: 'Choose a new fainin-password',
    },
    verify: {
        title: 'Welcome!',
        subTitle: 'You are finally a part of the fainin-Family! Stop buying, start sharing.',
        instructions: 'An email has been sent to you. You just need to verify it.',
        refreshToken: 'Please insert your email address to request a new verification token',
    },
    business: {
        title: 'Business',
        type: 'Company Type',
        name: 'Company Name/Display Name',
        registrationNumber: 'Registration Number/Share Symbol',
        vat: 'VAT',
    },
    error: {
        googleAuthFlowRestarted: 'Sign in flow restarted by the user',
        googleFailedToIssueCred: 'Failed to issue credentials for continuing with Google, please try again.',
        googleAuthCancelledByUser: 'Sign in with Google cancelled by the user.',
        googleOneTapOptedOut:
            'Please check if you have opted out of Google one Tap Sign In. If not then please try clearing the website cache and try again.',
        googleAuthSuppressedByUser: 'Sign in with Google suppressed by the user.',
        googleAuthCustomerSupport:
            'Something went wrong for signing in with Google, if this issue persists, please contact customer support.',
        googleAuthUnsupported:
            'Your browser does not support Continuing with Google, please try using an email or in another browser.',
        initGoogleAuthError: 'Failed to initialize Google Authentication. Please refresh and try again.',
        errorOccurred: 'An error occurred',
        phoneNumberError: 'Oops! Something is wrong with your phone number - did you add a country code to it?',
        errorDeletingLocation: 'Error deleting location',
        removeFailed: 'Removal Failed',
        startBeforeEnd: 'Starting date must be before the ending date!',
        noAddressProvided: 'No street address provided',
        failedToFindLocation: 'Failed to Find your Current Location',
        failedToCreateUploadLocation: 'Could not create upload location',
        failedToLocateAddress: 'Could not find the given address',
        failedToGeolocate: 'Failed to get geolocation data',
        invalidZip: 'Invalid Zip for City/Country',
        invalidCityName: 'Invalid City Name',
        invalidCountryName: 'Invalid Country Name',
        attention: 'Attention!',
        cantVerifyZipTitle: 'We cannot identify this zip code',
        cantVerifyZipBody: 'Are you sure you want to proceed with the current zip code?',
        zipCityDontMatchTitle: 'Attention!',
        zipCityDontMatchBody: 'Zip-Code and city do not match',
        startEndDates: 'Please select a starting and ending date (can be the same day)',
        startEndTimes: 'Please select pick-up and return times',
        invalidCredentials: 'Invalid credentials. Please try again.',
        recaptcha: 'ReCAPTCHA verification failed',
        email: 'Please enter your favorite e-mail address',
        password: 'Please enter a valid password',
        confirmPassword: 'Crap! The passwords are different',
        name: 'Please enter your name :)',
        firstName: 'Please enter your first name',
        lastName: 'Please enter your last name',
        gender: 'Please select',
        businessName: 'Please enter your business name',
        businessType: 'Please enter your business type',
        vat: 'Please enter your business vat',
        noToken: 'No token provided!',
        invalidToken: 'The token being used is invalid!',
        verificationTokenInvalid: 'Your verification token is invalid.',
        verificationTokenExpired: 'Your verification token is expired.',
        title: 'Title required (minimum 3 characters)',
        image: 'Upload at least one image',
        category: 'Choose a category please',
        subCategory: 'Choose a sub-category please',
        description: 'Write a nice description (min. 5 characters)',
        perDay: 'Minimum 1 €',
        perHour: 'Minimum 1 € and Maximum 2 Decimals',
        basePrice: 'Minimum 1 € and Maximum 2 Decimals',
        perWeek: 'Weekly price cannot be 0',
        perMonth: 'Monthly price cannot be 0',
        perWeek2: 'Weekly rates need to be higher than daily rates',
        perMonth2: 'Monthly rates need to be higher than weekly and daily rates',
        purchasePrice: 'Minimum 1 €',
        location: 'No locations found. Please add a new upload location.',
        locationName: 'Please enter location name',
        districtName: 'Please enter district name',
        zip: 'Zip required',
        cityName: 'Please enter city name',
        countryName: 'Please enter country name',
        agree: 'Must be accepted!',
        duplicateEmail: 'You have registered with this email before, try signing in!',
        nativeAuthenticationMethodsNotFound: "You've signed up with a social sign-up-method. Please try again there.",
        signedUpThroughEmail: 'There’s already an account. Try signing in with mail.',
        birthday: 'Birthday required',
        validEmail: 'Please enter a valid email address',
        checkInputs: 'Please check your inputs and try saving again',
        fillAllForBank: 'Please fill all fields required to add a bank account!',
        iban: 'IBAN is incorrect!',
        ibanMin: 'Max. 34 characters!',
        positionStack: 'Could not find the location entered, please try again with a valid address.',
        changeEmail: 'Please enter your password to change your email!',
        loadingError: 'Something went wrong, please check your network and refresh the page!',
        cantRemoveDefault: 'You cannot remove your default location',
    },
    migrationOptOut: {
        title: 'Cancel Migration',
        instructions:
            "If you start the opt-out process, we'll delete all your data. Thus, you can still sign-up to become a fainin member again.",
        button: 'Opt-Out!',
        buttonLabel: 'Migration Opt-Out Button',
    },
    migrationOptOutNotification: {
        onSuccessHeading: 'Migration Cancelled',
        onSuccessParagraph: "Sad you're leaving! Your data will be fully deleted.",
        onError: 'Something unexpected happened. Please get in touch with us via contact@fainin.com!',
    },
    migrationOptIn: {
        title: 'Finish Migration',
        instructions: 'Welcome back to the fainin Family! You may choose your new password now.',
        button: 'Choose Password',
        buttonLabel: 'Migration Opt-In Button',
    },
    migrationOptInNotification: {
        onError: 'Something unexpected happened. Please get in touch with us via contact@fainin.com!',
    },
    passwordResetNotification: {
        onSuccessHeading: 'Password has been reset.',
        onSuccessParagraph: 'Congratulations!',
    },
    checkoutConfirmation: {
        thankYouForOrder: 'Thank you for your order',
        orderCode: 'Order code',
        placed: 'Placed',
        createAccount: 'Create an account',
        createAccountMore: 'Create an account to enjoy faster checkouts, order tracking, order history and more',
        checkEmail: 'Great! Now check your email',
        toCompleteRegistration: 'to complete registration',
        pageNotFound: 'Page not found',
        payWithMethod: 'Pay with method',
        examplePaymentForm: 'This is an example payment form. Do not use real card details',
        expiry: 'Expiry',
        month: 'Month',
        year: 'Year',
        completeOrder: 'Complete Order',
        change: 'Change',
        selectAddress: 'Select Address',
        addNewAddress: 'Add new address',
        customerDetails: 'Customer Details',
        next: 'Next',
        shippingAddress: 'Shipping Address',
        sendToThisAddress: 'Send to this address',
        shippingMethod: 'Shipping Method',
        proceedToPayment: 'Proceed to payment',
        account: 'Account',
        shipping: 'Shipping',
        payment: 'Payment',
        complete: 'Complete',
        haveAccount: 'Already have an account',
        or: 'or',
        continueAsGuest: 'Continue as guest',
    },
    google: 'Continue with Google',
    apple: 'Continue with Apple',
    continueWithMail: 'Continue with Mail',
    termsAgreement: 'By using fainin, you agree to our Terms of Use and Privacy Policy.',
    termsAgreement1: 'By using fainin, you agree to our',
    termsAgreement2: 'Terms and Conditions',
    termsAgreement3: 'and',
    termsAgreement4: 'Privacy Policy',
    or: 'or',
    agreeToNotify: 'Please agree to the Terms and Conditions.',
    next: 'Next',
    whatsYourName: 'What is your name?',
    MandatoryField: 'Mandatory field',
};
