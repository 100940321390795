export default {
    firstName: 'Vorname',
    lastName: 'Nachname',
    fullName: 'Name',
    personalData: 'Persönliche Daten',
    address: 'Rechnungsadresse',
    contact: 'Kontakt',
    aboutYou: 'Über Dich',
    password: 'Passwort',
    passwordChange: 'Passwort ändern',
    bank: 'Bank Details',
    bankInfo: '(nicht öffentlich sichtbar)',
    save: 'Speichern',
    title: 'Profil bearbeiten',
    streetAndNumber: 'Straße + Hausnr.',
    plz: 'PLZ',
    city: 'Stadt',
    phoneNumber: 'Zum Beispiel: +4917534777777',
    email: 'Email Adresse',
    disclaimer: 'Um Deine neue E-Mail-Adresse zu bestätigen senden wir Dir eine Bestätigungsmail zu.',
    description: 'Beschreibung',
    oldPass: 'Altes Passwort',
    newPass: 'Neues Passwort',
    birthday: 'Geburtstag',
    saved: 'Daten gespeichert!',
    noUpdates: 'Keine neuen Änderungen!',
    emailChanged:
        'E-Mail geändert! Um den Vorgang abzuschließen, überprüfe bitte Deine neue E-Mail-Adresse, um die Änderungen zu bestätigen.',
    dd: 'TT',
    loading: 'Laden...',
    emailRequired: 'Email benötigt!',
};
