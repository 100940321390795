export default {
    noInvoices: 'No invoices were found.',
    noImageInForm: 'There is no image selected. Please add one',
    noImageUploaded: 'The image upload failed',
    imageSize: 'The size of a single file should not exceed {{size}}MB. Please try to use another image.',
    unknownSize: "Can't select this file as the size is unknown.",
    addAssetsToAlbum: 'Something went wrong while adding picture to the album',
    unexpectedError: 'An unexpected error occurred',
    noCameraPermission: 'No Permissions',
};
