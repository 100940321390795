import { UniversalCookieConsentOptions } from '@core/cookie-consent/models/universal-cookie-consent-options.model';

export const cookieConfig: UniversalCookieConsentOptions = {
    autoShow: true,
    cookieSettings: {
        expires: 365,
    },
    consentTypes: [
        {
            id: 'base',
            title: 'cookie.consent.base.title',
            description: 'cookie.consent.base.description',
            mandatory: true,
        },
        {
            id: 'analytics',
            title: 'cookie.consent.analytics.title',
            description: 'cookie.consent.analytics.description',
            default: true,
        },
        {
            id: 'ad_user_data',
            title: 'cookie.consent.ad_user_data.title',
            description: 'cookie.consent.ad_user_data.description',
        },
        {
            id: 'ad_personalization',
            title: 'cookie.consent.ad_personalization.title',
            description: 'cookie.consent.ad_personalization.description',
        },
        {
            id: 'ad_storage',
            title: 'cookie.consent.ad_storage.title',
            description: 'cookie.consent.ad_storage.description',
        },
    ],
    introText: 'cookie.message',
    customizeIntroText: 'cookie.intro',
    customizeText: 'cookie.button.customize',
    acceptText: 'cookie.button.accept',
    customizeHeadingText: 'cookie.heading',
    saveText: 'cookie.button.save',
    backText: 'cookie.button.back',
};
