import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JsonSDSchema, OrganizationSchema, WebsiteSchema } from './structured-data.types';

export const DEFAULT_WEBSITE_SCHEMA: WebsiteSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'fainin',
    url: environment.hostUrl,
    sameAs: [
        'https://www.instagram.com/fainin_sharing/',
        'https://www.linkedin.com/company/fainin/',
        'https://www.twitter.com/fainin_sharing',
        'https://www.tiktok.com/@fainin_sharing',
        'https://www.facebook.com/FaininSharing',
    ],
    potentialAction: {
        '@type': 'SearchAction',
        target: `${environment.hostUrl}/search?term={search_term_string}`,
        'query-input': 'required name=search_term_string',
    },
};

export const DEFAULT_ORG_SCHEMA: OrganizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'fainin',
    legalName: 'Fainin GmbH',
    url: 'https://fainin.com',
    logo: 'https://fainin.com/assets/logo_fainin_rgb_colored.svg',
    foundingDate: '2017',
    address: {
        '@type': 'PostalAddress',
        streetAddress: 'Mühlenkamp 4',
        addressLocality: 'Hamburg',
        postalCode: '22303',
        addressCountry: 'DE',
    },
    vatID: 'DE123456789',
    contactPoint: [
        {
            '@type': 'ContactPoint',
            email: 'contact@fainin.com',
            contactType: 'customer service',
        },
    ],
    sameAs: [
        'https://www.instagram.com/fainin_sharing/',
        'https://www.linkedin.com/company/fainin/',
        'https://www.twitter.com/fainin_sharing',
        'https://www.tiktok.com/@fainin_sharing',
        'https://www.facebook.com/FaininSharing',
    ],
};

@Injectable({
    providedIn: 'root',
})
export class StructuredDataService {
    private renderer2: Renderer2;
    private routerSubscription: Subscription;
    private toBeRemoved: string[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.routerSubscription = this.router.events
            .pipe(filter<any>(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const elements: any[] = [];
                this.toBeRemoved.forEach(className => {
                    elements.push(...Array.from(this._document.head.getElementsByClassName(className)));
                });
                elements.forEach(el => this.renderer2.removeChild(this._document.head, el));
            });
    }

    init(): void {}

    /**
     * Cancels the router subscription
     */
    public destroy() {}

    /**
     * Injects the default website schema and organization schema on the start page
     */
    public doInjectStartPageSchema() {
        this.doInjectJsonLD(DEFAULT_WEBSITE_SCHEMA);
        this.doInjectJsonLD(DEFAULT_ORG_SCHEMA);
    }

    public doInjectJsonLD(schema: JsonSDSchema, removeOnChange: boolean = false) {
        const classname = `structured-data-${schema['@type']}`.toLowerCase();

        let elem = this._document.head.getElementsByClassName(classname);
        if (elem.length) {
            // Already exists
            const script = elem[0] as HTMLScriptElement;
            script.text = JSON.stringify(schema, null, 2);
        } else {
            const script: HTMLScriptElement = this.renderer2.createElement('script');
            script.type = 'application/ld+json';
            script.text = `
${JSON.stringify(schema, null, 2)}
`;
            script.setAttribute('class', classname);
            this.renderer2.appendChild(this._document.head, script);
        }

        if (removeOnChange && !this.toBeRemoved.includes(classname)) this.toBeRemoved.push(classname);
    }
}
