export default {
    hero: {
        summer: {
            page1: {
                title: 'Літо тут!',
                subTitle: 'Прокат сапов, (надувних) човнів, каяків та інше на літо.',
            },
            page2: {
                title: 'Позичи все',
                subTitle: 'Беріть дрилі, лобзики, драбини напрокат, а не купуйте їх нові.',
            },
            page3: {
                title: 'Ігровий час 24/7',
                subTitle: 'Оренда ігрових консолей, контролерів, проекторів для ваших ігрових вечорів.',
            },
        },
        winter: {
            page1: {
                title: 'fainin',
                desktopTitle: 'fainin | Спільне використання - це нове володіння',
                subTitle: 'підробляти на стороні',
                text: 'Здавати в оренду речі, якими не користуєшся. До 15 000 євро застраховано та доступно для перевірених членів',
                button: 'Зареєструйтесь безкоштовно',
            },
            page2: {
                title: 'Заощаджуйте гроші',
                subTitle: 'Заощаджуйте гроші',
                text: 'Орендуйте недорого і заощаджуйте - будь то камера, дриль, SUP, саундбокс або дрон.',
                button: 'Орендуй зараз',
            },
            page3: {
                title: 'Ви студент?',
                subTitle: 'Ви студент?',
                text: 'Студенти беруть участь безкоштовно! Реєструйтеся та надсилайте докази на contact@fainin.com',
                button: 'Заощаджуйте',
            },
        },
        button: {
            becomeRenter: 'Стати орендарем',
            goToDashboard: 'Перейдіть на інформаційну панель',
        },
    },
    topSellers: {
        button: 'Дешева оренда житла',
    },
    news: {
        title: 'Відомий з...',
        toTheArticle: 'До статті',
    },
    trust: {
        title: 'Заробіток в Інтернеті',
        subTitle: 'Заощаджувати та заробляти легко! З універсальним пакетом безтурботності від Фаїни:',
        cards: {
            card1: {
                statement: 'Хвилюєтеся за свої пропозиції по оренді?',
                description:
                    'Не хвилюйтеся! Ergo страхує кожну оренду на суму до 15 000 євро. Завдяки нашому унікальному Design-For-Trust, кожна оренда через fainin повністю застрахована.',
            },
            card2: {
                statement: 'Шахраям не місце - всередині!',
                description:
                    'Кожен орендар повинен підтвердити свою особу у нашого верифікаційного партнера SumSub - у fainin ми не даємо шахраям жодного шансу.',
            },
            card3: {
                statement: 'Ви шукаєте щось конкретне?',
                description:
                    'Якщо бажана позиція відсутня у вашому регіоні - не проблема! За допомогою публічного запиту інші члени родини загиблого можуть побачити, що вам потрібно.',
            },
            card4: {
                statement: '... Ваш дизайн для довіри! ',
                description:
                    'Страхування, верифікація, розумні платежі та задокументований процес оренди роблять fainin найбезпечнішою спільнотою для спільного використання в Німеччині.',
            },
        },
        button: 'Надішліть товар зараз',
    },
    app: {
        reviews: {
            review1: {
                title: 'Ефективна та безпечна оренда.',
                description:
                    'Крістіан Вольф (Wolf-Record) оцифровує оренду обладнання для проведення заходів і заробляє за рахунок додаткового каналу доходу.',
            },
            review2: {
                title: 'Приємно ділитися більше.',
                description:
                    'Будучи студентом, Лайні подобається можливість заробити трохи кишенькових грошей під час оренди.',
            },
            review3: {
                title: 'Незабутні дні оренди',
                description: 'Йоганна любить колекціонувати незабутні моменти під час оренди',
            },
        },
    },
    campaign: {
        title: 'Топ тем',
        text: 'Вчіться у найефективніших орендарів - орендних компаній, торгових партнерів або власників малого бізнесу. У найбезпечнішій спільноті обміну інформацією виграють усі учасники.\n',
    },
    nearProducts: {
        title: 'Пропозиції оренди поруч з вами',
        subtitle: 'Зняти житло недорого в околицях',
        button: 'Вигідні пропозиції оренди',
    },
    blog: {
        title: 'Блог орендодавця',
        subtitle: 'Давайте поговоримо про економіку обміну',
        text: 'Вчіться у найефективніших орендарів - орендних компаній, торгових партнерів або власників малого бізнесу. У найбезпечнішій спільноті обміну інформацією виграють усі учасники.',
    },
    gif: {
        title: 'Безпечна оренда та лізинг',
        text: 'З Файніним оренда та спільне використання так само безпечні, як і оренда друзям чи знайомим по сусідству.',
        button: 'Зареєструватися зараз',
    },
    newsletter: {
        title: 'Не пропустіть жодної новини!',
        text: 'Підпишіться на нашу розсилку та отримуйте регулярні оновлення від родини Файніних.',
        button: 'Підписатися на Розсилку',
    },
    aboutUs:
        'Ми троє студентів, і нас звуть Йоганн, Макс і Кевін. Ми хочемо покращити світ разом із Файніним і дозволити всім учасникам отримати користь. Ми з нетерпінням чекаємо на ваше повідомлення.',
    yourSharingCommunity: 'Ваша спільна спільнота',
    getToKnowUs: 'Знайомтеся з нами',
    sharingIsOwning: '#SharingIsTheNewOwning',
    becomeFamily: "Станьте частиною сім'ї Файніних",
    title: 'fainin',
    readyForCommunity: 'Готові до найбезпечнішої спільноти обміну?',
};
