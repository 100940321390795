export default {
    uploadProduct: 'Artikel Hochladen',
    contact: 'Kontakt',
    search: 'Angebote finden',
    faq: 'FAQ',
    blog: 'Blog',
    home: 'Start',
    about: 'Über Uns',
    newsletter: 'Newsletter',
    rent: 'Buchungen',
    lend: 'Vermieten',
    press: 'Presse',
    career: 'Karriere',
    imprint: 'Impressum',
    agb: 'AGB',
    terms: 'Allgemeine Geschäftsbedingungen',
    privacy: 'Datenschutzerklärung',
    onboarding: 'Einführung',
    account: 'Konto',
    orders: 'Buchungen',
    requests: 'Anfragen',
    products: 'Produkte',
    address: 'Account Adressen',
    accountDetails: 'Konto Details',
    accountChange: 'Account Anpassungen',
    help: 'Hilfe',
    signIn: 'Anmeldung',
    register: 'Registrierung',
    verify: 'Verfizierung',
    resetPassword: 'Passwort zurücksetzen',
    forgottenPassword: 'Passwort vergessen',
    changeEmailAddress: 'Email-Adresse ändern',
    productUpload: 'Produkt-Upload',
    myProfile: 'Mein Profil',
    editProfile: 'Profil bearbeiten',
    favorites: 'Favoriten',
    WelcomeTofainin: 'Willkommen bei fainin',
    footer: {
        offers: 'Angebote',
        company: 'fainin',
        about: 'Über Uns',
        legal: 'Angebote',
        starText: '4,7 bei 155 Bewertungen',
        sepa: 'SEPA-Einzug',
    },
    communities: 'Communities',
    chats: 'Chats',
    chat: 'Chat',
    request: 'Anfrage',
    verification: 'Verifizierung',
    registerStep1: 'Registrierung Schritt 1',
    registerStep2: 'Registrierung Schritt 2',
    registerStep3: 'Registrierung Schritt 3',
    registerStep4: 'Registrierung Schritt 4',
    migrationOptOut: 'Migration Abbrechen',
    migrationOptIn: 'Migration Ausführen',
};
