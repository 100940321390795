export default {
    landingHero: {
        title: 'Завантаження товару',
        subTitle: 'Ділитися замість того, щоб володіти',
        yourProduct: 'Ваш бажаний продукт',
        upload: 'Завантаження товару',
    },
    requestAdditional: {
        title: 'Збільште свої шанси!',
        body: 'Ви маєте найкращі шанси отримати бажаний товар якнайшвидше, відправивши кілька запитів одночасно! Як щодо пошуку інших варіантів?',
        button: 'Знайти інші варіанти!',
    },
    categoryPages: {
        all: 'Усе',
        heroAlt: 'Ескіз',
    },
    favWhenLogin: 'Вибране можна зберегти назавжди лише після входу в систему',
    tapForProductsNearby: 'Натисніть тут, щоб переглянути товари поблизу',
    safestcommunity: 'Найбезпечніша спільнота для обміну даними',
    return: 'Повернення',
    filter: 'Фільтр',
    category: 'Категорія',
    community: {
        title: 'Спільнота',
        tags: {
            university: 'Університет',
            tech: 'Техніка',
            leisure: 'Відпочинок',
        },
        requirement: {
            'single-domain': "Електронна пошта з доменним ім'ям: {{domain}}",
            'any-domain': `Лист з одного з доменів: {{domain}}`,
            identified: 'Підтверджена особистість',
            age: 'Мінімальний вік: {{age}}',
        },
    },
    reset: 'Скинуті',
    showProducts: 'Показати продукти',
    copyRight: 'АВТОРСЬКЕ ПРАВО',
    allRightsReserved: 'ВСІ ПРАВА ЗАХИЩЕНІ',
    sorting: {
        sort: 'Сортувати',
        refresh: 'Відновити',
        priceAsc: 'Ціна - спершу найнижча',
        priceDesc: 'Ціна - спершу найвижча',
        newest: 'Новіший спочатку',
        location: 'Відстань',
    },
    assetGallery: {
        viewAll: 'Подивитись все',
        noAssetFound: 'Актив не знайдено',
    },
    collectionBreadCrumbs: {
        home: 'Додому',
    },
    menuMobile: {
        navigation: 'Навігація',
        categories: 'Категорії',
        faininStorefront: 'fainin Вітрина',
        close: 'Закрити',
    },
    myProductCard: {
        day: 'день',
        perDay: 'на день',
    },
    searchSheet: {
        loadMore: 'Завантажити ще',
        noResults: 'Немає результатів',
        no: 'Ні',
        results: 'Результати',
    },
    addressModal: {
        okay: 'добре',
    },
    cartContents: {
        orderAdjustments: 'Коригування замовлення',
        shipping: 'Доставка',
        total: 'Всього',
    },
    soonLabel: {
        comingSoon: 'Незабаром',
    },
    userComponent: {
        awarded: 'Нагороджений',
        onLoan: 'Борг',
    },
    locationPermission: {
        locationDisclaimer:
            'Ваша точна адреса не буде показана іншим користувачам. Під час пошуку вони бачитимуть лише назву вулиці.',
        location: 'Твоє місто',
        locationProductUpload: 'Номер вулиці та будинку.',
        allowLocation: 'Дозвіл на розміщення',
        useExactLocation: 'Використовуйте точне місцезнаходження',
        required: 'Для того, щоб один раз витягнути ваше місцезнаходження, нам потрібен ваш дозвіл.',
        allowLocationPointOne: 'Натисніть <b>Дозволити</b> - і чекаємо на наступне <b>спливаюче вікно.</b>',
        allowLocationPointTwo:
            'Дозвольте Файніну використовувати ваше поточне місцезнаходження <b>і продовжимо з того, на чому зупинилися.</b>',
        givePermission: 'Дозволити',
        missingLocation: 'Missing location permission',
        missingLocationPointOne: 'Відкрийте свій <b>налаштування браузера</b>',
        missingLocationPointTwo:
            '<b>Нехай fainin</b> використати своє поточне місцезнаходження в налаштуваннях та повернутися сюди.',
        missingLocationPointThree:
            "Натисніть <b>Спробуйте ще раз</b> - на екрані з'явиться <b>освіжитися</b> і ви зможете насолоджуватися усіма предметами поруч з вами.",
        notNow: 'Не зараз',
        notGiven: 'Дозвіл на розміщення не надавався!',
        checkInputTryAgain: 'Будь ласка, перевірте свої дані та спробуйте ще раз!',
        tryAgain: 'Спробуйте ще раз',
        zipCity: 'Поштовий індекс, Місто',
    },
    germany: 'Німеччина',
    currentLocation: 'Поточне місцезнаходження',
    searchbar: 'пошук',
    NoLocation: 'Немає Місцезнаходження',
};
