export default {
    noCommunity: {
        title: 'Нет доступа к сообществу',
        msg: 'Вы не являетесь членом этого сообщества. Пожалуйста, присоединитесь к сообществу, к которому принадлежит этот продукт, чтобы арендовать его!',
        okay: 'Хорошо',
    },
    leavingPage: 'Вы собираетесь покинуть страницу...',
    loginToChat: 'Чтобы общаться с другими пользователями, необходимо войти в систему',
    hour: 'Час',
    shippingAddress: 'Shipping Address',
    shippingReturnAddress: 'Return Address',
    shippingCost: 'Shipping Cost',
    failedClipboard: 'Не удалось скопировать ссылку в буфер обмена!',
    linkCopiedToClipboard: 'Ссылка копируется в буфер обмена!',
    invalidPeriod: 'Неверный период',
    requestAlreadyExists:
        'Запрос уже существует. Запрос этого продукта находится на рассмотрении. Пожалуйста, отмените его, чтобы запросить снова.',
    itemUnavailableForPeriod: 'Товар недоступен в течение запрошенного периода!',
    pleaseSignIn: 'Пожалуйста, войдите в систему, чтобы сделать запрос!',
    lessExpensiveDailyPrice: 'Ежедневная цена дешевле и была применена',
    incl: 'В том числе',
    inTotal: 'Всего',
    insuranceCoverage: 'Страховое покрытие до 15,000€',
    returnTime: 'Время возврата*',
    returnTimeOptional: 'Время возврата (необязательно)',
    pickupTime: 'Время забора*',
    pickupTimeOptional: 'Время заезда (по желанию)',
    overview: 'К обзору',
    availability: 'Доступность',
    exactPrice: 'Введите период аренды, чтобы увидеть точную цену',
    reviews: 'Отзывы',
    rentalPrice: 'Цена аренды',
    rentalPeriod: 'Период аренды',
    rentNow: 'Арендовать сейчас',
    days: 'Дни',
    serviceFee: 'Стоимость услуг',
    totalAmount: 'Общая сумма',
    similarProducts: 'Похожие товары',
    youMightAlsoLike: 'Вам также могут понравиться эти товары',
    checkAvailibility: 'Проверить наличие',
    inquireNow: 'Запросить сейчас',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    description: 'Описание',
    owner: 'Владелец',
    favorite: 'Любимый',
    shipping: {
        notSignedIn: 'Для выбора адреса необходимо войти в систему.',
        chooseAddress: 'Выберите свой адрес',
        newAddress: 'Новый адрес',
    },
    noInsuranceDisclaimer: 'Страхование данного продукта не предусмотрено.',
};
