export default {
    title: 'About Us',
    caption: {
        mission: 'The Mission',
        vision: 'Our Vision',
        what: 'What is fainin?',
        why: 'Why are we doing this?',
        story: 'fainins Story',
    },
    text: {
        mission: 'fainin enhances collective consumption by establishing comfort and safety for rentals.',
        vision: 'Share anything, everywhere for everyone!',
        what: `fainin is a place for lenders and renters. A win-win-community powered by a secure sharing environment.
               A place to connect with people while renting and lending things.`,
        why: `With fainin we want to make the world a better place. Everybody benefits while participating, because
        sharing creates mutual opportunities! #faininIsForYou`,
        us: `We are Johann, Max and Kevin - we want to make the world a better place and offer everybody the opportunity to contribute to this goal.
        #SharingIsTheNewOwning`,
        usCaption: "That's us",
    },
    card: {
        sustainability: {
            title: 'Sustainability',
            body: `The more we share the less we need. Save the world with the fainin-Family.`,
        },
        socialTrust: {
            title: 'SocialTrust',
            body: `Sharing is based on trust. That is why fainin is Designed-For-Trust.`,
        },
        adventure: {
            title: 'Freedom',
            body: `We believe that experiences make life worth living.`,
        },
        winWin: {
            title: 'WinWin',
            body: `Provide opportunities for your neighborhood and make money through secure rentals.`,
        },
        forYou: {
            title: 'For Everybody',
            body: `fainin provides 100 % secure transaction between friends & strangers.`,
        },
    },
    story: {
        '1': {
            title: 'The beginning',
            text: `fainin started in Berlin with the vision to establish more sustainable consumption within the neighborhood.`,
        },
        '2': {
            title: 'The re-start of an era',
            text: `We are Johann, Kevin and Max. Our motivation with fainin's app is to provide the best sharing experience to you.`,
        },
        '3': {
            title: 'Positive impact',
            text: `The fainin-Family aims to make the world a better place. With less things collecting dust,
            less ressources are consumed and sustainabilty promoted. The community brings the people together and creates win-win
            situations within the Family.`,
        },
        '4': {
            title: 'Core principles',
            // the \n needs to be in the beginning of a line, otherwise 4 whitespaces are leading to code indentation in markdown
            text: `fainin is based on three principles: <br>
                   win-win, sustainability and social closeness, <br>
                   the foundation for every activity within the fainin-Family.
            `,
        },
        '5': {
            title: 'Your sharing family',
            text: `Each member contributes to a more sustainable society. Together, we'll save our beloved planet!`,
        },
        '6': {
            title: 'Endless possibilities',
            text: `Become part of the most secure sharing community. Everybody benefits trough win-win situations within the fainin-Family
            and makes the world a bit better.`,
        },
    },
    team: {
        johann: ' I want to create endless possibilities for you',
        max: 'I want to make the world a better place',
        kevin: 'I want to establish trust within the fainin-Family',
    },
};
