import { NgIf, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import meta from '@core/localization/en/meta';
import { SeoDefault } from '@core/seo/seo.service';
import { HasMetadata } from '@core/seo/site-meta.types';
import { of } from 'rxjs';

@Component({
    selector: 'fainin-terms-english',
    templateUrl: './terms-english.component.html',
    styleUrls: ['./terms-english.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UpperCasePipe],
})
export class TermsEnglishComponent implements HasMetadata {
    pageMetadata = of({
        lang: 'en-US',
        title: meta.terms.title,
        description: meta.terms.description,
        keywords: SeoDefault.siteKeyword,
    });
}
