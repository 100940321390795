import { SearchResultSortParameter, SortOrder } from '@api/generated-types';

/**
 * FOR SEARCH
 * Takes sortParameters as a string input, determines the sort parameter type,
 * then returns the parameter value for parsing
 * @param params
 */
export function decodeSortParameter(params: string): SearchResultSortParameter | undefined {
    const sort = params.includes('ASC') ? SortOrder.ASC : SortOrder.DESC;

    if (params.includes('location')) {
        return {
            location: {
                latitude: 0,
                longitude: 0,
                sort,
            },
        };
    }
    if (params.includes('updatedAt')) {
        return {
            updatedAt: SortOrder.DESC,
        };
    }
    if (params.includes('price')) {
        return {
            price: sort,
        };
    }
}

/**
 * FOR URL
 * Returns a concatenated string containing sortParameter name and parameter value
 * without the unneeded characters for url
 * @param sortParameter
 * @type SearchResultSortParameter
 */
export function encodeSortParameter(sortParameter: SearchResultSortParameter) {
    if (sortParameter.location) {
        return `location:${sortParameter.location.sort}`;
    }
    if (sortParameter.price) {
        return `price:${sortParameter.price}`;
    }
    if (sortParameter.updatedAt) {
        return `updatedAt:${sortParameter.updatedAt}`;
    }
    return `updatedAt:${sortParameter.updatedAt}`;
}
