import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fainin-feedback-step4',
    templateUrl: './feedback-step4.component.html',
    styleUrls: ['./feedback-step4.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackStep4Component {
    @Input() averageRating: number;
    @Output() submitReview: EventEmitter<string> = new EventEmitter<string>();
    comment: string;

    // Maximum number of default feedback comments configured in localization files
    readonly DEFAULT_FEEDBACK_COMMENTS = 16;

    constructor(private translate: TranslateService) {
        const randomIndex = Math.min(
            Math.floor(Math.random() * this.DEFAULT_FEEDBACK_COMMENTS),
            this.DEFAULT_FEEDBACK_COMMENTS - 1,
        );
        this.comment = this.translate.instant(`account.defaultFeedback.${randomIndex}`);
    }
}
