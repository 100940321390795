export default {
    aboutUs:
        'Wir sind drei Studenten und heißen Johann, Max und Kevin – wir wollen mit fainin die Welt verbessern und alle Teilnehmer profitieren lassen. Wir freuen uns über Deine Nachricht.',
    yourSharingCommunity: 'Your Sharing Community',
    getToKnowUs: 'Get to Know Us',
    sharingIsOwning: '#SharingIsTheNewOwning',
    becomeFamily: 'Become part of the fainin Family',
    title: 'fainin',
    readyForCommunity: 'Ready for the safest Sharing Community?',
    partner: 'Partner',
    caption: {
        lend: 'Lend everything',
        lend2: 'you want',
        rent: 'Rent anything',
        rent2: 'you need',
    },
    tile: {
        safety: {
            title: 'Safety',
            subtitle: '– Design-For-Trust by fainin',
            content: 'Worried about your item? We fully understand! That’s why we designed fainin for trust.',
            point: ['Insurance up to 15’000 €', '100 % verified users', 'fully documented rental workflow'],
        },
        what: {
            title: 'Our goal',
            subtitle: '–The most secure sharing community',
            content: `We want our world to be a social and sustainable place, where people share and trust each other collectively. Therefore, we offer the opportunity to individually benefit through win-win-situations in the fainin-Family. Together, we can establish the most secure sharing community.<br> <b>#SharingIsTheNewOwning</b>
            <br><br>Are you on?`,
        },
        app: {
            title: 'Get fainin as an application',
            subtitle: 'For iOS and Android',
            content: `fainin\'s new App enables everybody to start trustworthy lending- and renting-transactions. Follow fainin\'s social media channels to get access as early as possible. <br> Are you ready?`,
        },
    },
    cta: {
        flying: {
            title: 'Any stuff <br>collecting dust?',
            label: 'Make money while sharing!',
            action: 'List item now',
        },
    },
    blog: {
        title: 'Blog',
        subtitle: 'Let’s talk about the sharing economy',
        text: 'Would you like to contribute to sustainability, social closeness or the sharing economy? Let us know on fainin’s social media channels or directly via mail contact@fainin.com.',
    },
    topSeller: {
        title: 'Featured products',
        subtitle: 'The best products in your area',
    },
    newsletter: {
        title: "Don't miss any news!",
        text: 'Sign up for our newsletter and receive regular updates from the fainin Family.',
        button: 'Subscribe to newsletter',
    },
};
