<div class="flex-md-row">
    <ng-container *ngIf="rating >= 1">
        <img
            *ngFor="let item of [].constructor(getFilledRatingBarsNumber())"
            src="/assets/rating/rating-bar-filled.svg" />
    </ng-container>

    <ng-container *ngIf="rating < 5">
        <img
            *ngFor="let item of [].constructor(getEmptyRatingBarsNumber())"
            src="/assets/rating/rating-bar-empty.svg" />
    </ng-container>
</div>
