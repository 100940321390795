import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GoogleTagManagerService } from '@core/providers/google-tag-manager.service';

@Component({
    selector: 'fainin-social-media-row',
    templateUrl: './social-media-row.component.html',
    styleUrls: ['./social-media-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaRowComponent {
    constructor(private tm: GoogleTagManagerService) {}

    doEmitEvent(platform: string) {
        this.tm.pushTag({
            event: 'SocialLinkClicked',
            link: platform,
        });
    }
}
