export default {
    noInvoices: 'Es wurden keine Rechnungen gefunden.',
    noImageInForm: 'Es wurde kein Bild ausgewählt. Bitte füge eins hinzu!',
    noImageUploaded: 'Es konnte kein Bild hochgeladen werden',
    imageSize:
        'Die Größe einer einzelnen Datei sollte {{size}}MB nicht überschreiten. Bitte versuche ein anderes Bild zu verwenden.',
    unknownSize: 'Diese Datei kann nicht ausgewählt werden, da die Größe unbekannt ist.',
    addAssetsToAlbum: 'Beim Hinzufügen eines Bildes zum Album ist ein Fehler aufgetreten',
    unexpectedError: 'Etwas ist schief gelaufen.',
    noCameraPermission: 'Keine Berechtigungen',
};
