export default {
    homeIcon: 'Домашня ікона Фаїни',
    searchIcon: 'Значок пошуку Fainin',
    lendIcon: 'Ікона Фаїни Ленд',
    uploadIcon: 'Fainin Завантажити піктограму продукту',
    logoutIcon: 'Значок виходу з системи',
    myProfileIcon: 'Іконка мого профілю',
    chatIcon: 'Іконка чату',
    uploadProductIcon: 'Завантажити іконку продукту',
    rentalsIcon: 'Іконка "Оренда',
    favoritesIcon: 'Іконка "Вибране',
    discoverIcon: 'Відкрийте для себе іконку',
    dashboardIcon: 'Піктограма приладової панелі',
    uploadImageAfterRental: 'Завантажте зображення після прокату',
    uploadImageBeforeRentalPlaceholder: 'Завантажте зображення перед орендою',
    renterThumbnail: 'Ескіз орендодавця',
    fallbackRenterThumbnail: 'Ескіз резервного орендаря',
    stepPending: 'Зупинити очікування',
    stepAction: 'Крок Дії',
    productImageAfterRental: 'Зображення продукту Після оренди',
    productImageBeforeRental: 'Зображення продукту перед прокатом',
    paypalIcon: 'Значок Paypal',
    visaIcon: 'Значок візи',
    masterCardIcon: 'Значок Master Card',
    return: 'Назад',
    borrowWhatYouNeed: 'Позичте те, що вам потрібно!',
    privateOrCompany: 'Ви тут приватно чи як компанія',
    private: 'Пріватний',
    businessAccount: 'Бізнес-рахунок',
    whatPassword: 'Який у вас пароль',
    insuranceIllustration: 'страхування ілюстрація',
    faininTeam: 'fainin команда',
    faininAppMockup: 'fainin Макет програми',
    appleAppstoreIcon: 'Значок Apple Appstore',
    googlePlaystoreIcon: 'Значок магазину Google Playn',
    flyingDroneIcon: 'Значок літаючого дрона',
    cookieSettingsButton: 'Кнопка налаштувань файлів cookie',
    johann: 'fainin Йохан',
    max: 'fainin Йохан',
    kev: 'fainin Йохан',
    govtLogo: 'Логотип Німецького федерального фонду охорони навколишнього середовища',
    companyLogo: 'Логотип компанії OVH',
    ergoLogo: 'Ergo логотип - запевнити означає зрозуміти',
    assetGallery: {
        productGalleryImage: 'Зображення галереї продуктів',
        productGalleryFallbackImage: 'Резервне зображення галереї продуктів',
        viewAll: 'Подивитись все',
        faininProductLocationDot: 'Продукт fainin Location Dot',
    },
    accountMenu: {
        chatButtonIcon: 'Значок кнопки чату',
        accountMenuIconSignedIn: 'Значок меню облікового запису – увійшов',
        accountMenuIconUnauthenticated: 'Значок меню облікового запису - не автентифікований',
        burgerMenuIcon: 'Бургер меню значок',
        closeDrawerIcon: 'Закрити шухляду значок',
        signupButton: 'Кнопка реєстрації',
        desktopLoginButton: 'Кнопка входу на робочий стіл',
    },
    cartToggle: {
        cartToggle: 'Перемикач кошика',
    },
    collectionBreadCrumbs: {
        breadcrumb: 'панірувальні сухарі',
    },
    layoutLogo: {
        faininStorefrontLogo: 'Логотип магазину fainin',
    },
    layoutSearchButton: {
        searchSheetClose: 'кнопка закриття аркуша пошуку',
    },
    mobileMenuToggle: 'Перемикач мобільного меню',
    myProductCard: {
        fallbackProductPreview: 'Зображення попереднього перегляду запасного продукту',
    },
    pageHeader: {
        back: 'Назад',
    },
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    userComponent: {
        ownerThumbnail: 'Ескіз власника продукту',
        fallbackOwnerthumbnail: 'Ескіз власника запасного продукту',
    },
    dashboard: {
        downloadAndroid: 'Завантажити Android',
        downloadApple: 'Завантажити Apple',
    },
    order: {
        productPreviewImage: 'Попередній перегляд зображення заброньованого товару',
    },
    button: {
        close: 'Кнопка закриття',
        clear: 'Скидання',
    },
};
