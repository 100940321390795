<div class="d-md-flex align-items-center justify-content-center">
    <div class="d-flex justify-content-center mr-md-2">
        <fa-icon class="star" icon="star"></fa-icon>
        <fa-icon class="star" icon="star"></fa-icon>
        <fa-icon class="star" icon="star"></fa-icon>
        <fa-icon class="star" icon="star"></fa-icon>
        <img src="/assets/icon/reviews/star-half.svg" alt="half star icon" class="star" />
    </div>
    <p class="paragraph-large text-center mb-md-0" [style]="'color: ' + textColor">
        &empty; {{ 'navigation.footer.starText' | translate }}
    </p>
</div>
