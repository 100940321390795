import { ASSET_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const GET_REQUEST_LIST = gql`
    query GetRequestList($options: RequestListOptions) {
        requests(options: $options) {
            items {
                id
                createdAt
                renterProfile {
                    id
                    name
                    type
                    featuredAsset {
                        ...Asset
                    }
                    statistics {
                        id
                        averageRating
                    }
                }
                ownerProfile {
                    id
                    name
                    type
                    featuredAsset {
                        ...Asset
                    }
                    statistics {
                        id
                        averageRating
                    }
                }
                productVariant {
                    product {
                        id
                        slug
                        name
                        featuredAsset {
                            ...Asset
                        }
                        owner {
                            id
                            name
                            type
                            featuredAsset {
                                ...Asset
                            }
                            statistics {
                                id
                                averageRating
                            }
                        }
                        location {
                            zip
                            cityName
                            districtName
                        }
                        assets {
                            ...Asset
                        }
                    }
                }
                startDate
                endDate
                deliveryMethod {
                    name
                }
                status
                days
                serviceFee
                subTotal
                total
                userRelation
                unread
                isHourly
            }
            totalItems
        }
    }

    ${ASSET_FRAGMENT}
`;
