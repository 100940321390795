import { ORDER_DETAIL_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const ADD_LATE_IMAGES_TO_ORDER = gql`
    mutation AddLateImagesToOrder($input: AddLateImagesToOrderInput!) {
        addLateImagesToOrder(input: $input) {
            __typename
            ... on ImagesCountError {
                errorCode
                message
            }
        }
    }
`;

export const GET_INVOICE = gql`
    query GetInvoice($orderId: ID!) {
        invoiceByOrder(orderId: $orderId) {
            id
            createdAt
            borrower
            lender
            identifier
            invoiceDate
            serviceDate
            unique
        }
    }
`;

export const ORDER = gql`
    query GetOrder($id: ID!) {
        order(id: $id) {
            ...OrderDetail
        }
    }
    ${ORDER_DETAIL_FRAGMENT}
`;

export const ORDER_NEEDS_FEEDBACK = gql`
    query OrderNeedsFeedback($id: ID!) {
        orderNeedsFeedback(orderId: $id)
    }
`;

export const SUBMIT_FEEDBACK = gql`
    mutation SubmitFeedback($input: SubmitFeedbackInput!) {
        submitFeedback(input: $input) {
            __typename
        }
    }
`;

export const ORDER_CANCEL = gql`
    mutation OrderCancel($input: CancelOrderInput!) {
        orderCancel(input: $input) {
            __typename
            ... on Order {
                state
            }
        }
    }
`;

export const ORDER_DELIVER = gql`
    mutation OrderDeliver($input: DeliverOrderInput!) {
        orderDeliver(input: $input) {
            __typename
            ... on Order {
                id
                state
            }
        }
    }
`;

export const ORDER_RETURN = gql`
    mutation OrderReturn($input: ReturnOrderInput!) {
        orderReturn(input: $input) {
            __typename
            ... on Order {
                id
                state
            }
        }
    }
`;

export const ORDER_REQUEST_REFUND = gql`
    mutation OrderRequestRefund($input: RequestRefundInput!) {
        orderRequestRefund(input: $input) {
            __typename
            ... on Order {
                state
            }
        }
    }
`;
