import account from './account';
import alert from './alert';
import asset from './asset';
import auth from './auth';
import content from './content';
import cookie from './cookie';
import core from './core';
import dialog from './dialog';
import error from './error';
import label from './label';
import meta from './meta';
import navigation from './navigation';
import term from './term';
import tooltip from './tooltip';

export default {
    langName: 'Deutsch',
    navigation,
    content,
    term,
    cookie,
    meta,
    auth,
    alert,
    error,
    account,
    core,
    dialog,
    asset,
    label,
    tooltip,
};
