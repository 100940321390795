export default {
    updateAddress: 'Обновить адрес',
    downloadAppNow: 'Загрузить приложение.',
    downloadAppNowNote: 'С помощью приложения вы можете использовать fainin еще проще.',
    uploadNewProduct: 'Загрузить новый продукт',
    verifyFree: 'Проверить личность бесплатно',
    nothingGoingOnHere: 'Здесь ничего не происходит?',
    uploadSomething: 'Загрузите что-нибудь!',
    toProducts: 'К товарам',
    noNewMsgs: 'У вас нет новых сообщений',
    noFavsYet: 'У вас еще нет фаворитов',
    search: 'Поиск',
    reachNewMS: 'Достигайте вершин и ожидайте отличных значков и наград',
    badgesAndAwards: 'Награды и достижения',
    stats: {
        title: 'Прогресс',
        totalCount: 'Вместе с fainin вы выполнили <b>{{totalCount}} заказов</b> до сих пор.',
        totalRevenue: 'Всего вы заработали <b>{{totalRevenue}} дохода</b>.',
        emissionSaved: 'В среднем, удалось сэкономить <b>{{emissionSaved}} кг CO2</b>!',
        totalCountLabel: 'Заказы',
        totalRevenueLabel: 'Доход',
        emissionSavedLabel: 'Экономия CO2 (Ср.)',
    },
};
