import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AssetPreviewSharedModule } from '@shared/asset-preview-shared.module';
import { ButtonSharedModule } from '@shared/button-shared.module';
import { FeedbackIconComponent } from '@shared/components/user/feedback-icon/feedback-icon.component';
import { UserComponent } from '@shared/components/user/user.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
    declarations: [UserComponent, FeedbackIconComponent],
    imports: [CommonModule, TranslateModule, AssetPreviewSharedModule, RouterLink, ButtonSharedModule, NzToolTipModule],
    exports: [UserComponent, FeedbackIconComponent],
})
export class UserSharedModule {}
