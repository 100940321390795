<div class="product-assets">
    <div
        class="gallery-images"
        *ngIf="assets.length > 0; else noAsset"
        [ngClass]="layoutClass"
        [class.view-more]="assets.length > 5">
        <ng-container *ngFor="let asset of assets | slice : 0 : 5; let i = index">
            <div class="gallery-image image-container">
                <img
                    class="gallery-image"
                    (click)="onImagePreview(i)"
                    [ngSrc]="asset | assetPreview : (i == 0 ? 'large' : 'medium')"
                    [height]="asset.height"
                    [width]="asset.width"
                    [faininFallbackImage]="'/assets/image/fallback/product.png'"
                    [alt]="assetAlt ? assetAlt : ('asset.assetGallery.productGalleryImage' | translate) + ' #' + i" />
                <nz-skeleton-element
                    class="image-container skeleton"
                    nzType="image"
                    [nzActive]="false"></nz-skeleton-element>
            </div>
        </ng-container>
        <ng-container *ngIf="assets.length > 5">
            <!-- TODO: view all images if assets count > 5 -->
            <div class="view-more-container">
                <button
                    class="btn btn-block btn-primary text-light"
                    attr.aria-label="{{ 'label.viewAll' | translate }}">
                    {{ 'core.assetGallery.viewAll' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
    <ng-template #noAsset>
        <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
    </ng-template>
</div>
