import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomGoogleEvent } from '@api/google-event';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { interval, skipWhile, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';

declare var gtag: Function;

const logger = new Logger('GoogleTagManager');

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService {
    private routerSubscription: Subscription;
    private renderer2: Renderer2;
    private readonly googleTagManagerId: string;
    private scriptsLoaded: boolean = false;

    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        },
    };

    constructor(
        private router: Router,
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.googleTagManagerId = environment.googleTagsId;
    }

    /**
     * Initialize the default configurations for the google tag manger consent
     */
    public initializeDefaults() {
        this.gtag('consent', 'default', {
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
        });
        this.gtag('js', new Date());
        if (this.googleTagManagerId) this.gtag('config', this.googleTagManagerId);
    }

    /**
     * Injects the googletagmanager and the gtag function
     */
    public initScript(consent: string[] | null) {
        if (this.googleTagManagerId && !this.scriptsLoaded && isPlatformBrowser(this.platformId)) {
            try {
                // We have to configure the consent settings first
                this.updateConsent(consent);
                this.doInjectGtmScript();
            } catch (e) {
                logger.error(`Could not load GTM script`, e);
            }
        }
    }

    /**
     * Cancels the router subscription
     */
    public destroy() {
        if (this.routerSubscription) this.routerSubscription.unsubscribe();
    }

    public getDataLayer(): any[] {
        const window = this.browserGlobals.windowRef();
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }

    public pushTag(item: CustomGoogleEvent): void {
        if (this.scriptsLoaded) {
            this.pushOnDataLayer(item);
        } else if (isPlatformBrowser(this.platformId)) {
            logger.debug('Script was not loaded yet!', item.event);
            // In case the dataLayer is not loaded yet - queue the event
            interval(1000)
                .pipe(
                    takeUntil(timer(5000)), // cancel after 5s
                    skipWhile(() => !this.scriptsLoaded), // try if script is loaded
                    take(1), // only take once - complete after that
                )
                .subscribe(() => this.pushOnDataLayer(item));
        }
    }

    /**
     * Configure the data consent for gtag manager
     * @see https://developers.google.com/tag-platform/security/guides/customize-cookies?hl=de#gtag.js
     * @param granted
     */
    public updateConsent(granted: string[] | null): void {
        const isAllowed = (grant: string): string => {
            return granted?.includes(grant) ? 'granted' : 'denied';
        };

        this.gtag('consent', 'update', {
            ad_storage: isAllowed('ad_storage'),
            ad_user_data: isAllowed('ad_user_data'),
            ad_personalization: isAllowed('ad_personalization'),
            analytics_storage: isAllowed('analytics'),
        });
    }

    private pushOnDataLayer(obj: object): void {
        logger.debug('pushOnDataLayer', obj);
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }

    private doInjectGtmScript() {
        this.pushOnDataLayer({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        const script: HTMLScriptElement = this.renderer2.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.addEventListener('load', () => {
            this.onGoogleTagManagerLoaded();
        });
        script.src = `https://www.googletagmanager.com/gtm.js?id=${this.googleTagManagerId}`;
        script.text = '';
        script.id = 'gtm';
        script.setAttribute('crossorigin', 'anonymous');
        this.renderer2.insertBefore(this._document.head, script, this._document.head.firstChild);

        const gtagIFrame = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.googleTagManagerId}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

        const begin = this.renderer2.createComment('Google Tag Manager (noscript)');
        const noscript: HTMLElement = this.renderer2.createElement('noscript');
        const end = this.renderer2.createComment('End Google Tag Manager (noscript)');
        // this code immediately after the opening <body> tag

        this.renderer2.insertBefore(this._document.body, end, this._document.body.firstChild);
        this.renderer2.insertBefore(this._document.body, noscript, this._document.body.firstChild);
        this.renderer2.insertBefore(this._document.body, begin, noscript);

        const iframe: HTMLIFrameElement = this.renderer2.createElement('iframe');
        iframe.width = '0';
        iframe.height = '0';
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.googleTagManagerId}`;
        this.renderer2.setStyle(iframe, 'display', 'none');
        this.renderer2.setStyle(iframe, 'visibility', 'hidden');

        this.renderer2.insertBefore(noscript, iframe, noscript.lastChild);
    }

    /**
     * This function is called once the googletagmanager got initialized
     * @private
     */
    private onGoogleTagManagerLoaded() {
        this.scriptsLoaded = true;
        logger.info('Initialized');

        this.gtag('js', new Date());

        // Initial page load
        const urlWithoutParams = this.router.url.split('?')[0];
        logger.debug('Event', 'page_view', urlWithoutParams);
        this.pushTag({
            event: 'page',
            pageName: urlWithoutParams,
        });

        this.routerSubscription = this.router.events
            .pipe(
                filter<any>(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects.split('?')[0]),
                distinctUntilChanged((x, y) => x === y),
            )
            .subscribe(urlWithoutParams => {
                logger.debug('Event', 'page_view', urlWithoutParams);
                this.pushTag({
                    event: 'page',
                    pageName: urlWithoutParams,
                });
            });
    }

    /**
     * Basic gtag function implementation
     * @param args list of arguments
     * @private
     */
    private gtag(...args: any) {
        const dataLayer = this.getDataLayer();
        dataLayer.push(args);
    }
}
