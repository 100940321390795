export default {
    landingHero: {
        title: 'Загрузка товара',
        subTitle: 'Делиться вместо того, чтобы владеть',
        yourProduct: 'Ваш желаемый продукт',
        upload: 'Загрузка товара',
    },
    requestAdditional: {
        title: 'Увеличьте свои шансы!',
        body: 'У вас больше шансов получить желаемый товар как можно скорее, если вы отправите сразу несколько запросов! Как насчет поиска других вариантов?',
        button: 'Найти другие варианты!',
    },
    categoryPages: {
        all: 'Все',
        heroAlt: 'Эскиз',
    },
    favWhenLogin: 'Избранное может быть сохранено навсегда только при входе в систему',
    tapForProductsNearby: 'Нажмите здесь, чтобы посмотреть товары поблизости',
    safestcommunity: 'Самое безопасное сообщество совместного использования',
    return: 'Назад',
    filter: 'Фильтр',
    category: 'Категория',
    community: {
        title: 'Сообщество',
        tags: {
            university: 'Университет',
            tech: 'Техника',
            leisure: 'Досуг',
        },
        requirement: {
            'single-domain': 'Электронная почта с доменным именем: {{domain}}',
            'any-domain': `Электронное письмо с одного из доменов: {{domain}}`,
            identified: 'Подтвержденная личность',
            age: 'Минимальный возраст: {{age}}',
        },
    },
    reset: 'Сброс',
    showProducts: 'Показать товары',
    copyRight: 'Авторские права',
    allRightsReserved: 'ВСЕ ПРАВА ЗАЩИЩЕНЫ',
    sorting: {
        sort: 'Сортировать',
        refresh: 'Обновление',
        priceAsc: 'Низкая цена слишком высокая',
        priceDesc: 'Высокая цена слишком низкая',
        newest: 'Новики первыйе',
        location: 'Дистанция',
    },
    assetGallery: {
        viewAll: 'Просмотреть все',
        noAssetFound: 'Фотографии не найдены',
    },
    collectionBreadCrumbs: {
        home: 'Главная',
    },
    menuMobile: {
        navigation: 'Навигатор',
        categories: 'Категории',
        faininStorefront: 'fainin Витрина',
        close: 'Закрыть',
    },
    myProductCard: {
        day: 'День',
        perDay: 'в день',
    },
    searchSheet: {
        loadMore: 'Загрузить ещё',
        noResults: 'Нет результатов',
        no: 'Отсутствует',
        results: 'Результаты',
    },
    addressModal: {
        okay: 'Хорошо',
    },
    cartContents: {
        orderAdjustments: 'Корректировка заказов',
        shipping: 'Доставка',
        total: 'Всего',
    },
    soonLabel: {
        comingSoon: 'Скоро',
    },
    userComponent: {
        awarded: 'Одолженный',
        onLoan: 'Заимствовано',
    },
    locationPermission: {
        locationDisclaimer:
            'Ваш точный адрес не будет показан другим пользователям. При поиске они смогут увидеть только название улицы.',
        location: 'Ваш город',
        locationProductUpload: 'Номер улицы и дома.',
        allowLocation: 'Разрешить разрешение местоположения',
        useExactLocation: 'Используйте точное местоположение',
        required: 'Чтобы извлечь ваше местоположение один раз, нам потребуется ваше разрешение.',
        allowLocationPointOne: 'Нажмите <b>Разрешить</b> - и дождаться следующего <b>всплывающее окно.</b>',
        allowLocationPointTwo:
            'Разрешите fainin использовать ваше текущее местоположение <b>& продолжайте с того места, где остановились.</b>.',
        givePermission: 'Разрешить',
        missingLocation: 'Отсутствует разрешение на определение местоположения',
        missingLocationPointOne: 'Откройте настройки <b>браузера</b>.',
        missingLocationPointTwo:
            '<b>Разрешите fainin</b> использовать ваше текущее местоположение в настройках и возвращайтесь сюда.',
        missingLocationPointThree:
            'Нажмите <b>Попробуйте еще раз</b> - экран будет <b>обновляться</b> И вы сможете наслаждаться всеми предметами, находящимися рядом с вами.',
        notNow: 'Не сейчас',
        notGiven: 'Разрешение на размещение не выдается!',
        checkInputTryAgain: 'Пожалуйста, проверьте введенные данные и повторите попытку!',
        tryAgain: 'Попробуйте еще раз',
        zipCity: 'Почтовый индекс, город',
    },
    germany: 'Германия',
    currentLocation: 'Текущее местоположение',
    searchbar: 'поиск',
    NoLocation: 'Нет местоположения',
};
