import { PermissionResponse } from '../permission';
import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const CAMERA_PERMISSION_EVENT_NAME = 'CameraPermissionEvent';
export interface ICameraPermissionPayload extends IResponsePayload, PermissionResponse {}

export type ICameraPermissionEvent = IWebViewEvent<any, ICameraPermissionPayload>;
export class CameraPermissionEvent
    extends AWebViewEvent<any, ICameraPermissionPayload>
    implements ICameraPermissionEvent
{
    constructor() {
        super(CAMERA_PERMISSION_EVENT_NAME);
    }
}

export default [CAMERA_PERMISSION_EVENT_NAME, CameraPermissionEvent];
