<div class="flag-container">
    <img
        class="flag flag-german"
        [class.active]="language === 'de-DE'"
        src="/assets/icon/flags/germany.svg"
        (click)="onLanguageChange('de-DE')"
        alt="german flag icon" />
    <img
        class="flag flag-english"
        [class.active]="language === 'en-US'"
        src="/assets/icon/flags/united_kingdom.svg"
        (click)="onLanguageChange('en-US')"
        alt="united kingdom flag icon" />
    <img
        class="flag flag-ukraine"
        [class.active]="language === 'uk-UA'"
        src="/assets/icon/flags/ukraine.svg"
        (click)="onLanguageChange('uk-UA')"
        alt="ukraine flag icon" />
    <img
        class="flag flag-russian"
        [class.active]="language === 'ru-RU'"
        src="/assets/icon/flags/russia.svg"
        (click)="onLanguageChange('ru-RU')"
        alt="russian flag icon" />
</div>
