import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@core/providers/data/data.service';
import { GoogleTagManagerService } from '@core/providers/google-tag-manager.service';
import { I18nService } from '@core/providers/i18n.service';
import { Logger } from '@core/utils/logger';
import { untilDestroyed } from '@core/utils/until-destroyed';

const log = new Logger('LanguageChange');

@Component({
    selector: 'fainin-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
    language: string;
    languages: string[];

    constructor(
        private i18nService: I18nService,
        private tm: GoogleTagManagerService,
        private elementRef: ElementRef<HTMLElement>,
        private dataService: DataService,
        private route: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {}

    ngOnInit(): void {
        this.languages = this.i18nService.supportedLanguages;
        this.language = this.i18nService.language;

        // Change active language
        this.i18nService
            .onLangChange()
            .pipe(untilDestroyed(this))
            .subscribe(event => (this.language = event.lang));
    }

    onLanguageChange(languageKey: string) {
        log.info('Changing language to', languageKey);
        this.i18nService.language = languageKey;

        this.tm.pushTag({
            event: 'languageChange',
            language: languageKey,
        });

        /**
         * Refresh pages that load collections from the backend
         * ==> Collections are localized by intercepting and modifying the uri in core module
         *     therefore, the page has to be refreshed to get the newly selected language translation
         */
        window.location.reload();
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }
}
