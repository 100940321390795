import { Injectable } from '@angular/core';
import { FacetValue, GetSubcategories } from '@api/generated-types';
import { CollectionService } from '@core/providers/data/collection.service';
import { DataService } from '@core/providers/data/data.service';
import { Logger } from '@core/utils/logger';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GET_SUBCATEGORIES } from '../../../category/category-page.graphql';

const logger = new Logger('SecondaryFooterService');

export type CategoryDisplayIndex = Array<{ collection: string; categories: string[] }>;
export type CategoryDisplay = Array<{
    collectionSlug: string;
    collectionName: string;
    categories: Array<Pick<FacetValue, 'name' | 'id' | 'code'>>;
}>;
@Injectable({
    providedIn: 'root',
})
export class SecondaryFooterService {
    private subCategoryIndexCache: CategoryDisplay | undefined;

    constructor(private dataService: DataService, private collectionService: CollectionService) {}

    async getSubCategoryIndex(index: CategoryDisplayIndex) {
        if (this.subCategoryIndexCache) return Promise.resolve(this.subCategoryIndexCache);
        return this.createSubCategoryIndex(index);
    }

    private async createSubCategoryIndex(index: CategoryDisplayIndex) {
        logger.debug('Init secondary footer getSubcategories');

        const subcategories = await lastValueFrom(
            this.dataService.query<GetSubcategories.Query, GetSubcategories.Variables>(GET_SUBCATEGORIES).pipe(
                take(1),
                map(data => data.subcategories),
            ),
        );

        const collections = await this.collectionService.fetchCollections();

        const data: CategoryDisplay = [];
        for (const { collection, categories } of index) {
            const collectionObj = collections.find(value => value.slug === collection);
            const subCategories = subcategories.filter(({ code }) => categories.includes(code));

            if (!collectionObj) continue;

            data.push({
                collectionName: collectionObj.name,
                collectionSlug: collectionObj.slug,
                categories: [...subCategories],
            });
        }

        this.subCategoryIndexCache = data;
        return data;
    }
}
