import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationOptions, NotificationType } from './notification-types';

/**
 * This service is responsible for instantiating a ModalDialog component and
 * embedding the specified component within.
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(
        private notification: NzNotificationService,
        private translate: TranslateService,
        @Inject(PLATFORM_ID) private platformId: string,
    ) {}

    /**
     * Display a "toast" notification.
     */
    notify(type: NotificationType, title: string, message: string, interpolateParams?: Object | undefined) {
        message = this.translateMsg(message, interpolateParams);
        return this.sendNotification({
            title: this.translate.instant(title),
            message,
            duration: 5000,
            type,
        });
    }

    /**
     * Display error notification
     * @param message
     * @param interpolateParams for the message translation
     */
    error(message: string = 'error.unexpectedError', interpolateParams?: Object | undefined) {
        message = this.translateMsg(message, interpolateParams);
        return this.sendNotification({
            title: this.translate.instant('auth.error.errorOccurred'),
            message,
            duration: 8000,
            type: 'error',
        });
    }

    /**
     * Display success notification
     * @param message
     * @param interpolateParams for the message translation
     */
    success(message: string, interpolateParams?: Object | undefined) {
        message = this.translateMsg(message, interpolateParams);
        return this.sendNotification({
            title: this.translate.instant('auth.success', interpolateParams),
            message,
            duration: 5000,
            type: 'success',
        });
    }

    /**
     * Display info notification
     * @param message
     * @param interpolateParams for the message translation
     */
    info(message: string, interpolateParams?: Object | undefined) {
        message = this.translateMsg(message, interpolateParams);
        return this.sendNotification({
            title: this.translate.instant('auth.information'),
            message,
            duration: 5000,
            type: 'info',
        });
    }

    private sendNotification(options: NotificationOptions) {
        if (isPlatformBrowser(this.platformId)) {
            const { type, message, title, duration } = options;
            return this.notification.create(type, title, message || '', { nzDuration: duration });
        }
    }

    private translateMsg(message: string, interpolateParams?: Object | undefined) {
        if (message.includes('native-authentication-methods-not-found')) {
            return this.translate.instant('auth.error.nativeAuthenticationMethodsNotFound');
        } else if (message.includes('Customer already exists')) {
            return this.translate.instant('auth.error.signedUpThroughEmail');
        }
        return this.translate.instant(message, interpolateParams);
    }
}
