import { PermissionResponse } from '../permission';
import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const MEDIA_PERMISSION_EVENT_NAME = 'MediaPermissionEvent';

export interface IMediaPermissionPayload extends IResponsePayload, PermissionResponse {}

export type IMediaPermissionEvent = IWebViewEvent<any, IMediaPermissionPayload>;
export class MediaPermissionEvent extends AWebViewEvent<any, IMediaPermissionPayload> implements IMediaPermissionEvent {
    constructor() {
        super(MEDIA_PERMISSION_EVENT_NAME);
    }
}

export default [MEDIA_PERMISSION_EVENT_NAME, MediaPermissionEvent];
