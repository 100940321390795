<ng-container>
    <nz-table
        class="striped-columns border border-light"
        #rowSelectionTable
        [nzData]="products"
        [nzShowPagination]="false"
        [nzFrontPagination]="false">
        <thead>
            <tr>
                <th
                    *ngFor="let column of listOfColumn"
                    [nzLoading]="true"
                    nzShowSort
                    (nzSortOrderChange)="onSortChange(column.field, $event)">
                    {{ column.title | translate }}
                </th>
                <th>{{ 'account.productTable.actions' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data" class="border border-dark">
                <td [routerLink]="['/product', data.slug]" class="cell-hover td-title">{{ data.name }}</td>
                <td [routerLink]="['/product', data.slug]" class="cell-hover">{{ getDateOnly(data.createdAt) }}</td>
                <td class="cell-hover td-description">
                    {{ data.description }}
                    <img
                        src="/assets/icon/figma/edit.svg"
                        atl="edit icon"
                        width="16"
                        height="16"
                        class="edit-icon"
                        [queryParams]="{ step: 1 }"
                        [routerLink]="['/account/product-edit', data.slug]" />
                </td>
                <td class="cell-hover td-price">
                    {{ data.variants[0].price | formatPrice }}
                    <img
                        src="/assets/icon/figma/edit.svg"
                        alt="edit icon"
                        width="16"
                        height="16"
                        class="edit-icon"
                        [queryParams]="{ step: 2 }"
                        [routerLink]="['/account/product-edit', data.slug]" />
                </td>
                <td>
                    <a [routerLink]="['/account/product-edit', data.slug]" class="product-table-link">{{
                        'account.editOnly' | translate
                    }}</a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a
                        [queryParams]="{ action: 'duplicate' }"
                        [routerLink]="['/account/product-edit', data.slug]"
                        class="product-table-link"
                        >{{ 'account.productUpload.duplicate' | translate }}</a
                    >
                    <nz-divider nzType="vertical"></nz-divider>
                    <a (click)="deleteProduct.emit(data)" class="product-table-link">{{
                        'account.productUpload.delete' | translate
                    }}</a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>
