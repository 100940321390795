import { Logger } from '@core/utils/logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export type PaginatedList<T> = { items: Array<T>; totalItems: number };
export type PaginatedResult<T> = PaginatedList<T> & { skip: number; take: number; pages: number; page: number };

/**
 * @description
 * This class us used to cache the current state of a paginated list. A fetch of
 * very first page of a paginated list will also be stored into local storage
 * to use it as a default value. With each change of page, take
 */
export class PaginatedListState<T> {
    /** Subject storing the current state of a paginated list */
    private subject: BehaviorSubject<PaginatedResult<T>>;
    /** Page index that starts with 1 */
    private _page: number = 1;
    private _take: number = 6;
    private logger: Logger;
    private _fetched: boolean = false;

    constructor(
        private _key: string,
        private queryFunction: (skip: number, take: number) => Observable<PaginatedList<T>>,
    ) {
        this.logger = new Logger('PaginatedListState');
        const state = this.retrieveState();
        this.subject = new BehaviorSubject<PaginatedResult<T>>(state);
        this.logger.debug(this._key, `Initialized with ${state.totalItems} items`);
    }

    /**
     * Refreshes the data based on the defined queryFunction
     */
    fetch(): Promise<PaginatedResult<T>> {
        const skip = Math.max(0, this._page - 1) * this._take;
        this.logger.debug(this._key, `Fetching with skip`, skip, `take`, this._take);
        this._fetched = true;
        return new Promise<PaginatedResult<T>>((resolve, reject) => {
            this.queryFunction(skip, this._take)
                .pipe(take(1))
                .subscribe({
                    next: value => {
                        if (value && value.items) {
                            // Only store the first page
                            if (this.page === 0) {
                                localStorage?.setItem(this.storageKey, JSON.stringify(value));
                            }
                            const next = {
                                ...value,
                                skip,
                                page: this._page,
                                take: this._take,
                                pages: Math.ceil(this.value.totalItems / this.take),
                            };
                            this.subject.next(next);
                            resolve(next);
                        }
                    },
                    error: (err: any) => {
                        this.logger.warn(`Error while fetching ${this.key}:`, err);
                        resolve(this.subject.value);
                    },
                });
        });
    }

    /**
     * Resets the list to a default state and deletes the storage key
     */
    reset(): void {
        this.logger.debug(`Resetting state for`, this.storageKey);
        this.subject.next(this.emptyState);
        localStorage?.removeItem(this.storageKey);
    }

    /**
     * Return to first page and fetch the list
     */
    refresh(): Promise<PaginatedResult<T>> {
        this._page = 1;
        return this.fetch();
    }

    /**
     * Observe the list state that is updated with every fetch
     */
    observe(): Observable<PaginatedResult<T>> {
        if (!this._fetched) {
            this.fetch().then(() => {});
        }
        return this.subject.asObservable();
    }

    /** Current value of the paginated list state */
    get value(): PaginatedResult<T> {
        return this.subject.value;
    }

    /**
     * The key used to store and identify the list in localstorage
     */
    get key(): string {
        return this._key;
    }

    /**
     * The page number, starting with 1
     */
    get page(): number {
        return this._page;
    }

    /**
     * The page number, starting with 1
     * @param value greater 0
     */
    set page(value: number) {
        this._page = Math.max(value, 1);
        this.fetch().then(() => {});
    }

    /**
     * The number of items to request
     * @param value greater 0
     */
    set take(value: number) {
        this._take = Math.max(value, 1);
        this.fetch().then(() => {});
    }

    /**
     * The number of items fetched for a page
     */
    get take(): number {
        return this._take;
    }

    /**
     * Indicator if the list already executed a fetch or just
     * uses the local storage default value.
     */
    get fetched(): boolean {
        return this._fetched;
    }

    private retrieveState(): PaginatedResult<T> {
        const empty = this.emptyState;
        const stateStr = localStorage?.getItem(this.storageKey) as string;
        if (stateStr) {
            const state = Object.assign(empty, JSON.parse(stateStr));
            state.pages = Math.ceil(state.totalItems / this._take);
            return state;
        }
        return empty;
    }

    private get emptyState() {
        return { skip: 0, take: this._take, totalItems: 0, items: [], pages: 0, page: 1 };
    }

    private get storageKey() {
        return `FaininList-${this._key}`;
    }
}
