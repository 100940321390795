import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RatingComponent } from '@shared/components/rating/rating.component';

@NgModule({
    declarations: [RatingComponent],
    imports: [CommonModule],
    exports: [RatingComponent],
})
export class RatingComponentModule {}
