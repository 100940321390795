import { ADDRESS_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const CREATE_ADDRESS = gql`
    mutation CreateAddress($input: CreateAddressInput!) {
        createCustomerAddress(input: $input) {
            ...Address
        }
    }
    ${ADDRESS_FRAGMENT}
`;
