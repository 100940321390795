export default {
    title: 'Über Uns',
    caption: {
        mission: 'Die Mission',
        vision: 'Unsere Vision',
        what: 'Was ist fainin?',
        why: 'Warum tun wir das?',
        story: 'fainins Story',
    },
    text: {
        mission:
            'fainin ermöglicht das sichere Teilen zwischen Freunden und Fremden. <br> ' +
            'Mit Vertrauen schaffenden Maßnahmen wird das Verleihen und Leihen zum Alltag und damit unser ' +
            'gemeinschaftlicher Konsum reduziert. <br> <br> Überzeuge Dich selbst von fainins Mission und werde Teil der Family.',
        vision: 'Teilen ist das neue Haben!',
        what: `fainin ist Deine Sharing Community zum sicheren Leihen und Verleihen. Wir ermöglichen Dir in der fainin Family
        unkompliziert zu verleihen und damit Geld zu verdienen. fainin ist 100% abgesichert und deckt alle Schritte der
        Transaktion ab. Das Design-For-Trust inklusive Versicherung, Verifizierung und Dokumentation, vermeidet
        negative Erfahrungen innerhalb der fainin Family.`,
        why: `Wir wollen mit fainin die Welt verbessern. Aus diesem Grund profitiert jeder bei der Teilnahme, denn durch
das Teilen schaffen wir uns gegenseitig Möglichkeiten! #faininIstFürDich`,
        us: `Wir heißen Johann, Max und Kevin. Wir wollen die Welt verbessern und bieten Dir mit fainin die Möglichkeit mitzumachen.`,
        usCaption: 'Das sind wir',
    },
    card: {
        sustainability: {
            title: 'Nachhaltigkeit',
            body: `Je mehr wir teilen, desto weniger brauchen wir.`,
        },
        socialTrust: {
            title: 'Sicherheit',
            body: `Teilen basiert auf Vertrauen. Deshalb ist fainin Designed-For-Trust.`,
        },
        adventure: {
            title: 'Freiheit',
            body: `Besitzen hat Vorteile, aber benutzen bringt Erfahrungen. Stop buying, start sharing.`,
        },
        winWin: {
            title: 'WinWin',
            body: `Biete Deinen Mitmenschen Möglichkeiten und verdiene Geld beim Verleihen.`,
        },
        forYou: {
            title: 'Für Alle',
            body: `Ein 100% sicherer Verleih zwischen Freunden & Fremden. Bist du dabei?`,
        },
    },
    story: {
        '1': {
            title: 'Die Anfangszeit',
            text: `Die fainin-Family wurde in Berlin mit der Vision gegründet, die Welt ein wenig nachhaltiger zu machen.`,
        },
        '2': {
            title: 'Der Neustart einer Ära',
            text: `Wir sind Johann, Kevin und Max. Wir bieten Dir mit fainins App die optimalen Rahmenbedingungen zum Teilen.

            `,
        },
        '3': {
            title: 'Positiver Einfluss',
            text: `Die fainin-Family hat das Ziel, die Welt ein wenig besser zu machen. Wenn weniger ungenutzte
            Dinge herumliegen, werden weniger Ressourcen benötigt und damit Nachhaltigkeit gefördert.
            Die Community bringt die Menschen zusammen und kreiert Win-Win-Situationen in der fainin-Family.`,
        },
        '4': {
            title: 'Drei Prinzipien',
            text: `fainin basiert auf drei Kernprinzipien: <br>
            Win-Win, Nachhaltigkeit und Soziale Nähe, <br>
            die Grundlage für jede Aktivität der fainin-Family.`,
        },
        '5': {
            title: 'Deine Sharing Family',
            text: `Jedes Mitglied kann seinen Beitrag
            zu einer nachhaltigen Umwelt leisten und ist Teil einer wundervollen Veränderung. Gemeinsam retten wir
            unseren geliebten Planeten!`,
        },
        '6': {
            title: 'Unendliche Möglichkeiten',
            text: `Werde Teil der sichersten Sharingcommunity. Jeder profitiert von
            Win-Win-Situationen in der fainin-Family und macht die Welt ein wenig besser.`,
        },
    },
    team: {
        johann: 'Ich möchte Dir grenzenlose Möglichkeiten schaffen',
        max: 'Ich möchte unsere Welt zu einem besseren Ort machen',
        kevin: 'Ich möchte Verleihern das nötige Vertrauen bieten',
    },
};
