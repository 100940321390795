import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@core/providers/i18n.service';
import { IMetaPageInfo, ITwitterMeta, MetaAssetType } from '@core/seo/site-meta.types';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IMetaInfo, OGMeta, TwitterMeta } from './site-meta.types';

const logger = new Logger('SeoService');

export class SeoDefault {
    public static readonly siteTitle = 'fainin | günstig Mieten & versichert Vermieten | Geld verdienen';
    public static readonly siteDescription = `fainin ist Deine Sharing Community zum Teilen in der Nachbarschaft. In fainins Community etablieren wir nachhaltigen Konsum, indem die Nutzer miteinander teilen können (mieten, leihen, vermieten, verleihen).`;
    public static readonly siteKeyword =
        'fainin, Zubehör leihen, Sharing, Community, Dinge mieten, Online leihen, verleihen, Film Equipment Verleih, Bootsverleih, Online Vermietung, shared economy insurance';
    public static readonly siteImage = `${environment.hostUrl}/assets/logo/icon-512x512.png`;

    public static readonly siteAsset = {
        source: this.siteImage,
        width: 512,
        height: 512,
        mimeType: 'image/png',
    };
    public static readonly pageMeta = {
        title: this.siteTitle,
        description: this.siteDescription,
        keywords: this.siteKeyword,
        asset: this.siteAsset,
    };

    public static readonly siteTwitterCardSite: string = '@fainin_sharing';

    public static readonly siteTwitterCard: ITwitterMeta = {
        card: 'summary_large_image',
        title: this.siteTitle,
        description: this.siteDescription,
        site: this.siteTwitterCardSite,
        image: this.siteImage,
        creator: undefined,
    };

    public static readonly siteOgMeta: IMetaInfo = {
        title: this.siteTitle,
        description: this.siteDescription,
        creator: 'fainin',
        image: this.siteImage,
    };
}

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    public readonly twitterMeta: TwitterMeta;
    public readonly ogMeta: OGMeta;
    private subRouter: Subscription;
    private subLangChange: Subscription;
    private _supportWebP: boolean;
    get supportWebP(): boolean {
        return this._supportWebP;
    }

    constructor(
        private title: Title,
        private translate: TranslateService,
        private i18n: I18nService,
        private meta: Meta,
        private router: Router,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.twitterMeta = new TwitterMeta(meta, SeoDefault.siteTwitterCard);
        this.ogMeta = new OGMeta(meta, SeoDefault.siteOgMeta);
    }

    init(): void {
        logger.debug('Initializing SEO Service');
        this.checkWebPFormatSupport();
        // Set default values even before routing is finished
        this.setTitle(this.translate.instant('meta.title'));
        this.setDescription(this.translate.instant('meta.description'));
        this.setKeyword(this.translate.instant('meta.keywords'));
        this.tagProperty('og:url', `${environment.hostUrl}${this.router.url}`);

        // Init canonical link
        this.updateCanonicalUrl(`${environment.hostUrl}${this.router.url}`);
        this.setLanguageTag(this.translate.currentLang || 'de');

        // Add Google Site Verification
        // <meta name="google-site-verification" content="fs2pB7RFKwfkUSUfFVVRdhkGgaJvY0A71FWXWh1V39c" />
        if (environment.name === 'production') {
            this.tagName('google-site-verification', 'fs2pB7RFKwfkUSUfFVVRdhkGgaJvY0A71FWXWh1V39c');
        }

        this.subRouter = this.router.events
            .pipe(filter<any>(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.tagProperty('og:url', `${environment.hostUrl}${event.urlAfterRedirects}`);
                if (this.i18n.isStaticLanguageRoute) {
                    // remove language part in URL
                    const lang = this.translate.currentLang.split('-')[0];
                    const url = this.router.url;
                    const canonical = url.slice(url.indexOf(lang) + lang.length);
                    this.updateCanonicalUrl(`${environment.hostUrl}${canonical}`);
                    this.updateAlternateUrls(`${canonical}`);
                } else {
                    this.updateCanonicalUrl(`${environment.hostUrl}${this.router.url}`);
                }
            });

        this.subLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setLanguageTag(event.lang);
        });
    }

    /**
     * Cancels the router subscription
     */
    public destroy() {
        if (this.subRouter) this.subRouter.unsubscribe();
    }

    /**
     * Set page metadata
     * @param metadata
     */
    setPageMetadata(metadata: IMetaPageInfo | undefined) {
        if (!metadata) return;
        logger.debug('Setting page meta data', metadata);
        this.setTitle(metadata.title);
        this.setDescription(metadata.description);
        this.setAuthor(metadata.author);
        this.setKeyword(metadata.keywords);

        if (metadata.asset) {
            this.setAsset(metadata.asset);
        } else {
            this.setImage(metadata.image);
        }

        if (metadata.canonicalUrl) {
            this.updateCanonicalUrl(metadata.canonicalUrl);
        }

        if (metadata.lang) {
            this.setLanguageTag(metadata.lang);
        }

        if (metadata?.noIndex) {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
        } else if (environment.allowIndexing) {
            this.meta.removeTag('name="robots"');
        }

        if (metadata.property) {
            Object.keys(metadata.property).forEach(tag => {
                const value = metadata.property?.[tag];
                if (value) this.tagProperty(tag, value);
            });
        }

        if (metadata.name) {
            Object.keys(metadata.name).forEach(tag => {
                const value = metadata.name?.[tag];
                if (value) this.tagName(tag, value);
            });
        }
    }

    /**
     * Updates or creates <meta property="..."> tags
     * @param property
     * @param content
     */
    public tagProperty(property: string, content: string) {
        if (this.meta.getTag(`property='${property}'`)) {
            this.meta.updateTag({
                property,
                content,
            });
        } else {
            this.meta.addTag({
                property,
                content,
            });
        }
    }

    /**
     * Updates or creates <meta name="..."> tags
     * @param name
     * @param content
     */
    public tagName(name: string, content: string) {
        const escapedName = name.replace(/([:.])/g, '\\$1');
        if (this.meta.getTag(`name=${escapedName}`)) {
            this.meta.updateTag({
                name,
                content,
            });
        } else {
            this.meta.addTag({
                name,
                content,
            });
        }
    }

    /**
     * Removes <meta property="..."> tags
     * @param property
     */
    removePropertyTag(property: string) {
        try {
            if (this.meta.getTag(`property='${property}'`)) {
                this.meta.removeTag(`property='${property}'`);
            }
        } catch (e) {
            // Nothing to do here
            console.error(e);
        }
    }

    /**
     * Removes <meta name="..."> tags
     * @param name
     */
    removeNameTag(name: string) {
        try {
            const escapedName = name.replace(/([:.])/g, '\\$1');
            if (this.meta.getTag(`name=${escapedName}`)) {
                this.meta.removeTag(`name=${escapedName}`);
            }
        } catch (e) {
            // Nothing to do here
        }
    }

    /**
     * Sets page title, twitter meta title, og meta title
     * @param title
     */
    setTitle(title?: string[] | string): SeoService {
        logger.debug('Setting title', title);
        if (title) {
            if (Array.isArray(title)) title = title.join(' | ');

            this.title.setTitle(title);
            this.tagName('title', title);
            this.twitterMeta.setTitle(title);
            this.ogMeta.setTitle(title);
        } else {
            this.title.setTitle(SeoDefault.siteTitle);
            this.tagName('title', SeoDefault.siteTitle);
            this.twitterMeta.setTitle(SeoDefault.siteTwitterCard.title);
            this.ogMeta.setTitle(SeoDefault.siteOgMeta.title);
        }
        return this;
    }

    /**
     * Sets page image, twitter meta image, og meta image.
     * This will also reset the og:image:width|height|type tags.
     * @param image
     */
    setImage(image?: string): SeoService {
        if (image) {
            if (image.startsWith('/')) {
                image = `${environment.hostUrl}${image}`;
            }
            this.twitterMeta.setImage(image);
            this.ogMeta.setImage(image);
            this.removePropertyTag('og:image:width');
            this.removePropertyTag('og:image:height');
            this.removePropertyTag('og:image:type');
        } else {
            this.setAsset(SeoDefault.siteAsset);
        }
        return this;
    }

    /**
     * Sets page image, twitter meta image, og meta image
     * @param asset
     */
    setAsset(asset?: MetaAssetType): SeoService {
        if (!asset) asset = SeoDefault.siteAsset;
        if ('preview' in asset) {
            this.twitterMeta.setImage(asset.preview);
            this.ogMeta.setImage(asset.preview);
            this.ogMeta.set('image:type', asset.mimeType);
        }
        if ('source' in asset && 'width' in asset && 'height' in asset) {
            this.twitterMeta.setImage(asset.source);
            this.ogMeta.setImage(asset.source);
            this.ogMeta.set('image:width', `${asset.width}`);
            this.ogMeta.set('image:height', `${asset.height}`);
            this.ogMeta.set('image:type', asset.mimeType);
        }
        return this;
    }

    /**
     * Resets all relevant tags to default values
     */
    reset(): SeoService {
        this.twitterMeta.setDefaults();
        this.ogMeta.setDefaults();

        this.title.setTitle(SeoDefault.siteTitle);
        this.meta.updateTag({
            name: 'keywords',
            content: SeoDefault.siteKeyword,
        });
        this.setDescription();
        this.setImage();
        this.meta.removeTag('author');
        return this;
    }

    /**
     * Sets the author tag
     * @param author
     */
    setAuthor(author?: string): SeoService {
        if (author) {
            if (this.meta.getTag('author')) {
                this.meta.updateTag({
                    name: 'author',
                    content: author,
                });
            } else {
                this.meta.addTag({
                    name: 'author',
                    content: author,
                });
            }
        } else {
            this.meta.removeTag('author');
        }
        return this;
    }

    setKeyword(keyword?: string): SeoService {
        if (keyword != null) {
            this.tagName('keywords', keyword);
        } else {
            this.tagName('keywords', SeoDefault.siteKeyword);
        }
        return this;
    }

    setKeywords(keyword?: string[]): SeoService {
        if (keyword) {
            this.tagName('keywords', keyword.join(','));
        }
        return this;
    }

    setDescription(description?: string): SeoService {
        if (description) {
            const content = this.processDescription(description);
            this.meta.updateTag({
                name: 'description',
                content,
            });
            this.twitterMeta.setDescription(content);
            this.ogMeta.setDescription(content);
        } else {
            this.meta.updateTag({
                name: 'description',
                content: SeoDefault.siteDescription,
            });
            this.twitterMeta.setDescription(SeoDefault.siteTwitterCard.description);
            this.ogMeta.setDescription(SeoDefault.siteOgMeta.description);
        }
        return this;
    }

    setTwitterCard(twitter: ITwitterMeta): SeoService {
        if (twitter) {
            this.twitterMeta
                .setCreator(twitter.creator)
                .setSite(twitter.site ?? SeoDefault.siteTwitterCardSite)
                .setImage(twitter.image)
                .setTitle(twitter.title)
                .setDescription(twitter.description);
        }
        return this;
    }

    /**
     * Sets the <html lang="${lang}" /> attribute
     * @param lang
     */
    setLanguageTag(lang: string) {
        const htmlElement = this._document.getElementsByTagName('html')[0];
        htmlElement.setAttribute('lang', lang.split('-')[0]);
    }

    /**
     * Checks if the browser supports the webp format
     * @private
     */
    private checkWebPFormatSupport() {
        if (!isPlatformServer(this.platformId)) {
            const elem = this._document.createElement('canvas');

            if (!!(elem.getContext && elem.getContext('2d'))) {
                // check if WebP representation works
                this._supportWebP = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
            } else {
                // very old browser like IE 8, canvas not supported
                this._supportWebP = false;
            }
        }
    }

    /**
     * Updated <link rel='canonical' href="${$url}" /> element
     * @param url
     */
    updateCanonicalUrl(url: string) {
        let element = this._document.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            this.createLinkForCanonicalURL(url);
        } else {
            element.setAttribute('href', this.clearUrlFromQuery(url));
        }
    }

    /**
     * Updated <link rel='canonical' href="${$url}" /> element
     * @param url
     */
    updateAlternateUrls(url: string) {
        for (const lang of this.i18n.supportedLanguages) {
            const langUrl = lang.split('-')[0];
            const alternateUrl = this.clearUrlFromQuery(`/${langUrl}${url}`);
            let element = this._document.querySelector(`link[rel='alternate'][hreflang='${langUrl}']`) || null;
            if (element == null) {
                this.createLinkForAlternateURL(`${environment.hostUrl}${alternateUrl}`, langUrl);
            } else {
                element.setAttribute('href', `${environment.hostUrl}${alternateUrl}`);
            }
        }

        const lang = environment.defaultLanguage;
        const langUrl = lang.split('-')[0];
        const alternateUrl = this.clearUrlFromQuery(`/${langUrl}${url}`);
        let element = this._document.querySelector(`link[rel='alternate'][hreflang='x-default']`) || null;
        if (element == null) {
            this.createLinkForAlternateURL(`${environment.hostUrl}${alternateUrl}`, 'x-default');
        } else {
            element.setAttribute('href', `${environment.hostUrl}${alternateUrl}`);
        }
    }

    /**
     * Creates <link rel='canonical' href="${$url}" /> element
     * @param url
     * @private
     */
    private createLinkForCanonicalURL(url: string) {
        const link: HTMLLinkElement = this._document.createElement('link');
        link.rel = 'canonical';
        link.href = this.clearUrlFromQuery(url);
        const head = this._document.getElementsByTagName('head')[0];
        head.appendChild(link);
    }

    /**
     * Creates <link rel="alternate" hreflang="${lang}" href="${url}" /> element
     * @param url route
     * @param lang language key
     * @private
     */
    private createLinkForAlternateURL(url: string, lang: string) {
        const link: HTMLLinkElement = this._document.createElement('link');
        link.rel = 'alternate';
        link.hreflang = lang.toLowerCase();
        link.href = this.clearUrlFromQuery(url);
        const head = this._document.getElementsByTagName('head')[0];
        head.appendChild(link);
    }

    private clearUrlFromQuery(url: string): string {
        return url.split('?')[0];
    }

    private processDescription(description: string): string {
        const sub = description.substr(0, 500);
        const sentences = sub.split(/\.\s*/).slice(0, 3).join('. ');
        return sentences.replace(/<\/?[^>]{1,5}>/g, '');
    }
}
