import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const DEVICE_TOKEN_EVENT_NAME = 'DeviceTokenEvent';
export interface IDeviceTokenPayload extends IResponsePayload {
    /** The device token */
    token?: string;
    platform: 'ios' | 'android' | 'windows' | 'macos' | 'web';
    deviceName: string | null;
    modelName: string | null;
}
export type IDeviceTokenEvent = IWebViewEvent<any, IDeviceTokenPayload>;

export class DeviceTokenEvent extends AWebViewEvent<any, IDeviceTokenPayload> implements IDeviceTokenEvent {
    constructor() {
        super(DEVICE_TOKEN_EVENT_NAME);
    }
}

export default [DEVICE_TOKEN_EVENT_NAME, DeviceTokenEvent];
