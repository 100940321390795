import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Logger } from '@core/utils/logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface LayoutState {
    /**
     * Show the footer
     * <code>(default: true)</code>
     */
    showFooter: boolean;
    /**
     * Show the secondary footer
     * <code>(default: true)</code>
     */
    showSecondaryFooter: boolean;
    /**
     * Show the subheader
     * <code>(default: true)</code>
     */
    showSubHeader: boolean;
    /**
     * Show the header
     * <code>(default: true)</code>
     */
    showHeader: boolean;
    /**
     * Block scrolling
     * <code>(default: false)</code>
     */
    blockScrolling: boolean;
    /**
     * Show the tab bar
     * <code>(default: true)</code>
     */
    showTabBar: boolean;
}

export const initialState: LayoutState = {
    showFooter: true,
    showSecondaryFooter: true,
    showSubHeader: true,
    showHeader: true,
    blockScrolling: false,
    showTabBar: true,
};

const logger = new Logger('LayoutService');

/**
 * A little helper function to define the layout state for a route
 * @param config
 * @param data
 */
export function useLayout(config: Partial<LayoutState>, data?: any | { reuseRoute?: boolean; title?: string }): any {
    return { ...data, layout: { ...config } };
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    protected state: LayoutState;
    private readonly stateSubject = new BehaviorSubject<LayoutState>({ ...initialState });

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.state = { ...initialState };
        this.stateSubject.next(this.state);

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    // Find the layout state for the current route
                    let route = this.activatedRoute;
                    let layoutConfig = { ...initialState };

                    // Iterate the route tree to find the layout states
                    while (route.firstChild) {
                        route = route.firstChild;
                        if (route.snapshot.data?.layout) {
                            layoutConfig = { ...layoutConfig, ...route.snapshot.data.layout };
                        }
                    }

                    if (route.snapshot.data?.layout) {
                        layoutConfig = { ...layoutConfig, ...route.snapshot.data.layout };
                    }
                    // Update the layout state with the merged layout config
                    return layoutConfig;
                }),
            )
            .subscribe(layout => {
                this.updateState(layout);
            });
    }

    init() {
        logger.debug('INIT');
    }

    /**
     * Set the layout state. (assigns the new value to the current state)
     * @param key
     * @param value
     */
    setState<T extends keyof LayoutState>(key: T, value: LayoutState[T]) {
        logger.debug('SET', key, 'TO', value);
        this.state[key] = value;
        this.stateSubject.next(this.state);
    }

    /**
     * Update the layout state with a partial value. (merges the new value with the current state)
     * @param value
     */
    updateState(value: Partial<LayoutState>) {
        logger.debug('PATCH', value);
        this.state = { ...this.state, ...value };
        this.stateSubject.next(this.state);
    }

    /**
     * Select an Observable of a layout state property
     * @param selector
     */
    select<R>(selector: (state: LayoutState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector));
    }

    /**
     * Get the current layout state value
     * @param key
     */
    currentState<T extends keyof LayoutState>(key: T): LayoutState[T] {
        return this.stateSubject.value[key];
    }
}
