<div id="top-search-section" *ngIf="showSecondaryFooter$ | async">
    <div class="container">
        <h2 class="text-h2 title-h2 text-white spacing-mb-small">{{ 'label.topCategories' | translate }}</h2>
        <div class="row">
            <ng-container *ngFor="let display of topCategoryDisplay; let i = index">
                <div class="col-6 col-md-3 d-md-block" [class.d-none]="i >= 4">
                    <h3 class="category-title text-h4 title-h4">{{ display.collectionName }}</h3>
                    <ul class="list-unstyled">
                        <li *ngFor="let subcat of display.categories">
                            <a class="category paragraph-small" [routerLink]="[display.collectionSlug, subcat.code]">
                                {{ subcat.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
    <img
        alt="footer-rocket"
        class="rocket"
        ngSrc="/assets/icon/category-footer/footer-rocket.svg"
        height="72"
        width="72" />
    <img
        alt="footer-house"
        class="house"
        ngSrc="/assets/icon/category-footer/footer-house.svg"
        height="72"
        width="61" />
    <img
        alt="footer-people"
        class="people"
        ngSrc="/assets/icon/category-footer/footer-people.svg"
        height="72"
        width="60" />
</div>
