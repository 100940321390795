import { AWebViewEvent, IRequestPayload, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const PAYPAL_EVENT_NAME = 'PaypalPaymentEvent';

export interface IPayPalRequestPayload extends IRequestPayload {
    orderId: string;
    /** Forward link for paypal payment site, generated by the backend */
    forwardLink: string;
}

export interface IPayPalResponsePayload extends IResponsePayload {
    success: boolean;
    reason?: string;
}

export type IPayPalEvent = IWebViewEvent<IPayPalRequestPayload, IPayPalResponsePayload>;

export class PayPalEvent extends AWebViewEvent<IPayPalRequestPayload, IPayPalResponsePayload> implements IPayPalEvent {
    constructor() {
        super(PAYPAL_EVENT_NAME);
    }
}

export default [PAYPAL_EVENT_NAME, PayPalEvent];
