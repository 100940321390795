import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fainin-feedback-selector',
    templateUrl: './feedback-selector.component.html',
    styleUrls: ['./feedback-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackSelectorComponent {
    @Input() rating: number;
    @Output() ratingChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    changeRating(value: number) {
        this.rating = value;
        this.ratingChange.emit(this.rating);
    }
}
