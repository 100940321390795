import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'img[faininFallbackImage]',
})
export class FallbackImageDirective {
    @Input() faininFallbackImage: string | undefined;
    constructor(private eRef: ElementRef) {}

    @HostListener('error')
    loadFallbackOnError() {
        const element: HTMLImageElement = this.eRef.nativeElement;
        element.src = this.faininFallbackImage || '/assets/image/fallback/logo.png';
    }
}
