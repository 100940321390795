import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { buildIconLibrary } from './icon-library';

@NgModule({
    imports: [FontAwesomeModule],
    exports: [FontAwesomeModule],
})
export class FontAwesomeCoreModule {
    constructor(library: FaIconLibrary) {
        buildIconLibrary(library);
    }
}
