export default {
    noInvoices: 'Счета не найдены.',
    noImageInForm: 'Изображение не было выбрано. Пожалуйста, добавьте один!',
    noImageUploaded: 'Не удалось загрузить изображение',
    imageSize:
        'Размер одного файла не должен превышать {{size}}МБ. Пожалуйста, попробуйте использовать другое изображение.',
    unknownSize: 'Этот файл не может быть выбран, потому что его размер неизвестен.',
    addAssetsToAlbum: 'Произошла ошибка при добавлении изображения в альбом.',
    unexpectedError: 'Что-то пошло не так.',
    noCameraPermission: 'Нет разрешений',
};
