export default {
    cameraPermissionsHeader: 'Разрешения на камеру',
    cameraPermissionsBody: 'Пожалуйста, включите разрешение камеры в настройках браузера и обновите страницу!',
    offerShipping: 'Offer shipping/delivery',
    shippingPrice: 'Цена доставки*',
    shippingNotice: 'Shipping must be done before the first day of rental.',
    hourlyShouldBeLower: 'Часовая цена должна быть ниже дневной!',
    choosePreviewImage: 'Выберите изображение для предварительного просмотра двойным нажатием',
    rentalPriceIsStaggered: 'Ваша цена аренды градуирована, со дня X арендаторы получат следующие скидки',
    egPrice: 'Например, цена',
    hourlyRental: 'Предложение почасовой аренды',
    makeSureBackground: 'Обеспечьте спокойный фон',
    thinkGoodExposure: 'Не забывайте о хорошей экспозиции',
    photoOnlyRentalOrder: 'Старайтесь фотографировать только порядок аренды',
    updateProduct: 'Обновление продукта',
    uploadProduct: 'Загрузить продукт',
    addressInput: 'Адресный ввод',
    noSavedAddresses: 'Нет сохраненных адресов',
    savedAddresses: 'Сохраненные адреса',
    loading: 'Загрузка',
    productLocation: 'Расположение продукта',
    howPriceIsCalculated: 'Мы предлагаем, чтобы почасовая цена составляла 50% от вашей дневной цены',
    findOutMoreHere: 'Узнайте больше здесь',
    weCalculateFixedScale: 'Мы рассчитываем еженедельные и ежемесячные цены с фиксированной шкалой.',
    priceGrad: 'Градация цен',
    graduatedTotalDay1: 'За 1 день аренды',
    graduatedTotalDay7: 'За 7 дней аренды',
    graduatedTotalDay30: 'За 30 дней аренды',
    gradDay: 'с {{day}}-го дня',
    gradPriceFullSentence:
        'Градация цен со 2-го дня: -- € | со 3-го дня: -- € | со 4-го дня: -- € | со 5-го дня: -- € | со 6-го дня: -- € | со 7-го дня: -- € | с 30-го дня: -- €',
    gradPriceFullSentenceMobile:
        'Градация цен со 2-го дня: -- € | со 3-го дня: -- € | \nсо 4-го дня: -- € | со 7-го дня: -- € | с 30-го дня: -- €',
    maxCharacters: 'Максимум 500 символов',
    productDescription: 'Описание продукта',
    galleryImage: 'Изображение галереи',
    photoTips: 'Фото советы',
    enterContactName: 'Ваше имя',
    enterStreetName: 'Номер вашей улицы',
    enterDistrictName: 'Ваш район',
    enterZip: '12345*',
    enterCityName: 'Ваш город*',
    enterCountryName: 'Введите название страны*',
    addNewLocation: 'Добавить новое местоположение',
    chooseLocation: 'Выберите место',
    productInformation: 'Информация о продукте',
    title: 'Название продукта*',
    uploadPhotos: 'Загружать фотографии',
    fromPhotos: 'Выберите из фотографий',
    fromCamera: 'Съемка с помощью фотоаппарата',
    category: 'Категория*',
    subCategory: 'Подкатегория*',
    description: 'Описание*',
    rentalPrice: 'Цена аренды',
    price: 'Ежедневная цена*',
    hourlyPrice: 'Почасовая цена*',
    optionalPrice: 'Цена в €',
    perDay: 'в день',
    perWeek: 'в неделю',
    perMonth: 'в месяц',
    purchasePrice: 'Цена покупки',
    purchaseDate: 'Дата покупки',
    uploadLocation: 'Загрузить местоположение',
    save: 'Сохранить',
    deleteLocation: 'Удалить местоположение',
    addLocation: 'Добавить местоположение',
    date: 'Дата',
    contactName: 'Имя',
    street: 'Улица',
    district: 'Район/Квартал',
    zip: 'Почтовый индекс*',
    city: 'Город*',
    country: 'Страна*',
    saveLocation: 'Безопасное место',
    getCurrentLocation: 'Текущее местоположение',
    enableLocationTitle: 'Использование местоположения не одобрено',
    locationDisclaimer: 'Точное местоположение никогда не публикуется. Через поиск виден только город.',
    enableLocation:
        'Пожалуйста, разрешите использование вашего местоположения в настройках браузера, чтобы использовать ваше текущее местоположение.',
    enableLocationServiceTitle: 'Использование местоположения отключено!',
    locationUploaded: 'Ваше загруженное местоположение будет отображаться в вашем списке.',
    locationRetrieved: 'Местоположение найдено',
    locationDeleted: 'Местоположение удалено',
    locationListUpdated: 'Список ваших загруженных местоположений был обновлён',
    productUploaded: 'Загруженный вами продукт будет отображаться в ваших списках',
    disclaimer: 'Эта информация не актуальна до момента подачи иска и может быть изменена и добавлена позже',
    success: {
        addMoreProducts: 'Add more products',
        gotoArticle: 'Go to Article',
        weLetYouKnow: "We'll let you know when someone sends you a rental request.",
        productUploaded: 'Your product has been uploaded.',
        congrats: 'Congratulations!',
        share: 'Share product',
    },
    delete: 'Delete',
    productSaved: 'Product saved!',
    product: 'Product',
    upload: 'Upload Product',
    duplicate: 'Duplicate',
    report: 'Report Product',
    create: 'Create',
    community: {
        title: 'Community',
        info: 'Выберите, в каких группах вы хотите, чтобы отображался ваш продукт.',
        placeHolder: 'Sales channel',
        public: 'Public',
    },
    pricing: {
        title: 'Установите свою цену',
        subTitle: 'Вы можете изменить ее в любое время',
        details: {
            title: 'Детали цены',
            basePrice: 'Базовая цена',
            serviceFee: 'Сервисный сбор для арендатора',
            comissionFee: 'Ваша комиссионная плата',
            total: 'Арендатор платит',
            earning: 'Вы получаете',
        },
        priceTierAscend: 'Price tiers should be in ascending order!',
    },
    advancedPricing: {
        title: 'Ценовые категории',
        subTitle: 'Настройте скидки для вашего предложения.',
        weekend: 'Цена за выходные',
        week: 'Цена за неделю',
        month: 'Цена за месяц',
        long: 'Цена за длительный срок',
        default: 'Цена',
    },
    error: {
        required: 'Требуется хотя бы одно изображение.',
        minlength: 'Вы должны загрузить хотя бы одно изображение.',
        maxlength:
            'Вы можете загрузить максимум {{requiredLength}} изображений. В настоящее время выбрано: {{actualLength}}.',
    },
};
