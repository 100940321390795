import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fainin-empty-state-card',
    templateUrl: './empty-state-card.component.html',
    styleUrls: ['./empty-state-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateCardComponent {
    @Input() routerLink: string;

    constructor() {}
}
