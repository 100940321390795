export default {
    aboutUs:
        'Мы трое студентов, нас зовут Йоханн, Макс и Кевин -   Мы хотим использовать fainin для улучшения мира и принесения пользы всем участникам. Мы с нетерпением ждем вашего сообщения.',
    yourSharingCommunity: 'Ваше сообщество обмена',
    getToKnowUs: 'Узнайте нас',
    sharingIsOwning: '#ДелитьсяЭтоНовоеОбладание',
    becomeFamily: 'Стань частью семьи fainin',
    title: 'fainin',
    readyForCommunity: 'Готовы к самому безопасному сообществу совместного использования?',
    partner: 'Партнер',
    caption: {
        lend: 'Одалживать всё',
        lend2: 'что вы хотите',
        rent: 'Одолжите всё',
        rent2: 'что вам нужно',
    },
    tile: {
        safety: {
            title: 'Доверие',
            subtitle: '– Design-For-Trust в fainin',
            content:
                'Вы беспокоитесь о своих вещах? Конечно, мы это понимаем! Именно поэтому в fainin мы разработали дизайн для доверия.',
            point: ["Страхование до 15'000 €", '100% проверенных пользователей', 'Документированный процесс аренды'],
        },
        what: {
            title: 'Наша цель',
            subtitle: '– Самое безопасное сообщество совместного использования',
            content: `Мы хотим сделать ваш город общественным местом. <br>
И мы предлагаем вам возможность получать прибыль в беспроигрышных ситуациях вместе с fainin! Вместе мы создадим
самое безопасное сообщество совместного пользования в вашем районе. <b>#ДелитьсяЭтоНовоеОбладание</b>
            <br><br>Вы присоединитесь к нам?`,
        },
        app: {
            title: 'Ваше приложение для совместного использования',
            subtitle: 'Для iOS и Android',
            content: `Новое приложение fainin обеспечивает надежное заимствование. Чтобы первыми использовать совершенно новое
приложение, вы можете следить за социальными сетями fainin. <br>
<br><br>Bist Вы готовы?`,
        },
    },
    cta: {
        flying: {
            title: 'У вас еще есть что-нибудь, что валяется без дела?',
            label: 'Одолжи это на fainin!',
            action: 'Одолжить сейчас',
        },
    },
    blog: {
        title: 'Блог',
        subtitle: 'Поговорим об экономике совместного пользования',
        text:
            'Если вы хотите внести свой вклад в тему устойчивости, социальной близости или совместного использования, ' +
            'тогда не стесняйтесь писать нам в социальных сетях или по электронной почте contact@fainin.com.',
    },
    topSeller: {
        title: 'Представленные товары',
        subtitle: 'Лучшие товары рядом с вами',
    },
    newsletter: {
        title: 'Не пропустите ни одной новости!',
        text: 'Подпишитесь на нашу рассылку и получайте регулярные новости от семьи Фейнин.',
        button: 'Подписаться на рассылку новостей',
    },
};
