export enum EOrderState {
    /** @deprecated No used anymore! */
    request = 'request',
    ValidatingCustomer = 'ValidatingCustomer',
    ValidationPending = 'ValidationPending',
    PaymentAuthorized = 'PaymentAuthorized',
    ArrangingPayment = 'ArrangingPayment',
    PaymentSettled = 'PaymentSettled',
    OrderDelivered = 'OrderDelivered',
    Shipped = 'Shipped',
    ShippedBack = 'ShippedBack',
    RentalEnded = 'RentalEnded',
    AwaitFeedback = 'AwaitFeedback',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    AwaitRefund = 'AwaitRefund',
    ItemDamaged = 'ItemDamaged',
}
