import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeCoreModule } from '@core/fontAwesome-core.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogButtonsDirective } from './dialog-buttons.directive';
import { DialogComponentOutletComponent } from './dialog-component-outlet.component';
import { DialogTitleDirective } from './dialog-title.directive';
import { ModalDialogComponent } from './modal-dialog.component';

@NgModule({
    declarations: [ModalDialogComponent, DialogTitleDirective, DialogButtonsDirective, DialogComponentOutletComponent],
    imports: [CommonModule, FontAwesomeCoreModule, TranslateModule],
    exports: [ModalDialogComponent, DialogTitleDirective, DialogButtonsDirective, DialogComponentOutletComponent],
})
export class SharedDialogModule {}
