export default {
    firstName: 'First Name',
    lastName: 'Last Name',
    fullName: 'Full Name',
    personalData: 'Personal Data',
    address: 'Billing Address',
    contact: 'Contact',
    aboutYou: 'About You',
    password: 'Password',
    passwordChange: 'Change Password',
    bank: 'Bank Details',
    bankInfo: '(not publicly visible)',
    save: 'Save',
    title: 'Edit Profile',
    streetAndNumber: 'Street & House No.',
    plz: 'Zip code',
    city: 'City',
    phoneNumber: 'For example: +4917534777777',
    email: 'Email Address',
    disclaimer: 'We will send you a confirmation email to confirm your new email address.',
    description: 'Description',
    oldPass: 'Old Password',
    newPass: 'New Password',
    birthday: 'Birthday',
    saved: 'Data Saved!',
    noUpdates: 'No new changes!',
    emailChanged: 'Email changed! To complete the process, please check your new email address to verify the changes.',
    dd: 'DD',
    loading: 'Loading...',
    emailRequired: 'Email Required!',
};
