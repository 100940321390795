import { CommunityDetail } from '@api/generated-types';

export function canJoinCommunity(
    community: Pick<CommunityDetail.Fragment, 'isMember' | 'visibility' | 'requirementFilters'> | null,
): boolean {
    return (
        !community?.isMember &&
        (community?.visibility === 'PUBLIC' ||
            (community?.visibility === 'CLOSED' && community?.requirementFilters?.length > 0))
    );
}
