export default {
    cameraPermissionsHeader: 'Дозволи камери',
    cameraPermissionsBody: 'Будь ласка, увімкніть дозволи на камеру в налаштуваннях браузера та оновіть сторінку!',
    offerShipping: 'Offer shipping/delivery',
    shippingPrice: 'Вартість доставки*',
    shippingNotice: 'Shipping must be done before the first day of rental.',
    hourlyShouldBeLower: 'Погодинна ціна повинна бути нижчою за добову!',
    choosePreviewImage: 'Виберіть зображення для попереднього перегляду подвійним тапом',
    rentalPriceIsStaggered: 'Ваша вартість оренди градуйована, з дня Х орендарі отримають наступні знижки',
    egPrice: 'Наприклад, ціна на',
    hourlyRental: 'Пропонуємо погодинну оренду',
    makeSureBackground: 'Переконайтеся, що у вас є тихий фон',
    thinkGoodExposure: "Пам'ятайте про хорошу експозицію",
    photoOnlyRentalOrder: 'Намагайтеся фотографувати тільки замовлення на прокат',
    updateProduct: 'Оновити продукт',
    uploadProduct: 'Завантажити продукт',
    addressInput: 'Введення адреси',
    noSavedAddresses: 'Немає збережених адрес',
    savedAddresses: 'Збережені адреси',
    loading: 'Завантаження',
    productLocation: 'Розташування продукту',
    howPriceIsCalculated: 'Ми пропонуємо погодинну оплату в розмірі 50% від Вашої добової вартості',
    findOutMoreHere: 'Дізнайтеся більше тут',
    weCalculateFixedScale: 'Ми розраховуємо тижневі та місячні ціни з фіксованою шкалою.',
    priceGrad: 'Градація цін',
    graduatedTotalDay1: 'За 1 день прокату',
    graduatedTotalDay7: 'За 7 днів прокату',
    graduatedTotalDay30: 'На 30 днів прокату',
    gradDay: 'з {{day}}-го дня',
    gradPriceFullSentence:
        'Градація цін з 2-го дня: -- € | з 3-го дня: -- € | з 4-го дня: -- € | з 5-го дня: -- € | з 6-го дня: -- € | з 7-го дня: -- € | з 30-го дня: -- €',
    gradPriceFullSentenceMobile:
        'Градація цін з 2-го дня: -- € | з 3-го дня: -- € | \nз 4-го дня: -- € | з 7-го дня: -- € | з 30-го дня: -- €',
    maxCharacters: 'Максимум 500 символів',
    productDescription: 'Опис продукту',
    galleryImage: 'Галерея Зображення',
    photoTips: 'Фото Поради',
    enterContactName: "Введіть ім'я контакту",
    enterStreetName: 'Введіть назву вулиці',
    enterDistrictName: 'Введіть назву району',
    enterZip: '12345*',
    enterCityName: 'Введіть назву міста*',
    enterCountryName: 'Введіть назву країни*',
    addNewLocation: 'Додати нове розташування',
    chooseLocation: 'Виберіть розташування',
    productInformation: 'Інформація про Продукт',
    title: 'Назва продукту*',
    uploadPhotos: 'Завантажити фотографії',
    fromPhotos: 'Виберіть з фотографій',
    fromCamera: 'Зйомка на камеру',
    category: 'Категорія*',
    subCategory: 'Підкатегорія*',
    description: 'Опис*',
    rentalPrice: 'Ціна оренди',
    price: 'Щоденна ціна*',
    hourlyPrice: 'Погодинна ціна*',
    optionalPrice: 'Ціна в євро',
    perDay: 'на день',
    perWeek: 'на тиждень',
    perMonth: 'на місяць',
    purchasePrice: 'Ціна покупки',
    purchaseDate: 'Дата покупки',
    uploadLocation: 'Місце покупки',
    save: 'Зберегти',
    deleteLocation: 'Видалити місцезнаходження',
    addLocation: 'Додати розташування',
    date: 'Дата',
    contactName: 'Контактна Особа',
    street: 'Вулиця',
    district: 'Район/округ',
    zip: 'Поштовий індекс*',
    city: 'Місто*',
    country: 'країна*',
    saveLocation: 'Безпечне місце',
    getCurrentLocation: 'Поточне місцезнаходження',
    enableLocationTitle: 'Використання місцезнаходження не схвалено',
    locationDisclaimer: 'Точне місцезнаходження ніколи не публікується. Через пошук видно лише місто.',
    enableLocation:
        'Будь ласка, дозвольте використання вашого місцезнаходження в налаштуваннях браузера, щоб використовувати ваше поточне місцезнаходження',
    enableLocationServiceTitle: 'Служба локації вимкнена!',
    locationUploaded: 'Ваше завантажене місцезнаходження з’явиться у вашому списку',
    locationRetrieved: 'Місцезнаходження отримано',
    locationDeleted: 'Місцезнаходження видалено!',
    locationListUpdated: 'Ваш список місць для завантаження оновлено',
    productUploaded: 'Ваш завантажений продукт буде показаний у ваших списках',
    disclaimer: 'Ця інформація актуальна лише у разі настання страхового випадку і може бути змінена та додана пізніше',
    success: {
        addMoreProducts: 'Add more products',
        gotoArticle: 'Go to Article',
        weLetYouKnow: "We'll let you know when someone sends you a rental request.",
        productUploaded: 'Your product has been uploaded.',
        congrats: 'Congratulations!',
        share: 'Share product',
    },
    delete: 'Delete',
    productSaved: 'Product saved!',
    product: 'Product',
    upload: 'Upload Product',
    duplicate: 'Duplicate',
    report: 'Report Product',
    create: 'Create',
    community: {
        title: 'Community',
        info: 'Виберіть, в яких групах ви хочете, щоб ваш продукт відображався.',
        placeHolder: 'Sales channel',
        public: 'Public',
    },
    pricing: {
        title: 'Встановіть свою ціну',
        subTitle: 'Ви можете змінити її в будь-який час',
        details: {
            title: 'Деталі ціни',
            basePrice: 'Базова ціна',
            serviceFee: 'Сервісний збір для орендаря',
            comissionFee: 'Ваша комісійна плата',
            total: 'Орендар сплачує',
            earning: 'Ви отримуєте',
        },
        priceTierAscend: 'Price tiers should be in ascending order!',
    },
    advancedPricing: {
        title: 'Цінові категорії',
        subTitle: 'Налаштуйте знижки для вашої пропозиції.',
        weekend: 'Ціна за вихідні',
        week: 'Ціна за тиждень',
        month: 'Ціна за місяць',
        long: 'Ціна за довготривалий термін',
        default: 'Ціна',
    },
    error: {
        required: 'Потрібно хоча б одне зображення.',
        minlength: 'Ви повинні завантажити щонайменше одне зображення.',
        maxlength: 'Ви можете завантажити максимум {{requiredLength}} зображень. Зараз обрано: {{actualLength}}.',
    },
};
