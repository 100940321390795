import about from './about';
import blog from './blog';
import community from './community';
import home from './home';
import landing from './landing';
import productDetail from './productDetail';
import productTemplate from './productTemplate';
import search from './search';

export default {
    about,
    blog,
    community,
    home,
    landing,
    productDetail,
    productTemplate,
    search,
};
