export default {
    hero: {
        summer: {
            page1: {
                title: 'Der Sommer ist da!',
                subTitle: 'Miete SUPs, (Schlauch-)Boote, Kayaks & andere Dinge für den Sommer.',
            },
            page2: {
                title: 'Verleihe alles',
                subTitle: 'Miete Bohrmaschinen, Stichsägen, Leitern statt sie neu zu kaufen.',
            },
            page3: {
                title: '24/7 Gamingzeit',
                subTitle: 'Miete Spielkonsolen, Controller, Beamer für Deine Gaming-Nights.',
            },
        },
        winter: {
            page1: {
                title: 'fainin',
                desktopTitle: 'fainin | Teilen ist das neue Haben',
                subTitle: 'Nebenbei verdienen',
                text: 'Vermiete Dinge, die Du nicht benutzt. Bis zu 15.000 € versichert & an verifizierte Mitglieder.',
                button: 'Kostenlos mitmachen',
            },
            page2: {
                title: 'Technik mieten',
                subTitle: 'Geld sparen',
                text: 'Günstig mieten und Geld sparen - egal ob Kamera, Bohrer, SUP, Soundboks oder Drohne.',
                button: 'Jetzt ausleihen',
            },
            page3: {
                title: 'Online vermieten',
                subTitle: 'Bist Du Student?',
                text: 'Wir bieten Deinen Kommilitonen beste Konditionen bei einer offiziellen Kooperation mit der Uni. Melde dich bei contact@fainin.com.',
                button: 'Rabatt sichern',
            },
        },
        button: {
            becomeRenter: '(Ver-)mieter:in werden',
            goToDashboard: 'Zum Profil',
        },
    },
    topSellers: {
        button: 'Günstige Mietangebote',
    },
    news: {
        title: 'Bekannt aus...',
        toTheArticle: 'Zum Artikel',
    },
    trust: {
        title: 'Geld verdienen per App',
        subTitle: 'Geld sparen & verdienen ist einfach! Mit fainins Rundum-sorglos-Paket:',
        cards: {
            card1: {
                statement: 'Angst um Deine Mietangebote?',
                description:
                    'Keine Sorge! Die Ergo versichert jede Vermietung bis zu 15.000€. Dank unserem einzigartigen Design-For-Trust ist jeder Verleih über fainin vollständig abgesichert.',
            },
            card2: {
                statement: '100% verifizierter Nutzer:innen',
                description:
                    'Machst Du Dir Sorgen um Deine Sachen? Das verstehen wir natürlich! Deshalb haben wir fainin designed-for-trust.',
            },
            card3: {
                statement: 'Dokumentierter Verleihprozess',
                description: 'Das Design-For-Trust erfasst die volle Dokumentation während der Verleihung.',
            },
            card4: {
                statement: '... Dein Design-For-Trust!',
                description:
                    'Versicherung, Verifizierung, Smart Payment und ein dokumentierter Verleihprozess machen fainin zur sichersten Sharing Community in Deutschland.',
            },
        },
        button: 'Jetzt Geld verdienen',
    },
    app: {
        reviews: {
            review1: {
                title: 'Equipment sicher vermieten.',
                description:
                    'Christian Wolf (Wolf-Record) digitalisiert die Vermietung von Eventequipment & verdient über einen zusätzlichen Umsatzkanal.',
            },
            review2: {
                title: 'Es ist schön mehr zu teilen.',
                description:
                    'Als Schülerin liebt Laini die Möglichkeit beim Vermieten nebenbei etwas Taschengeld zu verdienen.',
            },
            review3: {
                title: 'Unvergessliche Tage beim Mieten',
                description: 'Johanna liebt es beim Mieten unvergessliche Momente zu sammeln. ',
            },
        },
    },
    campaign: {
        title: 'Aktuelle Themen',
        text: 'Lerne von den effizientesten Vermieter:innen - egal ob Verleihunternehmen, Handelspartner oder Kleinunternehmer:innen. In der sichersten Sharing Community profitieren alle Teilnehmer:innen. ',
    },
    nearProducts: {
        title: 'Mietangebote in Deiner Nähe',
        subtitle: 'Günstig mieten in der Nähe',
        button: 'Günstige Mietangebote',
    },
    blog: {
        title: 'Vermieter:innen Blog',
        subtitle: 'Lass uns über die Sharing Economy sprechen',
        text: 'Lerne von den effizientesten Vermieter:innen - egal ob Verleihunternehmen, Handelspartner oder Kleinunternehmer:innen. In der sichersten Sharing Community profitieren alle Teilnehmer:innen.',
    },
    gif: {
        title: 'Sicher Mieten & Vermieten',
        text: 'Bei fainin ist das Vermieten und Teilen genauso sicher wie mit Freund:innen oder Bekannten aus der Nachbarschaft. ',
        button: 'Jetzt Registrieren',
    },
    newsletter: {
        title: 'Keine News mehr verpassen!',
        text: 'Melde dich für unseren Newsletter an und du erhältst regelmäßig Updates aus der fainin Family.',
        button: 'Newsletter abonnieren',
    },
    aboutUs:
        'Wir sind drei Studenten und heißen Johann, Max und Kevin – wir wollen mit fainin die Welt verbessern und alle Teilnehmer profitieren lassen. Wir freuen uns über Deine Nachricht.',
    yourSharingCommunity: 'Ihre Sharing-Community',
    getToKnowUs: 'Lern uns kennen',
    sharingIsOwning: '#TeilenIstDasNeueHaben',
    becomeFamily: 'Werde Teil der fainin Family',
    title: 'fainin',
    readyForCommunity: 'Bereit für die sicherste Sharing Community?',
};
