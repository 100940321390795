<div [class.d-none]="!open" class="action-sheet-boundary" #actionSheetBoundary></div>
<div class="action-sheet-bottom"></div>
<div
    class="action-sheet"
    [@fadeInOut]="{ value: open, params: { top: upperBoundary } }"
    cdkDrag
    [cdkDragDisabled]="disableDrag"
    [cdkDragBoundary]="actionSheetBoundary"
    cdkDragLockAxis="y"
    (cdkDragMoved)="onDragMove($event)"
    (cdkDragEnded)="onDragEnded($event)">
    <div class="d-flex justify-content-between action-sheet-header" cdkDragHandle>
        <img
            *ngIf="hasBackButton"
            class="back"
            src="/assets/icon/feedback/back-arrow.svg"
            alt="{{ 'asset.pageHeader.back' | translate }}"
            width="18"
            height="18"
            (click)="feedbackPrevStep.emit()" />
        <div class="text-h4">
            {{ title }}
        </div>
        <button
            (click)="doClose()"
            class="btn action-close"
            attr.aria-label="{{ 'label.closeActionSheet' | translate }}">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="action-sheet-content" [class.tabbar-open]="tabBarOpen$ | async">
        <ng-content></ng-content>
    </div>
</div>
