export default {
    button: {
        label: 'Перейти до статті',
    },
    alt: {
        imageBlog: 'Попереднє зображення для статті {{slug}}',
        imageCampaign: 'Попередній перегляд зображення для кампанії {{title}}',
        fallbackImageBlog: 'Запасне зображення для статті {{slug}}',
        fallbackImageCampaign: 'Запасне зображення для кампанії {{title}}',
    },
};
