export default {
    intro: 'С сообществами Fainin мы предоставляем вам возможность эксклюзивного кредитования в вашем университете, месте работы, спортивном клубе и многом другом.',
    requestCommunity: 'Если вы хотите создать сообщество, отправьте нам короткое письмо на адрес contact@fainin.com.',
    recommended: 'Рекомендации для вас:',
    join: 'Присоединиться',
    leave: 'Покинуть',
    show: 'Показать',
    allProducts: 'Все продукты',
    requiresLogin: 'Для вступления в сообщество вам необходимо войти в систему',
    section: {
        info: 'Информация',
        members: 'Участники',
        products: 'Продукты',
    },
};
