<div class="app-downloads">
    <div class="app-links d-flex">
        <div class="app-store mr-3 d-flex flex-column align-items-center">
            <a
                href="https://apps.apple.com/de/app/fainin/id1591056004"
                data-platform="ios"
                class=""
                rel="noreferrer"
                target="_blank"
                role="button"
                attr.aria-label="{{ 'label.downloadIOSApp' | translate }}">
                <img
                    src="/assets/icon/figma/DownloadApple.svg"
                    alt="{{ 'asset.appleAppstoreIcon' | translate }}"
                    width="151"
                    height="45" />
            </a>
            <ng-container *ngIf="withQRCodes">
                <div class="app-qr-codes mt-4">
                    <img
                        class="qr-code"
                        src="/assets/image/smartphone/qr-codes/app-store-qr-code.svg"
                        alt="Fainin App Store QR-Code" />
                </div>
            </ng-container>
        </div>
        <div class="google-play d-flex flex-column align-items-center">
            <a
                href="https://play.google.com/store/apps/details?id=com.fainin.share"
                data-platform="android"
                rel="noreferrer"
                target="_blank"
                role="button"
                attr.aria-label="{{ 'label.downloadAndroidApp' | translate }}">
                <img
                    src="/assets/icon/figma/DownloadGoogle.svg"
                    alt="{{ 'asset.googlePlaystoreIcon' | translate }}"
                    width="151"
                    height="45" />
            </a>
            <ng-container *ngIf="withQRCodes">
                <div class="app-qr-codes mt-4">
                    <img
                        class="qr-code"
                        src="/assets/image/smartphone/qr-codes/google-play-qr-code.svg"
                        alt="Fainin Play Store QR-Code" />
                </div>
            </ng-container>
        </div>
    </div>
</div>
