<div class="d-flex flex-row flex-grow-1 justify-content-end" id="connected-menu-buttons">
    <!-- Favorite button (hidden for now) -->
    <button *ngIf="false" class="menu-button">
        <img
            alt="{{ 'asset.accountMenu.accountMenuIconSignedIn' | translate }}"
            height="24"
            src="/assets/icon/figma/heart.svg"
            width="24" />
    </button>

    <button
        (click)="navigateToRentals()"
        *ngIf="(signedIn$ | async) && isDesktop"
        class="menu-button sub"
        onboarding-id="rentals-menu-button">
        <img
            [nz-tooltip]="'navigation.rent' | translate"
            nzTooltipPlacement="bottom"
            alt="{{ 'asset.rentalsIcon' | translate }}"
            height="24"
            src="/assets/icon/figma/rentals-desktop.svg"
            width="24" />
        <ng-container *ngIf="unreadOrdersRequests > 0">
            <span class="notification-dot-rentals"></span>
        </ng-container>
    </button>

    <!-- Toggle account icon -->
    <ng-container *ngIf="signedIn$ | async; else noAccountButton">
        <button (click)="navigateToDashboard()" class="menu-button sub">
            <img
                class="profile-icon"
                alt="{{ 'asset.accountMenu.accountMenuIconSignedIn' | translate }}"
                height="32"
                src="{{ profileImageUrl }}"
                width="32" />
            <ng-container *ngIf="unreadMessagesCount > 0 && isDesktop">
                <span class="notification-dot-rentals">{{ unreadMessagesCount }}</span>
            </ng-container>
        </button>
    </ng-container>
    <ng-template #noAccountButton>
        <button (click)="closeSearchAndNavigateSignIn()" class="menu-button sub">
            <img
                alt="{{ 'asset.accountMenu.accountMenuIconUnauthenticated' | translate }}"
                height="24"
                src="/assets/icon/figma/account-logged-out.svg"
                width="24" />
            <span *ngIf="firstVisit$ | async" class="notification-dot"></span>
        </button>
    </ng-template>

    <ng-container *ngTemplateOutlet="menuTemplateRef"></ng-container>
</div>

<ng-template #menuTemplateRef>
    <div (click)="onMenuClicked($event)" *ngIf="!drawerVisible" class="menu-button">
        <img
            alt="{{ 'asset.accountMenu.burgerMenuIcon' | translate }}"
            height="48"
            src="/assets/icon/menu.svg"
            width="48" />
    </div>
    <div (click)="onCloseMenuClicked($event)" *ngIf="drawerVisible" class="menu-button">
        <img
            alt="{{ 'asset.accountMenu.closeDrawerIcon' | translate }}"
            class="close-button"
            height="24"
            src="/assets/icon/figma/close.svg"
            width="24" />
    </div>
</ng-template>
