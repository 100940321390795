<div class="row">
    <div class="col-6 col-md-3 text-left">
        <div class="text-h4 mb-3">{{ 'navigation.footer.company' | translate }}</div>
        <ul class="list-unstyled">
            <li class="mb-2">
                <a [routerLink]="'/faq'" class="footer-link paragraph-regular text-dark">{{
                    'navigation.faq' | translate
                }}</a>
            </li>
            <li class="mb-2">
                <a [routerLink]="'/contact'" class="footer-link paragraph-regular text-dark">{{
                    'navigation.contact' | translate
                }}</a>
            </li>
            <li class="mb-2">
                <a [faininIfFeature]="'blog'" [routerLink]="'/blog'" class="footer-link paragraph-regular text-dark">{{
                    'navigation.blog' | translate
                }}</a>
            </li>
            <li class="mb-2">
                <a [routerLink]="'/about'" class="footer-link paragraph-regular text-dark">{{
                    'navigation.about' | translate
                }}</a>
            </li>
            <li class="mb-2">
                <a
                    href="https://fainin.notion.site/Press-fainin-b37ff54590e84bc88a4f2f16747eaab3"
                    class="footer-link paragraph-regular text-dark"
                    >{{ 'navigation.press' | translate }}</a
                >
            </li>
            <li class="mb-2">
                <a
                    href="https://fainin.notion.site/Career-fainin-a5f40fef3f964c00a63cc916c08323b2"
                    class="footer-link paragraph-regular text-dark"
                    >{{ 'navigation.career' | translate }}</a
                >
            </li>
            <li class="mb-2">
                <a [routerLink]="'/'" class="footer-link paragraph-regular text-dark" fragment="newsletter-section">{{
                    'navigation.newsletter' | translate
                }}</a>
            </li>
        </ul>
    </div>
    <div class="col-6 col-md-3">
        <div class="text-h4 mb-3">{{ 'navigation.footer.offers' | translate }}</div>
        <ul *ngIf="collections.length" class="list-unstyled">
            <ng-container *ngFor="let collection of collections">
                <li class="mb-2">
                    <a
                        [queryParams]="{ collection: collection.slug }"
                        [routerLink]="['/search']"
                        class="footer-link paragraph-regular text-dark"
                        >{{ collection.name }}</a
                    >
                </li>
            </ng-container>
        </ul>
    </div>
</div>
<hr />
<div class="d-md-flex align-items-center py-md-2">
    <div class="d-flex justify-content-center mb-3 mb-md-0 mr-md-4">
        <fainin-app-downloads></fainin-app-downloads>
    </div>
    <fainin-star-rating></fainin-star-rating>
    <div class="d-flex justify-content-center justify-content-md-end flex-grow-1">
        <div class="payment-cards mr-2">
            <img
                alt="{{ 'asset.paypalIcon' | translate }}"
                class="icon-paypal"
                height="16"
                src="/assets/icon/figma/paypal.svg"
                width="14" />
        </div>
        <div class="payment-cards sepa">
            {{ 'navigation.footer.sepa' | translate }}
        </div>
    </div>
</div>

<hr />
<div class="row justify-content-between">
    <fainin-social-media-row class="col-12 col-md-5 primary my-3"></fainin-social-media-row>
    <div class="col-12 col-md-5 my-3 d-flex flex-wrap justify-content-center justify-content-md-end">
        <a
            (click)="doOpenConsentDrawer($event)"
            aria-label="cookie settings button"
            class="footer-link paragraph-regular text-dark mr-2"
            role="button"
            ><fa-icon class="mr-1" icon="cookie-bite"></fa-icon>{{ 'term.settings' | translate }}</a
        >
        <a [routerLink]="'/privacy'" class="footer-link paragraph-regular text-dark mr-2">{{
            'navigation.privacy' | translate
        }}</a>
        <a [routerLink]="'/terms'" class="footer-link paragraph-regular text-dark mr-2">{{
            'navigation.agb' | translate
        }}</a>
        <a [routerLink]="'/imprint'" class="footer-link paragraph-regular text-dark">{{
            'navigation.imprint' | translate
        }}</a>
    </div>
</div>

<div class="row justify-content-md-end">
    <div class="col-12 col-md-4">
        <div class="text-center text-md-right text-muted">
            <div class="paragraph-xsmall">
                {{ 'core.copyRight' | translate }} © {{ currentYear }} {{ 'core.allRightsReserved' | translate }}<br />
                Fainin GmbH
            </div>
            <div aria-label="storefront-version" class="paragraph-xsmall hidden-version">v{{ version }}</div>
        </div>
    </div>
</div>
