export default {
    button: {
        label: 'Zum Artikel',
    },
    alt: {
        imageBlog: 'Vorschaubild für den {{slug}} Artikel',
        imageCampaign: 'Vorschaubild für die {{title}} Kampagne',
        fallbackImageBlog: 'Standardbild für den {{slug}} Artikel',
        fallbackImageCampaign: 'Standardbild für die {{title}} Kampagne',
    },
};
