import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[faininShadow]',
})
export class ShadowDirective implements OnInit {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.renderer.setStyle(this.elementRef.nativeElement, 'box-shadow', '0px 0px 16px rgba(0, 0, 0, 0.1)');
    }
}
