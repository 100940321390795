/**
 * Deep comparison function to check structural equality of two objects
 * @param obj1
 * @param obj2
 */
function isEqual(obj1: any, obj2: any): boolean {
    // If both are the same reference or both are null/undefined, they are equal
    if (obj1 === obj2) return true;

    // If types are different, they are not equal
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If number of properties is different, objects are not equal
    if (keys1.length !== keys2.length) return false;

    // Check if all properties are equal (deep check)
    for (let key of keys1) {
        if (!keys2.includes(key)) return false; // One object has a property the other doesn't

        // If values of the same property are different according to a recursive check, objects are not equal
        if (!isEqual(obj1[key], obj2[key])) return false;
    }

    // No differences found, objects are equal
    return true;
}

export default isEqual;
