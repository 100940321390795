export default {
    noInvoices: 'Рахунок-фактур не знайдено.',
    noImageInForm: 'Не вибрано жодного зображення. Будь-ласка, додайте один!',
    noImageUploaded: 'Не вдалося завантажити зображення',
    imageSize: 'Розмір окремого файлу не повинен перевищувати {{size}} MB. Спробуйте використати інше зображення.',
    unknownSize: 'Цей файл не можна вибрати, оскільки розмір невідомий.',
    addAssetsToAlbum: 'Під час додавання зображення до альбому сталася помилка',
    unexpectedError: 'Щось пішло не так.',
    noCameraPermission: 'Немає дозволів',
};
