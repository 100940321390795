import { AWebViewEvent, IRequestPayload, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const SUMSUB_VERIFY_EVENT_NAME = 'SumSubVerifyEvent';

type ActionResult = {
    /**
     * Applicant action identifier to check the results against the server
     */
    actionId: string;
    /**
     * Overall result. Typical values are GREEN, RED or ERROR
     */
    answer: 'GREEN' | 'RED' | 'ERROR' | string;
};

type SumSubStatus =
    | 'Ready'
    | 'Failed'
    | 'Initial'
    | 'Incomplete'
    | 'Pending'
    | 'TemporarilyDeclined'
    | 'FinallyRejected'
    | 'Approved'
    | 'ActionCompleted';

type SumSubErrorType =
    | 'Unknown'
    | 'InvalidParameters'
    | 'Unauthorized'
    | 'InitialLoadingFailed'
    | 'ApplicantNotFound'
    | 'ApplicantMisconfigured'
    | 'InititlizationError'
    | 'NetworkError'
    | 'UnexpectedError';

type SumSubResult = {
    /**
     * The boolean value indicates if there was an error on the moment the sdk is closed.
     * Use errorType and errorMsg to see the reasons of the error if any
     */
    success: boolean;
    /**
     * ApplicantStatus
     */
    status: SumSubStatus;
    /**
     * A formal reason of the error if any
     */
    errorType?: SumSubErrorType;
    /**
     * A verbose error description if any
     */
    errorMsg?: string;
    /**
     * Describes the result of the last applicant action's invocation if any
     */
    actionResult?: ActionResult;
};

export interface ISumSubResultPayload extends IResponsePayload {
    result: SumSubResult;
}

export interface ISumSubVerifyPayload extends IRequestPayload {
    accessToken?: string;
    applicantEmail?: string;
    applicantPhone?: any;
    customI18nMessages?: any;
}

export type ISumSubVerifyEvent = IWebViewEvent<ISumSubVerifyPayload, ISumSubResultPayload>;

export class SumSubVerifyEvent
    extends AWebViewEvent<ISumSubVerifyPayload, ISumSubResultPayload>
    implements ISumSubVerifyEvent
{
    constructor() {
        super(SUMSUB_VERIFY_EVENT_NAME);
    }
}

export default [SUMSUB_VERIFY_EVENT_NAME, SumSubVerifyEvent];
