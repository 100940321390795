export default {
    hero: {
        summer: {
            page1: {
                title: 'Лето пришло!',
                subTitle: 'Аренда SUP, (надувных) лодок, каяков и других вещей на лето.',
            },
            page2: {
                title: 'Одолжить все',
                subTitle: 'Арендуйте дрели, лобзики, лестницы вместо того, чтобы покупать новые.',
            },
            page3: {
                title: '24/7 игровое время',
                subTitle: 'Аренда игровых приставок, контроллеров, телекамер для ваших игровых вечеров.',
            },
        },
        winter: {
            page1: {
                title: 'fainin',
                desktopTitle: 'fainin | Делиться - это новое владение',
                subTitle: 'зарабатывать деньги на стороне',
                text: 'Сдавайте в аренду вещи, которыми вы не пользуетесь. До 15 000 евро застраховано и доступно для проверенных участников.',
                button: 'Регистрация бесплатно',
            },
            page2: {
                title: 'Экономия денег',
                subTitle: 'Экономия денег',
                text: 'Арендуйте недорого и экономьте деньги - будь то камера, дрель, SUP, саундбокс или дрон.',
                button: 'Аренда сейчас',
            },
            page3: {
                title: 'Вы студент?',
                subTitle: 'Вы студент?',
                text: 'Студенты участвуют бесплатно! Зарегистрируйтесь и пришлите доказательства на contact@fainin.com',
                button: 'Экономия денег',
            },
        },
        button: {
            becomeRenter: 'Стать арендатором',
            goToDashboard: 'Перейти к приборной панели',
        },
    },
    topSellers: {
        button: 'Дешевая аренда',
    },
    news: {
        title: 'Известен по...',
        toTheArticle: 'К статье',
    },
    trust: {
        title: 'Заработать деньги в Интернете',
        subTitle: 'Экономить и зарабатывать деньги легко! С универсальным беззаботным пакетом fainin:',
        cards: {
            card1: {
                statement: 'Беспокоитесь о своих предложениях по аренде?',
                description:
                    'Не волнуйтесь! Ergo страхует каждую аренду на сумму до 15 000 евро. Благодаря нашему уникальному Design-For-Trust, каждый прокат через fainin полностью покрывается.',
            },
            card2: {
                statement: 'Не место для мошенников:внутри!',
                description:
                    'Каждый арендатор должен подтвердить свою личность с помощью нашего партнера по проверке SumSub - в fainin мы не даем мошенникам ни единого шанса.',
            },
            card3: {
                statement: 'Вы ищете что-то конкретное?',
                description:
                    'Если желаемого товара нет в наличии в вашем регионе - не беда! При публичном запросе другие члены семьи fainin-family могут увидеть, что вам нужно.',
            },
            card4: {
                statement: '... Ваш дизайн для доверия!',
                description:
                    'Страхование, проверка, интеллектуальная оплата и документированный процесс аренды делают fainin самым безопасным сообществом совместного использования жилья в Германии.',
            },
        },
        button: 'Отправить продукт сейчас',
    },
    app: {
        reviews: {
            review1: {
                title: 'Эффективная и безопасная аренда.',
                description:
                    'Кристиан Вольф (Wolf-Record) оцифровывает аренду оборудования для мероприятий и зарабатывает через дополнительный канал дохода.',
            },
            review2: {
                title: 'Приятно делиться больше.',
                description:
                    'Как студентке, Лайни нравится возможность заработать карманные деньги на стороне во время аренды.',
            },
            review3: {
                title: 'Аренда незабываемых дней',
                description: 'Йоханна любит собирать незабываемые моменты во время аренды.',
            },
        },
    },
    campaign: {
        title: 'Главные темы',
        text: 'Учитесь у самых эффективных арендаторов - будь то арендные компании, торговые партнеры или владельцы малого бизнеса. В самом безопасном сообществе совместного пользования выгоду получают все участники.\n',
    },
    nearProducts: {
        title: 'Предложения аренды рядом с вами',
        subtitle: 'Дешевая аренда в окрестностях',
        button: 'Выгодные предложения по аренде',
    },
    blog: {
        title: 'Блог арендодателя',
        subtitle: 'Поговорим об экономике совместного пользования',
        text: 'Учитесь у самых эффективных арендаторов - будь то арендные компании, торговые партнеры или владельцы малого бизнеса. В самом безопасном сообществе совместного пользования выгоду получают все участники.',
    },
    topSeller: {
        title: 'Представленные товары',
        subtitle: 'Лучшие товары рядом с вами',
    },
    gif: {
        title: 'Безопасная аренда и лизинг',
        text: 'С fainin аренда и совместное использование так же безопасны, как и аренда у друзей или знакомых по соседству. ',
        button: 'Зарегистрируйтесь сейчас',
    },
    newsletter: {
        title: 'Не пропустите ни одной новости!',
        text: 'Подпишитесь на нашу рассылку и получайте регулярные новости от семьи Фейнин.',
        button: 'Подписаться на рассылку новостей',
    },
    aboutUs:
        'Мы трое студентов, нас зовут Йоханн, Макс и Кевин -   Мы хотим использовать fainin для улучшения мира и принесения пользы всем участникам. Мы с нетерпением ждем вашего сообщения.',
    yourSharingCommunity: 'Ваше сообщество обмена',
    getToKnowUs: 'Узнайте нас',
    sharingIsOwning: '#ДелитьсяЭтоНовоеОбладание',
    becomeFamily: 'Стань частью семьи fainin',
    title: 'fainin',
    readyForCommunity: 'Готовы к самому безопасному сообществу совместного использования?',
};
