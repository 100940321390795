import { ASSET_FRAGMENT, ORDER_ADDRESS_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const GET_ORDER_WITH_FEEDBACK_REMAINING = gql`
    query GetOrderWithFeedbackRemaining {
        orderWithFeedbackRemaining {
            id
            lines {
                featuredAsset {
                    ...Asset
                }
                productVariant {
                    name
                }
            }
            customFields {
                ownerProfile {
                    name
                }
                duration {
                    startDate
                    endDate
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const GET_ORDER_LIST = gql`
    query GetOrderList($options: OrderListOptions) {
        orders(options: $options) {
            items {
                id
                createdAt
                updatedAt
                state
                lines {
                    id
                    featuredAsset {
                        ...Asset
                    }
                    quantity
                    linePrice
                    productVariant {
                        id
                        name
                        price
                    }
                }
                totalQuantity
                subTotal
                subTotalWithTax
                total
                totalWithTax
                unread
                customFields {
                    requestId
                    duration {
                        startDate
                        endDate
                        isHourly
                    }
                    ownerProfile {
                        id
                        name
                    }
                    renterProfile {
                        id
                        name
                    }
                }
                shippingAddress {
                    ...OrderAddress
                }
                billingAddress {
                    ...OrderAddress
                }
                userRelation
            }
            totalItems
        }
    }
    ${ASSET_FRAGMENT}
    ${ORDER_ADDRESS_FRAGMENT}
`;
