import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortal } from '@angular/cdk/portal';

@Component({
    selector: 'fainin-menu-drawer',
    templateUrl: './menu-drawer.component.html',
    styleUrls: ['./menu-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDrawerComponent {
    @HostBinding('class.visible')
    @Input()
    visible = false;

    @HostBinding('class.foreground')
    @Input()
    foreground = false;

    @HostBinding('class.large')
    @Input()
    large = false;

    @Input() title: string | undefined;
    @Input() closeable = true;

    @Output() closeDrawer = new EventEmitter<void>();

    @Input() backdrop = false;

    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay, private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

    open() {
        this.visible = true;
        if (this.backdrop) {
            this.createBackdrop();
        }
        this.cdr.markForCheck();
    }

    close() {
        this.visible = false;
        this.closeDrawer.emit();
        this.overlayRef.detach();
        this.cdr.markForCheck();
    }

    private createBackdrop() {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                disposeOnNavigation: true,
                backdropClass: ['cdk-overlay-dark-backdrop', 'menudrawer-backdrop'],
                panelClass: ['menudrawer-overlay-panel'],
            });

            this.overlayRef.backdropClick().subscribe(() => this.close());
        }

        this.overlayRef.attach(new DomPortal(this.elementRef.nativeElement));
    }
}
