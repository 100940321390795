export default {
    title: 'fainin',
    partner: 'Партнер',
    aboutUs:
        'Ми троє студентів, нас звати Йоганн, Макс і Кевін - ми хочемо покращити світ разом з fainin, щоб всі учасники отримали користь. Ми з нетерпінням чекаємо на Вашу відповідь.',
    caption: {
        lend: 'Здавай в оренду все',
        lend2: 'що ти хочеш',
        rent: 'орендуй все',
        rent2: 'що тобі потрібно',
    },
    tile: {
        safety: {
            title: 'Довіра',
            subtitle: '– Design-For-Trust в fainin',
            content: 'Ви турбуєтеся про свої речі? Звичайно, ми це розуміємо! Тому ми створили fainin для довіри.',
            point: [
                'Страховка до 15 000 євро',
                '100% перевірених користувачів',
                'Документально оформлений процес оренди',
            ],
        },
        what: {
            title: 'Наша ціль',
            subtitle: '– Найбезпечніша спільнота обміну інформацією',
            content: `Ми хочемо зробити ваше місто місцем громади. <br>
І ми пропонуємо вам можливість отримати вигоду у безпрограшних ситуаціях з fainin! Разом ми це встановимо
найбезпечніша спільнота у вашому районі. <b>#ДілитисяЦеНовеВолодіти</b>
            <br><br>Ти з нами?`,
        },
        app: {
            title: 'Ваша програма для спільного доступу',
            subtitle: 'для iOS та Android',
            content: `Новий додаток fainin дозволяє надійно брати та брати в борг. Для початку, новенький
Щоб користуватися додатком, ви можете стежити за каналами fainin в соціальних мережах. <br>
<br><br>Ви готові?`,
        },
    },
    cta: {
        flying: {
            title: 'У вас є ще щось?<br> що завалялось?',
            label: 'Перерахуйте це на fainin!',
            action: 'Розмістити рекламу',
        },
    },
    blog: {
        title: 'Блог',
        subtitle: 'Давайте поговоримо про економіку обміну',
        text:
            'Якщо ви хочете зробити свій внесок про стійкість, соціальну близькість або обмін, ' +
            'тоді, будь ласка, напишіть нам у соціальних мережах або надішліть електронного листа на contact@fainin.com.',
    },
    topSeller: {
        title: 'Рекомендовані товари',
        subtitle: 'Найкращі продукти поблизу',
    },
    newsletter: {
        title: 'Не пропустіть жодної новини!',
        text: 'Підпишіться на нашу розсилку та отримуйте регулярні оновлення від родини Файніних.',
        button: 'Підписатися на Розсилку',
    },
};
