import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterContentComponent } from '@core/components/footer-content/footer-content.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import { AppDownloadsComponent } from './components/app-downloads/app-downloads.component';
import { SocialMediaRowComponent } from './components/social-media-row/social-media-row.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

@NgModule({
    declarations: [FooterContentComponent, SocialMediaRowComponent, StarRatingComponent, AppDownloadsComponent],
    imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule, SharedDirectiveModule],
    exports: [FooterContentComponent, SocialMediaRowComponent, StarRatingComponent, AppDownloadsComponent],
})
export class FooterSharedModule {}
