import { signal, WritableSignal } from '@angular/core';

/**
 * Creates signal with a wrapper which will automatically cache and refresh its value after a given TTL.
 * @param initialValue
 * @param ttl
 * @param refreshFn
 */
export function createCacheSignal<T>(initialValue: T, ttl: number, refreshFn: () => Promise<T>): WritableSignal<T> {
    const cachedSignal: WritableSignal<T> = signal<T>(initialValue);
    let expirationTime = Date.now() - 1; // Expire immediately to force a refresh
    let refreshPromise: Promise<void> | null = null;

    const checkAndRefresh = async () => {
        if (Date.now() > expirationTime) {
            if (!refreshPromise) {
                refreshPromise = refreshFn()
                    .then(newValue => {
                        cachedSignal.set(newValue);
                        expirationTime = Date.now() + ttl;
                    })
                    .finally(() => {
                        refreshPromise = null;
                    });
            }
            await refreshPromise;
        }
    };

    const wrappedSignal: WritableSignal<T> = new Proxy(cachedSignal, {
        get(target, prop, receiver) {
            if (prop === 'value' || prop === 'asObservable' || prop === 'subscribe' || typeof prop === 'symbol') {
                checkAndRefresh().then(); // Trigger refresh if needed
            }
            return Reflect.get(target, prop, receiver);
        },
        set(target, prop, value, receiver) {
            if (prop === 'value') {
                expirationTime = Date.now() + ttl;
            }
            return Reflect.set(target, prop, value, receiver);
        },
    });

    return wrappedSignal;
}
