import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';

@Component({
    selector: 'fainin-layout-logo',
    templateUrl: './layout-logo.component.html',
    styleUrls: ['./layout-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLogoComponent implements OnInit, OnDestroy {
    @HostBinding('class.isSearching') isSearching: boolean = false;

    constructor(private stateService: StateService) {}

    ngOnInit(): void {
        this.stateService
            .select(state => state.showSubHeader)
            .pipe(untilDestroyed(this))
            .subscribe(showSubHeader => {
                this.isSearching = showSubHeader;
            });
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }
}
