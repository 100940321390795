export default {
    title: 'fainin | cheap & insured rentals | sharing & earning money',
    keywords:
        'rent online, insured lending, Online rental, second hand lending, rent equipment, shared economy insurance, rent studio, rental insurance, Top 10 rental, lending insurance',
    description:
        'Make money online while renting safely. Or rent tech products cheap from 1 day. Secure, easy & sustainable renting at fainin',
    faq: {
        title: 'Frequently Asked Questions | Secured Rentals',
        description:
            'We establish secure renting- and lending-transactions. Would you like to know how? Log-in to get access to personalized support.',
        keywords:
            'rent online, insured lending, Online rental, second hand lending, what can i lend, rent control, tenant protection, what can i borrow, where can i rent, how to insure rental, make money with lending',
    },
    about: {
        title: 'About us | Your secure rental community',
        description:
            'fainin provides secure rental and lending transactions in your sharing community. We establish sustainable consumption and provide the opportunity to make the world a better place with us.',
        keywords:
            'rent online, insured lending, Online rental, second hand lending, secure rentals, why renting, Sharing Is The New Owning, secure lending, sharing freedom, trustworthy rentals',
    },
    blog: {
        title: 'The fainin Blog: How to share securely',
        description: 'Get inspired through news, articles and resources from sustainably oriented fellows and friends.',
        keywords:
            'rent online, insured lending, Online rental, second hand lending, what can i lend, rent control, tenant protection, what can i borrow, where can i rent, how to insure rental, make money with lending',
    },
    search: {
        title: 'fainin | Products in your neighbourhood',
        description:
            'On fainin you can find anything for secure sharing within the city. Use everything what you need and benefit by secure rental- and lending-transactions.',
        keywords:
            'rent online, insured lending, Online rental, second hand lending, rent equipment, rent online, room rentals, shared economy insurance',
    },
    linktree: {
        title: 'fainin | Linktree',
        description:
            'Be part of fainins sharing community. Together we can make renting, lending and sustainable consumption easier than it has ever been.',
        keywords:
            'sharing community, rent monthly, sports equipment, safe rental, borrow sound books, rent consoles, rent brands',
    },
    contact: {
        title: 'Contacts for fainin',
        description:
            'How can we help you? Reach out to us via contact@fainin.com or simply give us a call via +491753477656.',
    },
    privacy: {
        title: 'Dataprivacy of fainin',
        description: 'We may retain impersonal activity information as necessary for fraud prevention, damage or loss.',
    },
    imprint: {
        title: 'Imprint - fainin',
        description: 'Imprint. Provider of the website: fainin GmbH, private company -Mühlenkamp 4, 22303 Hamburg.',
    },
    terms: {
        title: 'Terms and Conditions of fainin',
        description:
            'As a lender, fainin offers you the perfect rental platform to share your items, experience or other lending services with our vibrant community.',
    },
    community: {
        title: 'fainin | In the university simply rent: flexible and cheap',
        description:
            'Rent and hire cheaply at university: Simple and fully insured ✓ Select period and go! ✓ Flexible from 1 day ✓ Rent now',
        keywords:
            'students, rent music system, rent music system, rent playstation, rent console, rent consoles, rent xbox, rent drone, rent drone, rent drone, rent drone, rent drone, rent drone, rent drone',
    },
    press: {
        title: 'fainin | Press',
        description: 'Logos, images, and press releases from fainin for your reporting.',
    },
};
