import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { lastValueFrom } from 'rxjs';

/**
 * An adapter that allows the Angular HttpClient to be used as a replacement for the global `fetch` function.
 * This is used to supply a custom fetch function to the apollo-upload-client whilst also allowing the
 * use of Angular's http infrastructure such as interceptors.
 */
@Injectable()
export class FetchAdapter {
    constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: any) {}

    fetch = (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
        // Determine the URL from the input parameter
        const url = input instanceof Request ? input.url : input.toString();
        // Determine the HTTP method from the input parameter
        const method = input instanceof Request ? input.method : init && init.method ? init.method : 'GET';

        return lastValueFrom(
            this.httpClient.request(method, url, {
                body: init?.body,
                headers: init?.headers as any,
                observe: 'response',
                responseType: 'json',
                withCredentials: true,
            }),
        )
            .then(result => {
                return new Response(JSON.stringify(result?.body), {
                    status: result?.status,
                    statusText: result?.statusText,
                });
            })
            .catch(reason => {
                console.error(reason, init?.body);
                return Response.error();
            });
    };
}
