<ng-template faininDialogTitle>
    <h3 class="modal-title text-h3">{{ 'core.locationPermission.allowLocation' | translate }}</h3>
</ng-template>

<ng-container *ngIf="!deniedPermission; else hasDeniedPermission">
    <p class="paragraph-regular">{{ 'core.locationPermission.required' | translate }}</p>
    <ol>
        <li class="paragraph-regular" [innerHTML]="'core.locationPermission.allowLocationPointOne' | translate"></li>
        <li class="paragraph-regular" [innerHTML]="'core.locationPermission.allowLocationPointTwo' | translate"></li>
    </ol>
</ng-container>

<ng-template #hasDeniedPermission>
    <p class="paragraph-regular">{{ 'core.locationPermission.allowLocation' | translate }}</p>
    <ol>
        <li class="paragraph-regular" [innerHTML]="'core.locationPermission.missingLocationPointOne' | translate"></li>
        <li class="paragraph-regular" [innerHTML]="'core.locationPermission.missingLocationPointTwo' | translate"></li>
        <li
            class="paragraph-regular"
            [innerHTML]="'core.locationPermission.missingLocationPointThree' | translate"></li>
    </ol>
</ng-template>

<div class="buttons">
    <fainin-button [size]="'block'" buttonClass="primary spacing-mb-tiny" (debounceClick)="givePermission()">
        <ng-container *ngIf="!deniedPermission; else hasDeniedPermission2">
            {{ 'core.locationPermission.givePermission' | translate }}
        </ng-container>
        <ng-template #hasDeniedPermission2>
            {{ 'core.locationPermission.tryAgain' | translate }}
        </ng-template>
    </fainin-button>
    <fainin-button [size]="'block'" buttonClass="primary outline" (debounceClick)="close()">{{
        'core.locationPermission.notNow' | translate
    }}</fainin-button>
</div>
