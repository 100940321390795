import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ActionSheetComponent } from '@shared/components/action-sheet/action-sheet.component';

@NgModule({
    declarations: [ActionSheetComponent],
    imports: [CommonModule, RouterModule, TranslateModule, DragDropModule],
    exports: [ActionSheetComponent],
})
export class ActionSheetModule {}
