import { ACTIVE_PROFILE_FRAGMENT, ADDRESS_FRAGMENT, ERROR_RESULT_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const GET_ACTIVE_PROFILE = gql`
    query GetActiveProfile {
        activeProfile {
            ...ActiveProfile
        }
    }
    ${ACTIVE_PROFILE_FRAGMENT}
`;

export const GET_OWNER_STATISTICS = gql`
    query GetOwnerStats {
        ownerStats {
            id
            totalCount
            totalServiceFee
            totalRevenue
        }
    }
`;

export const UPDATE_CUSTOMER_DETAILS = gql`
    mutation UpdateCustomerDetails($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            id
            firstName
            lastName
            emailAddress
            phoneNumber
            customFields {
                birthday
            }
            user {
                customFields {
                    paypalEmail
                }
            }
        }
    }
`;

export const CREATE_BUSINESS_PROFILE = gql`
    mutation CreateBusinessProfile($input: CreateBusinessInput!) {
        createBusinessProfile(input: $input) {
            id
            identifier
            businessName
        }
    }
`;

export const SAVE_PAYPAL = gql`
    mutation SavePaypalEmail($email: String!) {
        savePaypalEmail(email: $email) {
            ... on Success {
                success
            }
        }
    }
`;

/**
 * Fetch user bank account info
 */
export const GET_USER_BANK_ACCOUNT = gql`
    query GetUserBankAccount {
        getUserBankAccount {
            last4
            currency
            accountHolderName
            country
        }
    }
`;

export const DELETE_STRIPE_ACCOUNT = gql`
    mutation DeleteStripeAccount {
        deleteStripeAccount {
            success
        }
    }
`;

/**
 * Create stripe account
 */
export const CREATE_STRIPE_ACCOUNT = gql`
    mutation CreateStripeAccount($input: CreateStripeAccountInput!) {
        createStripeAccount(input: $input) {
            ... on Success {
                success
            }
        }
    }
`;

/**
 * Update stripe account
 */
export const UPDATE_STRIPE_ACCOUNT = gql`
    mutation UpdateStripeAccount($IBAN: String!) {
        updateStripeAccount(IBAN: $IBAN) {
            ... on Success {
                success
            }
        }
    }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
    mutation UpdateCustomerAddress($input: UpdateAddressInput!) {
        updateCustomerAddress(input: $input) {
            ...Address
        }
    }
    ${ADDRESS_FRAGMENT}
`;

/**
 * Update user profile picture
 */
export const UPDATE_PROFILE = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
        updateProfile(input: $input) {
            ...ActiveProfile
        }
    }
    ${ACTIVE_PROFILE_FRAGMENT}
`;

export const UPDATE_BUSINESS = gql`
    mutation updateBusinessProfile($input: UpdateBusinessInput!) {
        updateBusinessProfile(input: $input) {
            ... on BusinessProfile {
                id
                businessName
                businessType
                registrationNumber
            }
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($old: String!, $new: String!) {
        updateCustomerPassword(currentPassword: $old, newPassword: $new) {
            ... on Success {
                success
            }
            ...ErrorResult
        }
    }
    ${ERROR_RESULT_FRAGMENT}
`;

export const CHANGE_EMAIL_ADDRESS = gql`
    mutation ChangeEmailAddress($password: String!, $emailAddress: String!) {
        requestUpdateCustomerEmailAddress(password: $password, newEmailAddress: $emailAddress) {
            ... on Success {
                success
            }
            ...ErrorResult
        }
    }
    ${ERROR_RESULT_FRAGMENT}
`;

export const REQUEST_ACCOUNT_DELETION = gql`
    mutation RequestAccountDeletion {
        deleteAccountRequest {
            success
        }
    }
`;
