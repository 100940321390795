<div *ngIf="isMobile" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
        <a *ngIf="currentStep === 1" class="onboarding-marker step-1"> </a>
        <a *ngIf="currentStep === 2" class="onboarding-marker step-2"> </a>
        <a *ngIf="currentStep === 3" class="onboarding-marker step-3"> </a>
        <a *ngIf="currentStep === 4" class="onboarding-marker skip-onboarding"> </a>
        <div class="container-parent">
            <div faininShadow class="onboarding-container">
                <swiper *ngIf="!skipOnBoarding" id="onBoarding-swiper" [config]="config">
                    <ng-template *ngFor="let step of onBoardingSteps" swiperSlide>
                        <img class="image-container" [src]="step.image" />
                        <img
                            *ngIf="step.step > 1"
                            class="slider-back"
                            src="/assets/image/onBoarding/back-arrow.svg"
                            (click)="onPrevious()" />
                        <img
                            class="skip-onboarding-cross"
                            src="/assets/icon/figma/close.svg"
                            (click)="toggleOnBoardingSkip(false)" />
                        <div class="text-h4 title-h4 onboarding-title">{{ step.title }}</div>
                        <div class="paragraph-small onboarding-step-info">{{ step.info }}</div>
                        <button
                            *ngIf="step.buttonType === 'next'"
                            faininShadow
                            class="btn btn-primary"
                            (click)="onNext()">
                            {{ step.buttonName }}
                        </button>
                        <button
                            [disabled]="finishingOnboarding"
                            *ngIf="step.buttonType === 'start'"
                            faininShadow
                            class="btn btn-primary"
                            (click)="finishOnBoarding()">
                            {{ step.buttonName }}
                        </button>
                    </ng-template>
                </swiper>
                <swiper *ngIf="skipOnBoarding" id="skip-onBoarding-swiper" [config]="config">
                    <ng-template swiperSlide>
                        <img
                            class="slider-back"
                            src="/assets/image/onBoarding/back-arrow.svg"
                            (click)="toggleOnBoardingSkip(true)" />
                        <img class="image-container skip" [src]="skipOnBoardingStep.image" />
                        <div class="text-h4 title-h4 onboarding-title">{{ skipOnBoardingStep.title }}</div>
                        <div class="paragraph-small onboarding-step-info">{{ skipOnBoardingStep.info }}</div>
                        <fainin-button
                            [disabled]="finishingOnboarding"
                            buttonClass="btn-outline-secondary skip-button"
                            (debounceClick)="finishOnBoarding()">
                            {{ skipOnBoardingStep.buttonName }}
                        </fainin-button>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isMobile" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
        <a *ngIf="currentStep === 1" class="onboarding-marker desktop-step-1"> </a>
        <a *ngIf="currentStep === 2" class="onboarding-marker desktop-step-2"> </a>
        <a *ngIf="currentStep === 3" class="onboarding-marker desktop-step-3"> </a>
        <a *ngIf="currentStep === 4" class="onboarding-marker skip-onboarding"> </a>
        <div class="container-parent" (click)="containerClicked($event)">
            <div faininShadow class="onboarding-container-desktop">
                <swiper *ngIf="!skipOnBoarding" id="onBoarding-swiper-desktop" [config]="config">
                    <ng-template *ngFor="let step of onBoardingSteps" swiperSlide>
                        <img class="image-container desktop" [src]="step.image" />
                        <div class="slider-back-desktop" (click)="onPrevious()">
                            <img
                                class="slider-back"
                                *ngIf="step.step > 1"
                                src="/assets/image/onBoarding/back-arrow.svg" />
                        </div>
                        <img
                            class="skip-onboarding-cross"
                            src="/assets/icon/figma/close.svg"
                            (click)="toggleOnBoardingSkip(false)" />
                        <div class="text-h4 title-h4 onboarding-title">{{ step.title }}</div>
                        <div class="paragraph-regular onboarding-step-info">{{ step.info }}</div>
                        <button
                            *ngIf="step.buttonType === 'next'"
                            faininShadow
                            class="btn btn-primary"
                            (click)="onNext()">
                            {{ step.buttonName }}
                        </button>
                        <button
                            [disabled]="finishingOnboarding"
                            *ngIf="step.buttonType === 'start'"
                            faininShadow
                            class="btn btn-primary"
                            (click)="finishOnBoarding()">
                            {{ step.buttonName }}
                        </button>
                    </ng-template>
                </swiper>
                <swiper *ngIf="skipOnBoarding" id="skip-onBoarding-swiper-desktop" [config]="config">
                    <ng-template swiperSlide>
                        <img
                            class="slider-back"
                            src="/assets/image/onBoarding/back-arrow.svg"
                            (click)="toggleOnBoardingSkip(true)" />
                        <img class="image-container skip" [src]="skipOnBoardingStep.image" />
                        <div class="text-h4 title-h4 onboarding-title">{{ skipOnBoardingStep.title }}</div>
                        <div class="paragraph-regular onboarding-step-info">{{ skipOnBoardingStep.info }}</div>
                        <button
                            [disabled]="finishingOnboarding"
                            faininShadow
                            class="btn btn-primary"
                            (click)="finishOnBoarding()">
                            {{ skipOnBoardingStep.buttonName }}
                        </button>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
</div>
