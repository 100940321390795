import { Component, effect, Input, OnDestroy } from '@angular/core';
import { CollectionFragment } from '@api/generated-types';
import { UniversalCookieConsentViewState } from '@core/cookie-consent/models/universal-cookie-consent-view-state.model';
import { UniversalCookieConsentService } from '@core/cookie-consent/services/universal-cookie-consent.service';
import { CollectionService } from '@core/providers/data/collection.service';
import { StateService } from '@core/providers/state/state.service';

@Component({
    selector: 'fainin-footer-content',
    templateUrl: './footer-content.component.html',
    styleUrls: ['./footer-content.component.scss'],
})
export class FooterContentComponent implements OnDestroy {
    protected collections: CollectionFragment[] = [];

    @Input() version: string;
    @Input() currentYear: number;
    constructor(
        private collectionService: CollectionService,
        private stateService: StateService,
        private cookieConsentService: UniversalCookieConsentService,
    ) {
        effect(() => {
            this.collections = this.collectionService.collections();
        });
    }

    // eslint-disable-next-line
    ngOnDestroy(): void {
        //This function is mandatory for the untilDestroyed pipe
    }

    doOpenConsentDrawer(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.stateService.setState('cookieConsentDialogOpen', true);
        this.cookieConsentService.setViewState(UniversalCookieConsentViewState.ADVANCED);
    }
}
