import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { APOLLO_CACHE_PROVIDER, APOLLO_CLIENT_PROVIDER } from '@core/apollo-client-provider';
import { FetchAdapter } from '@core/providers/data/fetch-adapter';
import { DefaultInterceptor } from '@core/providers/data/interceptor';
import { environment } from '@env/environment';
import { ApolloModule } from 'apollo-angular';

@NgModule({
    imports: [ApolloModule, HttpClientModule],
    providers: [
        FetchAdapter,
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: environment.baseHref },
        APOLLO_CACHE_PROVIDER,
        APOLLO_CLIENT_PROVIDER,
    ],
})
export class ApolloCoreModule {
    /*    extractState() {
        return apolloCache.extract();
    }

    restoreState(state: any) {
        if (apolloCache) {
            apolloCache.restore(state);
        }
        providedCacheState = state;
    }*/
}
