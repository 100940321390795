import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const APPLE_SIGN_IN_EVENT_NAME = 'AppleSignInEvent';

export interface IAppleSignInResponsePayload extends IResponsePayload {
    email?: string;
    firstName?: string;
    lastName?: string;
    identityToken: string;
}

export type IAppleSignInEvent = IWebViewEvent<any, IAppleSignInResponsePayload>;

export class AppleSignInEvent extends AWebViewEvent<any, IAppleSignInResponsePayload> implements IAppleSignInEvent {
    constructor() {
        super(APPLE_SIGN_IN_EVENT_NAME);
    }
}

export default [APPLE_SIGN_IN_EVENT_NAME, AppleSignInEvent];
