import { NgIf, UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import meta from '@core/localization/de/meta';
import { SeoDefault } from '@core/seo/seo.service';
import { HasMetadata } from '@core/seo/site-meta.types';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { TermsEnglishComponent } from './terms-english/terms-english.component';

@Component({
    selector: 'fainin-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
    standalone: true,
    imports: [NgIf, UpperCasePipe, TermsEnglishComponent],
})
export class TermsComponent implements HasMetadata {
    pageMetadata = of({
        lang: 'de-DE',
        title: meta.terms.title,
        description: meta.terms.description,
        keywords: SeoDefault.siteKeyword,
    });

    constructor(private translate: TranslateService) {}

    get currentLang() {
        return this.translate.currentLang;
    }
}
