export default {
    uploadProduct: 'Загрузить продукт',
    contact: 'Контакт',
    search: 'Find offers',
    faq: 'Вопросы',
    blog: 'Блог',
    home: 'Старт',
    about: 'О нас',
    newsletter: 'Рассылка',
    rent: 'Аренда',
    lend: 'Аренда',
    press: 'Пресса',
    career: 'Карьера',
    imprint: 'Оттиск',
    agb: 'AGОбщие положения и условияB',
    terms: 'Общие положения и условия',
    privacy: 'Политика конфиденциальности',
    onboarding: 'Onboarding',
    account: 'Аккаунт',
    orders: 'Бронирование',
    requests: 'Запросы',
    products: 'Товары',
    address: 'Адреса аккаунтов',
    accountDetails: 'Детали аккаунта',
    accountChange: 'Корректировка аккаунта',
    help: 'Помощь',
    signIn: 'Регистрация',
    register: 'Регистрироваться',
    verify: 'Верификация',
    resetPassword: 'Сброс пароля',
    forgottenPassword: 'Забыли пароль',
    changeEmailAddress: 'Изменить адрес электронной почты',
    productUpload: 'Загрузка товара',
    myProfile: 'Мой профиль',
    editProfile: 'Редактировать профиль',
    favorites: 'Избранное',
    WelcomeToFainin: 'Добро пожаловать в fainin',
    footer: {
        offers: 'Предлагает',
        company: 'fainin',
        about: 'О нас',
        legal: 'Правовое',
        starText: '4,7 с 155 оценками',
        sepa: 'Прямой дебет SEPA',
    },
    communities: 'Communities',
    chats: 'Чаты',
    chat: 'Чат',
    request: 'Запрос',
    verification: 'Верификация',
    registerStep1: 'Шаг регистрации 1',
    registerStep2: 'Шаг регистрации 2',
    registerStep3: 'Шаг регистрации 3',
    registerStep4: 'Шаг регистрации 4',
    migrationOptOut: 'Миграция Opt-Out',
    migrationOptIn: 'Миграция Opt-In',
};
