import { AWebViewEvent, IResponsePayload, IWebViewEvent } from '../web-view.events';

export const GOOGLE_SIGNIN_EVENT_NAME = 'GoogleSigninEvent';

/**
 * Represents an authorization response error: [Section 5.2](https://tools.ietf.org/html/rfc6749#section-5.2).
 * Often times providers will fail to return the proper error message for a given error code.
 * This error method will add the missing description for more context on what went wrong.
 */
export interface AuthError {
    /**
     * Required only if state is used in the initial request
     */
    state?: string;
    /**
     * Used to assist the client developer in
     * understanding the error that occurred.
     */
    description?: string;
    /**
     * A URI identifying a human-readable web page with
     * information about the error, used to provide the client
     * developer with additional information about the error.
     */
    uri?: string;
    /**
     * Raw results of the error.
     */
    params: Record<string, string>;
    code: string;
    info?: any;
    message: string;
}

export interface IGoogleSignInResponsePayload extends IResponsePayload {
    /**
     * How the auth completed.
     */
    type: 'cancel' | 'dismiss' | 'opened' | 'locked' | 'error' | 'success';
    /**
     * On success this can be fetched from response.params.id_token
     */
    id_token: string | undefined;
    /**
     * Possible error if the auth failed with type `error`.
     */
    error?: AuthError | null;
    /**
     * Platform
     */
    platform: 'ios' | 'android' | 'windows' | 'macos' | 'web';
}

export type IGoogleSignInEvent = IWebViewEvent<any, IGoogleSignInResponsePayload>;

export class GoogleSignInEvent extends AWebViewEvent<any, IGoogleSignInResponsePayload> implements IGoogleSignInEvent {
    constructor() {
        super(GOOGLE_SIGNIN_EVENT_NAME);
    }
}

export default [GOOGLE_SIGNIN_EVENT_NAME, GoogleSignInEvent];
