import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocationPermissionModalComponent } from '@shared/components/location-permission-modal/location-permission-modal.component';
import { GoogleGeocodingService } from './google-geocoding.service';
import { ModalService } from './modal/modal.service';

const logger = new Logger('LocationService');

export interface LocationCoords {
    longitude: number;
    latitude: number;
}

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        private geocoder: GoogleGeocodingService,
        private sanitizer: DomSanitizer,
    ) {}
    private gmUrl = 'https://www.google.com/maps/embed/v1/place?key=' + environment.googleDynamicMapsApiKey;

    getLocationWithDialog(): Promise<LocationCoords> {
        return new Promise(async (resolve, reject) => {
            if (!(await this.hasLocationPermission())) {
                logger.debug(`getLocationWithDialog - requesting permissions`);
                this.modalService
                    .fromComponent(LocationPermissionModalComponent, {
                        closable: true,
                    })
                    .subscribe(locationCoords => {
                        if (locationCoords) {
                            resolve(locationCoords);
                        } else {
                            const error = this.translate.instant('core.locationPermission.notGiven');
                            logger.debug(`getLocationWithDialog`, error);
                            reject(new Error(this.translate.instant('core.locationPermission.notGiven')));
                        }
                    }); // Subscribe to activate X(close) button
            } else {
                logger.debug(`getLocationWithDialog - resolving location`);
                this.getLocation().then(
                    position => {
                        resolve({
                            longitude: position.longitude,
                            latitude: position.latitude,
                        });
                    },
                    e => {
                        reject(new Error('Unexpected getLocationWithDialog error'));
                    },
                );
            }
        });
    }

    getLocation(): Promise<LocationCoords> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        logger.debug('getCurrentPosition resolved to', position);
                        const longitude = position.coords.longitude;
                        const latitude = position.coords.latitude;
                        resolve({ longitude, latitude });
                    },
                    error => {
                        reject(error);
                    },
                );
            } else {
                logger.error('No support for geolocation!');
                reject('No support for geolocation');
            }
        });
    }

    async hasLocationPermission() {
        try {
            if (!('permissions' in navigator)) {
                logger.debug('permissions not in navigator');
                return false;
            }
            const result = await navigator.permissions.query({
                name: 'geolocation',
            });
            return result.state === 'granted';
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    /**
     * Generates a Google Maps URL for embeddings in an iframe
     * @param place
     */
    embeddedMap(place: string): SafeResourceUrl {
        const query = place ? place : 'Deutschland';
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.gmUrl}&q=${query}`);
    }
}
