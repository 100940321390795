import about from './about';
import blog from './blog';
import community from './community';
import home from './home';
import landing from './landing';
import productDetail from './productDetail';

export default {
    about,
    blog,
    home,
    landing,
    productDetail,
    community,
};
