import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    get navigateBackToRoute(): string | undefined {
        return this._navigateBackToRoute;
    }

    set navigateBackToRoute(value: string | undefined) {
        this._navigateBackToRoute = value;
        // Store in local storage
        if (this._navigateBackToRoute) {
            localStorage?.setItem('navigateBackToRoute', this._navigateBackToRoute);
        } else {
            localStorage?.removeItem('navigateBackToRoute');
        }
    }
    private _navigateBackToRoute: string | undefined;
    private history: string[] = [];

    private _hasNavigated = new BehaviorSubject<boolean>(false);
    public hasNavigated = this._hasNavigated.asObservable();

    constructor(private router: Router, private location: Location) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._hasNavigated.next(true);
            }
        });
        // Fetch navigateBackToRoute from last session
        const navigateBackToRoute = localStorage?.getItem('navigateBackToRoute');
        if (navigateBackToRoute) {
            this._navigateBackToRoute = navigateBackToRoute;
            localStorage?.removeItem('navigateBackToRoute');
        }
    }

    public resetNavigationStatus(): void {
        this._hasNavigated.next(false);
    }

    public navigateToBackRoute() {
        const url = this.navigateBackToRoute;
        if (!url) return;
        const urlParts = url.split('?');
        const baseUrl = urlParts[0];
        const queryParams = urlParts[1];

        if (queryParams) {
            const params = queryParams.split('&').reduce((paramsObj, param) => {
                const [key, value] = param.split('=');
                paramsObj[key] = value;
                return paramsObj;
            }, {} as any);

            // Navigate with the base URL and query parameters
            this.navigateBackToRoute = undefined;
            this.router.navigate([baseUrl], { queryParams: params });
        } else {
            // Navigate with just the base URL if there are no query parameters
            this.navigateBackToRoute = undefined;
            this.router.navigate([baseUrl]);
        }
    }

    /**
     * Navigates back if possible. If the history is empty
     * the defaultURl is used instead
     * @param defaultUrl (default: '/')
     */
    back(defaultUrl: string = '/'): void {
        // this.history.pop() commented out, poping and reading length leads to loss of history
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl(defaultUrl).then(() => {});
        }
    }
}
