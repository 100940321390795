import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
    ActiveCustomer,
    ActiveProfile,
    Channel,
    CommunityDetailFragment,
    CurrentUser,
    OrderStatistics,
} from '@api/generated-types';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export const PUBLIC_CHANNEL_TOKEN = new InjectionToken<string | undefined>('DefaultPublicChannelToken');

export const PUBLIC_CHANNEL_CODE = '__public_channel__';
export const DEFAULT_CHANNEL_CODE = '__default_channel__';

export type SearchLocation = {
    locationString?: string;
    neighborhood?: string;
    locality?: string;
    zip?: string;
    lat?: number;
    lng?: number;
};

export function isNewSearchLocation(a?: SearchLocation | null, b?: SearchLocation | null) {
    return (!a && !!b) || (!!a && !!b && (a.lng !== b.lng || a.lat !== b.lat));
}

export interface AppState {
    signedIn: boolean;
    activeOrderId: string | null;
    showSubHeader: boolean;
    mobileNavMenuIsOpen: boolean;
    cookieConsentDialogOpen: boolean;
    menuDrawerOpen: boolean;
    hideHeader: boolean;
    hideTabBar: boolean;
    subscribedNewsletter: boolean;
    favoriteList: Array<string>;
    notifiedFavoriteNeedsAuth: boolean;
    searchLocation: SearchLocation;
    firstVisit: boolean;
    webTutorialSeen: boolean;
    activeCustomer: ActiveCustomer.Fragment | undefined;
    activeProfile: ActiveProfile.Fragment | undefined;
    activeUser: CurrentUser.Fragment | undefined;
    channels: Pick<Channel, 'code' | 'token' | 'id'>[];
    communities: CommunityDetailFragment[];
    ownerStats: OrderStatistics | undefined;
    displayProductsInTable: boolean;
    totalUnreadMessages: number;
}

export const initialState: AppState = {
    signedIn: false,
    activeOrderId: null,
    showSubHeader: false,
    mobileNavMenuIsOpen: false,
    menuDrawerOpen: false,
    cookieConsentDialogOpen: false,
    hideHeader: false,
    hideTabBar: false,
    subscribedNewsletter: false,
    favoriteList: [],
    notifiedFavoriteNeedsAuth: false,
    searchLocation: {},
    firstVisit: true,
    webTutorialSeen: true,
    activeCustomer: undefined,
    activeProfile: undefined,
    activeUser: undefined,
    channels: [],
    communities: [],
    ownerStats: undefined,
    displayProductsInTable: false,
    totalUnreadMessages: 0,
};

const logger = new Logger('StateService');

/**
 * A simple, observable store of global app state.
 */
@Injectable({
    providedIn: 'root',
})
export class StateService {
    protected state: AppState;
    private readonly stateSubject = new BehaviorSubject<AppState>({ ...initialState });
    private localStorageKey = 'AppState';

    constructor(@Optional() @Inject(PUBLIC_CHANNEL_TOKEN) private publicChannelToken: any) {
        const localState = this.retrieveState();
        this.state = localState ? Object.assign({ ...initialState }, localState) : { ...initialState };
        this.stateSubject.next(this.state);
    }

    /**
     * Get the default public channel token.
     */
    getPublicChannelToken(): string | undefined {
        return this.publicChannelToken || environment.publicChannelToken;
    }

    get searchLocationIsValid(): boolean {
        return Object.keys(this.state.searchLocation).length > 0;
    }

    setState<T extends keyof AppState>(key: T, value: AppState[T]) {
        logger.debug('SET', key, 'TO', value);
        this.state[key] = value;
        localStorage?.setItem(this.localStorageKey, JSON.stringify(this.state));
        this.stateSubject.next(this.state);
    }

    updateState(value: Partial<AppState>) {
        logger.debug('PATCH', value);
        this.state = Object.assign(this.state, value);
        localStorage?.setItem(this.localStorageKey, JSON.stringify(this.state));
        this.stateSubject.next(this.state);
    }

    toggleState<T extends keyof AppState>(key: T, value: any) {
        if (Array.isArray(this.state[key])) {
            logger.debug(`TOGGLE ${value} in`, this.state[key]);
            this.addOrRemove(this.state[key] as Array<any>, value);
            this.stateSubject.next(this.state);
        } else if (typeof this.state[key] === 'boolean') {
            logger.debug(`TOGGLE ${value}`);
            const state = this.state[key] as boolean;
            this.setState(key, !state as AppState[T]);
        }
    }

    select<R>(selector: (state: AppState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector), distinctUntilChanged());
    }

    currentState<T extends keyof AppState>(key: T): AppState[T] {
        return this.stateSubject.value[key];
    }

    resetState() {
        logger.debug(`Reset state`);
        localStorage?.removeItem(this.localStorageKey);
        this.state = { ...initialState }; // copy!
        this.stateSubject.next(initialState);
    }

    private addOrRemove<T>(array: Array<T>, value: T) {
        const index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    private retrieveState(): AppState | undefined {
        const stateStr = localStorage?.getItem(this.localStorageKey) as string;
        if (stateStr) {
            return JSON.parse(stateStr);
        }
    }
}
