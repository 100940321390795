import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { Overlay, OverlayConfig, ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { supportsScrollBehavior } from '@angular/cdk/platform';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ViewportScrollPosition } from '@angular/cdk/scrolling';
import { Injectable, Injector, Type } from '@angular/core';
import { Observable, race } from 'rxjs';
import { finalize, mapTo, take } from 'rxjs/operators';

import { ModalDialogComponent } from '../../components/modal-dialog/modal-dialog.component';

import { Dialog, DIALOG_COMPONENT, MODAL_OPTIONS, ModalOptions } from './modal-types';

const scrollBehaviorSupported = supportsScrollBehavior();

/**
 * Strategy that will prevent the user from scrolling while the overlay is visible.
 */
export class CustomBlockScrollStrategy implements ScrollStrategy {
    private _previousScrollPosition: ViewportScrollPosition;
    private _isEnabled = false;
    private _document: Document;

    constructor(private _viewportRuler: ViewportRuler, document: any) {
        this._document = document;
    }

    /** Attaches this scroll strategy to an overlay. */
    attach() {}

    /** Blocks page-level scroll while the attached overlay is open. */
    enable() {
        if (this._canBeEnabled()) {
            const html = this._document.documentElement!;
            const body = this._document.body!;

            this._previousScrollPosition = this._viewportRuler.getViewportScrollPosition();

            // Note: `body` may have margin, but we assume it has none.
            body.style.left = coerceCssPixelValue(-this._previousScrollPosition.left);
            body.style.top = coerceCssPixelValue(-this._previousScrollPosition.top);
            html.classList.add('cdk-global-scrollblock');
            this._isEnabled = true;
        }
    }

    /** Unblocks page-level scroll while the attached overlay is open. */
    disable() {
        if (this._isEnabled) {
            const html = this._document.documentElement!;
            const body = this._document.body!;
            const htmlStyle = html.style;
            const bodyStyle = body.style;
            const previousHtmlScrollBehavior = htmlStyle.scrollBehavior || '';
            const previousBodyScrollBehavior = bodyStyle.scrollBehavior || '';

            this._isEnabled = false;

            bodyStyle.removeProperty('left');
            bodyStyle.removeProperty('top');
            html.classList.remove('cdk-global-scrollblock');

            // Disable user-defined smooth scrolling temporarily while we restore the scroll position.
            // See https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
            // Note that we don't mutate the property if the browser doesn't support `scroll-behavior`,
            // because it can throw off feature detections in `supportsScrollBehavior` which
            // checks for `'scrollBehavior' in documentElement.style`.
            if (scrollBehaviorSupported) {
                htmlStyle.scrollBehavior = bodyStyle.scrollBehavior = 'auto';
            }

            window.scroll(this._previousScrollPosition.left, this._previousScrollPosition.top);

            if (scrollBehaviorSupported) {
                htmlStyle.scrollBehavior = previousHtmlScrollBehavior;
                bodyStyle.scrollBehavior = previousBodyScrollBehavior;
            }
        }
    }

    private _canBeEnabled(): boolean {
        // Since the scroll strategies can't be singletons, we have to use a global CSS class
        // (`cdk-global-scrollblock`) to make sure that we don't try to disable global
        // scrolling multiple times.
        const html = this._document.documentElement!;

        if (html.classList.contains('cdk-global-scrollblock') || this._isEnabled) {
            return false;
        }

        const body = this._document.body;
        const viewport = this._viewportRuler.getViewportSize();
        return body.scrollHeight > viewport.height || body.scrollWidth > viewport.width;
    }
}

/**
 * This service is responsible for instantiating a ModalDialog component and
 * embedding the specified component within.
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
    constructor(private overlay: Overlay, private injector: Injector, private viewportRuler: ViewportRuler) {}

    /**
     * Create a modal from a component. The component must implement the {@link Dialog} interface.
     * Additionally, the component should include templates for the title and the buttons to be
     * displayed in the modal dialog. See example:
     *
     * @example
     * ```
     * class MyDialog implements Dialog {
     *  resolveWith: (result?: any) => void;
     *
     *  okay() {
     *    doSomeWork().subscribe(result => {
     *      this.resolveWith(result);
     *    })
     *  }
     *
     *  cancel() {
     *    this.resolveWith(false);
     *  }
     * }
     * ```
     *
     * ```
     * <ng-template faininDialogTitle>Title of the modal</ng-template>
     *
     * <p>
     *     My Content
     * </p>
     *
     * <ng-template faininDialogButtons>
     *     <button type="button"
     *             class="btn"
     *             (click)="cancel()">Cancel</button>
     *     <button type="button"
     *             class="btn btn-primary"
     *             (click)="okay()">Okay</button>
     * </ng-template>
     * ```
     */
    fromComponent<T extends Dialog<any>, R>(
        component: Type<T> & Type<Dialog<R>>,
        options?: ModalOptions<T>,
    ): Observable<R | undefined> {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
        const scrollStrategy = new CustomBlockScrollStrategy(this.viewportRuler, document);
        const overlayRef = this.overlay.create(
            new OverlayConfig({
                scrollStrategy,
                positionStrategy,
                hasBackdrop: true,
            }),
        );

        const portal = new ComponentPortal(ModalDialogComponent, null, this.createInjector(component, options));
        const modal = overlayRef.attach(portal);
        setTimeout(() => modal.changeDetectorRef.markForCheck());

        const close$ = new Observable<R>(subscriber => {
            modal.instance.closeModal = (result: R) => {
                subscriber.next(result);
                subscriber.complete();
            };
        });
        const backdropClick$ = overlayRef.backdropClick().pipe(mapTo(undefined));

        return race(close$, backdropClick$).pipe(
            take(1),
            finalize(() => overlayRef.dispose()),
        );
    }

    private createInjector<T, R>(component: Type<T> & Type<Dialog<R>>, options?: ModalOptions<T>): PortalInjector {
        const weakMap = new WeakMap<any, any>([
            [DIALOG_COMPONENT, component],
            [MODAL_OPTIONS, options],
        ]);
        return new PortalInjector(this.injector, weakMap);
    }
}
