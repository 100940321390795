export default {
    updateAddress: 'Update Address',
    downloadAppNow: 'Jetzt App downloaden.',
    downloadAppNowNote: 'Mit der App kannst du fainin noch einfacher nutzen.',
    uploadNewProduct: 'Neues Produkt hochladen',
    verifyFree: 'Identität kostenlos verifizieren',
    nothingGoingOnHere: 'Nichts los hier?',
    uploadSomething: 'Lade etwas hoch!',
    toProducts: 'Zu Produkten',
    noNewMsgs: 'Du hast keine neuen Nachrichten',
    noFavsYet: 'Du hast noch keine Favoriten',
    search: 'Zur Suche',
    reachNewMS: 'Erreiche Meilensteine und freue dich auf tolle',
    badgesAndAwards: 'Auszeichnungen & Erfolge',
    stats: {
        title: 'Fortschritt',
        totalCount: 'Zusammen mit fainin hast du bisher <b>{{totalCount}} Bestellungen</b> ausgeführt.',
        totalRevenue: 'Ingesammt has du <b>{{totalRevenue}} Umsatz</b> gemacht.',
        emissionSaved: 'Im Durschnitt wurden dadurch <b>{{emissionSaved}}kg CO2</b> eingespart!',
        totalCountLabel: 'Bestellungen',
        totalRevenueLabel: 'Umsatz',
        emissionSavedLabel: 'CO2 Einsparung (Ø)',
    },
};
